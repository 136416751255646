import currencyMapping from "./currencyMapping";
import countries from "../components/common/countries";

window.selectedSort = function (arr, selectedArr, key) {
   let i = -1;

   for (let j = 0; j < arr.length; j++) {
      if (selectedArr.includes(arr[j][key])) {
         let t = arr[i + 1];
         arr[i + 1] = arr[j];
         arr[j] = t;
         ++i;
      }
   }
};

window.isUndefinedEmpty = (value) => {
   return value === undefined || value === "" || (value instanceof String && value.trim() === "");
};

window.isUndefinedEmptyNaN = (value) => {
   return (
      value === "" ||
      value === undefined ||
      isNaN(parseInt(value)) ||
      (value instanceof String && value.trim() === "")
   );
};

window.isUndefinedEmptyNull = (value) => {
   return (
      value === "" ||
      value === undefined ||
      value === null ||
      (value instanceof String && value.trim() === "")
   );
};

function foreignSystem(x) {
   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function indianSystem(x) {
   x = x.toString();
   let lastThree = x.substring(x.length - 3);
   let otherNumbers = x.substring(0, x.length - 3);
   if (otherNumbers != "") lastThree = "," + lastThree;
   let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
   return res;
}

window.currencySanity = (value, currencyCode) => {
   if (value === undefined || value === null) return value;
   let v1 = value.toString().trim();

   let v2 = parseFloat(v1);
   if (isNaN(v2)) return value;

   let isNegative = v2 < 0;
   let absV2 = isNegative ? -1 * v2 : v2;

   let v3 = absV2.toFixed(2);

   let v3Split = v3.split(".");
   if (currencyCode === "INR") {
      v3Split[0] = indianSystem(v3Split[0]);
   } else {
      v3Split[0] = foreignSystem(v3Split[0]);
   }

   let v4 = v3Split.join(".");

   let v5 = (isNegative ? "- " : "") + currencyMapping(currencyCode) + (isNegative ? "" : " ") + v4;
   return v5;
};
window.currencySymbol = (currencyCode) => {
   return Intl.NumberFormat("en-US", { style: "currency", currency: currencyCode })
      .formatToParts("")
      .find((x) => x.type === "currency").value;
};
window.toBase64 = (file) =>
   new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
   });

window.setReportGlobalFilters = (key, value) => {
   let filters = JSON.parse(sessionStorage.getItem("report_filters"));
   if (!filters) filters = {};
   filters[key] = value;
   sessionStorage.setItem("report_filters", JSON.stringify(filters));
};

window.getReportGlobalFilters = () => JSON.parse(sessionStorage.getItem("report_filters"));

window.sanitizePathname = (pathname) => {
   if (pathname.charAt(pathname.length - 1) === "/")
      return pathname.substring(0, pathname.length - 1);
   else return pathname;
};

window.splitIntoWords = (s) => {
   let output;
   output = s.charAt(0).toUpperCase() + s.substring(1);
   output = output.split(/(?=[A-Z])/).join(" ");
   return output;
};

window.intArrayIntersection = (arr1, arr2) => {
   if (arr1.length !== arr2.length) return false;
   let map = {};
   arr1.map((v, i) => {
      map[v] = i;
   });
   for (let i = 0; i < arr2.length; i++) {
      if (map[arr2[i]] === undefined) return false;
   }
   return true;
};

window.getURLSearchObject = (searchString) => {
   let s = searchString.substring(1);
   let a = s.split("&");
   let output = {};
   a.map((v) => {
      let ar = v.split("=");
      output[ar[0]] = ar[1];
   });
   return output;
};

window.debounce = (call, delay) => {
   let timer;
   return function (...args) {
      // Call debounce into an variable with their args and call it where we want with some delay.
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
         call(...args);
      }, delay);
   };
};

window.roundDecimal = (num) => {
   let value = Number(num);
   return Math.round((value + Number.EPSILON) * 100) / 100;
};

window.getCallingCode = (countryName) => {
   let filteredCountryData = countries?.find((elem) => elem.name == countryName);
   return filteredCountryData.callingCodes;
};

export const superScan = (barcode, products, productGroups, operation,operationType,uniqueAttributesList=[]) => {
  const searchParameter=["barcode","groupBarcode"]
  searchParameter.push(...uniqueAttributesList.map((el) => el.linkedTo?.toString()));
  const inputLower = String(barcode).toLowerCase();

   let getScnnedProducts = new Promise((resolve, reject) => {
      if (!products.length || !barcode.toString().length || !operation) {
         reject({ message: "please provide all arguments" });
      }

      const isBatchedProductAllowed = ["Stock In", "Stock Requisition"].includes(operation);

      let scannedProducts = isBatchedProductAllowed
         ? products.filter((el) => el.barcode == barcode)
         : products.filter((el) => el.barcode == barcode && el.stockItemsType == "UNBATCHED");
      resolve(scannedProducts || []);
   });

   let getScnnedProductGroups = new Promise((resolve, reject) => {
      // let scannedProductGroups = productGroups.filter(
      //    (el) => el.barcode == barcode || el.groupBarcode == barcode
      // );
      let scannedProductGroups =
      productGroups &&
      productGroups.filter((item) => {
         const reqStr = searchParameter.reduce(
            (accumulator, param) => accumulator + item[param],
            ""
         );
         return reqStr.toLowerCase().includes(inputLower);
      });
      resolve(scannedProductGroups || []);
   });

   return Promise.all([getScnnedProducts, getScnnedProductGroups])
      .then((res) => {
         return { message: "Searched products.", results: [...res[0], ...res[1]] };
      })
      .catch((err) => {
         return { message: "something went wrong", results: [] };
      });
};

export const superSearch = (input = "", products = [], productGroups = [], operation = "",operationType="", uniqueAttributesList = []) => {
   console.log(operationType, "operationType-s");
   let productSearchParameter = ["productID", "productName", "barcode", "sku"];
   let productGroupSearchParameter = [
      "productID",
      "productName",
      "barcode",
      "groupBarcode",
      "sku",
      "batchVariantName",
      "batchVariantID",
   ];
   productGroupSearchParameter.push(...uniqueAttributesList.map((el) => el.linkedTo?.toString()));

   const isBatchedProductAllowed = ["Stock In", "Stock Requisition"].includes(operation);
   console.log(isBatchedProductAllowed, "isBatchedProductAllowed");

   const productsList = isBatchedProductAllowed
      ? products
      : products.filter((el) => el.stockItemsType == "UNBATCHED");

   if (isNaN(Number(input)) && input.length > 2) {
      let searchedProducts = new Promise((resolve, reject) => {
         resolve(handleInputChange(input, productSearchParameter, productsList));
      });

      let searchedProductGroups = new Promise((resolve, reject) => {
         resolve(handleInputChange(input, productGroupSearchParameter, productGroups));
      });

      return Promise.all([searchedProducts, searchedProductGroups])
         .then((res) => {
            return { message: "Searched products.", results: [...res[0], ...res[1]] };
         })
         .catch((err) => {
            return { message: "something went wrong", results: [] };
         });
   } else if (!isNaN(Number(input)) && input > 0) {
      let searchedProducts = new Promise((resolve, reject) => {
         resolve(handleInputChange(input, productSearchParameter, productsList));
      });

      let searchedProductGroups = new Promise((resolve, reject) => {
         resolve(handleInputChange(input, productGroupSearchParameter, productGroups));
      });

      return Promise.all([searchedProducts, searchedProductGroups])
         .then((res) => {
            return { message: "Searched products.", results: [...res[0], ...res[1]] };
         })
         .catch((err) => {
            return { message: "something went wrong", results: [] };
         });
   } else {
      return Promise.resolve({
         message: "not searched yet because entered input value not satisfied",
         results: [],
      });
   }
};

// using in super search function
const handleInputChange = (input, searchParameter, data = []) => {
   const inputLower = String(input).toLowerCase();
   console.log(data, "adasdasdasdasd");
   const filteredData =
      data &&
      data.filter((item) => {
         const reqStr = searchParameter.reduce(
            (accumulator, param) => accumulator + item[param],
            ""
         );
         return reqStr.toLowerCase().includes(inputLower);
      });
   return filteredData;
};

// using in super search function
const removeDuplicates = (arr1, arr2) => {
   const combinedArray = arr1.concat(arr2);

   const uniqueObjects = {};

   combinedArray.forEach((obj) => {
      const key = obj.productID + String(obj.batchVariantID || "");
      if (!uniqueObjects[key]) {
         uniqueObjects[key] = obj;
      }
   });
   console.log(uniqueObjects);

   return Object.values(uniqueObjects);
};

export const superCartSearch = (input = "", products = [],uniqueAttributesList=[]) => {
   let searchParameter = [
      "productID",
      "productName",
      "barcode",
      "sku",
      "batchVariantName",
      "batchVariantID",
   ];
   searchParameter.push(...uniqueAttributesList.map((el) => el.linkedTo?.toString()));

   return handleInputChange(input, searchParameter, products);
};

export const superTaxCalculation = ({ product = {}, taxList = [], locationState }) => {
   const { operation, basicInfo } = locationState;
   const { pin, purchaseOrderSummary,vendorBillSummary, selectedVendor, sourceWarehouse, destinationWarehouse } = basicInfo;

   if(!basicInfo.isApplyTaxes){
      product.taxIDs = "";
      product.taxNames = "";
      product.taxPercentages = "";
      product.taxValues = "";
      product.totalTaxValue = 0;
      product.taxableValue=product.displayCostPriceToDisplay;
      product.taxableAmount=product.displayCostPriceToDisplay;
      product.productValue=product.displayCostPriceToDisplay;
      product.productBasePrice = product.displayCostPriceToDisplay;
      product.productActualPrice = product.displayCostPriceToDisplay;
      product.displayProductActualPrice = `${product.displayCostPriceToDisplay}.000`;

      product.totalTaxValuePurchaseReturn = 0;

      if (pin == "PURCHASE-ORDER" || pin == "VENDOR-BILL") {
         product.totalPurchaseReturnPrice =
            Number(product.displayCostPrice) * Number(product.purchaseReturnQty);
         product.itemSalesPurchaseReturn = (
            Number(product.productBasePrice) * Number(product.purchaseReturnQty)
         ).toFixed(4);
         product.taxableValuePurchaseReturn = product.itemSalesPurchaseReturn;
         console.log(basicInfo,product,'basicInfo-12')
      }

      if (operation == "Stock Audit") {
         product.quantity =
            Math.ceil(Number(product.inventory)) -
            Number(product.displayQuantity) * product.conversionFactor;
   
         product.totalVariantPrice2 = product.totalVariantPrice;
   
         product.totalVariantPrice = Math.abs(product.quantity * product.costPrice);
         product.oldQuantity = product.inventory;
         product.newQuantity = product.displayQuantity;
      }
     
      return product;

   }
    

   product.costPrice = (product.displayCostPriceToDisplay / product.conversionFactor).toFixed(1); // resetting cost price so that tax value added only once.
   product.productBasePrice = product.costPrice; 
   product.productActualPrice = product.costPrice; 

   if (typeof product.taxIDs == "number") {
      product.taxIDs = product.taxIDs.toString();
   }

   let taxes = (product.taxIDs && product?.taxIDs?.split(",")) || [];
   let mappedTaxesWithProduct = taxList.filter((element) => taxes.includes(`${element.taxID}`));

   if (pin == "PURCHASE-ORDER" && operation != "Stock Out") {    //
      let source = getSourceObject(locationState, purchaseOrderSummary.sourceID);
      console.log(source, "sourcesourcesource", locationState, purchaseOrderSummary);
      if (
         source?.outletAddCountry == "India" &&
         selectedVendor.country == "India" &&
         source?.outletAddState != selectedVendor.state
      ) {
         mappedTaxesWithProduct = mappedTaxesWithProduct.filter((el) => el.isIGST == 1);
      } else {
         mappedTaxesWithProduct = mappedTaxesWithProduct.filter((el) => el.isIGST != 1);
      }
   }

   else if (pin == "VENDOR-BILL" && operation != "Stock Out") {
      let source = getSourceObject(locationState, vendorBillSummary.sourceID);
      console.log(source, "sourcesourcesource", locationState, vendorBillSummary);
      if (
         source?.outletAddCountry == "India" &&
         selectedVendor.country == "India" &&
         source?.outletAddState != selectedVendor.state
      ) {
         mappedTaxesWithProduct = mappedTaxesWithProduct.filter((el) => el.isIGST == 1);
      } else {
         mappedTaxesWithProduct = mappedTaxesWithProduct.filter((el) => el.isIGST != 1);
      }
   }
   
   else if (
      operation == "Stock Transfer" ||
      (pin == "TRANSACTION-ID" && operation == "Stock In")
   ) {
      let source = getSourceObject(locationState, sourceWarehouse.value);
      let destination = getSourceObject(locationState, destinationWarehouse.value);

      if (
         source.outletAddCountry == "India" &&
         destination.outletAddCountry == "India" &&
         source.outletAddState != destination.outletAddState
      ) {
         mappedTaxesWithProduct = mappedTaxesWithProduct.filter((t) => t.isIGST == 1);
      } else {
         mappedTaxesWithProduct = mappedTaxesWithProduct.filter((t) => t.isIGST != 1);
      }
   } else {
      mappedTaxesWithProduct = mappedTaxesWithProduct.filter((el) => el.isIGST != 1);
   }

   const totalTaxPercentage =
      mappedTaxesWithProduct.reduce((ttl, i) => ttl + i.taxPercentage, 0) || 0;

   const taxPercentagesList = mappedTaxesWithProduct.map((b) => b.taxPercentage);
   let taxValuesList = taxPercentagesList.map((c) =>
      (
         Number(product.displayCostPriceToDisplay) *
         (Number(c) / 100) *
         Number(product.displayQuantity)
      ).toFixed(4)
   );

   let taxValuesListPoReturn = taxPercentagesList.map((c) =>
      (
         Number(product.displayCostPriceToDisplay) *
            (+c / 100) *
            Number(product.purchaseReturnQty) || 1
      ).toFixed(4)
   );

   let taxNames = mappedTaxesWithProduct.map((el) => el.taxName);
   let taxIDS = mappedTaxesWithProduct.map((el) => el.taxID);

   let calculatedTaxAmountOnCostPrice = product.costPrice * (totalTaxPercentage / 100);

   let calculatedTaxAmountOnDisplayCostPrice =
      product.displayCostPriceToDisplay * (totalTaxPercentage / 100);

   if (!product?.priceIncludesTaxes) {
      // && !product.batchVariantID -> this needs to be handled
      //not inclusive case
      product.displayProductActualPrice = (Number(product.displayCostPriceToDisplay)).toFixed(4);
      product.costPrice = (calculatedTaxAmountOnCostPrice + Number(product.costPrice)).toFixed(4);

      product.displayCostPrice = (
         Number(product.displayCostPriceToDisplay) + calculatedTaxAmountOnDisplayCostPrice
      ).toFixed(4);

      product.totalTaxValue =
         calculatedTaxAmountOnDisplayCostPrice * Number(product.displayQuantity);

      product.productValue =
         Number(product.productBasePrice) * Number(product.quantity) + // product.displayCostPriceToDisplay *  product.displayQuantity
         calculatedTaxAmountOnDisplayCostPrice * Number(product.displayQuantity);

      product.taxValues = taxValuesList.join(",");

      if (pin == "PURCHASE-ORDER" || pin == "VENDOR-BILL") {
         product.totalTaxValuePurchaseReturn =
            calculatedTaxAmountOnDisplayCostPrice * Number(product.purchaseReturnQty);

         product.productValuePurchaseReturn =
            Number(product.productBasePrice) * Number(product.purchaseReturnQty) +
            +calculatedTaxAmountOnDisplayCostPrice * Number(product.purchaseReturnQty);

         product.taxValuesPurchaseReturn = taxValuesListPoReturn.join(",");
      }
   } else {
      // inclusive case
      // 100*price / (100+tax)
      let calculatedTaxAmountOnDisplayCostPrice =
         (product.displayCostPriceToDisplay * 100) / (totalTaxPercentage + 100);

      calculatedTaxAmountOnDisplayCostPrice =
         product.displayCostPriceToDisplay - calculatedTaxAmountOnDisplayCostPrice;

      // taxValuesList = taxPercentagesList.map((c) =>
      //    ((Number(product.displayCostPriceToDisplay) * 100) / (Number(c) + 100)).toFixed(4)
      // );

      product.productBasePrice = Number(
         ((100 * product.costPrice) / (100 + totalTaxPercentage)).toFixed(4)   //reverse tax calculation
      );

      product.productActualPrice = product.costPrice;  // inclusive k case mein productBasePrice and productActulaPrice different hoga
      product.displayProductActualPrice = `${product.displayCostPriceToDisplay}.000`;

      product.totalTaxValue = (
         Number((100 * product.displayCostPriceToDisplay) / (100 + totalTaxPercentage)) *
         (totalTaxPercentage / 100) *
         product.displayQuantity
      ).toFixed(4);

      product.productValue = (
         Number(
            ((100 * product.displayCostPriceToDisplay) / (100 + totalTaxPercentage)) *
               Number(product.displayQuantity)
         ) + Number(product.totalTaxValue)
      ).toFixed(4);

      product.taxValues = taxPercentagesList
         .map((c) =>
            (
               (Number(c) / totalTaxPercentage) *
               (Number((100 * product.displayCostPriceToDisplay) / (100 + totalTaxPercentage)) *
                  (totalTaxPercentage / 100)) *
               Number(product.displayQuantity)
            ).toFixed(4)
         )
         .join(",");

      product.totalPrices = Number(product.displayCostPriceToDisplay) * product.displayQuantity;
      product.totalPrice = Number(product.displayCostPriceToDisplay) * product.displayQuantity;

      if (pin == "PURCHASE-ORDER" || pin == "VENDOR-BILL") {
         product.totalTaxValuePurchaseReturn = Number(
            (
               ((100 * Number(product.displayCostPriceToDisplay)) / (100 + totalTaxPercentage)) *
               (totalTaxPercentage / 100) *
               Number(product.purchaseReturnQty)
            ).toFixed(4)
         );

         product.productValuePurchaseReturn = (
            Number(product.productBasePrice) * Number(product.purchaseReturnQty) +
            Number(calculatedTaxAmountOnDisplayCostPrice) * Number(product.purchaseReturnQty)
         ).toFixed(2);

         product.taxValuesPurchaseReturn = taxPercentagesList
            .map((c) =>
               (
                  (Number(c) / totalTaxPercentage) *
                  (Number((100 * product.displayCostPriceToDisplay) / (100 + totalTaxPercentage)) *
                     (totalTaxPercentage / 100)) *
                  Number(product.purchaseReturnQty)
               ).toFixed(4)
            )
            .join(",");
      }
   }

   if (pin == "PURCHASE-ORDER" || pin == "VENDOR-BILL") {
      product.totalPurchaseReturnPrice =
         Number(product.displayCostPrice) * Number(product.purchaseReturnQty);
      product.itemSalesPurchaseReturn = (
         Number(product.productBasePrice) * Number(product.purchaseReturnQty)
      ).toFixed(4);
      product.taxableValuePurchaseReturn = product.itemSalesPurchaseReturn;
   }

   product.itemSales = (Number(product.productBasePrice) * Number(product.quantity)).toFixed(4);
   product.taxableValue = product.itemSales;
   product.taxableAmount = product.itemSales;
   product.taxIDs = taxIDS.join(",");
   product.taxNames = taxNames.join(",");
   product.taxPercentages = taxPercentagesList.join(",");
   product.totalVariantPrice = Number(product.displayCostPrice) * Number(product.displayQuantity);
   product.price = Number(product.displayCostPrice) * Number(product.displayQuantity);

   if (operation == "Stock Audit") {
      product.quantity =
         Math.ceil(Number(product.inventory)) -
         Number(product.displayQuantity) * product.conversionFactor;

      product.totalVariantPrice2 = product.totalVariantPrice;

      product.totalVariantPrice = Math.abs(product.quantity * product.costPrice);
      product.oldQuantity = product.inventory;
      product.newQuantity = product.displayQuantity;
   }
   debugger;
   return product;
};

const getSourceObject = (locationState, sourceID) => {
   const { basicInfo } = locationState;
   const { chainStoreList } = basicInfo;

   let wareList =
      (chainStoreList &&
         chainStoreList.warehousesData?.map((u) => {
            u["storeID"] = u.warehouseID;
            u.outletAddCountry = u.warehouseAddCountry;
            u.outletAddState = u.warehouseAddState;
            return u;
         })) ||
      [];

   let storeWareList = chainStoreList ? [...chainStoreList.data, ...wareList] : [];

   return storeWareList.find((el) => el.storeID == sourceID);
};

export const checkBatchedProductQuantityExceedsRemaining = (product, productList) => {
   const result = {};

   if (
      product &&
      product.stockItemsType == "BATCHED" &&
      (!product.batchVariantID || Number(product.batchVariantID) < 0)
   ) {
      productList.forEach((item) => {
         if (item.stockItemsType == "BATCHED" && item.productID === product.productID) {
            const currentProduct = result[item.productID] || {
               displayQuantity: 0,
               purchaseReturnQty: 0,
               isErrorFound: false,
            };

            const updatedDisplayQuantity =
               currentProduct.displayQuantity + Number(item.displayQuantity || 0);
            const updatedPurchaseReturnQty =
               currentProduct.purchaseReturnQty + Number(item.purchaseReturnQty || 0);

            const isErrorFound =
               updatedDisplayQuantity + updatedPurchaseReturnQty > Number(item.remainingQty);

            result[item.productID] = {
               displayQuantity: updatedDisplayQuantity,
               purchaseReturnQty: updatedPurchaseReturnQty,
               isErrorFound: isErrorFound,
            };
         }
      });
   }
   debugger;
   return result[product.productID]?.isErrorFound || false;
};
