import { defineMessages } from "react-intl";
// import {type Messages} from "./MessageDescriptor"

const messages = {
   home: {},
   catalogue: {
      listOfProductsTitle: {
         id: "app.catalogue.listOfProductsTitle",
         defaultMessage: "List of Products",
         description: "Description of the chain profile is to mentioned here.",
      },
      listOfProductsDescription: {
         id: "app.catalogue.listOfProductsDescription",
         defaultMessage: "List of Products",
         description: "Description of Product is to mentioned here.",
      },
      // Customer Dashboard
      dashboardTitle: {
         id: "app.catalogue.dashboardTitle",
         defaultMessage: "Customers Dashboard",
         description: "Descriptive text for list of Customers",
      },
      dashboardDescription: {
         id: "app.catalogue.dashboardDescription",
         defaultMessage: "Descriptive text for list of Customers",
         description: "Descriptive text for list of Customers",
      },
      dashboardTotalCustomers: {
         id: "app.catalogue.dashboardTotalCustomers",
         defaultMessage: "Total Customers",
         description: "",
      },
      dashboardTotalSales: {
         id: "app.catalogue.dashboardTotalSales",
         defaultMessage: "Total Sales",
         description: "",
      },
      dashboardActiveCustomers: {
         id: "app.catalogue.dashboardActiveCustomers",
         defaultMessage: "Active Customers",
         description: "",
      },
      dashboardInActiveCustomers: {
         id: "app.catalogue.dashboardInActiveCustomers",
         defaultMessage: "Inactive Customers",
         description: "",
      },
      inactiveCustomersDescription: {
         id: "app.catalogue.inactiveCustomersDescription",
         defaultMessage: "List of Inactive Customers",
         description: "",
      },
      loyalitySettings: {
         id: "app.catalogue.loyalitySettings",
         defaultMessage: "Loyality Settings",
         description: "",
      },
      loyalitySettingsDescription: {
         id: "app.catalogue.loyalitySettingsDescription",
         defaultMessage: "Description of the loyalty settings is to mentioned here Need Help?",
         description: "",
      },
      dashboardNewCustomers: {
         id: "app.catalogue.dashboardNewCustomers",
         defaultMessage: "New Customers",
         description: "",
      },
      customerCreditSale: {
         id: "app.catalogue.customerCreditSale",
         defaultMessage: "Customer Credit Sales",
         description: "",
      },
      customerCreditDescription: {
         id: "app.catalogue.customerCreditDescription",
         defaultMessage: "Customer Credit Sales description can be found here",
         description: "",
      },
      creditSettlement: {
         id: "app.catalogue.creditSettlement",
         defaultMessage: "Credit Settlement",
         description: "",
      },
      creditSettlementDescription: {
         id: "app.catalogue.creditSettlementDescription",
         defaultMessage: "Credit Settlement History description can be found here",
         description: "",
      },
      creditNoteHistory: {
         id: "app.catalogue.creditNoteHistory",
         defaultMessage: "Credit Note History",
         description: "",
      },
      creditNoteHistoryDescription: {
         id: "app.catalogue.creditNoteHistoryDescription",
         defaultMessage: "Credit Note History description can be found here",
         description: "",
      },
      messageHistory: {
         id: "app.catalogue.messageHistory",
         defaultMessage: "Message History",
         description: "",
      },
      messageHistoryDescription: {
         id: "app.catalogue.messageHistoryDescription",
         defaultMessage: "Message History description can be found here ",
         description: "",
      },
      smsCreditsPurchase: {
         id: "app.catalogue.smsCreditsPurchase",
         defaultMessage: "SMS Credit Purchase",
         description: "",
      },
      smsCreditsDesc: {
         id: "app.catalogue.smsCreditsDesc",
         defaultMessage: "Description of the SMS Credit is to mentioned here",
         description: "",
      },
      whatsappCredits: {
         id: "app.catalogue.whatsappCredits",
         defaultMessage: "WhatsApp Credit Purchase",
         description: "",
      },
      whatsappCreditsDescription: {
         id: "app.catalogue.whatsappCreditsDescription",
         defaultMessage: "WhatsApp Credit details",
         description: "",
      },
      customersSalesReport: {
         id: "app.catalogue.customersSalesReport",
         defaultMessage: "Customer Sales Report",
         description: "",
      },
      customersSalesReportDescription: {
         id: "app.catalogue.customersSalesReportDescription",
         defaultMessage: "Descriptive text for Customer Sales",
         description: "",
      },

      // List of Customers
      listOfCustomers: {
         id: "app.catalogue.listOfCustomers",
         defaultMessage: "List of Customers",
         description: "",
      },
      addCustomers: {
         id: "app.catalogue.addCustomers",
         defaultMessage: "Add Customers",
         description: "",
      },
      editCustomers: {
         id: "app.catalogue.editCustomers",
         defaultMessage: "Edit Customers",
         description: "",
      },
      bulkCreateCustomers: {
         id: "app.catalogue.bulkCreateCustomers",
         defaultMessage: "Bulk - Create Customers",
         description: "",
      },
      bulkEditCustomers: {
         id: "app.catalogue.bulkEditCustomers",
         defaultMessage: "Bulk - Edit Customers",
         description: "",
      },
      previewCreateCustomers: {
         id: "app.catalogue.previewCreateCustomers",
         defaultMessage: "Preview - Create Customers",
         description: "",
      },
      previewEditCustomers: {
         id: "app.catalogue.previewEditCustomers",
         defaultMessage: "Preview - Edit Customers",
         description: "",
      },
      searchCustomers: {
         id: "app.catalogue.searchCustomers",
         defaultMessage: "Search Customers",
         description: "",
      },
      noCustomers: {
         id: "app.catalogue.noCustomers",
         defaultMessage: "No Customers",
         description: "",
      },
      customerSince: {
         id: "app.catalogue.customerSince",
         defaultMessage: "Customer Since",
      },
      pointsCollected: {
         id: "app.catalogue.pointsCollected",
         defaultMessage: "Points Collected",
      },
      pointsRedeemed: {
         id: "app.catalogue.pointsRedeemed",
         defaultMessage: "Points Redeemed",
      },
      availablePoints: {
         id: "app.catalogue.availablePoints",
         defaultMessage: "Available Points",
      },
      orderCount: {
         id: "app.catalogue.orderCount",
         defaultMessage: "Orders",
      },
      purchase: {
         id: "app.catalogue.purchase",
         defaultMessage: "Purchase",
      },
      revenuePerOrder: {
         id: "app.catalogue.revenuePerOrder",
         defaultMessage: "Purchase/Order",
      },
      discountCount: {
         id: "app.catalogue.discountCount",
         defaultMessage: "Discounted Orders",
      },

      discountCount: {
         id: "app.catalogue.discountCount",
         defaultMessage: "Discounted Orders",
      },
      contactPersonName: {
         id: "app.catalogue.contactPersonName",
         defaultMessage: "Contact Person Name",
      },
      contactPersonPhone: {
         id: "app.catalogue.contactPersonPhone",
         defaultMessage: "Contact Person Phone",
      },
      passportNumber: {
         id: "app.catalogue.passportNumber",
         defaultMessage: "Passport Number",
      },
      passportExpiry: {
         id: "app.catalogue.passportExpiry",
         defaultMessage: "Passport Expiry",
      },
      passportCountryCode: {
         id: "app.catalogue.passportCountryCode",
         defaultMessage: "Passport Country Code",
      },
      lastOrderTime: {
         id: "app.catalogue.lastOrderTime",
         defaultMessage: "Last Order",
      },
      balance: {
         id: "app.catalogue.balance",
         defaultMessage: "Balance",
      },
      enableCRM: {
         id: "app.catalouge.enableCRM",
         defaultMessage: "Enable CRM",
      },
      orderHistory: {
         id: "app.catalogue.orderHistory",
         defaultMessage: "Order History",
      },
      pointLedger: {
         id: "app.catalogue.pointLedger",
         defaultMessage: "Points Ledger",
      },
      viewLedger: {
         id: "app.catalogue.viewLedger",
         defaultMessage: "View Ledger",
      },
      viewHistory: {
         id: "app.catalogue.viewHistory",
         defaultMessage: "View History",
      },
      pointsLedger: {
         id: "app.catalogue.pointsLedger",
         defaultMessage: "Points Ledger",
      },
      transactionSubType: {
         id: "app.catalogue.transactionSubType",
         defaultMessage: "Transaction Sub Type",
      },
      transactionPoints: {
         id: "app.catalogue.transactionPoints",
         defaultMessage: "Transaction Points",
      },

      customerBalance: {
         id: "app.catalogue.customerBalance",
         defaultMessage: "Available Points",
      },
      registrationPoint: {
         id: "app.catalogue.registrationPoint",
         defaultMessage: "Registration Points",
      },

      products: {
         createProductDes: {
            id: "app.catalogue.products.createProductDes",
            defaultMessage: "Create a product and customise by adding modifiers, recipes and more.",
         },
         productRepresentation: {
            id: "app.catalogue.products.productRepresentation",
            defaultMessage: "Product Representation",
         },
         productRepresentationDes: {
            id: "app.catalogue.products.productRepresentationDes",
            defaultMessage:
               "Upload a product picture and/or pick a product color to uniquely identify the product.",
         },
         productColorDes: {
            id: "app.catalogue.products.productColorDes",
            defaultMessage: "This allows you to choose a unique product color.",
         },
         productBasicInfoDes: {
            id: "app.catalogue.products.productBasicInfoDes",
            defaultMessage: "Provide Some Basic Information about the Product.",
         },
         productDescription: {
            id: "app.catalogue.products.productDescription",
            defaultMessage: "Product Description",
         },
         dateTimeInfo: {
            id: "app.catalogue.products.dateTimeInfo",
            defaultMessage: "Please select date before time selection",
         },
         pricingInfo: {
            id: "app.catalogue.products.pricingInfo",
            defaultMessage: "Pricing Information",
         },
         pricingInfoDes: {
            id: "app.catalogue.products.pricingInfoDes",
            defaultMessage: "Add prices, taxes, and other charges applicable on the product.",
         },
         isSellableOnPOSDes: {
            id: "app.catalogue.products.isSellableOnPOSDes",
            defaultMessage: "Allow product to be sellable on POS",
         },
         allowVariablePricingDes: {
            id: "app.catalogue.products.allowVariablePricingDes",
            defaultMessage: "Allow product for variable pricing",
         },
         storeWisePricingCardDes: {
            id: "app.catalogue.products.storeWisePricingCardDes",
            defaultMessage: "Update product pricing & low stock alerts for individual stores.",
         },
         enableLowStockAlertLabel: {
            id: "app.catalogue.products.enableLowStockAlertLabel",
            defaultMessage:
               "Product will not be available for billing if the stock level reaches 0",
         },
         enableLowStockAlertLabelDes: {
            id: "app.catalogue.products.enableLowStockAlertLabelDes",
            defaultMessage:
               "Disable the billing section functionality for products with zero stocks available.",
         },
         selectInventoryMethod: {
            id: "app.catalogue.products.selectInventoryMethod",
            defaultMessage: "Select Inventory Method",
         },
         inventoryReductionMethodDirect: {
            id: "app.catalogue.products.inventoryReductionMethodDirect",
            defaultMessage: "Direct",
         },
         inventoryReductionMethodDirectDes: {
            id: "app.catalogue.products.inventoryReductionMethodDirectDes",
            defaultMessage: "Reduce Inventory Directly",
         },
         inventoryReductionMethodIndirect: {
            id: "app.catalogue.products.inventoryReductionMethodIndirect",
            defaultMessage: "Indirect",
         },
         inventoryReductionMethodIndirectDes: {
            id: "app.catalogue.products.inventoryReductionMethodIndirectDes",
            defaultMessage: "Reduce Inventory Indirectly",
         },

         stockReductionEvent: {
            id: "app.catalogue.products.stockReductionEvent",
            defaultMessage: "Stock Reduction Event",
         },
         stockInOperation: {
            id: "app.catalogue.products.stockInOperation",
            defaultMessage: "Stock In Operation",
         },
         stockInOperationDes: {
            id: "app.catalogue.products.stockInOperationDes",
            defaultMessage: "Reduce Stock during Stock In (Stock Consumption)",
         },
         billing: {
            id: "app.catalogue.products.billing",
            defaultMessage: "Billing",
         },
         billingDesc: {
            id: "app.catalogue.products.billingDesc",
            defaultMessage: "Reduce Stock during Billing (RECIPE)",
         },
         addItemsForInventoryReduction: {
            id: "app.catalogue.products.addItemsForInventoryReduction",
            defaultMessage: "Add Items For Inventory Reduction",
         },
         attachModifier: {
            id: "app.catalogue.products.attachModifier",
            defaultMessage: "Attach Modifier",
         },
         attachModifierDes: {
            id: "app.catalogue.products.attachModifierDes",
            defaultMessage: "Attach modifiers to this product.",
         },
         editProductDes: {
            id: "app.catalogue.products.editProductDes",
            defaultMessage: "Edit and/or customise the product.",
         },
         pleaseUploadImage: {
            id: "app.catalogue.products.pleaseUploadImage",
            defaultMessage: "Please upload image",
         },
         stockItemsType: {
            id: "app.catalogue.products.stockItemsType",
            defaultMessage: "Stock Items Type",
         },
         useExcelForAddingModifierDes: {
            id: "app.catalogue.products.useExcelForAddingModifierDes",
            defaultMessage: "Add modifier using excel file.",
         },
         bulkFirstTimeSetup: {
            id: "app.catalogue.products.bulkFirstTimeSetup",
            defaultMessage: "Bulk - First Time Setup",
         },
         bulkCreateProducts: {
            id: "app.catalogue.products.bulkCreateProducts",
            defaultMessage: "Bulk - Create Products",
         },
         bulkEditProducts: {
            id: "app.catalogue.products.bulkEditProducts",
            defaultMessage: "Bulk - Edit Products",
         },
         previewFirstTimeSetup: {
            id: "app.catalogue.products.previewFirstTimeSetup",
            defaultMessage: "Preview - First Time Setup",
         },
         previewCreateProducts: {
            id: "app.catalogue.products.previewCreateProducts",
            defaultMessage: "Preview - Create Products",
         },
         previewEditProducts: {
            id: "app.catalogue.products.previewEditProducts",
            defaultMessage: "Preview - Edit Products",
         },
         noOfPrints: {
            id: "app.catalogue.products.noOfPrints",
            defaultMessage: "No of Prints",
         },
         selectProductsToPrintBarcode: {
            id: "app.catalogue.products.selectProductsToPrintBarcode",
            defaultMessage: "Select Products to print barcode",
         },

         deviceScreenSize: {
            id: "app.catalogue.products.deviceScreenSize",
            defaultMessage: "Device Screen Size",
         },
         deviceScreenSizeDes: {
            id: "app.catalogue.products.deviceScreenSizeDes",
            defaultMessage: "Which screen size(in) you are using ?",
         },
         selectLabelSize: {
            id: "app.catalogue.products.selectLabelSize",
            defaultMessage: "Select Label Size",
         },
         selectLabelSizeDes: {
            id: "app.catalogue.products.selectLabelSizeDes",
            defaultMessage:
               "Select the size of sticker of your printer roll and choose print attributes. ",
         },
         selectPreferredLabelSize: {
            id: "app.catalogue.products.selectPreferredLabelSize",
            defaultMessage: "Select preferred label size",
         },
         selectPrintAttributes: {
            id: "app.catalogue.products.selectPrintAttributes",
            defaultMessage: "Select Print Attributes",
         },
         barcodeDes: {
            id: "app.catalogue.products.barcodeDes",
            defaultMessage: "Barcode will be printed by default.",
         },
         productNameDes: {
            id: "app.catalogue.products.productNameDes",
            defaultMessage: "Enabling this will print the product name on labe",
         },
         hsnDes: {
            id: "app.catalogue.products.hsnDes",
            defaultMessage: "Enabling this will print HSN / SAC Code on label",
         },
         skuDesc: {
            id: "app.catalogue.products.skuDesc",
            defaultMessage: "Enabling this will print Stock Keeping Unit on label",
         },
         mrpDesc: {
            id: "app.catalogue.products.mrpDesc",
            defaultMessage: "Enabling this will print Maximum retail price on label",
         },
         printBarcodeNote: {
            id: "app.catalogue.products.printBarcodeNote",
            defaultMessage:
               "Note: Please configure your label size in your printer page setup properties. Print area size may vary on your Mac OS. We recommend you to use Windows OS to print barcode labels properly",
         },
         sellingPriceDesc: {
            id: "app.catalogue.products.sellingPriceDesc",
            defaultMessage: "Enabling this will print Selling price on label.",
         },
         customAttribute: {
            id: "app.catalogue.products.customAttribute",
            defaultMessage: "Custom Attribute",
         },
         customAttributeDes: {
            id: "app.catalogue.products.customAttributeDes",
            defaultMessage: "Enabling this will print custom information on label.",
         },
         customeInformation: {
            id: "app.catalogue.products.customeInformation",
            defaultMessage: "Custome Information",
         },
         bulkPrintProductBarcode: {
            id: "app.catalogue.products.bulkPrintProductBarcode",
            defaultMessage: "Bulk - Print Product Barcode",
         },
         printBarcodeExcelNote: {
            id: "app.catalogue.products.printBarcodeExcelNote",
            defaultMessage:
               "Note : On Selecting store , product prices will be fetched as per their respective stores. ",
         },
         clickToDownloadForBarcode: {
            id: "app.catalogue.products.clickToDownloadForBarcode",
            defaultMessage:
               "Click here to download excel template & upload it to print multiple product barcodes",
         },
         inventoryReductionValidation: {
            id: "app.catalogue.products.inventoryReductionValidation",
            defaultMessage: "Items cannot be repeated in added Items for Inventory Reduction",
         },
         recipeQuantityValidation: {
            id: "app.catalogue.products.recipeQuantityValidation",
            defaultMessage: "Recipe Product must have quantity",
         },
         inventoryReductionItemsValidation: {
            id: "app.catalogue.products.inventoryReductionItemsValidation",
            defaultMessage: "Please Add Items For Inventory Reduction",
         },
         attachModifierValidation: {
            id: "app.catalogue.products.attachModifierValidation",
            defaultMessage: "Modifiers cannot be repeated in added modifiers",
         },
         authorizationValidatiom: {
            id: "app.catalogue.products.authorizationValidatiom",
            defaultMessage: "User not authorized to create product with this brand.",
         },
         userAddPriceValiadation: {
            id: "app.catalogue.products.userAddPriceValiadation",
            defaultMessage: "User not authorized to change price.",
         },
         userUpdatePriceValiadation: {
            id: "app.catalogue.products.userUpdatePriceValiadation",
            defaultMessage: "User not authorized to update price.",
         },
         generateBarcodePDF: {
            id: "app.catalogue.products.generateBarcodePDF",
            defaultMessage: "Generate Barcode PDF",
         },
         proceedPenerateBarcodePDFAttributes: {
            id: "app.catalogue.products.proceedPenerateBarcodePDFAttributes",
            defaultMessage: "Proceed to generate barcode PDF with selected attributes",
         },
         generatePDF: {
            id: "app.catalogue.products.generatePDF",
            defaultMessage: "Generate PDF",
         },
      },

      listOfSubCategory: {
         listOfSubCategoryTitle: {
            id: "app.catalogue.listOfSubCategory.listOfSubCategoryTitle",
            defaultMessage: "Sub Category",
         },
         listOfSubCategoryDescription: {
            id: "app.catalogue.listOfSubCategory.listOfSubCategoryDescription",
            defaultMessage: "Organize products under the same category as subcategories.",
         },
         listOfSubCategoryCreateTitle: {
            id: "app.catalogue.listOfSubCategory.listOfSubCategoryCreateTitle",
            defaultMessage: "Create Sub Category",
         },
         listOfSubCategoryEditSubCategory: {
            id: "app.catalogue.listOfSubCategory.listOfSubCategoryEditSubCategory",
            defaultMessage: "Edit Sub Category",
         },
         listOfSubCategoryExportSubCategory: {
            id: "app.catalogue.listOfSubCategory.listOfSubCategoryExportSubCategory",
            defaultMessage: "Export Sub Category",
         },
         Productsinsubcategory: {
            id: "app.catalogue.listOfSubCategory.Productsinsubcategory",
            defaultMessage: "Products in sub-category",
         },
         listOfSubCategoryBulkCreateTitle: {
            id: "app.catalogue.listOfSubCategory.listOfSubCategoryBulkCreateTitle",
            defaultMessage: "Bulk - Create Sub Categories",
         },
         listOfSubCategoryBulkCreatePreviewTitle: {
            id: "app.catalogue.listOfSubCategory.listOfSubCategoryBulkCreatePreviewTitle",
            defaultMessage: "Preview - Create Sub Categories",
         },
         listOfSubCategoryBulkEditTitle: {
            id: "app.catalogue.listOfSubCategory.listOfSubCategoryBulkEditTitle",
            defaultMessage: "Bulk - Edit Sub Categories",
         },
         listOfSubCategoryBulkEditPreviewTitle: {
            id: "app.catalogue.listOfSubCategory.listOfSubCategoryBulkEditPreviewTitle",
            defaultMessage: "Preview - Edit Sub Categories",
         },
         listOfSubCategoryPreviewDescription: {
            id: "app.catalogue.listOfSubCategory.listOfSubCategoryPreviewDescription",
            defaultMessage: "Step 2 of 2",
         },
         listOfSubCategorySelectProductsTitle: {
            id: "app.catalogue.listOfSubCategory.listOfSubCategorySelectProductsTitle",
            defaultMessage: "Select Applicable Products",
         },
      },

      listOfBrands: {
         listOfBrandsTitle: {
            id: "app.catalogue.listOfBrands.listOfBrandsTitle",
            defaultMessage: "Brands",
         },
         listOfBrandsDescription: {
            id: "app.catalogue.listOfBrands.listOfBrandsDescription",
            defaultMessage: "List all the different brands according to the category.",
         },
         listOfBrandsCreateTitle: {
            id: "app.catalogue.listOfBrands.listOfBrandsCreateTitle",
            defaultMessage: "Create Brand",
         },
         listOfBrandsBulkCreateTitle: {
            id: "app.catalogue.listOfBrands.listOfBrandsBulkCreateTitle",
            defaultMessage: "Bulk - Create Brands",
         },
         listOfBrandsBulkCreatePreviewTitle: {
            id: "app.catalogue.listOfBrands.listOfBrandsBulkCreatePreviewTitle",
            defaultMessage: "Preview - Create Brands",
         },
         listOfBrandsBulkEditTitle: {
            id: "app.catalogue.listOfBrands.listOfBrandsBulkEditTitle",
            defaultMessage: "Bulk - Edit Brands",
         },
         listOfBrandsBulkEditPreviewTitle: {
            id: "app.catalogue.listOfBrands.listOfBrandsBulkEditPreviewTitle",
            defaultMessage: "Preview - Edit Brands",
         },
         columnBrandName: {
            id: "app.catalogue.listOfBrands.columnBrandName",
            defaultMessage: "Brand Name",
         },
         columnManufacturer: {
            id: "app.catalogue.listOfBrands.columnManufacturer",
            defaultMessage: "Manufacturer",
         },
         columnSortOrder: {
            id: "app.catalogue.listOfBrands.columnSortOrder",
            defaultMessage: "Sort Sequence",
         },
         viewProds: {
            id: "app.catalogue.listOfBrands.viewProds",
            defaultMessage: "View Products",
         },
         delete: {
            id: "app.catalogue.listOfBrands.delete",
            defaultMessage: "Delete",
         },
         cancelBtn: {
            id: "app.catalogue.listOfBrands.cancelBtn",
            defaultMessage: "Cancel",
         },
         deleteConfirmation: {
            id: "app.catalogue.listOfBrands.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete brand",
         },
         productName: {
            id: "app.catalogue.listOfBrands.productName",
            defaultMessage: "Product Name",
         },
         skus: {
            id: "app.catalogue.listOfBrands.skus",
            defaultMessage: "SKUS",
         },
         brandName: {
            id: "app.catalogue.listOfBrands.brandName",
            defaultMessage: "Brand",
         },
         categoryName: {
            id: "app.catalogue.listOfBrands.categoryName",
            defaultMessage: "Category",
         },
         price: {
            id: "app.catalogue.listOfBrands.price",
            defaultMessage: "M.R.P.",
         },
         prodsInBrand: {
            id: "app.catalogue.listOfBrands.prodsInBrand",
            defaultMessage: "Products in Brand",
         },
         bulkOps: {
            id: "app.catalogue.listOfBrands.bulkOps",
            defaultMessage: "Bulk Operations",
         },
         createBrand: {
            id: "app.catalogue.listOfBrands.createBrand",
            defaultMessage: "Create Brand",
         },
         editBrand: {
            id: "app.catalogue.listOfBrands.editBrand",
            defaultMessage: "Edit Brand",
         },
         exportBrand: {
            id: "app.catalogue.listOfBrands.exportBrand",
            defaultMessage: "Export Brands",
         },
         nextBtn: {
            id: "app.catalogue.listOfBrands.nextBtn",
            defaultMessage: "Next",
         },
         brandInfo: {
            id: "app.catalogue.listOfBrands.brandInfo",
            defaultMessage: "Brand Information",
         },
         uploadImage: {
            id: "app.catalogue.listOfBrands.uploadImage",
            defaultMessage: "Upload Brand Image",
         },
         uploadImageDesc: {
            id: "app.catalogue.listOfBrands.uploadImageDesc",
            defaultMessage: "This image will be displayed in the app and eStore",
         },
         saveBtn: {
            id: "app.catalogue.listOfBrands.saveBtn",
            defaultMessage: "Save",
         },
         authorizedUsers: {
            id: "app.catalogue.listOfBrands.authorizedUsers",
            defaultMessage: "Price Change Authorized Users",
         },
         enableLockPrice: {
            id: "app.catalogue.listOfBrands.enableLockPrice",
            defaultMessage: "Enable Lock Price?",
         },
         uploadExcelDocument: {
            id: "app.catalogue.listOfBrands.uploadExcelDocument",
            defaultMessage: "Upload Excel Document",
         },
      },

      listOfManufacturers: {
         listOfManufacturersTitle: {
            id: "app.catalogue.listOfManufacturers.listOfManufacturersTitle",
            defaultMessage: "Manufacturers",
         },
         listOfManufacturersDescription: {
            id: "app.catalogue.listOfManufacturers.listOfManufacturersDescription",
            defaultMessage:
               "Assign manufacturers to all your products and brands according to your choice.",
         },
         listOfManufacturersCreateTitle: {
            id: "app.catalogue.listOfManufacturers.listOfManufacturersCreateTitle",
            defaultMessage: "Create Manufacturer",
         },
         listOfManufacturersBulkCreateTitle: {
            id: "app.catalogue.listOfManufacturers.listOfManufacturersBulkCreateTitle",
            defaultMessage: "Bulk - Create Manufacturers",
         },
         listOfManufacturersBulkCreatePreviewTitle: {
            id: "app.catalogue.listOfManufacturers.listOfManufacturersBulkCreatePreviewTitle",
            defaultMessage: "Preview - Create Manufacturers",
         },
         listOfManufacturersBulkEditTitle: {
            id: "app.catalogue.listOfManufacturers.listOfManufacturersBulkEditTitle",
            defaultMessage: "Bulk - Edit Manufacturers",
         },
         listOfManufacturersBulkEditPreviewTitle: {
            id: "app.catalogue.listOfManufacturers.listOfManufacturersBulkEditPreviewTitle",
            defaultMessage: "Preview - Edit Manafacturers",
         },
         listOfManufacturersSelectBrandsTitle: {
            id: "app.catalogue.listOfManufacturers.listOfManufacturersSelectBrandsTitle",
            defaultMessage: "Select Applicable Brands",
         },
         createManufacturer: {
            id: "app.catalogue.listOfManufacturers.createManufacturer",
            defaultMessage: "Create Manufacturer",
         },
         editManufacturer: {
            id: "app.catalogue.listOfManufacturers.editManufacturer",
            defaultMessage: "Edit Manufacturer",
         },
         exportManufacturer: {
            id: "app.catalogue.listOfManufacturers.exportManufacturer",
            defaultMessage: "Export Manufacturer",
         },
         deleteConfirmation: {
            id: "app.catalogue.listOfManufacturers.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete Manufacturer -",
         },
         cancelBtn: {
            id: "app.catalogue.listOfManufacturers.cancelBtn",
            defaultMessage: "Cancel",
         },
         delete: {
            id: "app.catalogue.listOfManufacturers.delete",
            defaultMessage: "Delete",
         },
         brandsInManufacturer: {
            id: "app.catalogue.listOfManufacturers.brandsInManufacturer",
            defaultMessage: "Brands in Manufacturer :",
         },
         manufacturerTotalKeyword: {
            id: "app.catalogue.listOfManufacturers.manufacturerTotalKeyword",
            defaultMessage: "Manufacturer(s)",
         },
         columnID: {
            id: "app.catalogue.listOfManufacturers.columnID",
            defaultMessage: "Manufacturer ID",
         },
         columnName: {
            id: "app.catalogue.listOfManufacturers.columnName",
            defaultMessage: "Manufacturer Name",
         },
         manfInfo : {
            id: "app.catalogue.listOfManufacturers.manfInfo",
            defaultMessage: "Manufacturer Information",
         },
         columnBrandOwner: {
            id: "app.catalogue.listOfManufacturers.columnBrandOwner",
            defaultMessage: "Brand Owner Name",
         },
         columnBrand: {
            id: "app.catalogue.listOfManufacturers.columnBrand",
            defaultMessage: "Brand",
         },
         columnDescription: {
            id: "app.catalogue.listOfManufacturers.columnDescription",
            defaultMessage: "Manufacturer Description",
         },
         columnBrandID: {
            id: "app.catalogue.listOfManufacturers.columnBrandID",
            defaultMessage: "Brand ID",
         },
         brandName: {
            id: "app.catalogue.listOfManufacturers.brandName",
            defaultMessage: "Brand Name",
         },
         prodCount: {
            id: "app.catalogue.listOfManufacturers.prodCount",
            defaultMessage: "Number of Items",
         },
         products: {
            id: "app.catalogue.listOfManufacturers.products",
            defaultMessage: "Products",
         },
         viewProds: {
            id: "app.catalogue.listOfManufacturers.viewProds",
            defaultMessage: "View Products",
         },
         productID: {
            id: "app.catalogue.listOfManufacturers.productID",
            defaultMessage: "Product ID",
         },
         productName: {
            id: "app.catalogue.listOfManufacturers.productName",
            defaultMessage: "Product Name",
         },
         barcode: {
            id: "app.catalogue.listOfManufacturers.barcode",
            defaultMessage: "Barcode",
         },
         skus: {
            id: "app.catalogue.listOfManufacturers.skus",
            defaultMessage: "SKU",
         },
         prodInBrand: {
            id: "app.catalogue.listOfManufacturers.prodInBrand",
            defaultMessage: "Product in Brands :",
         },
         viewBrand: {
            id: "app.catalogue.listOfManufacturers.viewBrand",
            defaultMessage: "View Brands",
         },
         multipleManufacturers: {
            id: "app.catalogue.listOfManufacturers.multipleManufacturers",
            defaultMessage: "multiple manufacturers",
         },
         duplicateManufacturers: {
            id: "app.catalogue.listOfManufacturers.duplicateManufacturers",
            defaultMessage: " Duplicate manufacturers",
         }
      },

      listOfIncomeHead: {
         listOfIncomeHeadTitle: {
            id: "app.catalogue.listOfIncomeHead.listOfIncomeHeadTitle",
            defaultMessage: "Income Head",
         },
         listOfIncomeHeadDescription: {
            id: "app.catalogue.listOfIncomeHead.listOfIncomeHeadDescription",
            defaultMessage: "List of all Income Heads.",
         },
         columnIncomeHeadName: {
            id: "app.catalogue.listOfIncomeHead.columnIncomeHeadName",
            defaultMessage: "Income Head",
         },
         columnIncomeHeadProds: {
            id: "app.catalogue.listOfIncomeHead.columnIncomeHeadProds",
            defaultMessage: "Products",
         },
         viewProds: {
            id: "app.catalogue.listOfIncomeHead.viewProds",
            defaultMessage: "View Products",
         },
         columnProductID: {
            id: "app.catalogue.listOfIncomeHead.columnProductID",
            defaultMessage: "Product ID",
         },
         columnProductName: {
            id: "app.catalogue.listOfIncomeHead.columnProductName",
            defaultMessage: "Product",
         },
         columnSkus: {
            id: "app.catalogue.listOfIncomeHead.columnSkus",
            defaultMessage: "SKU",
         },
         columnColor: {
            id: "app.catalogue.listOfIncomeHead.columnColor",
            defaultMessage: "Colour",
         },
         columnBarcodes: {
            id: "app.catalogue.listOfIncomeHead.columnBarcodes",
            defaultMessage: "Barcode",
         },
         columnSoldIn: {
            id: "app.catalogue.listOfIncomeHead.columnSoldIn",
            defaultMessage: "Unit",
         },
         deleteConfirmation: {
            id: "app.catalogue.listOfIncomeHead.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete Income Head :",
         },
         delete: {
            id: "app.catalogue.listOfIncomeHead.delete",
            defaultMessage: "Delete",
         },
         prodsInIncomeHead: {
            id: "app.catalogue.listOfIncomeHead.prodsInIncomeHead",
            defaultMessage: "Products in Income Head:",
         },
         createIncomeHead: {
            id: "app.catalogue.listOfIncomeHead.createIncomeHead",
            defaultMessage: "Create Income Head",
         },
         assignProds: {
            id: "app.catalogue.listOfIncomeHead.assignProds",
            defaultMessage: "Assign Products",
         },
         assignProdsDesc: {
            id: "app.catalogue.listOfIncomeHead.assignProdsDesc",
            defaultMessage: "Assign Products to Income Head",
         },
         addMoreProds: {
            id: "app.catalogue.listOfIncomeHead.addMoreProds",
            defaultMessage: "Add More Products?",
         },
         deleteMsg: {
            id: "app.catalogue.listOfIncomeHead.deleteMsg",
            defaultMessage: "Income head successfully deleted",
         },
      },

      services: {
         servicesTitle: {
            id: "app.catalogue.services.servicesTitle",
            defaultMessage: "Services",
         },
         servicesDescription: {
            id: "app.catalogue.services.servicesDescription",
            defaultMessage: "List of all services",
         },
         createService: {
            id: "app.catalogue.services.createService",
            defaultMessage: "Create Service",
         },
         createServiceDescription: {
            id: "app.catalogue.services.createServiceDescription",
            defaultMessage: "Create service by providing below information",
         },
         serviceInformation: {
            id: "app.catalogue.services.serviceInformation",
            defaultMessage: "Service Information",
         },
         serviceName: {
            id: "app.catalogue.services.serviceName",
            defaultMessage: "Service Name",
         },
         deleteService: {
            id: "app.catalogue.services.deleteService",
            defaultMessage: "Are you sure you want to delete service",
         },
         treatmentType: {
            id: "app.catalogue.services.treatmentType",
            defaultMessage: "Treatment Type",
         },
         availableFor: {
            id: "app.catalogue.services.availableFor",
            defaultMessage: "Available For",
         },
         serviceGroup: {
            id: "app.catalogue.services.serviceGroup",
            defaultMessage: "Service Group",
         },
         extraTime: {
            id: "app.catalogue.services.extraTime",
            defaultMessage: "Extra Time",
         },
         editService: {
            id: "app.catalogue.services.editService",
            defaultMessage: "Edit Service",
         },
         addService: {
            id: "app.catalogue.services.addService",
            defaultMessage: "Add Service",
         },
         bulkcreateService: {
            id: "app.catalogue.services.bulkcreateService",
            defaultMessage: "Bulk - Create Services",
         },
         serviceRepresentation: {
            id: "app.catalogue.services.serviceRepresentation",
            defaultMessage: "Service Representation",
         },
         bulkeditService: {
            id: "app.catalogue.services.bulkeditService",
            defaultMessage: "Bulk - Edit Services",
         },
         editServiceDiscription: {
            id: "app.catalogue.services.editServiceDiscription",
            defaultMessage: "Edit service by providing below information",
         },
         extraTimeDuration: {
            id: "app.catalogue.services.extraTimeDuration",
            defaultMessage: "Extra Time Duration",
         },
         storeCardDescription: {
            id: "app.catalogue.services.storeCardDescription",
            defaultMessage: "Change price for specific store.",
         },

         storeAvailable: {
            id: "app.catalogue.services.storeAvailable",
            defaultMessage: "Store Available",
         },
         serviceNameValidation: {
            id: "app.catalogue.services.serviceNameValidation",
            defaultMessage: "Service Name Is Required..!",
         },
         serviceDurationValidation: {
            id: "app.catalogue.services.serviceDurationValidation",
            defaultMessage: "Duration is Required..!",
         },
         serviceTimeValidation: {
            id: "app.catalogue.services.serviceTimeValidation",
            defaultMessage: "enter valid time..!",
         },
         servicePriceValidation: {
            id: "app.catalogue.services.servicePriceValidation",
            defaultMessage: "Price is Required..!",
         },
         fromDuration:{
            id: "app.catalogue.services.fromDuration",
            defaultMessage: "From Duration"
         },
         toDuration:{
            id: "app.catalogue.services.toDuration",
            defaultMessage: "To Duration"
         },
         durationUnit:{
            id: "app.catalogue.services.durationUnit",
            defaultMessage: "Duration Unit"
         },
         rate:{
            id: "app.catalogue.services.rate",
            defaultMessage: "Rate"
         },
         toDurationFromDurationVal:{
            id: "app.catalogue.services.toDurationFromDurationVal",
            defaultMessage: "To Duration Should be greater than From Duration"
         },
         slotName:{
            id: "app.catalogue.services.slotName",
            defaultMessage: "Slot Name"
         },
         enableDynamicPricingDesc:{
            id: "app.catalogue.services.enableDynamicPricingDesc",
            defaultMessage: "Check this for enabling dynamic pricing"
         },
         rateType:{
            id: "app.catalogue.services.rateType",
            defaultMessage: "Rate Type"
         },
         applicableDays:{
            id: "app.catalogue.services.applicableDays",
            defaultMessage: "Applicable Days"
         },
         slotNamesUniqueError:{
            id: "app.catalogue.services.slotNamesUniqueError",
            defaultMessage: "Slotnames must be unique"
         },
         prevSlotDurationVal:{
            id: "app.catalogue.services.prevSlotDurationVal",
            defaultMessage: "Slots should not collapse for same applicable days."
         }
      },

      serviceGroup: {
         createServiceGroup: {
            id: "app.catalogue.serviceGroup.createServiceGroup",
            defaultMessage: "Create Service Group",
         },
         bulkCreateServiceGroup: {
            id: "app.catalogue.serviceGroup.bulkCreateServiceGroup",
            defaultMessage: "Bulk - Create Service Groups",
         },
         editServiceGroup: {
            id: "app.catalogue.serviceGroup.editServiceGroup",
            defaultMessage: "Edit Service Group",
         },
         bulkEditServiceGroup: {
            id: "app.catalogue.serviceGroup.bulkEditServiceGroup",
            defaultMessage: "Bulk - Edit Service Groups",
         },
         serviceGroupDescription: {
            id: "app.catalogue.serviceGroup.serviceGroupDescription",
            defaultMessage: "List of all service groups",
         },
         viewServices: {
            id: "app.catalogue.serviceGroup.viewServices",
            defaultMessage: "View Services",
         },
         servicesinQA: {
            id: "app.catalogue.serviceGroup.servicesinQA",
            defaultMessage: "Services in",
         },
         deleteServiceQA: {
            id: "app.catalogue.serviceGroup.deleteServiceQA",
            defaultMessage: "Are you sure you want to delete service group?",
         },
         serviceGroupName: {
            id: "app.catalogue.serviceGroup.serviceGroupName",
            defaultMessage: "Service Group Name",
         },
         serviceGroupInformation: {
            id: "app.catalogue.serviceGroup.serviceGroupInformation",
            defaultMessage: "Service Group Information",
         },
         selectApplicableServices: {
            id: "app.catalogue.serviceGroup.selectApplicableServices",
            defaultMessage: "Select Applicable Services",
         },
         bulkDownloadServices: {
            id: "app.catalogue.serviceGroup.bulkDownloadServices",
            defaultMessage:
               "Click here to download excel template & upload it to create multiple service groups",
         },
         bulkDownloadServicesEdit: {
            id: "app.catalogue.serviceGroup.bulkDownloadServicesEdit",
            defaultMessage:
               "Click here to download excel template & upload it to edit multiple service groups",
         },
         serviceDuration: {
            id: "app.catalogue.serviceGroup.serviceDuration",
            defaultMessage: "Service Duration",
         },
         serviceGroupListEmpty: {
            id: "app.catalogue.serviceGroup.serviceGroupListEmpty",
            defaultMessage: "Service group list empty",
         },
         exportServiceGroup: {
            id: "app.catalogue.serviceGroup.exportServiceGroup",
            defaultMessage: "Export Service Group",
         },
         serviceGroupsNameValidation: {
            id: "app.catalogue.serviceGroup.serviceGroupsNameValidation",
            defaultMessage: "Service Group name is required",
         },
         previewCreateServiceGroup: {
            id: "app.catalogue.serviceGroup.previewCreateServiceGroup",
            defaultMessage: "Preview - Create Service Groups",
         },
         previewEditServiceGroup: {
            id: "app.catalogue.serviceGroup.previewEditServiceGroup",
            defaultMessage: "Preview - Edit Service Groups",
         },
      },
      taxes: {
         taxesDescription: {
            id: "app.catalogue.taxes.taxesDescription",
            defaultMessage: "It shows all the list of taxes ",
         },
         createTaxes: {
            id: "app.catalogue.taxes.createTaxes",
            defaultMessage: "Create Taxes",
         },
         taxInformation: {
            id: "app.catalogue.taxes.taxInformation",
            defaultMessage: "Tax Information",
         },
         taxName: {
            id: "app.catalogue.taxes.taxName",
            defaultMessage: "Tax Name",
         },
         taxPercentage: {
            id: "app.catalogue.taxes.taxPercentage",
            defaultMessage: "Tax Percentage",
         },
         parentTax: {
            id: "app.catalogue.taxes.parentTax",
            defaultMessage: "Parent Tax",
         },
         deleteTax: {
            id: "app.catalogue.taxes.deleteTax",
            defaultMessage: "Are you sure you want to delete tax",
         },
         taxListEmpty: {
            id: "app.catalogue.taxes.taxListEmpty",
            defaultMessage: "Tax list empty",
         },
         productInTaxes: {
            id: "app.catalogue.taxes.productInTaxes",
            defaultMessage: "Product in Taxes ",
         },
         taxNameValidation: {
            id: "app.catalogue.taxes.taxNameValidation",
            defaultMessage: "Tax name is required",
         },
         taxPercentageValidation: {
            id: "app.catalogue.taxes.taxPercentageValidation",
            defaultMessage: "Tax percentage is required",
         },
         editTax: {
            id: "app.catalogue.taxes.editTax",
            defaultMessage: "Edit Tax",
         },
         taxNamePlaceholder: {
            id: "app.catalogue.taxes.taxNamePlaceholder",
            defaultMessage: "New Tax",
         },
         nameOnBill: {
            id: "app.catalogue.taxes.nameOnBill",
            defaultMessage: "Name on Bill",
         },
         nameOnBillValidation: {
            id: "app.catalogue.taxes.nameOnBillValidation",
            defaultMessage: "Name on bill is required",
         },
      },
      charges: {
         chargesDescription: {
            id: "app.catalogue.charges.chargesDescription",
            defaultMessage: "It shows all the list of charges ",
         },
         createCharge: {
            id: "app.catalogue.charges.createCharge",
            defaultMessage: "Create Charge",
         },
         chargeInformation: {
            id: "app.catalogue.charges.chargeInformation",
            defaultMessage: "Charge Information",
         },
         chargeName: {
            id: "app.catalogue.charges.chargeName",
            defaultMessage: "Charge Name",
         },
         charegAppliedOn: {
            id: "app.catalogue.charges.charegAppliedOn",
            defaultMessage: "Charge Applied On",
         },
         applyOnOrderDelivery: {
            id: "app.catalogue.charges.applyOnOrderDelivery",
            defaultMessage: "Apply on order delivery?",
         },
         maximumOrderValue: {
            id: "app.catalogue.charges.maximumOrderValue",
            defaultMessage: "Maximum Order Value",
         },
         chargeType: {
            id: "app.catalogue.charges.chargeType",
            defaultMessage: "Charge Type",
         },
         additionalCharges: {
            id: "app.catalogue.charges.additionalCharges",
            defaultMessage: "Order Type",
         },
         chargeValue: {
            id: "app.catalogue.charges.chargeValue",
            defaultMessage: "Charge Value",
         },
         chargesAppliedOn: {
            id: "app.catalogue.charges.chargesAppliedOn",
            defaultMessage: "Charge Applied On",
         },
         deleteCharge: {
            id: "app.catalogue.charges.deleteCharge",
            defaultMessage: "Are you sure you want to delete charge ",
         },
         createChargesDescription: {
            id: "app.catalogue.charges.createChargesDescription",
            defaultMessage: "Create Charge by providing below information.",
         },
         chargeNameValidation: {
            id: "app.catalogue.charges.chargeNameValidation",
            defaultMessage: "Charge name is required",
         },
         maximumOrderValidation: {
            id: "app.catalogue.charges.maximumOrderValidation",
            defaultMessage: "Maximum order value is required",
         },
         chargeTypeValidation: {
            id: "app.catalogue.charges.chargeTypeValidation",
            defaultMessage: "Charge Type is required",
         },
         chargeValueValidation: {
            id: "app.catalogue.charges.chargeValueValidation",
            defaultMessage: "Value is required",
         },
         applyOnlyOnlineOrder: {
            id: "app.catalogue.charges.applyOnlyOnlineOrder",
            defaultMessage: "Apply Only On Online Orders",
         },
         editChargesDescription: {
            id: "app.catalogue.charges.editChargesDescription",
            defaultMessage: "Edit Charge by providing below information.",
         },
         editCharges: {
            id: "app.catalogue.charges.editCharges",
            defaultMessage: "Edit Charge",
         },
         deleteSuccess : {
            id: "app.catalogue.charges.deleteSuccess",
            defaultMessage: "Charge Deleted Successfully.",
         },
         applyChargesForChannelOrders:{
            id:"app.catalogue.charges.applyChargesForChannelOrders",
            defaultMessage:"Applied Charges"
         },
         applyDiscountForChannelOrders:{
            id:"app.catalogue.charges.applyDiscountForChannelOrders",
            defaultMessage:"Applied Discounts"
         },
         applyTaxesForChannelOrders:{
            id:"app.catalogue.charges.applyTaxesForChannelOrders",
            defaultMessage:"Applied Taxes"
         }
      },
      discount: {
         discountTitle: {
            id: "app.catalogue.discount.discountTitle",
            defaultMessage: "Discount",
         },
         discountDescription: {
            id: "app.catalogue.discount.discountDescription",
            defaultMessage: "It shows all the list of discounts",
         },
         createDiscount: {
            id: "app.catalogue.discount.createDiscount",
            defaultMessage: "Create Discount",
         },
      },
      counter: {
         counterID: {
            id: "app.catalogue.counter.counterID",
            defaultMessage: "Counter ID",
         },
         sellOnCounter: {
            id: "app.catalogue.counter.sellOnCounter",
            defaultMessage: "Sell On Counter",
         },
         wantToDelete: {
            id: "app.catalogue.counter.wantToDelete",
            defaultMessage: "Are you sure you want to delete product",
         },
         fromCounter: {
            id: "app.catalogue.counter.fromCounter",
            defaultMessage: "from counter",
         },
         mapProDesc: {
            id: "app.catalogue.counter.mapProDesc",
            defaultMessage: "Map/Unmap products to Counter",
         },
         selectCounter: {
            id: "app.catalogue.counter.selectCounter",
            defaultMessage: "Select Counter",
         },
         selectProductFromTab: {
            id: "app.catalogue.counter.selectProductFromTab",
            defaultMessage: "Please Select Products From Table",
         },
         counterName: {
            id: "app.catalogue.counter.counterName",
            defaultMessage: "Counter Name",
         },
         storeCounterInformation: {
            id: "app.catalogue.counter.storeCounterInformation",
            defaultMessage: "Store/Counter Information",
         },
         previewAssignProductsToCounter: {
            id: "app.catalogue.counter.previewAssignProductsToCounter",
            defaultMessage: "Preview - Assign Products to Counter",
         },
         pleaseSelectStore: {
            id: "app.catalogue.counter.pleaseSelectStore",
            defaultMessage: "Please select store",
         },
         moreThanFiftyStore: {
            id: "app.catalogue.counter.moreThanFiftyStore",
            defaultMessage: "More than 50 stores selected",
         }
      },

      createCounter:{
         counterTitle: {
            id: "app.catalogue.createCounter.counterTitle",
            defaultMessage: "Create Counter",
         },
         counterDesc: {
            id: "app.catalogue.createCounter.counterDesc",
            defaultMessage: "Description",
         },
         counterInfo: {
            id: "app.catalogue.createCounter.counterInfo",
            defaultMessage: "Counter Information",
         },
         counterName: {
            id: "app.catalogue.createCounter.counterName",
            defaultMessage: "Counter Name",
         },
         counterType: {
            id: "app.catalogue.createCounter.counterType",
            defaultMessage: "Counter Type",
         },
         counterCode: {
            id: "app.catalogue.createCounter.counterCode",
            defaultMessage: "Counter Code",
         },
         selectStore: {
            id: "app.catalogue.createCounter.selectStore",
            defaultMessage: "Select Store",
         },
         userList: {
            id: "app.catalogue.createCounter.userList",
            defaultMessage: "User List",
         },
         counterDescriptionField: {
            id: "app.catalogue.createCounter.counterDescriptionField",
            defaultMessage: "Counter Description",
         },
         selectStoreFirst: {
            id: "app.catalogue.createCounter.selectStoreFirst",
            defaultMessage: "Select Store first",
         },
         isAvailable: {
            id: "app.catalogue.createCounter.isAvailable",
            defaultMessage: "Is Available",
         },
         counterNameIsRequired: {
            id: "app.catalogue.createCounter.counterNameIsRequired",
            defaultMessage: "Counter name is required",
         },
         counterCodeIsRequired: {
            id: "app.catalogue.createCounter.counterCodeIsRequired",
            defaultMessage: "Counter code is required",
         },
         counterTypeIsRequired: {
            id: "app.catalogue.createCounter.counterTypeIsRequired",
            defaultMessage: "Counter type is required",
         },
         selectStoreVal: {
            id: "app.catalogue.createCounter.selectStoreVal",
            defaultMessage: "Select store...!",
         },
         selectUserVal: {
            id: "app.catalogue.createCounter.selectUserVal",
            defaultMessage: "Select User...!",
         },
      },
      editCounter:{
         editCounterTitle: {
            id: "app.catalogue.editCounter.editCounterTitle",
            defaultMessage: "Edit Counter",
         },

      },
      inactiveCustomersTableColumns: {
         Sno: {
            id: "app.catalogue.inactiveCustomersTableColumns.Sno",
            defaultMessage: "S.No.",
         },
         firstName: {
            id: "app.catalogue.inactiveCustomersTableColumns.firstName",
            defaultMessage: "Name",
         },
         phone: {
            id: "app.catalogue.inactiveCustomersTableColumns.phone",
            defaultMessage: "Phone Number",
         },
         email: {
            id: "app.catalogue.inactiveCustomersTableColumns.email",
            defaultMessage: "Email",
         },
         addressLine1: {
            id: "app.catalogue.inactiveCustomersTableColumns.addressLine1",
            defaultMessage: "Address",
         },
      },
      loyalitySettingsCols: {
         basicSetting: {
            id: "app.catalogue.loyalitySettingsCols.basicSetting",
            defaultMessage: "Basic Settings",
         },
         loyaltyMode: {
            id: "app.catalogue.loyalitySettingsCols.loyaltyMode",
            defaultMessage: "Loyalty Mode",
         },
         earnedPointsSettings: {
            id: "app.catalogue.loyalitySettingsCols.earnedPointsSettings",
            defaultMessage: "Earned Points Settings",
         },
         loyalityType: {
            id: "app.catalogue.loyalitySettingsCols.loyalityType",
            defaultMessage: "Loyalty Type",
         },
         orderLevel: {
            id: "app.catalogue.loyalitySettingsCols.orderLevel",
            defaultMessage: "Order Level",
         },
         productLevel: {
            id: "app.catalogue.loyalitySettingsCols.productLevel",
            defaultMessage: "Product Level",
         },
         sendSmsOnPointsEarned: {
            id: "app.catalogue.loyalitySettingsCols.sendSmsOnPointsEarned",
            defaultMessage: "Send SMS on points earned",
         },
         sendSmsOnPointsEarnedDesc: {
            id: "app.catalogue.loyalitySettingsCols.sendSmsOnPointsEarnedDesc",
            defaultMessage:
               "This would send a SMS about the loyalty points earned and the points available to the customer",
         },
         purchasePoint: {
            id: "app.catalogue.loyalitySettingsCols.purchasePoint",
            defaultMessage: "Purchase Point",
         },
         purchaseAmount: {
            id: "app.catalogue.loyalitySettingsCols.purchaseAmount",
            defaultMessage: "Purchase Amount",
         },
         minOrderValToEarnPoint: {
            id: "app.catalogue.loyalitySettingsCols.minOrderValToEarnPoint",
            defaultMessage: "Minimum Order Value To Earn Points",
         },
         reedemedPointsSettings: {
            id: "app.catalogue.loyalitySettingsCols.reedemedPointsSettings",
            defaultMessage: "Redeem Points Settings",
         },
         sendSmsOnPointsReedemed: {
            id: "app.catalogue.loyalitySettingsCols.sendSmsOnPointsReedemed",
            defaultMessage: "Send SMS on redeeming points",
         },
         sendSmsOnPointsReedemedDesc: {
            id: "app.catalogue.loyalitySettingsCols.sendSmsOnPointsReedemedDesc",
            defaultMessage:
               "This would send a SMS about the loyalty points redeemed and the points available.",
         },
         sendOtpToReedemPoints: {
            id: "app.catalogue.loyalitySettingsCols.sendOtpToReedemPoints",
            defaultMessage: "Send OTP to redeem points",
         },
         sendOtpToReedemPointsDesc: {
            id: "app.catalogue.loyalitySettingsCols.sendOtpToReedemPointsDesc",
            defaultMessage: "This would send a SMS to redeem the points.",
         },
         reedemAmontLabel: {
            id: "app.catalogue.loyalitySettingsCols.reedemAmontLabel",
            defaultMessage: "Redeem Amount",
         },
         reedemPointLabel: {
            id: "app.catalogue.loyalitySettingsCols.reedemPointLabel",
            defaultMessage: "Redeem Point",
         },
         minOrdrValToRedemPoint: {
            id: "app.catalogue.loyalitySettingsCols.minOrdrValToRedemPoint",
            defaultMessage: "Minimum order value to redeem points",
         },
         maxPointRedemPerOrder: {
            id: "app.catalogue.loyalitySettingsCols.maxPointRedemPerOrder",
            defaultMessage: "Maximum Points Redeemed Per Order",
         },
         maxPercentageRedemPerOrder: {
            id: "app.catalogue.loyalitySettingsCols.maxPercentageRedemPerOrder",
            defaultMessage: "Maximum Percentage Redeemed Per Order",
         },
         expiryPoints: {
            id: "app.catalogue.loyalitySettingsCols.expiryPoints",
            defaultMessage: "Expire points (days)",
         },
         notifyCustomers: {
            id: "app.catalogue.loyalitySettingsCols.notifyCustomers",
            defaultMessage: "Notify Customers (days)",
         },
         minAvailPointForRedempt: {
            id: "app.catalogue.loyalitySettingsCols.minAvailPointForRedempt",
            defaultMessage: "Minimum Available Points For Redemption",
         },
         expriryPointsSetting: {
            id: "app.catalogue.loyalitySettingsCols.expriryPointsSetting",
            defaultMessage: "Expiry Points Settings",
         },
         sendSmsOnOPointExpiry: {
            id: "app.catalogue.loyalitySettingsCols.sendSmsOnOPointExpiry",
            defaultMessage: "Send SMS on points expiry",
         },
         sendSmsOnOPointExpiryDesc: {
            id: "app.catalogue.loyalitySettingsCols.sendSmsOnOPointExpiryDesc",
            defaultMessage: "This would send a SMS on exipiring Points.",
         },
         registrationSetting: {
            id: "app.catalogue.loyalitySettingsCols.registrationSetting",
            defaultMessage: "Registration Settings",
         },
         registrationPoints: {
            id: "app.catalogue.loyalitySettingsCols.registrationPoints",
            defaultMessage: "Registration Points",
         },
         birthAndAnniversarySetting: {
            id: "app.catalogue.loyalitySettingsCols.birthAndAnniversarySetting",
            defaultMessage: "Birthday and Anniversary Settings",
         },
         birthDayPoints: {
            id: "app.catalogue.loyalitySettingsCols.birthDayPoints",
            defaultMessage: "Birthday Points",
         },
         anniversaryPoints: {
            id: "app.catalogue.loyalitySettingsCols.anniversaryPoints",
            defaultMessage: "Anniversary Points",
         },
         desc: {
            id: "app.catalogue.loyalitySettingsCols.desc",
            defaultMessage: "This would send a SMS about the loyalty points earned and the points available to the customer.",
         },
         loyaltyPoints: {
            id: "app.catalogue.loyalitySettingsCols.loyaltyPoints",
            defaultMessage: "Loyalty Points",
         },
         minQtyEarned: {
            id: "app.catalogue.loyalitySettingsCols.minQtyEarned",
            defaultMessage: "Min. Order Qty. Earned",
         },
         minQtyBurned: {
            id: "app.catalogue.loyalitySettingsCols.minQtyBurned",
            defaultMessage: "Min. Order Qty. Burned",
         },
         redemption: {
            id: "app.catalogue.loyalitySettingsCols.redemption",
            defaultMessage: "Redemption",
         },
         payment: {
            id: "app.catalogue.loyalitySettingsCols.payment",
            defaultMessage: "Payment",
         },
         loyalityName:{
            id: "app.catalogue.loyalitySettingsCols.loyalityName",
            defaultMessage:"Loyalty Name"
         },
         loyaltyRedeemType:{
            id: "app.catalogue.loyalitySettingsCols.loyaltyRedeemType",
            defaultMessage:"Loyalty Redeem Type"
         },
         redeemAmountVal:{
            id: "app.catalogue.loyalitySettingsCols.redeemAmountVal",
            defaultMessage:"Redemption percentage must fall within the range of 0 to 100"
         },
         loyaltyAlertOnSave : {
            id: "app.catalogue.loyalitySettingsCols.loyaltyAlertOnSave",
            defaultMessage:"Updating loyalty settings will affect points of existing customers.Are you sure you want to continue?"
         }
      },
      customerCreditSaleTableColumns: {
         Sno: {
            id: "app.catalogue.customerCreditSaleTableColumns.Sno",
            defaultMessage: "S.No.",
         },
         firstName: {
            id: "app.catalogue.customerCreditSaleTableColumns.firstName",
            defaultMessage: "Name",
         },
         phone: {
            id: "app.catalogue.customerCreditSaleTableColumns.phone",
            defaultMessage: "Phone",
         },
         email: {
            id: "app.catalogue.customerCreditSaleTableColumns.email",
            defaultMessage: "Email",
         },
         balance: {
            id: "app.catalogue.customerCreditSaleTableColumns.balance",
            defaultMessage: "Amount Due",
         },
         history: {
            id: "app.catalogue.customerCreditSaleTableColumns.history",
            defaultMessage: "View History",
         },
      },
      creditSettlementTableColumns: {
         Sno: {
            id: "app.catalogue.creditSettlementTableColumns.Sno",
            defaultMessage: "S.No.",
         },
         customerName: {
            id: "app.catalogue.creditSettlementTableColumns.customerName",
            defaultMessage: "Customer Name",
         },
         phone: {
            id: "app.catalogue.creditSettlementTableColumns.phone",
            defaultMessage: "Phone",
         },
         email: {
            id: "app.catalogue.creditSettlementTableColumns.email",
            defaultMessage: "Email",
         },
         redeemStoreID: {
            id: "app.catalogue.creditSettlementTableColumns.redeemStoreID",
            defaultMessage: "Redeemed StoreID",
         },
         redeemStoreName: {
            id: "app.catalogue.creditSettlementTableColumns.redeemStoreName",
            defaultMessage: "Redeemed Store Name",
         },
         transactionType: {
            id: "app.catalogue.creditSettlementTableColumns.transactionType",
            defaultMessage: "Transaction Type",
         },
         amount: {
            id: "app.catalogue.creditSettlementTableColumns.amount",
            defaultMessage: "Amount Paid",
         },
         paymentType: {
            id: "app.catalogue.creditSettlementTableColumns.paymentType",
            defaultMessage: "Payment Type",
         },
         creditPaymentDate: {
            id: "app.catalogue.creditSettlementTableColumns.creditPaymentDate",
            defaultMessage: "Payment Date",
         },
         creditPaymentTime: {
            id: "app.catalogue.creditSettlementTableColumns.creditPaymentTime",
            defaultMessage: "Payment Time",
         },
         username: {
            id: "app.catalogue.creditSettlementTableColumns.username",
            defaultMessage: "User",
         },
         creditTransactionID: {
            id: "app.catalogue.creditSettlementTableColumns.creditTransactionID",
            defaultMessage: "Transaction ID",
         },
      },

      creditNoteHistoryTableColumns: {
         Sno: {
            id: "app.catalogue.creditNoteHistoryTableColumns.Sno",
            defaultMessage: "S.No.",
         },
         storeName: {
            id: "app.catalogue.creditNoteHistoryTableColumns.storeName",
            defaultMessage: "Store",
         },
         channelName: {
            id: "app.catalogue.creditNoteHistoryTableColumns.channelName",
            defaultMessage: "Channel",
         },
         tableNo : {
            id: "app.catalogue.creditNoteHistoryTableColumns.tableNo",
            defaultMessage: "Table Number",
         },
         customerName: {
            id: "app.catalogue.creditNoteHistoryTableColumns.customerName",
            defaultMessage: "Customer Name",
         },
         customerPhone: {
            id: "app.catalogue.creditNoteHistoryTableColumns.customerPhone",
            defaultMessage: "Customer Phone",
         },
         amount: {
            id: "app.catalogue.creditNoteHistoryTableColumns.amount",
            defaultMessage: "Amount",
         },
         orderID: {
            id: "app.catalogue.creditNoteHistoryTableColumns.orderID",
            defaultMessage: "Issued on Order",
         },
         orderDate: {
            id: "app.catalogue.creditNoteHistoryTableColumns.orderDate",
            defaultMessage: "Issue Date",
         },
         orderTime: {
            id: "app.catalogue.creditNoteHistoryTableColumns.orderTime",
            defaultMessage: "Issue Time",
         },
         redeemOrderID: {
            id: "app.catalogue.creditNoteHistoryTableColumns.redeemOrderID",
            defaultMessage: "Redeemed on Order",
         },
         redeemStoreID: {
            id: "app.catalogue.creditNoteHistoryTableColumns.redeemStoreID",
            defaultMessage: "Redeemed StoreID",
         },
         redeemStoreName: {
            id: "app.catalogue.creditNoteHistoryTableColumns.redeemStoreName",
            defaultMessage: "Redeemed Store Name",
         },
         redeemDate: {
            id: "app.catalogue.creditNoteHistoryTableColumns.redeemDate",
            defaultMessage: "Redeem Date",
         },
         redeemTime: {
            id: "app.catalogue.creditNoteHistoryTableColumns.redeemTime",
            defaultMessage: "Redeem Time",
         },
      },

      messageHistoryTableColumns: {
         storeName: {
            id: "app.catalogue.messageHistoryTableColumns.storeName",
            defaultMessage: "Store",
         },
         orderID: {
            id: "app.catalogue.messageHistoryTableColumns.orderID",
            defaultMessage: "Order ID",
         },
         phone: {
            id: "app.catalogue.messageHistoryTableColumns.phone",
            defaultMessage: "Mobile",
         },
         messageType: {
            id: "app.catalogue.messageHistoryTableColumns.messageType",
            defaultMessage: "Message Type",
         },
         message: {
            id: "app.catalogue.messageHistoryTableColumns.message",
            defaultMessage: "Message",
         },
         creditsConsumed: {
            id: "app.catalogue.messageHistoryTableColumns.creditsConsumed",
            defaultMessage: "Credits Used",
         },
      },
      smsCredits: {
         smspurchased: {
            id: "app.catalogue.smsCredits.smspurchased",
            defaultMessage: "SMS Purchased",
         },
         smsconsumed: {
            id: "app.catalogue.smsCredits.smsconsumed",
            defaultMessage: "SMS Consumed",
         },
         smsleft: {
            id: "app.catalogue.smsCredits.smsleft",
            defaultMessage: "SMS Left",
         },
         buysms: {
            id: "app.catalogue.smsCredits.buysms",
            defaultMessage: "Buy SMS",
         },
         purchaseSmsCredits: {
            id: "app.catalogue.smsCredits.purchaseSmsCredits",
            defaultMessage: "Purchase SMS Credits",
         },
         minSmsCredits: {
            id: "app.catalogue.smsCredits.minSmsCredits",
            defaultMessage: "Enter SMS Credits (min 100)",
         },
         typeRequiredCredit: {
            id: "app.catalogue.smsCredits.typeRequiredCredit",
            defaultMessage: "Type required Credits",
         },
         smsRatefirstHalf: {
            id: "app.catalogue.smsCredits.smsRatefirstHalf",
            defaultMessage: "SMS rate :",
         },
         smsRateSecondHalf: {
            id: "app.catalogue.smsCredits.smsRateSecondHalf",
            defaultMessage: " per SMS",
         },
         validQuantityCredit: {
            id: "app.catalogue.smsCredits.validQuantityCredit",
            defaultMessage: "Please enter valid quantity of credits",
         },
         min100QuantityCredit: {
            id: "app.catalogue.smsCredits.validQuantityCredit",
            defaultMessage: "Please enter minimum 100 credits",
         },
         smsSuccessfullyUpadted: {
            id: "app.catalogue.smsCredits.smsSuccessfullyUpadted",
            defaultMessage: "QB Sms Credit successfully updated",
         },
      },
      whatsappCreditsColumns: {
         creditsConsumed: {
            id: "app.catalogue.whatsappCreditsColumns.creditsConsumed",
            defaultMessage: "WhatsApp Credits Purchased",
         },

         creditsPurchased: {
            id: "app.catalogue.whatsappCreditsColumns.creditsPurchased",
            defaultMessage: "WhatsApp Credits Consumed",
         },
         creditsLeft: {
            id: "app.catalogue.whatsappCreditsColumns.creditsLeft",
            defaultMessage: "WhatsApp Credits Left",
         },
      },
      customersSalesTableColumns: {
         customersIDs: {
            id: "app.catalogue.customersSalesTableColumns.customersIDs",
            defaultMessage: "Customer ID",
         },
         customerName: {
            id: "app.catalogue.customersSalesTableColumns.customerName",
            defaultMessage: "Name",
         },
         phone: {
            id: "app.catalogue.customersSalesTableColumns.phone",
            defaultMessage: "Mobile",
         },
         email: {
            id: "app.catalogue.customersSalesTableColumns.email",
            defaultMessage: "Email",
         },
         orderCount: {
            id: "app.catalogue.customersSalesTableColumns.orderCount",
            defaultMessage: "Orders",
         },
         sales: {
            id: "app.catalogue.customersSalesTableColumns.sales",
            defaultMessage: "Sales",
         },
         pointsEarned: {
            id: "app.catalogue.customersSalesTableColumns.pointsEarned",
            defaultMessage: "Points Earned",
         },
         pointsBurned: {
            id: "app.catalogue.customersSalesTableColumns.pointsBurned",
            defaultMessage: "Points Burned",
         },
         createdOnLocal: {
            id: "app.catalogue.customersSalesTableColumns.createdOnLocal",
            defaultMessage: "Customer Since",
         },
      },

      voucher: {
         voucherTitle: {
            id: "app.catalogue.voucher.voucherTitle",
            defaultMessage: "Vouchers",
         },
         voucherDescription: {
            id: "app.catalogue.voucher.voucherDescription",
            defaultMessage: "List of all Vouchers",
         },
         createVoucher: {
            id: "app.catalogue.voucher.createVoucher",
            defaultMessage: "Create Voucher",
         },
         bulkOperations: {
            id: "app.catalogue.voucher.bulkOperations",
            defaultMessage: "Bulk Operations",
         },
         createBulkVoucher: {
            id: "app.catalogue.voucher.createBulkVoucher",
            defaultMessage: "Create Voucher",
         },
         exportBulkVoucher: {
            id: "app.catalogue.voucher.exportBulkVoucher",
            defaultMessage: "Export Voucher",
         },
         deleteConfirmation: {
            id: "app.catalogue.voucher.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete this Voucher?",
         },
         columnVoucherCode: {
            id: "app.catalogue.voucher.columnVoucherCode",
            defaultMessage: "Voucher Code",
         },
         columnValidFrom: {
            id: "app.catalogue.voucher.columnValidFrom",
            defaultMessage: "Valid From",
         },
         columnValidTo: {
            id: "app.catalogue.voucher.columnValidTo",
            defaultMessage: "Valid To",
         },
         columnVoucherType: {
            id: "app.catalogue.voucher.columnVoucherType",
            defaultMessage: "Voucher Type",
         },
         columnAmount: {
            id: "app.catalogue.voucher.columnAmount",
            defaultMessage: "Amount",
         },
         columnMaxVoucherValue: {
            id: "app.catalogue.voucher.columnMaxVoucherValue",
            defaultMessage: "Max. Voucher Value",
         },
         columnAllocated: {
            id: "app.catalogue.voucher.columnAllocated",
            defaultMessage: "Allocated",
         },
         columnAvailable: {
            id: "app.catalogue.voucher.columnAvailable",
            defaultMessage: "Available",
         },
         columnRedeemed: {
            id: "app.catalogue.voucher.columnRedeemed",
            defaultMessage: "Redeemed",
         },
         columnisUsedStatus: {
            id: "app.catalogue.voucher.columnisUsedStatus",
            defaultMessage: "Is Used?",
         },
         columnPhone: {
            id: "app.catalogue.voucher.columnPhone",
            defaultMessage: "Customer",
         },
         columnStoreName: {
            id: "app.catalogue.voucher.columnStoreName",
            defaultMessage: "Store",
         },
         columnDeviceID: {
            id: "app.catalogue.voucher.columnDeviceID",
            defaultMessage: "Device ID",
         },
         columnOrderIDUsedOn: {
            id: "app.catalogue.voucher.columnOrderIDUsedOn",
            defaultMessage: "View Orders",
         },
         columnDescription: {
            id: "app.catalogue.voucher.columnDescription",
            defaultMessage: "Description",
         },
         voucherValue: {
            id: "app.catalogue.voucher.voucherValue",
            defaultMessage: "Voucher Value",
         },
         dateRange: {
            id: "app.catalogue.voucher.dateRange",
            defaultMessage: "Date Range",
         },
         countToDistribute: {
            id: "app.catalogue.voucher.countToDistribute",
            defaultMessage: "Voucher Count To Distribute",
         },
         description: {
            id: "app.catalogue.voucher.description",
            defaultMessage: "Description",
         },
         closeBtn: {
            id: "app.catalogue.voucher.closeBtn",
            defaultMessage: "Close",
         },
         saveBtn: {
            id: "app.catalogue.voucher.saveBtn",
            defaultMessage: "Save",
         },
         nextBtn: {
            id: "app.catalogue.voucher.nextBtn",
            defaultMessage: "Next",
         },
         editVoucher: {
            id: "app.catalogue.voucher.editVoucher",
            defaultMessage: "Edit Voucher",
         },
         stepBulkOps: {
            id: "app.catalogue.voucher.stepBulkOps",
            defaultMessage: "Step 1 of 2",
         },
         step1BulkOps: {
            id: "app.catalogue.voucher.step1BulkOps",
            defaultMessage: "Step 2 of 2",
         },
         downloadTemplate: {
            id: "app.catalogue.voucher.downloadTemplate",
            defaultMessage: "Download Template",
         },
         uploadTemplate: {
            id: "app.catalogue.voucher.uploadTemplate",
            defaultMessage: "Upload Template",
         },
         downloadExcelDocument: {
            id: "app.catalogue.voucher.downloadExcelDocument",
            defaultMessage: "Download Excel Document",
         },
         uploadExcelDocument: {
            id: "app.catalogue.voucher.uploadExcelDocument",
            defaultMessage: "Upload Excel Document",
         },
         dropFiles: {
            id: "app.catalogue.voucher.dropFiles",
            defaultMessage: "Drop files or click to upload",
         },
         donwloadTemlateDesc: {
            id: "app.catalogue.voucher.donwloadTemlateDesc",
            defaultMessage:
               "Click here to download excel template & upload it to create multiple vouchers",
         },
         fileUploaded: {
            id: "app.catalogue.voucher.fileUploaded",
            defaultMessage: "File successfully uploaded",
         },
         errorUpload: {
            id: "app.catalogue.voucher.errorUpload",
            defaultMessage: "Please Upload Valid Excel file!",
         },
         previewCreateVoucher: {
            id: "app.catalogue.voucher.previewCreateVoucher",
            defaultMessage: "Preview - Create Vouchers",
         },
      },
      measurementUnits: {
         unitTitle: {
            id: "app.catalogue.measurementUnits.unitTitle",
            defaultMessage: "Measurement Unit",
         },
         unitDescription: {
            id: "app.catalogue.measurementUnits.unitDescription",
            defaultMessage: "List of all Measurement Units",
         },
         createUnit: {
            id: "app.catalogue.measurementUnits.createUnit",
            defaultMessage: "Create Measurement Unit",
         },
         columnUnitName: {
            id: "app.catalogue.measurementUnits.columnUnitName",
            defaultMessage: "Measurement Unit",
         },
         columnDescription: {
            id: "app.catalogue.measurementUnits.columnDescription",
            defaultMessage: "Description",
         },
         columnConversionFactor: {
            id: "app.catalogue.measurementUnits.columnConversionFactor",
            defaultMessage: "Conversion Factor",
         },
         columnBaseUnit: {
            id: "app.catalogue.measurementUnits.columnBaseUnit",
            defaultMessage: "Base Unit",
         },
         createUnitDesc: {
            id: "app.catalogue.measurementUnits.createUnitDesc",
            defaultMessage: "Create measurement units by provideing necesssary informartion",
         },
         saveBtn: {
            id: "app.catalogue.measurementUnits.saveBtn",
            defaultMessage: "Save",
         },
         unitInfo: {
            id: "app.catalogue.measurementUnits.unitInfo",
            defaultMessage: "Unit Information",
         },
         unitName: {
            id: "app.catalogue.measurementUnits.unitName",
            defaultMessage: "Measurement Unit Name",
         },
         baseUnit: {
            id: "app.catalogue.measurementUnits.baseUnit",
            defaultMessage: "Select Base Unit",
         },
         unitDesc: {
            id: "app.catalogue.measurementUnits.unitDesc",
            defaultMessage: "Description should not be greater than 50 character",
         },
         editUnit: {
            id: "app.catalogue.measurementUnits.editUnit",
            defaultMessage: "Edit Measurement Unit",
         },
         editUnitDesc: {
            id: "app.catalogue.measurementUnits.editUnitDesc",
            defaultMessage: "Edit measurement units by providing necessasy information.",
         },
      },
      assignProdToStore: {
         assignProdToStoreTitle: {
            id: "app.catalgue.assignProdToStore.assignProdToStoreTitle",
            defaultMessage: "Assign Product To Store",
         },
         mapUnmapProd: {
            id: "app.catalgue.assignProdToStore.mapUnmapProd",
            defaultMessage: "Map/Unmap products to store",
         },
         bulkOperations: {
            id: "app.catalogue.assignProdToStore.bulkOperations",
            defaultMessage: "Bulk Operations",
         },
         assignNewProds: {
            id: "app.catalogue.assignProdToStore.assignNewProds",
            defaultMessage: "Assign new products",
         },
         columnProductID: {
            id: "app.catalogue.assignProdToStore.columnProductID",
            defaultMessage: "Product ID",
         },
         columnProductName: {
            id: "app.catalogue.assignProdToStore.columnProductName",
            defaultMessage: "Product Name",
         },
         columnSafeStockLevel: {
            id: "app.catalogue.assignProdToStore.columnSafeStockLevel",
            defaultMessage: "Safe Stock Level",
         },
         columnLowStockValue: {
            id: "app.catalogue.assignProdToStore.columnLowStockValue",
            defaultMessage: "Low Stock Level",
         },
         columnStoreMRP: {
            id: "app.catalogue.assignProdToStore.columnStoreMRP",
            defaultMessage: "M.R.P",
         },
         columnPrice: {
            id: "app.catalogue.assignProdToStore.columnPrice",
            defaultMessage: "Selling Price",
         },
         columnBrandName: {
            id: "app.catalogue.assignProdToStore.columnBrandName",
            defaultMessage: "Brand",
         },
         columnCategoryName: {
            id: "app.catalogue.assignProdToStore.columnCategoryName",
            defaultMessage: "Category",
         },
         editProdDetail: {
            id: "app.catalogue.assignProdToStore.editProdDetail",
            defaultMessage: "Edit product details",
         },
         cancelBtn: {
            id: "app.catalogue.assignProdToStore.cancelBtn",
            defaultMessage: "Cancel",
         },
         saveBtn: {
            id: "app.catalogue.assignProdToStore.saveBtn",
            defaultMessage: "Save",
         },
         deleteBtn: {
            id: "app.catalogue.assignProdToStore.deleteBtn",
            defaultMessage: "Delete",
         },
         nextBtn: {
            id: "app.catalogue.assignProdToStore.nextBtn",
            defaultMessage: "Next",
         },
         selectApplicableProds: {
            id: "app.catalogue.assignProdToStore.selectApplicableProds",
            defaultMessage: "Select applicable products",
         },
         stepBulkOps: {
            id: "app.catalogue.assignProdToStore.stepBulkOps",
            defaultMessage: "Step 1 of 2",
         },
         step1BulkOps: {
            id: "app.catalogue.assignProdToStore.step1BulkOps",
            defaultMessage: "Step 2 of 2",
         },
         brandLabel: {
            id: "app.catalogue.assignProdToStore.brandLabel",
            defaultMessage: "Brand",
         },
         categoryLabel: {
            id: "app.catalogue.assignProdToStore.categoryLabel",
            defaultMessage: "Category",
         },
         previewTitle: {
            id: "app.catalogue.assignProdToStore.previewTitle",
            defaultMessage: "Preview - Assign products to store",
         },
         bulkOps: {
            id: "app.catalogue.assignProdToStore.bulkOps",
            defaultMessage: "Bulk Operation",
         },
         storeSelection: {
            id: "app.catalogue.assignProdToStore.storeSelection",
            defaultMessage: "Please Select Upto 50 Stores",
         },
         stores: {
            id: "app.catalogue.assignProdToStore.stores",
            defaultMessage: "Stores",
         },
         donwloadTemplate: {
            id: "app.catalogue.assignProdToStore.donwloadTemplate",
            defaultMessage: "Download Template",
         },
         uploadTemplate: {
            id: "app.catalogue.assignProdToStore.uploadTemplate",
            defaultMessage: "Upload Template",
         },
         uploadExcelDocument: {
            id: "app.catalogue.assignProdToStore.uploadExcelDocument",
            defaultMessage: "Upload Excel Document",
         },
         clickToUpload: {
            id: "app.catalogue.assignProdToStore.clickToUpload",
            defaultMessage: "Click to upload",
         },
         fileUploaded: {
            id: "app.catalogue.assignProdToStore.fileUploaded",
            defaultMessage: "File successfully uploaded",
         },
         previewBulkOps: {
            id: "app.catalogue.assignProdToStore.previewBulkOps",
            defaultMessage: "Preview - Bulk Operation",
         },
      },
      category: {
         columnCategoryName: {
            id: "app.catalogue.category.columnCategoryName",
            defaultMessage: "Category Name",
         },
         columnCategoryType: {
            id: "app.catalogue.category.columnCategoryType",
            defaultMessage: "Category Type",
         },
         columnSortOrder: {
            id: "app.catalogue.category.columnSortOrder",
            defaultMessage: "Sort Sequence",
         },
         columnViewProds: {
            id: "app.catalogue.category.columnViewProds",
            defaultMessage: "View",
         },
         columnProductID: {
            id: "app.catalogue.category.columnProductID",
            defaultMessage: "Product ID",
         },
         columnProductName: {
            id: "app.catalogue.category.columnProductName",
            defaultMessage: "Product Name",
         },
         columnProductType: {
            id: "app.catalogue.category.columnProductType",
            defaultMessage: "Product Type",
         },
         columnSkus: {
            id: "app.catalogue.category.columnSkus",
            defaultMessage: "SKU",
         },
         columnBrandName: {
            id: "app.catalogue.category.columnBrandName",
            defaultMessage: "Brand",
         },
         columnSoldIn: {
            id: "app.catalogue.category.columnSoldIn",
            defaultMessage: "Unit",
         },
         columnBarcodes: {
            id: "app.catalogue.category.columnBarcodes",
            defaultMessage: "Barcode",
         },
         columnPrice: {
            id: "app.catalogue.category.columnPrice",
            defaultMessage: "M.R.P.",
         },
         viewProds: {
            id: "app.catalogue.category.viewProds",
            defaultMessage: "View Products",
         },
         cancelBtn: {
            id: "app.catalogue.category.cancelBtn",
            defaultMessage: "Cancel",
         },
         deleteBtn: {
            id: "app.catalogue.category.deleteBtn",
            defaultMessage: "Delete",
         },
         nextBtn: {
            id: "app.catalogue.category.nextBtn",
            defaultMessage: "Next",
         },
         saveBtn: {
            id: "app.catalogue.category.saveBtn",
            defaultMessage: "Save",
         },
         deleteConfirmation: {
            id: "app.catalogue.category.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete Category",
         },
         delete: {
            id: "app.catalogue.category.delete",
            defaultMessage: "Delete",
         },
         prodInCategory: {
            id: "app.catalogue.category.prodInCategory",
            defaultMessage: "Products in Category",
         },
         categoryTitle: {
            id: "app.catalogue.category.categoryTitle",
            defaultMessage: "Category",
         },
         categoryDesc: {
            id: "app.catalogue.category.categoryDesc",
            defaultMessage: "Categorize all your products according to your choice",
         },
         bulkOps: {
            id: "app.catalogue.category.bulkOps",
            defaultMessage: "Bulk Operations",
         },
         createCategory: {
            id: "app.catalogue.category.createCategory",
            defaultMessage: "Create Category",
         },
         editCategory: {
            id: "app.catalogue.category.editCategory",
            defaultMessage: "Edit Category",
         },
         exportCategory: {
            id: "app.catalogue.category.exportCategory",
            defaultMessage: "Export Category",
         },
         categoryInfo: {
            id: "app.catalogue.category.categoryInfo",
            defaultMessage: "Category Information",
         },
         categoryDescription: {
            id: "app.catalogue.category.categoryDescription",
            defaultMessage: "Category Description",
         },
         stepBulkOps: {
            id: "app.catalogue.category.stepBulkOps",
            defaultMessage: "Step 1 of 2",
         },
         step1BulkOps: {
            id: "app.catalogue.category.step1BulkOps",
            defaultMessage: "Step 2 of 2",
         },
         uploadCategoryImage: {
            id: "app.catalogue.category.uploadCategoryImage",
            defaultMessage: "Upload Category Image",
         },
         uploadImageDesc: {
            id: "app.catalogue.category.uploadImageDesc",
            defaultMessage: "This image will be displayed in the app and eStore",
         },
         selectApplicableProds: {
            id: "app.catalogue.category.selectApplicableProds",
            defaultMessage: "Select Applicable Products",
         },
      },
      subCategory: {
         columnSoldIn: {
            id: "app.catalogue.subCategory.columnSoldIn",
            defaultMessage: "Unit",
         },
         columnBarcodes: {
            id: "app.catalogue.subCategory.columnBarcodes",
            defaultMessage: "Barcode",
         },
         delete: {
            id: "app.catalogue.subCategory.delete",
            defaultMessage: "Delete",
         },
         deleteConfirmation: {
            id: "app.catalogue.subCategory.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete sub category",
         },
         cancelBtn: {
            id: "app.catalogue.subCategory.cancelBtn",
            defaultMessage: "Cancel",
         },
         columnProductID: {
            id: "app.catalogue.subCategory.columnProductID",
            defaultMessage: "Product ID",
         },
         columnProductName: {
            id: "app.catalogue.subCategory.columnProductName",
            defaultMessage: "Product Name",
         },
         columnBrandName: {
            id: "app.catalogue.subCategory.columnBrandName",
            defaultMessage: "Brand",
         },
         columnCategoryName: {
            id: "app.catalogue.subCategory.columnCategoryName",
            defaultMessage: "Category",
         },
         columnSkus: {
            id: "app.catalogue.subCategory.columnSkus",
            defaultMessage: "SKUS",
         },
         columnPrice: {
            id: "app.catalogue.subCategory.columnPrice",
            defaultMessage: "M.R.P.",
         },
         nextBtn: {
            id: "app.catalogue.subCategory.nextBtn",
            defaultMessage: "Next",
         },
         subCategoryInfo: {
            id: "app.catalogue.subCategory.subCategoryInfo",
            defaultMessage: "Sub Category Information",
         },
         subCategoryName: {
            id: "app.catalogue.subCategory.subCategoryName",
            defaultMessage: "Sub Category Name",
         },
         selectCategory: {
            id: "app.catalogue.subCategory.selectCategory",
            defaultMessage: "Select Category",
         },
         sortOrder: {
            id: "app.catalogue.subCategory.sortOrder",
            defaultMessage: "Sort Sequence",
         },
         subCategoryDescription: {
            id: "app.catalogue.subCategory.subCategoryDescription",
            defaultMessage: "Sub Category Description",
         },
         saveBtn: {
            id: "app.catalogue.subCategory.saveBtn",
            defaultMessage: "Save",
         },
         stepBulkOps: {
            id: "app.catalogue.subCategory.stepBulkOps",
            defaultMessage: "Step 1 of 2",
         },
         step1BulkOps: {
            id: "app.catalogue.subCategory.step1BulkOps",
            defaultMessage: "Step 2 of 2",
         },
         editSubCategory: {
            id: "app.catalogue.subCategory.editSubCategory",
            defaultMessage: "Edit Sub Category",
         },
         donwloadExcelDocument: {
            id: "app.catalogue.subCategory.donwloadExcelDocument",
            defaultMessage: "Download Excel Document",
         },
         uploadExcelDocument: {
            id: "app.catalogue.subCategory.uploadExcelDocument",
            defaultMessage: "Upload Excel Document",
         },
         donwloadDesc1: {
            id: "app.catalogue.subCategory.donwloadDesc1",
            defaultMessage: "Click here to download excel template & upload it to",
         },
         downloadDesc2: {
            id: "app.catalogue.subCategory.downloadDesc2",
            defaultMessage: "multiple sub categories",
         },
         update: {
            id: "app.catalogue.subCategory.update",
            defaultMessage: "update",
         },
         create: {
            id: "app.catalogue.subCategory.create",
            defaultMessage: "create",
         },
         uploadTemplate: {
            id: "app.catalogue.subCategory.uploadTemplate",
            defaultMessage: "Upload Template",
         },
         downloadTemplate: {
            id: "app.catalogue.subCategory.downloadTemplate",
            defaultMessage: "Download Template",
         },
         dropClick: {
            id: "app.catalogue.subCategory.dropClick",
            defaultMessage: "Drop files or click to upload",
         },
         fileUploaded: {
            id: "app.catalogue.subCategory.fileUploaded",
            defaultMessage: "File Uploaded Successfully",
         },
         validExcelFile: {
            id: "app.catalogue.subCategory.validExcelFile",
            defaultMessage: "Please upload a valid excel file!",
         },
         columnDescription: {
            id: "app.catalogue.subCategory.columnDescription",
            defaultMessage: "Description",
         },
      },
      modifierGroups: {
         columnModifierGroupName: {
            id: "app.catalogue.modifierGroups.columnModifierGroupName",
            defaultMessage: "Modifier Group",
         },
         columnMinSelectable: {
            id: "app.catalogue.modifierGroups.columnMinSelectable",
            defaultMessage: "Min Selectable",
         },
         columnMaxSelectable: {
            id: "app.catalogue.modifierGroups.columnMaxSelectable",
            defaultMessage: "Max Selectable",
         },
         columnSortOrder: {
            id: "app.catalogue.modifierGroups.columnSortOrder",
            defaultMessage: "Sort Sequence",
         },
         columnTags: {
            id: "app.catalogue.modifierGroups.columnTags",
            defaultMessage: "Tags",
         },
         columnModifierName: {
            id: "app.catalogue.modifierGroups.columnModifierName",
            defaultMessage: "Modifier",
         },
         columnModifierPrice: {
            id: "app.catalogue.modifierGroups.columnModifierPrice",
            defaultMessage: "Modifier Price",
         },
         columnModifierType: {
            id: "app.catalogue.modifierGroups.columnModifierType",
            defaultMessage: "Type",
         },
         columnFoodType: {
            id: "app.catalogue.modifierGroups.columnFoodType",
            defaultMessage: "Food Type",
         },
         columnDescription: {
            id: "app.catalogue.modifierGroups.columnDescription",
            defaultMessage: "Description",
         },
         modifiersInModifierGroup: {
            id: "app.catalogue.modifierGroups.modifiersInModifierGroup",
            defaultMessage: "Modifiers in Modifiers Group:",
         },
         listOfModifierGroups: {
            id: "app.catalogue.modifierGroups.listOfModifierGroups",
            defaultMessage: "List of all Modifier Groups",
         },
         modifierGroupTitle: {
            id: "app.catalogue.modifierGroups.modifierGroupTitle",
            defaultMessage: "Modifier Groups",
         },
         createModifierGroup: {
            id: "app.catalogue.modifierGroups.createModifierGroup",
            defaultMessage: "Create Modifier Group",
         },
         deleteConfirmation: {
            id: "app.catalogue.modifierGroups.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete Modifier Group",
         },
         assignModifiers: {
            id: "app.catalogue.modifierGroups.assignModifiers",
            defaultMessage: "Save and Assign Modifiers",
         },
         modifierGroupName: {
            id: "app.catalogue.modifierGroups.modifierGroupName",
            defaultMessage: "Modifier Group Name",
         },
         editModifierGroup: {
            id: "app.catalogue.modifierGroups.editModifierGroup",
            defaultMessage: "Edit Modifier Group",
         },
         deleteConfirmation: {
            id: "app.catalogue.modifierGroups.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete Modifier Group ",
         },
      },
      membership: {
         columnMembership: {
            id: "app.catalogue.membership.columnMembership",
            defaultMessage: "Membership",
         },
         columnMembershipPrice: {
            id: "app.catalogue.membership.columnMembershipPrice",
            defaultMessage: "Price",
         },
         columnMembershipValidity: {
            id: "app.catalogue.membership.columnMembershipValidity",
            defaultMessage: "Validity",
         },
         columnMembershipCategory: {
            id: "app.catalogue.membership.columnMembershipCategory",
            defaultMessage: "Category",
         },
         columnMembershipSubCategory: {
            id: "app.catalogue.membership.columnMembershipSubCategory",
            defaultMessage: "Sub Category",
         },
         columnProducts: {
            id: "app.catalogue.membership.columnProducts",
            defaultMessage: "Products",
         },
         columnStorePrice: {
            id: "app.catalogue.membership.columnStorePrice",
            defaultMessage: "Store Price",
         },
         viewProds: {
            id: "app.catalogue.membership.viewProds",
            defaultMessage: "View Products",
         },
         viewPrice: {
            id: "app.catalogue.membership.viewPrice",
            defaultMessage: "View Price",
         },
         deleteConfirmation: {
            id: "app.catalogue.membership.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete Membership",
         },
         stores: {
            id: "app.catalogue.membership.stores",
            defaultMessage: "Stores",
         },
         products: {
            id: "app.catalogue.membership.products",
            defaultMessage: "Products",
         },
         listOfMemberships: {
            id: "app.catalogue.membership.listOfMemberships",
            defaultMessage: "List of all Memberships",
         },
         createMembership: {
            id: "app.catalogue.membership.createMembership",
            defaultMessage: "Create Membership",
         },
         editMembership: {
            id: "app.catalogue.membership.editMembership",
            defaultMessage: "Edit Membership",
         },
         storeID: {
            id: "app.catalogue.membership.storeID",
            defaultMessage: "Store ID",
         },
         storeName: {
            id: "app.catalogue.membership.storeName",
            defaultMessage: "Store Name",
         },
         barcode: {
            id: "app.catalogue.membership.barcode",
            defaultMessage: "Barcode",
         },
         priceIncludesTaxes: {
            id: "app.catalogue.membership.priceIncludesTaxes",
            defaultMessage: "Is Price Inclusive of Taxes?",
         },
         validity: {
            id: "app.catalogue.membership.validity",
            defaultMessage: "Validity (In Days)",
         },
         category: {
            id: "app.catalogue.membership.category",
            defaultMessage: "Category",
         },
         subCategory: {
            id: "app.catalogue.membership.subCategory",
            defaultMessage: "Sub-Category",
         },
         taxes: {
            id: "app.catalogue.membership.taxes",
            defaultMessage: "Taxes",
         },
         charges: {
            id: "app.catalogue.membership.charges",
            defaultMessage: "Charges",
         },
         hsnOrSac: {
            id: "app.catalogue.membership.hsnOrSac",
            defaultMessage: "HSN or SAC",
         },
         discountType: {
            id: "app.catalogue.membership.discountType",
            defaultMessage: "Discount Type",
         },
         discount: {
            id: "app.catalogue.membership.discount",
            defaultMessage: "Discount",
         },
         quantity: {
            id: "app.catalogue.membership.quantity",
            defaultMessage: "Quantity",
         },
         storeWisePricing: {
            id: "app.catalogue.membership.storeWisePricing",
            defaultMessage: "Store Wise Pricing",
         },
         pricingDesc: {
            id: "app.catalogue.membership.pricingDesc",
            defaultMessage: "Manage Store Wise Pricing.",
         },
         columnProductName: {
            id: "app.catalogue.membership.columnProductName",
            defaultMessage: "Product Name",
         },
         columnBrand: {
            id: "app.catalogue.membership.columnBrand",
            defaultMessage: "Brand",
         },
         columnCategory: {
            id: "app.catalogue.membership.columnCategory",
            defaultMessage: "Category",
         },
         columnUnit: {
            id: "app.catalogue.membership.columnUnit",
            defaultMessage: "Unit",
         },
         columnCostPrice: {
            id: "app.catalogue.membership.columnCostPrice",
            defaultMessage: "Cost Price",
         },
         productList: {
            id: "app.catalogue.membership.productList",
            defaultMessage: "Products List",
         },
         listOfProds: {
            id: "app.catalogue.membership.listOfProds",
            defaultMessage: "List of all Products",
         },
         bulkEdit: {
            id: "app.catalogue.membership.bulkEdit",
            defaultMessage: "Bulk Edit Products (Excel)",
         },
         bulkAdd: {
            id: "app.catalogue.membership.bulkAdd",
            defaultMessage: "Bulk Add Products (Excel)",
         },
         assignProds: {
            id: "app.catalogue.membership.assignProds",
            defaultMessage: "Please Assign Some Products.",
         },
         taxes: {
            id: "app.catalogue.membership.taxes",
            defaultMessage: "Taxes",
         },
         charges: {
            id: "app.catalogue.membership.charges",
            defaultMessage: "Charges",
         },
         noneSelected: {
            id: "app.catalogue.membership.noneSelected",
            defaultMessage: "None Selected",
         },
         multiple: {
            id: "app.catalogue.membership.multiple",
            defaultMessage: "Multiple",
         },
         prodsAdded: {
            id: "app.catalogue.membership.prodsAdded",
            defaultMessage: "Product(s) Added",
         },
         downloadDesc: {
            id: "app.catalogue.membership.downloadDesc",
            defaultMessage:
               "Click here to download excel template & upload it to create or edit multiple membership",
         },
         autoRenewMembership: {
            id: "app.catalogue.membership.autoRenewMembership",
            defaultMessage: "Auto Renew Membership?",
         },
         membershipRenewalPeriod: {
            id: "app.catalogue.membership.membershipRenewalPeriod",
            defaultMessage: "Membership Renewal Period (In Days)",
         },
         membershipRenewalExpiryPeriod: {
            id: "app.catalogue.membership.membershipRenewalExpiryPeriod",
            defaultMessage: "Membership Renewal Expiry Period (In Days)",
         },
      },
      promotions: {
         promotionDescription: {
            id: "app.catalogue.promotions.promotionDescription",
            defaultMessage: "List of all Promotions.",
         },
         createPromotion: {
            id: "app.catalogue.promotions.createPromotion",
            defaultMessage: "Create Promotion",
         },
         createPromotionDes: {
            id: "app.catalogue.promotions.createPromotionDes",
            defaultMessage: "Please enter the details",
         },
         discountName: {
            id: "app.catalogue.promotions.discountName",
            defaultMessage: "Discount Name",
         },
         promotionIsAutoApplied: {
            id: "app.catalogue.promotions.promotionIsAutoApplied",
            defaultMessage: "Is auto applied?",
         },
         minCartValue: {
            id: "app.catalogue.promotions.minCartValue",
            defaultMessage: "Min Cart Value",
         },
         maxDiscountValue: {
            id: "app.catalogue.promotions.maxDiscountValue",
            defaultMessage: "Max Discount Value",
         },
         discountOnMRP: {
            id: "app.catalogue.promotions.discountOnMRP",
            defaultMessage: "Discount on MRP",
         },
         promotionProductsDes: {
            id: "app.catalogue.promotions.promotionProductsDes",
            defaultMessage:
               "Define the basic information about this product's inventory management.",
         },
         couponDetails: {
            id: "app.catalogue.promotions.couponDetails",
            defaultMessage: "Coupon Details",
         },
         promotionsSlots: {
            id: "app.catalogue.promotions.promotionsSlots",
            defaultMessage: "Promotions Slots",
         },
         promotionsSlotsDesc: {
            id: "app.catalogue.promotions.promotionsSlotsDesc",
            defaultMessage: "Enable this to create Promotion Slots.",
         },
         slotStartTime: {
            id: "app.catalogue.promotions.slotStartTime",
            defaultMessage: "Slot Start time",
         },
         SlotEndTime: {
            id: "app.catalogue.promotions.SlotEndTime",
            defaultMessage: "Slot End time",
         },
         editPromotion: {
            id: "app.catalogue.promotions.editPromotion",
            defaultMessage: "Edit Promotion",
         },
         editCouponDetails: {
            id: "app.catalogue.promotions.editCouponDetails",
            defaultMessage: "Enable this to update and add coupons.",
         },
         editpromotionsSlotsDesc: {
            id: "app.catalogue.promotions.editpromotionsSlotsDesc",
            defaultMessage: "Enable this to edit Promotion Slots.",
         },
         deletePromotions: {
            id: "app.catalogue.promotions.deletePromotions",
            defaultMessage: "Are you sure you want to delete discount",
         },

         discountNameValidation: {
            id: "app.catalogue.promotions.discountNameValidation",
            defaultMessage: "Please enter discount name.",
         },
         discountValueValidation: {
            id: "app.catalogue.promotions.discountValueValidation",
            defaultMessage: "Please enter discount value.",
         },
         cartValueValidation: {
            id: "app.catalogue.promotions.cartValueValidation",
            defaultMessage: "Please enter valid minimum cart value.",
         },
         minTargetProductQuantity: {
            id: "app.catalogue.promotions.minTargetProductQuantity",
            defaultMessage: "Min Target Product Quantity",
         },
         selectTargetProducts: {
            id: "app.catalogue.promotions.selectTargetProducts",
            defaultMessage: "Select Target Products",
         },
         couponDetailsDes: {
            id: "app.catalogue.promotions.couponDetailsDes",
            defaultMessage: "Enable this to create coupons",
         },
         couponNameValidation: {
            id: "app.catalogue.promotions.couponNameValidation",
            defaultMessage: "Please enter a valid coupon name.",
         },
         couponAllocationValidation: {
            id: "app.catalogue.promotions.couponAllocationValidation",
            defaultMessage: "Please enter a valid coupon allocation value.",
         },
         baseProductValidation: {
            id: "app.catalogue.promotions.baseProductValidation",
            defaultMessage: "Please select base products.",
         },
         targetProductValidation: {
            id: "app.catalogue.promotions.targetProductValidation",
            defaultMessage: "Please select target products.",
         },
         baseAndTargetValidation: {
            id: "app.catalogue.promotions.baseAndTargetValidation",
            defaultMessage: "Please select base products & target products.",
         },
         sameDayValidation: {
            id: "app.catalogue.promotions.sameDayValidation",
            defaultMessage: "Please don't select same day twice",
         },
         minBaseProductQuantity: {
            id: "app.catalogue.promotions.minBaseProductQuantity",
            defaultMessage: "Min Base Products Quantity",
         },
         minCartQuantity: {
            id: "app.catalogue.promotions.minCartQuantity",
            defaultMessage: "Min Cart Quantity",
         },
         conditionalDiscountInfo: {
            id: "app.catalogue.promotions.conditionalDiscountInfo",
            defaultMessage: "Conditional Discount Information",
         },
         previewEditPromotionsTitle: {
            id: "app.catalogue.promotions.previewEditPromotionsTitle",
            defaultMessage: "Edit Promotions Preview",
         },
         previewCreatePromotionsTitle: {
            id: "app.catalogue.promotions.previewCreatePromotionsTitle",
            defaultMessage: "Create Promotions Preview",
         },
         previewEditPromotionsDes: {
            id: "app.catalogue.promotions.previewEditPromotionsDes",
            defaultMessage: "Edit Promotions Preview",
         },
         previewCreatePromotionsDes: {
            id: "app.catalogue.promotions.previewCreatePromotionsTitle",
            defaultMessage: "Create Promotions Preview",
         },
         deleteProductPopUp: {
            id: "app.catalogue.promotions.deleteProductPopUp",
            defaultMessage: " Are you sure you want to delete the Product:",
         },
         bulkEditBaseProds:{
            id: "app.catalogue.promotions.bulkEditBaseProds",
            defaultMessage: "Bulk - Edit Base Products",
         },
         bulkEditTargetProds:{
            id: "app.catalogue.promotions.bulkEditTargetProds",
            defaultMessage: "Bulk - Edit Target Products",
         },
         bulkCreateBaseProds:{
            id: "app.catalogue.promotions.bulkCreateBaseProds",
            defaultMessage: "Bulk - Create Base Products",
         },
         bulkCreateTargetProds:{
            id: "app.catalogue.promotions.bulkCreateTargetProds",
            defaultMessage: "Bulk - Create Target Products",
         },
         discountDeleted : {
            id: "app.catalogue.promotions.discountDeleted",
            defaultMessage: "Discount Successfully Deleted.",
         },
         discountCreated : {
            id: "app.catalogue.promotions.discountCreated",
            defaultMessage: "Discount Successfully Created.",
         },
         discountUpdated : {
            id: "app.catalogue.promotions.discountUpdated",
            defaultMessage: "Discount Successfully Updated.",
         },
         discountDeletedErr : {
            id: "app.catalogue.promotions.discountDeletedErr",
            defaultMessage: "Error Creating Discount.",
         },
         discountCreatedErr : {
            id: "app.catalogue.promotions.discountCreatedErr",
            defaultMessage: "Error Deleting Discount.",
         },
         discountUpdatedErr : {
            id: "app.catalogue.promotions.discountUpdatedErr",
            defaultMessage: "Error Updating Discount.",
         },
         useForCouponDistribution:{
            id: "app.catalogue.promotions.useForCouponDistribution",
            defaultMessage: "Use for Coupon Distribution?",
         },
         minimumEligibleCouponAmount:{
            id: "app.catalogue.promotions.minimumEligibleCouponAmount=",
            defaultMessage: "Minimum Eligible Coupon Amount",
         },
         orderIDDistributedOn:{
            id: "app.catalogue.promotions.orderIDDistributedOn",
            defaultMessage: "Order ID Distributed On",
         },
         allocatedCouponValDis:{
            id: "app.catalogue.promotions.allocatedCouponValDis",
            defaultMessage: "Allocated quantity must not exceed 1 when 'Use for Coupon Distribution' is set to 'Yes'",
         }
      },
      product: {
         addProduct: {
            id: "app.catalogue.product.addProduct",
            defaultMessage: "Add Product",
         },
         editProduct: {
            id: "app.catalogue.product.editProduct",
            defaultMessage: "Edit Product",
         },
         downloadProducts: {
            id: "app.catalogue.product.downloadProducts",
            defaultMessage: "Download Products",
         },
         printProductBarcodeForm: {
            id: "app.catalogue.product.printProductBarcodeForm",
            defaultMessage: "Print Product Barcode - Form",
         },
         printProductBarcodeExcel: {
            id: "app.catalogue.product.printProductBarcodeExcel",
            defaultMessage: "Print Product Barcode - Excel",
         },
         createProducts: {
            id: "app.catalogue.product.createProducts",
            defaultMessage: "Create Product",
         },
      },
      modifier: {
         modifierName: {
            id: "app.catalogue.modifier.modifierName",
            defaultMessage: "Modifier Name",
         },
         modifierType: {
            id: "app.catalogue.modifier.modifierType",
            defaultMessage: "Modifier Type",
         },
         foodType: {
            id: "app.catalogue.modifier.foodType",
            defaultMessage: "Food Type",
         },
         price: {
            id: "app.catalogue.modifier.price",
            defaultMessage: "Price",
         },
         sortOrder: {
            id: "app.catalogue.modifier.sortOrder",
            defaultMessage: "Sort Sequence",
         },
         modifiersTitle: {
            id: "app.catalogue.modifier.modifiersTitle",
            defaultMessage: "Modifiers",
         },
         modifiersDesc: {
            id: "app.catalogue.modifier.modifiersDesc",
            defaultMessage: "List of all Modifiers.",
         },
         createModifier: {
            id: "app.catalogue.modifier.createModifier",
            defaultMessage: "Create Modifier",
         },
         editModifier: {
            id: "app.catalogue.modifier.editModifier",
            defaultMessage: "Edit Modifier",
         },
         editModifierDesc: {
            id: "app.catalogue.modifier.editModifierDesc",
            defaultMessage: "Update modifier and customise by adding recipes & more.",
         },
         storeName: {
            id: "app.catalogue.modifier.storeName",
            defaultMessage: "Store Name",
         },
         sellingPrice: {
            id: "app.catalogue.modifier.sellingPrice",
            defaultMessage: "Selling Price",
         },
         sellingPriceDesc: {
            id: "app.catalogue.modifier.sellingPriceDesc",
            defaultMessage: "Selling price is required to map the modifier to a particular store.",
         },
         MRP: {
            id: "app.catalogue.modifier.MRP",
            defaultMessage: "M.R.P.",
         },
         lowStockValue: {
            id: "app.catalogue.modifier.lowStockValue",
            defaultMessage: "Low Stock Value",
         },
         createModifierDesc: {
            id: "app.catalogue.modifier.createModifierDesc",
            defaultMessage: "Create a modifier and customise by adding products, recipes and more.",
         },
         modifierRepresentation: {
            id: "app.catalogue.modifier.modifierRepresentation",
            defaultMessage: "Modifier Representation",
         },
         modifierRepresentationDesc: {
            id: "app.catalogue.modifier.modifierRepresentationDesc",
            defaultMessage:
               "Upload a modifier picture and/or pick a modifier color to uniquely identify the modifier.",
         },
         uploadPicture: {
            id: "app.catalogue.modifier.uploadPicture",
            defaultMessage: "Upload Picture",
         },
         chooseColor: {
            id: "app.catalogue.modifier.chooseColor",
            defaultMessage: "Choose Color",
         },
         basicInfoDesc: {
            id: "app.catalogue.modifier.basicInfoDesc",
            defaultMessage: "Provide some basic information about the modifier product.",
         },
         modifierDesc: {
            id: "app.catalogue.modifier.modifierDesc",
            defaultMessage: "Modifier Description",
         },
         specialAttributes: {
            id: "app.catalogue.modifier.specialAttributes",
            defaultMessage: "Special Attributes",
         },
         specialAttributesDesc: {
            id: "app.catalogue.modifier.specialAttributesDesc",
            defaultMessage:
               "Create custom identifiers like size, color, bar code etc. to differentiate one product SKU from the other.",
         },
         pricingDetails: {
            id: "app.catalogue.modifier.pricingDetails",
            defaultMessage: "Pricing Details",
         },
         pricingDetailsDesc: {
            id: "app.catalogue.modifier.pricingDetailsDesc",
            defaultMessage: "Add prices, taxes, and other charges applicable to the modifier.",
         },
         isSellableOnPOS: {
            id: "app.catalogue.modifier.isSellableOnPOS",
            defaultMessage: "Is Sellable on POS",
         },
         isSellableOnPOSDesc: {
            id: "app.catalogue.modifier.isSellableOnPOSDesc",
            defaultMessage: "Allow modifier to be sellable on POS.",
         },
         includeTax: {
            id: "app.catalogue.modifier.includeTax",
            defaultMessage: "Include Tax",
         },
         storeDetailsDesc: {
            id: "app.catalogue.modifier.storeDetailsDesc",
            defaultMessage:
               "Enable this to update modifier pricing & low stock alerts for individual stores.",
         },
         regionStore: {
            id: "app.catalogue.modifier.regionStore",
            defaultMessage: "Region/Store",
         },
         manageInventory: {
            id: "app.catalogue.modifier.manageInventory",
            defaultMessage: "Manage Inventory",
         },
         manageInventoryDesc: {
            id: "app.catalogue.modifier.manageInventoryDesc",
            defaultMessage:
               "Define the basic information about this modifier's inventory management. Visit the inventory module for extensive functionality.",
         },
         enableStockAlert: {
            id: "app.catalogue.modifier.enableStockAlert",
            defaultMessage:
               "Modifier will not be available for billing if the stock level reaches 0",
         },
         enableStockAlertDesc: {
            id: "app.catalogue.modifier.enableStockAlertDesc",
            defaultMessage:
               "Disable the billing section functionality for modifiers with zero stocks available.",
         },
         defaultLowStockValue: {
            id: "app.catalogue.modifier.defaultLowStockValue",
            defaultMessage: "Default Low Stock Value",
         },
         inventoryBehaviourOnSales: {
            id: "app.catalogue.modifier.inventoryBehaviourOnSales",
            defaultMessage: "Inventory Behaviour On Sales",
         },
         stockItemType: {
            id: "app.catalogue.modifier.stockItemType",
            defaultMessage: "Stock Item Type",
         },
         batchedProd: {
            id: "app.catalogue.modifier.batchedProd",
            defaultMessage: "Batched Product",
         },
         batchedProdDesc: {
            id: "app.catalogue.modifier.batchedProdDesc",
            defaultMessage:
               "Different stock batches will be linked to a specific and unique batch number. Batches will be distinguished by stock attributes.",
         },
         unbatchedProd: {
            id: "app.catalogue.modifier.unbatchedProd",
            defaultMessage: "Unbatched Product",
         },
         unbatchedProdDesc: {
            id: "app.catalogue.modifier.unbatchedProdDesc",
            defaultMessage: "No differentiation amongst stock items.",
         },
         stockAttributesToolTip: {
            id: "app.catalogue.modifier.stockAttributesToolTip",
            defaultMessage: "Stock Attributes cannot be updated once mapped.",
         },
         selectAttributes: {
            id: "app.catalogue.modifier.selectAttributes",
            defaultMessage: "Select Attributes",
         },
         attachRecipe: {
            id: "app.catalogue.modifier.attachRecipe",
            defaultMessage: "Attach Recipe",
         },
         attachRecipeDesc: {
            id: "app.catalogue.modifier.attachRecipeDesc",
            defaultMessage:
               "Stock of items in the recipe will be reduced whenever the product is sold.",
         },
         modifierToProd: {
            id: "app.catalogue.modifier.modifierToProd",
            defaultMessage: "Add this modifier to product",
         },
         modifierToProdDesc: {
            id: "app.catalogue.modifier.modifierToProdDesc",
            defaultMessage: "Add one or more products to this modifier.",
         },
         attributeName: {
            id: "app.catalogue.modifier.attributeName",
            defaultMessage: "Attribute Name",
         },
         attributeType: {
            id: "app.catalogue.modifier.attributeType",
            defaultMessage: "Attribute Type",
         },
         uploadPictureDesc: {
            id: "app.catalogue.modifier.uploadPictureDesc",
            defaultMessage:
               "This allows you to upload a unique modifier picture. (Max-size: 1.0 Mb)",
         },
         chooseColorDesc: {
            id: "app.catalogue.modifier.chooseColorDesc",
            defaultMessage: "This allows you to choose a unique modifier color.",
         },
         createAttribute: {
            id: "app.catalogue.modifier.createAttribute",
            defaultMessage: "Create Attribute",
         },
         stockAttributes: {
            id: "app.catalogue.modifier.stockAttributes",
            defaultMessage: "Stock Attributes",
         },
         noStockAttributesMapped: {
            id: "app.catalogue.modifier.noStockAttributesMapped",
            defaultMessage: "No Stock Attributes Mapped",
         },
      },
      productGroup: {
         productGroups: {
            id: "app.catalogue.productGroup.productGroups",
            defaultMessage: "Product Groups",
         },
         listOfAllProductGroups: {
            id: "app.catalogue.productGroup.listOfAllProductGroups",
            defaultMessage: " List of all product groups",
         },
         deleteProductGroup: {
            id: "app.catalogue.productGroup.deleteProductGroup",
            defaultMessage: "Are you sure you want to delete product group",
         },
         createProductGroups: {
            id: "app.catalogue.productGroup.createProductGroups",
            defaultMessage: "Create Product Groups",
         },
         createProductGroup: {
            id: "app.catalogue.productGroup.createProductGroup",
            defaultMessage: "Create Product Group",
         },
         exportProductGroups: {
            id: "app.catalogue.productGroup.exportProductGroups",
            defaultMessage: "Export Product Groups",
         },
         selectProductToCreateGroup: {
            id: "app.catalogue.productGroup.selectProductToCreateGroup",
            defaultMessage: "Select a product to create a group",
         },
         pleaseSelectProduct: {
            id: "app.catalogue.productGroup.pleaseSelectProduct",
            defaultMessage: "Please Select Product",
         },
         manufacturingDateValidation: {
            id: "app.catalogue.productGroup.manufacturingDateValidation",
            defaultMessage: "Expiry Date Can't be greater than Manufacturing date",
         },
         productInfo: {
            id: "app.catalogue.productGroup.productInfo",
            defaultMessage: "Enter product group information",
         },
         productGroupNameValidation: {
            id: "app.catalogue.productGroup.productGroupNameValidation",
            defaultMessage: "Product group name is required.",
         },
         additionalAttributes: {
            id: "app.catalogue.productGroup.additionalAttributes",
            defaultMessage: "Additional Attributes",
         },
         noAdditionalAttributes: {
            id: "app.catalogue.productGroup.noAdditionalAttributes",
            defaultMessage: "No Additional Attributes",
         },
         pickADate: {
            id: "app.catalogue.productGroup.pickADate",
            defaultMessage: "Pick a date",
         },
         editProductGroupDes: {
            id: "app.catalogue.productGroup.editProductGroupDes",
            defaultMessage: "Edit and update all the information of a specific product group",
         },
         bulkCreateProductGroups: {
            id: "app.catalogue.productGroup.bulkCreateProductGroups",
            defaultMessage: "Bulk - Create Product Groups",
         },
         downLoadExcelDocProductGroupDes: {
            id: "app.catalogue.productGroup.downLoadExcelDocProductGroupDes",
            defaultMessage:
               "Click here to download excel template & upload it to create multiple product groups",
         },
         previewCreateProductGroupTitle: {
            id: "app.catalogue.productGroup.previewCreateProductGroupTitle",
            defaultMessage: "Preview - Create Product Groups",
         },
         previewEditProductGroupTitle: {
            id: "app.catalogue.productGroup.previewEditProductGroupTitle",
            defaultMessage: "Preview - Edit Products Groups",
         },
      },
      bundle: {
         listOfBundle: {
            id: "app.catalogue.bundle.listOfBundle",
            defaultMessage: "List of Product Bundle",
         },
         listOfBundleDes: {
            id: "app.catalogue.bundle.listOfBundleDes",
            defaultMessage: "Descriptive text for Product Bundle",
         },
         minPrice: {
            id: "app.catalogue.bundle.minPrice",
            defaultMessage: "Min. Price",
         },
         maxPrice: {
            id: "app.catalogue.bundle.maxPrice",
            defaultMessage: "Max. Price",
         },
         deleteBundle: {
            id: "app.catalogue.bundle.deleteBundle",
            defaultMessage: "Are you sure you want to delete this Bundle",
         },
         createBundle: {
            id: "app.catalogue.bundle.createBundle",
            defaultMessage: "Create Bundle",
         },
         createBundleDes: {
            id: "app.catalogue.bundle.createBundleDes",
            defaultMessage: "Create Bundle by providing below information.",
         },
         uploadBundleImage: {
            id: "app.catalogue.bundle.uploadBundleImage",
            defaultMessage:
               "This will enable and disable you to upload a picture option for your bundle",
         },
         bundleName: {
            id: "app.catalogue.bundle.bundleName",
            defaultMessage: "Bundle Name",
         },
         sortOrder: {
            id: "app.catalogue.bundle.sortOrder",
            defaultMessage: "Sort Order",
         },
         bundleItemQuantity: {
            id: "app.catalogue.bundle.bundleItemQuantity",
            defaultMessage: "Bundle Item Quantity",
         },
         displayBundleItemsOnInvoice: {
            id: "app.catalogue.bundle.displayBundleItemsOnInvoice",
            defaultMessage: "Display Bundle Items on Invoice",
         },
         enableDynamicPricing: {
            id: "app.catalogue.bundle.enableDynamicPricing",
            defaultMessage: "Enable Dynamic Pricing",
         },
         enableDynamicPricingDes: {
            id: "app.catalogue.bundle.enableDynamicPricingDes",
            defaultMessage:
               "If enable this setting will set the price of the bundle at the submission of all the selected products",
         },
         variablePricing: {
            id: "app.catalogue.bundle.variablePricing",
            defaultMessage: "Allow Variable Pricing",
         },
         variablePricingDes: {
            id: "app.catalogue.bundle.variablePricingDes",
            defaultMessage: "Allow product for variable pricing",
         },
         addProductsForThisBundle: {
            id: "app.catalogue.bundle.addProductsForThisBundle",
            defaultMessage: "Add Products for this bundle",
         },
         minQuantity: {
            id: "app.catalogue.bundle.minQuantity",
            defaultMessage: "Min. Qty",
         },
         maxQuantity: {
            id: "app.catalogue.bundle.maxQuantity",
            defaultMessage: "Max. Qty",
         },
         updateBundle: {
            id: "app.catalogue.bundle.updateBundle",
            defaultMessage: "Update Bundle",
         },
         updateBundleDes: {
            id: "app.catalogue.bundle.updateBundleDes",
            defaultMessage: "Update Bundle by providing below information.",
         },
         defaultSubCat: {
            id: "app.catalogue.bundle.defaultSubCat",
            defaultMessage: "Default sub category (none)",
         },

         bundleNameValidation: {
            id: "app.catalogue.bundle.bundleNameValidation",
            defaultMessage: "Bundle Name is Required..!",
         },
         totalBundleQuantityvaliadtion: {
            id: "app.catalogue.bundle.totalBundleQuantityvaliadtion",
            defaultMessage: "Bundle Item Quantity is Required..!",
         },
         bundlePricevaliadation: {
            id: "app.catalogue.bundle.bundlePricevaliadation",
            defaultMessage: "Bundle Price is Required..!",
         },
         minBundlePriceValidation: {
            id: "app.catalogue.bundle.minBundlePriceValidation",
            defaultMessage: "Min. bundle price can't be less than zero!",
         },
         maxBundlePriceValidation: {
            id: "app.catalogue.bundle.maxBundlePriceValidation",
            defaultMessage: "Max. bundle price can't be less than equal to zero!",
         },
         minBundlePriceGreaterMaxVal: {
            id: "app.catalogue.bundle.minBundlePriceGreaterMaxVal",
            defaultMessage: "Min. Bundle Price should always less than Max. Bundle Price",
         },
         productSelectVal: {
            id: "app.catalogue.bundle.productSelectVal",
            defaultMessage: "Please Select Products",
         },
         productNameValidation: {
            id: "app.catalogue.bundle.productNameValidation",
            defaultMessage: "Product Name Can't be Empty",
         },
         minMaxQuantityValidation: {
            id: "app.catalogue.bundle.minMaxQuantityValidation",
            defaultMessage: "Min Quantity Should be less than Max Quantity",
         },
         minBundlePrice: {
            id: "app.catalogue.bundle.minBundlePrice",
            defaultMessage: "Min. Bundle Price",
         },
         maxBundlePrice: {
            id: "app.catalogue.bundle.maxBundlePrice",
            defaultMessage: "Max. Bundle Price",
         },
         pleaseSelectTheCategory: {
            id: "app.catalogue.bundle.pleaseSelectTheCategory",
            defaultMessage: "Please select the category.",
         },
         useBulkUpload: {
            id: "app.catalogue.bundle.useBulkUpload",
            defaultMessage: "Use Bulk Upload.",
         },
         clickToDownloadProd: {
            id: "app.catalogue.bundle.clickToDownloadProd",
            defaultMessage:
               "Click here to download excel template & upload it to multiple Products",
         },
         addProdVal: {
            id: "app.catalogue.bundle.addProdVal",
            defaultMessage: "Please Add Any product to Bundle!",
         },
         minMaxQuantityExcelVal: {
            id: "app.catalogue.bundle.minMaxQuantityExcelVal",
            defaultMessage: "Please Fill Minimum Quantity and Maximum Qunatity Fields!",
         },
      },
      priceList: {
         priceTitle: {
            id: "app.catalogue.priceList.priceList",
            defaultMessage: "Price List",
         },
         priceListDes: {
            id: "app.catalogue.priceList.priceListDes",
            defaultMessage: "Descriptive text for Price List",
         },
         priceListName: {
            id: "app.catalogue.priceList.priceListName",
            defaultMessage: "Price List Name",
         },
         ruleType: {
            id: "app.catalogue.priceList.ruleType",
            defaultMessage: "Rule Type",
         },
         priceList: {
            id: "app.catalogue.priceList.channel",
            defaultMessage: "Channel",
         },
         viewChannel: {
            id: "app.catalogue.priceList.viewChannel",
            defaultMessage: "View Channel",
         },
         viewCustomers: {
            id: "app.catalogue.priceList.viewCustomers",
            defaultMessage: "View Customers",
         },
         createPriceList: {
            id: "app.catalogue.priceList.createPriceList",
            defaultMessage: "Create Price List",
         },
         createPriceListDesc: {
            id: "app.catalogue.priceList.createPriceListDesc",
            defaultMessage: "Create PriceList by providing below information.",
         },
         rule: {
            id: "app.catalogue.priceList.rule",
            defaultMessage: "Rule",
         },
         markup: {
            id: "app.catalogue.priceList.markup",
            defaultMessage: "Mark Up",
         },
         markDown: {
            id: "app.catalogue.priceList.markDown",
            defaultMessage: "Mark Down",
         },
         custom: {
            id: "app.catalogue.priceList.custom",
            defaultMessage: "Custom",
         },
         productList: {
            id: "app.catalogue.priceList.productList",
            defaultMessage: "Product List",
         },
         productListDes: {
            id: "app.catalogue.priceList.productListDes",
            defaultMessage: "Select the product where this price rule will be applicable.",
         },
         addProdUsingExcel: {
            id: "app.catalogue.priceList.addProdUsingExcel",
            defaultMessage: "Add Product using Excel file",
         },
         customerList: {
            id: "app.catalogue.priceList.customerList",
            defaultMessage: "Customer List",
         },
         customListDes: {
            id: "app.catalogue.priceList.customListDes",
            defaultMessage: "Please select customers",
         },
         addCustomersExcel: {
            id: "app.catalogue.priceList.addCustomersExcel",
            defaultMessage: "Add Customers using Excel file",
         },
         channelList: {
            id: "app.catalogue.priceList.channelList",
            defaultMessage: "Channel List",
         },
         channelListDes: {
            id: "app.catalogue.priceList.channelListDes",
            defaultMessage: "Please select channel",
         },
         addChannelUsingExcel: {
            id: "app.catalogue.priceList.addChannelUsingExcel",
            defaultMessage: "Add Channels using Excel file",
         },
         channelName: {
            id: "app.catalogue.priceList.channelName",
            defaultMessage: "Channel Name",
         },
         channelCode: {
            id: "app.catalogue.priceList.channelCode",
            defaultMessage: "Channel Code",
         },
         downloadCustomerExcel: {
            id: "app.catalogue.priceList.downloadCustomerExcel",
            defaultMessage:
               "Click here to download excel template & upload it to multiple Customers",
         },
         downloadChannelExcel: {
            id: "app.catalogue.priceList.downloadChannelExcel",
            defaultMessage:
               "Click here to download excel template & upload it to multiple Channels",
         },
         updatePriceList: {
            id: "app.catalogue.priceList.updatePriceList",
            defaultMessage: "Update Price List",
         },
         updatePriceListDes: {
            id: "app.catalogue.priceList.updatePriceListDes",
            defaultMessage: "Update PriceList by providing below information.",
         },
         customPrice: {
            id: "app.catalogue.priceList.customPrice",
            defaultMessage: "Custom Price",
         },
         channelID: {
            id: "app.catalogue.priceList.channelID",
            defaultMessage: "Channel ID",
         },
         channels: {
            id: "app.catalogue.priceList.channels",
            defaultMessage: "Channels",
         },
         priceNameVal: {
            id: "app.catalogue.priceList.priceNameVal",
            defaultMessage: "Please Fill Price Name",
         },
         marginValueVal: {
            id: "app.catalogue.priceList.marginValueVal",
            defaultMessage: "Please Fill Value",
         },
         valuePercantageVal: {
            id: "app.catalogue.priceList.valuePercantageVal",
            defaultMessage: "Value can't be greater than 100%",
         },
         customPriceVal: {
            id: "app.catalogue.priceList.customPriceVal",
            defaultMessage: "Please fill custom price of selected product",
         },
         pleaseAddAnyProduct: {
            id: "app.catalogue.priceList.pleaseAddAnyProduct",
            defaultMessage: "Please Add Any product!",
         },
      },
      reservations: {
         reservationSlots: {
            id: "app.catalogue.reservations.reservationSlots",
            defaultMessage: "Reservation Slots",
         },
         reservationSlotsDesc: {
            id: "app.catalogue.reservations.reservationSlotsDesc",
            defaultMessage: "All bookings for store visit and services.",
         },
         reservation: {
            id: "app.catalogue.reservations.reservation",
            defaultMessage: "Reservation",
         },
         lastStorevisitDate: {
            id: "app.catalogue.reservations.lastStorevisitDate",
            defaultMessage: "Last store visit date",
         },
         slotDetails: {
            id: "app.catalogue.reservations.slotDetails",
            defaultMessage: "Slot Details",
         },
         reservationID: {
            id: "app.catalogue.reservations.reservationID",
            defaultMessage: "Reservation ID",
         },
         totalVisitor: {
            id: "app.catalogue.reservations.totalVisitor",
            defaultMessage: "Total Visitor",
         },
         noSlotFound: {
            id: "app.catalogue.reservations.noSlotFound",
            defaultMessage: "No Slots Found",
         },
         generateNewSlot: {
            id: "app.catalogue.reservations.generateNewSlot",
            defaultMessage: "Generate New Slot",
         },
         errorGettingSlots: {
            id: "app.catalogue.reservations.errorGettingSlots",
            defaultMessage: "Error While getting slots data",
         },
         editTimeSlot: {
            id: "app.catalogue.reservations.editTimeSlot",
            defaultMessage: "Edit Time Slot",
         },
         noOfGuestAllowed: {
            id: "app.catalogue.reservations.noOfGuestAllowed",
            defaultMessage: "Number of guest allowed",
         },
         noOfGuestAllowedVal: {
            id: "app.catalogue.reservations.noOfGuestAllowedVal",
            defaultMessage: "Please fill Number of guest allowed",
         },
         minimumNumberOfGuest: {
            id: "app.catalogue.reservations.minimumNumberOfGuest",
            defaultMessage: "Minimum number of guest",
         },
         minimumNumberOfGuestVal: {
            id: "app.catalogue.reservations.minimumNumberOfGuestVal",
            defaultMessage: "Please fill Minimum number of guest",
         },
         maxNumberOfGuestValGreater: {
            id: "app.catalogue.reservations.maxNumberOfGuestValGreater",
            defaultMessage: "Maximum number of guest shoudn't be greater than 1000.",
         },
         maxNumberOfGuest: {
            id: "app.catalogue.reservations.maxNumberOfGuest",
            defaultMessage: "Maximum number of guest",
         },
         maxNumberOfGuestVal: {
            id: "app.catalogue.reservations.maxNumberOfGuestVal",
            defaultMessage: "Please fill Maximum number of guest",
         },
         noOfDays: {
            id: "app.catalogue.reservations.noOfDays",
            defaultMessage: "Number of days",
         },
         noOfDaysDesc: {
            id: "app.catalogue.reservations.noOfDaysDesc",
            defaultMessage: "New Slots will be created till Reservation End Date only",
         },
         noOfDaysVal: {
            id: "app.catalogue.reservations.noOfDaysVal",
            defaultMessage: "Please fill Number of days",
         },
         slotDuration: {
            id: "app.catalogue.reservations.slotDuration",
            defaultMessage: "Slot Duration (minutes)",
         },
         activeDays: {
            id: "app.catalogue.reservations.activeDays",
            defaultMessage: "Active days",
         },
         bookingOpeningTime: {
            id: "app.catalogue.reservations.bookingOpeningTime",
            defaultMessage: "Booking opening time",
         },
         bookingClosingTime: {
            id: "app.catalogue.reservations.bookingClosingTime",
            defaultMessage: "Booking closing time",
         },
         selectSlotDuration: {
            id: "app.catalogue.reservations.selectSlotDuration",
            defaultMessage: "Select Slot Duration",
         },
         reservationEndDate: {
            id: "app.catalogue.reservations.reservationEndDate",
            defaultMessage: "Reservation End Date",
         },
         noOfGuestPerSlots: {
            id: "app.catalogue.reservations.noOfGuestPerSlots",
            defaultMessage: "Number of Guest per slots",
         },
         noOfGuestAllowedPerBooking: {
            id: "app.catalogue.reservations.noOfGuestAllowedPerBooking",
            defaultMessage: "Number of Guest allowed per booking",
         },
         totalNumberOfDaysVal: {
            id: "app.catalogue.reservations.totalNumberOfDaysVal",
            defaultMessage:
               "Number of days should not be greater than the total number of days according to the selected date range.",
         },
         activeBookings: {
            id: "app.catalogue.reservations.activeBookings",
            defaultMessage: "Active Bookings",
         },
         bookingsAvailable: {
            id: "app.catalogue.reservations.bookingsAvailable",
            defaultMessage: "Bookings available",
         },
         bookingsNotAvailable: {
            id: "app.catalogue.reservations.bookingsNotAvailable",
            defaultMessage: "Bookings not available",
         },
         bulkEdit: {
            id: "app.catalogue.reservations.bulkEdit",
            defaultMessage: "Bulk Edit",
         },
         clickToDownloadSlotExcel: {
            id: "app.catalogue.reservations.clickToDownloadSlotExcel",
            defaultMessage:
               "Click here to download excel template & upload it to update multiple reservation slots",
         },
         slotID: {
            id: "app.catalogue.reservations.slotID",
            defaultMessage: "Slot ID",
         },
         slotDate: {
            id: "app.catalogue.reservations.slotDate",
            defaultMessage: "Slot Date",
         },
         bookingsAllowed: {
            id: "app.catalogue.reservations.bookingsAllowed",
            defaultMessage: "Bookings Allowed",
         },
         minGuestPerBooking: {
            id: "app.catalogue.reservations.minGuestPerBooking",
            defaultMessage: "Min Guest Per Booking",
         },
         maxGuestPerBooking: {
            id: "app.catalogue.reservations.maxGuestPerBooking",
            defaultMessage: "Max Guest Per Booking",
         },
         availableForBooking: {
            id: "app.catalogue.reservations.availableForBooking",
            defaultMessage: "Available For Booking",
         },
         updateResError: {
            id: "app.catalogue.reservations.updateResError",
            defaultMessage: "Error while  updating reservation, please contact support team",
         },
         bulkEditReservationPreview: {
            id: "app.catalogue.reservations.bulkEditReservationPreview",
            defaultMessage: "Bulk Edit Reservation Preview",
         },
         bulkEditReservationPreviewDesc: {
            id: "app.catalogue.reservations.bulkEditReservationPreviewDesc",
            defaultMessage: "All bookings for store visit and services.",
         },
         reservationName: {
            id: "app.catalogue.reservations.reservationName",
            defaultMessage: "Reservation Name",
         },
         createReservation: {
            id: "app.catalogue.reservations.createReservation",
            defaultMessage: "Create Reservation",
         },
         reservationDesc: {
            id: "app.catalogue.reservations.reservationDesc",
            defaultMessage: "List of all reservations.",
         },
         createReservationDesc: {
            id: "app.catalogue.reservations.createReservationDesc",
            defaultMessage: "Create Reservations by providing below information.",
         },
         uploadReservationImageDesc: {
            id: "app.catalogue.reservations.uploadReservationImageDesc",
            defaultMessage:
               "This will enable and disable you to upload a picture option for your reservation",
         },
         ticketTypes: {
            id: "app.catalogue.reservations.ticketTypes",
            defaultMessage: "Ticket Types",
         },
         ticketType: {
            id: "app.catalogue.reservations.ticketType",
            defaultMessage: "Ticket Type",
         },
         guestCount: {
            id: "app.catalogue.reservations.guestCount",
            defaultMessage: "Guest Count",
         },
         ticketPrice: {
            id: "app.catalogue.reservations.ticketPrice",
            defaultMessage: "Ticket Price",
         },
         channelPricingDec: {
            id: "app.catalogue.reservations.channelPricingDec",
            defaultMessage: "Manage channel pricing.",
         },
         channelPricing: {
            id: "app.catalogue.reservations.channelPricing",
            defaultMessage: "Channel Pricing",
         },
         configuration: {
            id: "app.catalogue.reservations.configuration",
            defaultMessage: "Configuration",
         },
         autoGenerateSlots: {
            id: "app.catalogue.reservations.autoGenerateSlots",
            defaultMessage: "Auto generate slots for reservation",
         },
         autoGenerateSlotsDesc: {
            id: "app.catalogue.reservations.autoGenerateSlotsDesc",
            defaultMessage: "Enable this to generate slots automatically for advance booking",
         },
         advanceBookingDays: {
            id: "app.catalogue.reservations.advanceBookingDays",
            defaultMessage: "Advance Booking Days",
         },
         minTicketAllowed: {
            id: "app.catalogue.reservations.minTicketAllowed",
            defaultMessage: "Minimum tickets allowed per booking",
         },
         maxTicketAllowed: {
            id: "app.catalogue.reservations.maxTicketAllowed",
            defaultMessage: "Maximum tickets allowed per booking",
         },
         minGuestAllowed: {
            id: "app.catalogue.reservations.minGuestAllowed",
            defaultMessage: "Minimum guest allowed per ticket",
         },
         storeVisitTimeSlots: {
            id: "app.catalogue.reservations.storeVisitTimeSlots",
            defaultMessage: "Store visit time slots",
         },
         noteDesc: {
            id: "app.catalogue.reservations.noteDesc",
            defaultMessage: " Click on any slot to disable it for booking",
         },
         morning: {
            id: "app.catalogue.reservations.morning",
            defaultMessage: "Morning",
         },
         afterNoon: {
            id: "app.catalogue.reservations.afterNoon",
            defaultMessage: "After Noon",
         },
         evening: {
            id: "app.catalogue.reservations.evening",
            defaultMessage: "Evening",
         },
         night: {
            id: "app.catalogue.reservations.night",
            defaultMessage: "Night",
         },
         editReservation: {
            id: "app.catalogue.reservations.editReservation",
            defaultMessage: "Edit Reservation",
         },
         editeservationDesc: {
            id: "app.catalogue.reservations.editeservationDesc",
            defaultMessage: "Update Reservations by change below information.",
         },
         viewStoresList: {
            id: "app.catalogue.reservations.viewStoresList",
            defaultMessage: "View Stores list",
         },
         viewChannelList: {
            id: "app.catalogue.reservations.viewChannelList",
            defaultMessage: "View Channel list",
         },
         slotDurationCol: {
            id: "app.catalogue.reservations.slotDurationCol",
            defaultMessage: "Slot Duration",
         },
         storeWisePricingIn: {
            id: "app.catalogue.reservations.storeWisePricingIn",
            defaultMessage: "Store Wise Pricing in",
         },
         reservationStartDate: {
            id: "app.catalogue.reservations.reservationStartDate",
            defaultMessage: "Reservation Start Date",
         },
         channelPricingIn: {
            id: "app.catalogue.reservations.channelPricingIn",
            defaultMessage: "Channel Pricing in",
         },
         deleteReservation: {
            id: "app.catalogue.reservations.deleteReservation",
            defaultMessage: "Delete Reservation",
         },
         ticketTypeValidation: {
            id: "app.catalogue.reservations.ticketTypeValidation",
            defaultMessage: "Ticket Type is required.",
         },
         guestCountValidation: {
            id: "app.catalogue.reservations.guestCountValidation",
            defaultMessage: "Guest Count is required.",
         },
         guestCountMaxGuestPerTicketValOne: {
            id: "app.catalogue.reservations.guestCountMaxGuestPerTicketValOne",
            defaultMessage: "Guest Count should not be greater than maximum guest per ticket",
         },
         guestCountMaxGuestPerTicketValTwo: {
            id: "app.catalogue.reservations.guestCountMaxGuestPerTicketValTwo",
            defaultMessage: "Guest Count should not be greater than maximum guest per ticket",
         },
         guestCountMaxGuestPerTicketValThree: {
            id: "app.catalogue.reservations.guestCountMaxGuestPerTicketValThree",
            defaultMessage: "Guest Count should not be lesser than minimum guest per ticket",
         },
         guestCountMaxGuestPerTicketValFour: {
            id: "app.catalogue.reservations.guestCountMaxGuestPerTicketValFour",
            defaultMessage: "Guest Count should not be lesser than minimum guest per ticket",
         },
         reseavtaionNameVal: {
            id: "app.catalogue.reservations.reseavtaionNameVal",
            defaultMessage: "Reservation Name is Required.",
         },
         reservationDateVal: {
            id: "app.catalogue.reservations.reservationDateVal",
            defaultMessage: "Reservation Start Date Should be greater than current date",
         },
         reservationPricingError: {
            id: "app.catalogue.reservations.reservationPricingError",
            defaultMessage: "Make sure to enter the all required fields in Pricing Information",
         },
         advanceBookingDaysVal: {
            id: "app.catalogue.reservations.advanceBookingDaysVal",
            defaultMessage:
               "Advance Booking days should not be greater than the total number of days according to the selected date range.",
         },
         activeDaysVal: {
            id: "app.catalogue.reservations.activeDaysVal",
            defaultMessage: "Atleast one active day should be selected.",
         },
         noOfGuestPerSlotVal: {
            id: "app.catalogue.reservations.noOfGuestPerSlotVal",
            defaultMessage: "Number of guest per slot is Required.",
         },
         minGuestPerBookingVal: {
            id: "app.catalogue.reservations.minGuestPerBookingVal",
            defaultMessage: "Minimum number of guest is required.",
         },
         minGuestPerBookingZeroVal: {
            id: "app.catalogue.reservations.minGuestPerBookingZeroVal",
            defaultMessage: "Minimum number of guest should not be zero.",
         },
         maxNoOfGuestVal: {
            id: "app.catalogue.reservations.maxNoOfGuestVal",
            defaultMessage: "Maximum number of guest is required.",
         },
         minGuestCompareValOne: {
            id: "app.catalogue.reservations.minGuestCompareValOne",
            defaultMessage:
               "Minimum guest per booking shouldn't be greater than maximum guest per booking.",
         },
         maxGuestBookingCompareVal: {
            id: "app.catalogue.reservations.maxGuestBookingCompareVal",
            defaultMessage: "Maximum guest per booking shouldn't be greater than 1000.",
         },
         minNoOfTicketReqVal: {
            id: "app.catalogue.reservations.minNoOfTicketReqVal",
            defaultMessage: "Minimum No of tickets is Required.",
         },
         minGuestCompareValTwo: {
            id: "app.catalogue.reservations.minGuestCompareValTwo",
            defaultMessage: "Minimum No of tickets should not be zero",
         },
         minGuestCompareValThree: {
            id: "app.catalogue.reservations.minGuestCompareValThree",
            defaultMessage:
               "Minimum tickets per booking shouldn't be greater than maximum tickets per booking",
         },
         maxTicketValOne: {
            id: "app.catalogue.reservations.maxTicketValOne",
            defaultMessage: "Maximum tickets per booking shouldn't be greater than 1000.",
         },
         defaultCategory: {
            id: "app.catalogue.reservations.defaultCategory",
            defaultMessage: "Default category (none)",
         },
         ticketPriceVal: {
            id: "app.catalogue.reservations.ticketPriceVal",
            defaultMessage: "Ticket Price is required.",
         },
         storesAvailable: {
            id: "app.catalogue.reservations.storesAvailable",
            defaultMessage: "Stores Available",
         },
         channelsAvailable: {
            id: "app.catalogue.reservations.channelsAvailable",
            defaultMessage: "Channels Available",
         },
         maxTicketRequired: {
            id: "app.catalogue.reservations.maxTicketRequired",
            defaultMessage: "Maximum No of tickets is Required.",
         },
         disabledSlotVal: {
            id: "app.catalogue.reservations.disabledSlotVal",
            defaultMessage: "You can't disabled full day slot...!",
         },
         maximumGuestAllowedPerTicket: {
            id: "app.catalogue.reservations.maximumGuestAllowedPerTicket",
            defaultMessage: "Maximum guest allowed per ticket",
         },
         noTimeSlotsAvilableForMorning: {
            id: "app.catalogue.reservations.noTimeSlotsAvilableForMorning",
            defaultMessage: "No Time Slots Avilable For This Range In Morning Slot.",
         },
         noTimeSlotsAvilableForAfterNon: {
            id: "app.catalogue.reservations.noTimeSlotsAvilableForAfterNon",
            defaultMessage: "No Time Slots Avilable For This Range In AfterNoon Slot.",
         },
         noTimeSlotsAvilableForEvening: {
            id: "app.catalogue.reservations.noTimeSlotsAvilableForEvening",
            defaultMessage: "No Time Slots Avilable For This Range In Evening Slot.",
         },
         noTimeSlotsAvilableForNight: {
            id: "app.catalogue.reservations.noTimeSlotsAvilableForNight",
            defaultMessage: "No Time Slots Avilable For This Range In Night Slot.",
         },
         filterGroup:{
            id: "app.catalogue.reservations.filterGroup",
            defaultMessage: "Filter Group",
         },
         filterGroupDesc:{
            id: "app.catalogue.reservations.filterGroupDesc",
            defaultMessage: "List of filter groups",
         },
         createFilterGroup:{
            id: "app.catalogue.reservations.createFilterGroup",
            defaultMessage: "Create Filter Group",
         },
         createFilterGroupDesc:{
            id: "app.catalogue.reservations.createFilterGroupDesc",
            defaultMessage: "Create Filter Group by providing information",
         },
         updateFilterGroup:{
            id: "app.catalogue.reservations.updateFilterGroup",
            defaultMessage: "Update Filter Group",
         },
         updateFilterGroupDesc:{
            id: "app.catalogue.reservations.updateFilterGroupDesc",
            defaultMessage: "Update Filter Group by providing information",
         },
         filterGroupCreateMsg:{
            id: "app.catalogue.reservations.filterGroupCreateMsg",
            defaultMessage: "Filter Group created successfully",
         },
         filterGroupCreateMsgVal:{
            id: "app.catalogue.reservations.filterGroupCreateMsgVal",
            defaultMessage: "Unable to create. Please contact support team.!",
         },
         filterGroupUpdateMsg:{
            id: "app.catalogue.reservations.filterGroupUpdateMsg",
            defaultMessage: "Filter Group updated successfully",
         },
         filterGroupUpdateMsgVal:{
            id: "app.catalogue.reservations.filterGroupUpdateMsgVal",
            defaultMessage: "Unable to update. Please contact support team.!",
         },
         filterGroupDeleteMsg:{
            id: "app.catalogue.reservations.filterGroupDeleteMsg",
            defaultMessage: "Filter Group deleted successfully",
         },
         filterGroupDeleteMsgVal:{
            id: "app.catalogue.reservations.filterGroupDeleteMsgVal",
            defaultMessage: "Unable to delete. Please contact support team.!",
         },
         deleteConfirmationFilterGroup: {
            id: "app.catalogue.reservations.deleteConfirmationFilterGroup",
            defaultMessage: "Are you sure you want to delete Filter Group :",
         },
         reservationFilter: {
            id: "app.catalogue.reservations.reservationFilter",
            defaultMessage: "Reservation Filter",
         },
         reservationFilterDesc: {
            id: "app.catalogue.reservations.reservationFilterDesc",
            defaultMessage: "List of Filters",
         },
         createFilter: {
            id: "app.catalogue.reservations.createFilter",
            defaultMessage: "Create Filter",
         },
         createFilterDesc: {
            id: "app.catalogue.reservations.createFilterDesc",
            defaultMessage: "Provide information for creating filter",
         },
         filterName: {
            id: "app.catalogue.reservations.filterName",
            defaultMessage: "Filter Name",
         },
         filterDatesList: {
            id: "app.catalogue.reservations.filterDatesList",
            defaultMessage: "Filter Dates",
         },
         deleteConfirmationFilter: {
            id: "app.catalogue.reservations.deleteConfirmationFilter",
            defaultMessage: "Are you sure you want to delete Filter :",
         },
         groupType:{
            id: "app.catalogue.reservations.groupType",
            defaultMessage: "Group Type",
         },
         viewDates:{
            id: "app.catalogue.reservations.viewDates",
            defaultMessage: "View Dates",
         },
         filterDeleteMsg:{
            id: "app.catalogue.reservations.filterDeleteMsg",
            defaultMessage: "Filter deleted successfully",
         },
         selectDates:{
            id: "app.catalogue.reservations.selectDates",
            defaultMessage: "Select Dates",
         },
         filterCreateMsg:{
            id: "app.catalogue.reservations.filterCreateMsg",
            defaultMessage: "Filter created successfully",
         },
         filterUpdateMsg:{
            id: "app.catalogue.reservations.filterUpdateMsg",
            defaultMessage: "Filter updated successfully",
         },
         updateFilter:{
            id: "app.catalogue.reservations.updateFilter",
            defaultMessage: "Update Filter",
         },
         updateFilterDesc:{
            id: "app.catalogue.reservations.updateFilterDesc",
            defaultMessage: "Update Filter by providing information",
         },
         guestName:{
            id: "app.catalogue.reservations.guestName",
            defaultMessage: "Guest Name",
         },
         guestMobile:{
            id: "app.catalogue.reservations.guestMobile",
            defaultMessage: "Guest Mobile",
         },
         bookingDate:{
            id: "app.catalogue.reservations.bookingDate",
            defaultMessage: "Booking Date",
         },
         bookingType:{
            id: "app.catalogue.reservations.bookingType",
            defaultMessage: "Booking Type",
         },
         slot:{
            id: "app.catalogue.reservations.slot",
            defaultMessage: "Slot",
         },
         visitorName:{
            id: "app.catalogue.reservations.visitorName",
            defaultMessage: "Visitor Name",
         },
         vistorPhone:{
            id: "app.catalogue.reservations.vistorPhone",
            defaultMessage: "Visitor Phone", 
         },
         enableFilters:{
            id: "app.catalogue.reservations.enableFilters",
            defaultMessage: "Enable Reservation Filter",
         },
         enableFiltersDesc:{
            id: "app.catalogue.reservations.enableFiltersDesc",
            defaultMessage: "Check this for enable reservation filters",
         }
      },
      giftVouchers: {
         generateVoucherCode: {
            id: "app.catalogue.giftVouchers.generateVoucherCode",
            defaultMessage: "Generate Voucher Code",
         },
         enterPrefix: {
            id: "app.catalogue.giftVouchers.enterPrefix",
            defaultMessage: "Enter Prefix",
         },
         noOfVouchers: {
            id: "app.catalogue.giftVouchers.noOfVouchers",
            defaultMessage: "Number of Vouchers",
         },
         deleteConfirmation: {
            id: "app.catalogue.giftVouchers.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete gift voucher",
         },
         generateConfirmation: {
            id: "app.catalogue.giftVouchers.generateConfirmation",
            defaultMessage: "Create voucher codes for gift voucher : ",
         },
         numberOfVouchers: {
            id: "app.catalogue.giftVouchers.numberOfVouchers",
            defaultMessage: "Number of Vouchers",
         },
         source: {
            id: "app.catalogue.giftVouchers.source",
            defaultMessage: "Source",
         },
         title: {
            id: "app.catalogue.giftVouchers.title",
            defaultMessage: "Gift Vouchers",
         },
         desc: {
            id: "app.catalogue.giftVouchers.desc",
            defaultMessage: "List of all Gift Vouchers.",
         },
         createGiftVoucher: {
            id: "app.catalogue.giftVouchers.createGiftVoucher",
            defaultMessage: "Create Gift Voucher",
         },
         createVoucherDesc: {
            id: "app.catalogue.giftVouchers.createVoucherDesc",
            defaultMessage: "Create a gift voucher and customise by adding required attributes.",
         },
         voucherRepresentation: {
            id: "app.catalogue.giftVouchers.voucherRepresentation",
            defaultMessage: "Voucher Representation",
         },
         voucherRepresentationDesc: {
            id: "app.catalogue.giftVouchers.voucherRepresentationDesc",
            defaultMessage:
               "Upload a voucher picture and/or pick a voucher color to uniquely identify the product.",
         },
         voucherDesc: {
            id: "app.catalogue.giftVouchers.voucherDesc",
            defaultMessage: "Voucher Description",
         },
         voucherConfigration: {
            id: "app.catalogue.giftVouchers.voucherConfigration",
            defaultMessage: "Voucher Configration",
         },
         setVoucherConfigration: {
            id: "app.catalogue.giftVouchers.setVoucherConfigration",
            defaultMessage: "Set Voucher Configration.",
         },
         uploadPictureDesc: {
            id: "app.catalogue.giftVouchers.uploadPictureDesc",
            defaultMessage:
               "This allows you to upload a unique gift voucher picture. (Max-size: 10.0 Mb)",
         },
         chooseColorDesc: {
            id: "app.catalogue.giftVouchers.chooseColorDesc",
            defaultMessage: "This allows you to choose a unique gift voucher color.",
         },
         updateGiftVoucher: {
            id: "app.catalogue.giftVouchers.updateGiftVoucher",
            defaultMessage: "Update Gift Voucher",
         },
         updateGiftVoucherDesc: {
            id: "app.catalogue.giftVouchers.updateGiftVoucherDesc",
            defaultMessage: "Edit and/or customise the gift voucher.",
         },
      },
      catalogDashboard: {
         title: {
            id: "app.catalogue.catalogDashboard.title",
            defaultMessage: "Catalog Dashboard",
         },
         desc: {
            id: "app.catalogue.catalogDashboard.desc",
            defaultMessage: "Here's an overview of your brand catalog.",
         },
         insights: {
            id: "app.catalogue.catalogDashboard.insights",
            defaultMessage: "Insights",
         },
         activePromotions: {
            id: "app.catalogue.catalogDashboard.activePromotions",
            defaultMessage: "Active Promotions",
         },
         features: {
            id: "app.catalogue.catalogDashboard.features",
            defaultMessage: "Features",
         },
         count: {
            id: "app.catalogue.catalogDashboard.count",
            defaultMessage: "Count",
         },
         lastUpdate: {
            id: "app.catalogue.catalogDashboard.lastUpdate",
            defaultMessage: "Last Update",
         },
         promotion: {
            id: "app.catalogue.catalogDashboard.promotion",
            defaultMessage: "Promotion",
         },
         type: {
            id: "app.catalogue.catalogDashboard.type",
            defaultMessage: "Type",
         },
         validFrom: {
            id: "app.catalogue.catalogDashboard.validFrom",
            defaultMessage: "Valid From",
         },
         validTo: {
            id: "app.catalogue.catalogDashboard.validTo",
            defaultMessage: "Valid To",
         },
         salesInsights: {
            id: "app.catalogue.catalogDashboard.salesInsights",
            defaultMessage: "Sales Insights",
         },
         products: {
            id: "app.catalogue.catalogDashboard.products",
            defaultMessage: "Products",
         },
         categories: {
            id: "app.catalogue.catalogDashboard.categories",
            defaultMessage: "Categories",
         },
         brands: {
            id: "app.catalogue.catalogDashboard.brands",
            defaultMessage: "Brands",
         },
         combos: {
            id: "app.catalogue.catalogDashboard.combos",
            defaultMessage: "Combos",
         },
         availableForSale: {
            id: "app.catalogue.catalogDashboard.availableForSale",
            defaultMessage: "Available For Sale",
         },
         soldToday: {
            id: "app.catalogue.catalogDashboard.soldToday",
            defaultMessage: "Sale Today",
         },
         coverage: {
            id: "app.catalogue.catalogDashboard.coverage",
            defaultMessage: "Coverage",
         },
         topProdSold: {
            id: "app.catalogue.catalogDashboard.topProdSold",
            defaultMessage: "Top Product Sold",
         },
         none: {
            id: "app.catalogue.catalogDashboard.none",
            defaultMessage: "None",
         },
      },
      combos: {
         comboName: {
            id: "app.catalogue.combos.comboName",
            defaultMessage: "Combo Name",
         },
         comboPrice: {
            id: "app.catalogue.combos.comboPrice",
            defaultMessage: "Combo Price",
         },
         setName: {
            id: "app.catalogue.combos.setName",
            defaultMessage: "Set Name",
         },
         deleteConfirmation: {
            id: "app.catalogue.combos.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete combo ",
         },
         listOfCombos: {
            id: "app.catalogue.combos.listOfCombos",
            defaultMessage: "List of Combos",
         },
         desc: {
            id: "app.catalogue.combos.desc",
            defaultMessage: "Add/Update combos for your brand.",
         },
         createCombo: {
            id: "app.catalogue.combos.createCombo",
            defaultMessage: "Create Combo",
         },
         default: {
            id: "app.catalogue.combos.default",
            defaultMessage: "Sold by Default",
         },
         productSetID: {
            id: "app.catalogue.combos.productSetID",
            defaultMessage: "Product Set ID",
         },
         productSetName: {
            id: "app.catalogue.combos.productSetName",
            defaultMessage: "Product Set Name",
         },
         options: {
            id: "app.catalogue.combos.options",
            defaultMessage: "Options to choose",
         },
         totalProducts: {
            id: "app.catalogue.combos.totalProducts",
            defaultMessage: "Total Products",
         },
         deleteProdSetConfirmation: {
            id: "app.catalogue.combos.deleteProdSetConfirmation",
            defaultMessage: "Are you sure you want to delete Product Set ",
         },
         noProdSet: {
            id: "app.catalogue.combos.noProdSet",
            defaultMessage: "No Product Set created.",
         },
         noProdSetDesc: {
            id: "app.catalogue.combos.noProdSetDesc",
            defaultMessage: "Product set can be created by clicking `Add Product Set`.",
         },
         createComboDesc: {
            id: "app.catalogue.combos.createComboDesc",
            defaultMessage: "Create all the combo offer for your customers.",
         },
         managePricing: {
            id: "app.catalogue.combos.managePricing",
            defaultMessage: "Manage Store Wise Pricing.",
         },
         regions: {
            id: "app.catalogue.combos.regions",
            defaultMessage: "Region(s) available",
         },
         regionStore: {
            id: "app.catalogue.combos.regionStore",
            defaultMessage: "Region/Store",
         },
         prodSets: {
            id: "app.catalogue.combos.prodSets",
            defaultMessage: "Product Sets",
         },
         prodSetsDesc: {
            id: "app.catalogue.combos.prodSetsDesc",
            defaultMessage: "Create product set with different products.",
         },
         addProdSet: {
            id: "app.catalogue.combos.addProdSet",
            defaultMessage: "Add Product Set",
         },
         editProdSet: {
            id: "app.catalogue.combos.editProdSet",
            defaultMessage: "Edit Product Set",
         },
         editProdSetDesc: {
            id: "app.catalogue.combos.editProdSetDesc",
            defaultMessage: "Edit Product Set with different Products",
         },
         prodSetsDesc1: {
            id: "app.catalogue.combos.prodSetsDesc1",
            defaultMessage:
               "Create combos by adding products and selecting combination that you wish to create.",
         },
         prodSetsInfo: {
            id: "app.catalogue.combos.prodSetsInfo",
            defaultMessage: "Product Set Information",
         },
         addProdDesc: {
            id: "app.catalogue.combos.addProdDesc",
            defaultMessage: "Add new Products to the list while creating a combo.",
         },
         editCombo: {
            id: "app.catalogue.combos.editCombo",
            defaultMessage: "Edit Combo",
         },
         editComboDesc: {
            id: "app.catalogue.combos.editComboDesc",
            defaultMessage: "Edit the product and customize by updating the combo.",
         },
         editProducts: {
            id: "app.catalogue.combos.editProducts",
            defaultMessage: "Edit Products",
         },
         editProductsDesc: {
            id: "app.catalogue.combos.editProductsDesc",
            defaultMessage: "Edit Products you wish to consign.",
         },
      },
      tables: {
         title: {
            id: "app.catalogue.tables.title",
            defaultMessage: "Tables",
         },
         roomName: {
            id: "app.catalogue.tables.roomName",
            defaultMessage: "Room Name",
         },
         floorName: {
            id: "app.catalogue.tables.floorName",
            defaultMessage: "Floor Name",
         },
         tableType: {
            id: "app.catalogue.tables.tableType",
            defaultMessage: "Table Type",
         },
         minSeatCount: {
            id: "app.catalogue.tables.minSeatCount",
            defaultMessage: "Min. Seat Count",
         },
         maxSeatCount: {
            id: "app.catalogue.tables.maxSeatCount",
            defaultMessage: "Max. Seat Count",
         },
         includeTable: {
            id: "app.catalogue.tables.includeTable",
            defaultMessage: "Include Room In Table Calculation",
         },
         deleteConfirmation: {
            id: "app.catalogue.tables.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete Table : ",
         },
         listOfTables: {
            id: "app.catalogue.tables.listOfTables",
            defaultMessage: "List of all Tables.",
         },
         createTables: {
            id: "app.catalogue.tables.createTables",
            defaultMessage: "Create Table",
         },
         noTable: {
            id: "app.catalogue.tables.noTable",
            defaultMessage: "No Tables Record Found",
         },
         tableName: {
            id: "app.catalogue.tables.tableName",
            defaultMessage: "Table Name",
         },
         tableInformation: {
            id: "app.catalogue.tables.tableInformation",
            defaultMessage: "Table Information",
         },
         selectFloor: {
            id: "app.catalogue.tables.selectFloor",
            defaultMessage: "Select Floor",
         },
         selectRoom: {
            id: "app.catalogue.tables.selectRoom",
            defaultMessage: "Select Room",
         },
         floorWarning: {
            id: "app.catalogue.tables.floorWarning",
            defaultMessage: "Select Floor First",
         },
         tableInfo: {
            id: "app.catalogue.tables.tableInfo",
            defaultMessage: "Table Information",
         },
         tableNameErr: {
            id: "app.catalogue.tables.tableNameErr",
            defaultMessage: "Tabel name is required",
         },
         tableTypeErr: {
            id: "app.catalogue.tables.tableTypeErr",
            defaultMessage: "Tabel type is required",
         },
         storeNameErr: {
            id: "app.catalogue.tables.storeNameErr",
            defaultMessage: "Store name is required",
         },
         floorNameErr: {
            id: "app.catalogue.tables.floorNameErr",
            defaultMessage: "Floor name is required",
         },
         seatCountErr: {
            id: "app.catalogue.tables.seatCountErr",
            defaultMessage: "Minimum seat count should not be greater that maximum seat count",
         },
         roomNameErr: {
            id: "app.catalogue.tables.roomNameErr",
            defaultMessage: "Room name is required",
         },
         editTable: {
            id: "app.catalogue.tables.editTable",
            defaultMessage: "Edit Table",
         },
      }
   },
   setup: {
      chainProfile: {
         chainProfileTitle: {
            id: "app.setup.chainProfile.chainProfileTitle",
            defaultMessage: "Chain Profile",
         },
         chainProfileDescription: {
            id: "app.setup.chainProfile.chainProfileDescription",
            defaultMessage: "Chain Profile",
         },
         fssaiLicenseNumber: {
            id: "app.setup.chainProfile.fssaiLicenseNumber",
            defaultMessage: "FSSAI License Number",
         },
         enableInventory: {
            id: "app.setup.chainProfile.enableInventory",
            defaultMessage: "Enable Inventory",
         },
         enableInventoryDescription: {
            id: "app.setup.chainProfile.enableInventoryDescription",
            defaultMessage: "This will enable you to perform all inventory operations.",
         },
         automaticPrint: {
            id: "app.setup.chainProfile.automaticPrint",
            defaultMessage: "Automatic Print",
         },
         automaticPrintDescription: {
            id: "app.setup.chainProfile.automaticPrintDescription",
            defaultMessage:
               "Allow this option to disable automatic print after the final bill settlement.",
         },
         allowVariablePricing: {
            id: "app.setup.chainProfile.allowVariablePricing",
            defaultMessage: "Allow Variable Pricing",
         },
         allowVariablePricingDescription: {
            id: "app.setup.chainProfile.allowVariablePricingDescription",
            defaultMessage:
               "This allows a cashier to update the price of a product while generating an invoice.",
         },
         allowOpenItem: {
            id: "app.setup.chainProfile.allowOpenItem",
            defaultMessage: "Allow Open Item",
         },
         allowOpenItemDescription: {
            id: "app.setup.chainProfile.allowOpenItemDescription",
            defaultMessage: "This allows the cashier to generate a bill of a specific amount.",
         },
         custumerInfo: {
            id: "app.setup.chainProfile.custumerInfo",
            defaultMessage: "Customer Info",
         },
         custumerInfoDescription: {
            id: "app.setup.chainProfile.custumerInfoDescription",
            defaultMessage:
               "Enable this option if you want the mandatorily capture the customer information on bill (Invoice).",
         },
         allowOpenBill: {
            id: "app.setup.chainProfile.allowOpenBill",
            defaultMessage: "Allow Open Bill",
         },
         allowOpenBillDescription: {
            id: "app.setup.chainProfile.allowOpenBillDescription",
            defaultMessage: "This allows the cashier to generate a bill of a specific amount.",
         },
         roundingFinalAmt: {
            id: "app.setup.chainProfile.roundingFinalAmt",
            defaultMessage: "Enable Rounding Of Final Amount",
         },
         roundingFinalAmtDescription: {
            id: "app.setup.chainProfile.roundingFinalAmtDescription",
            defaultMessage:
               "Enable this option if you want the final bill (invoice) to be rounded off to the nearest integer value.",
         },
         lowStockAlert: {
            id: "app.setup.chainProfile.lowStockAlert",
            defaultMessage: "Low Stock Alert",
         },
         lowStockAlertDescription: {
            id: "app.setup.chainProfile.lowStockAlertDescription",
            defaultMessage: "Gives you an alert when products go below their alert level",
         },
         outOfStockProd: {
            id: "app.setup.chainProfile.outOfStockProd",
            defaultMessage: "Hide Out of Stock Products",
         },
         outOfStockProdDescription: {
            id: "app.setup.chainProfile.outOfStockProdDescription",
            defaultMessage:
               "Hide out of stock products from the list of products on Selling Screen.",
         },
         proformaInvoice: {
            id: "app.setup.chainProfile.proformaInvoice",
            defaultMessage: "Enable Proforma Invoice",
         },
         proformaInvoiceDescription: {
            id: "app.setup.chainProfile.proformaInvoiceDescription",
            defaultMessage: "This will enable you to perform all inventory operations.",
         },
         userPayment: {
            id: "app.setup.chainProfile.userPayment",
            defaultMessage: "Show Billing User On Payment",
         },
         userPaymentDescription: {
            id: "app.setup.chainProfile.userPaymentDescription",
            defaultMessage:
               "Enable this option if you want to change the user during the billing operation.",
         },
         manageStore: {
            id: "app.setup.chainProfile.manageStore",
            defaultMessage: "Manage store day and batches",
         },
         manageStoreDescription: {
            id: "app.setup.chainProfile.manageStoreDescription",
            defaultMessage: "This will help in managing store day and batches.",
         },
      },
      storeList: {
         storeListTitle: {
            id: "app.setup.storeList.storeListTitle",
            defaultMessage: "Stores List",
            description: "Component Title",
         },
         storeListDescription: {
            id: "app.setup.storeList.storeListDescription",
            defaultMessage: "Stores List",
         },
         createStoreListTitle: {
            id: "app.setup.storeList.createStoreListTitle",
            defaultMessage: "Create Store",
         },
         createStoreListDescription: {
            id: "app.setup.storeList.createStoreListDescription",
            defaultMessage: "Create Stores List",
         },
         editStoreListDescription: {
            id: "app.setup.storeList.editStoreListDescription",
            defaultMessage: "Edit Stores List",
         },
         enableVoucher: {
            id: "app.setup.storeList.enableVoucher",
            defaultMessage: "Enable Voucher Validation",
         },
         customGst: {
            id: "app.setup.storeList.customGst",
            defaultMessage: "Customer GST Order Prefix",
         },
         reciptSettings: {
            id: "app.setup.storeList.reciptSettings",
            defaultMessage: "Recipt Settings",
         },
      },
      stockTransferRule: {
         stockTransferRuleTitle: {
            id: "app.setup.stockTransferRule.stockTransferRuleTitle",
            defaultMessage: "Stock Transfer Rule",
            description: "Component Title",
         },
         stockTransferRuleDescription: {
            id: "app.setup.stockTransferRule.stockTransferRuleDescription",
            defaultMessage: "List of All Regions",
         },
         stockTransferRuleTotalKeyword : {
            id: "app.setup.stockTransferRule.stockTransferRuleTotalKeyword",
            defaultMessage : "STR config(s)"
         },
         stockTransferRuleTitleCreate: {
            id: "app.setup.stockTransferRule.stockTransferRuleTitleCreate",
            defaultMessage: "Create Stock Transfer Rule",
         },
         stockTransferRuleTitleUpdate: {
            id: "app.setup.stockTransferRule.stockTransferRuleTitleUpdate",
            defaultMessage: "Update Stock Transfer Rule",
         },
         stockTransferRuleTitleCreateDesc: {
            id: "app.setup.stockTransferRule.stockTransferRuleTitleCreateDesc",
            defaultMessage: "Source - Destination mapping for stock transfer rule",
         },
      },
      region: {
         regionTitle: {
            id: "app.setup.region.regionTitle",
            defaultMessage: "Region",
            description: "Component Title",
         },
         regionDescription: {
            id: "app.setup.region.regionDescription",
            defaultMessage: "List of All Regions",
         },
         createRegionTitle: {
            id: "app.setup.region.createRegionTitle",
            defaultMessage: "Create Region",
            description: "Component Title",
         },
         createRegionDescription: {
            id: "app.setup.region.createRegionDescription",
            defaultMessage: "Description of this screen is to mentioned here",
         },
         editRegionTitle: {
            id: "app.setup.region.editRegionTitle",
            defaultMessage: "Edit Region",
            description: "Component Title",
         },
         editRegionDescription: {
            id: "app.setup.region.editRegionDescription",
            defaultMessage: "Description of this screen is to mentioned here",
         },
         viewStoresTitle: {
            id: "app.setup.region.viewStoresTitle",
            defaultMessage: "View Store",
            description: "Component Title",
         },
         viewStoresDescription: {
            id: "app.setup.region.viewStoresDescription",
            defaultMessage: "Description of this screen is to mentioned here",
         },
         regionCardTitle: {
            id: "app.setup.region.regionCardTitle",
            defaultMessage: "Region Information",
         },
         regionName: {
            id: "app.setup.region.regionName",
            defaultMessage: "Region Name",
         },
         regionDescription1: {
            id: "app.setup.region.regionDescription1",
            defaultMessage: "Region Description",
         },
         create: {
            id: "app.setup.region.create",
            defaultMessage: "Create Region",
         },
         deleteRegion: {
            id: "app.setup.region.deleteRegion",
            defaultMessage: "Delete Region",
         },
         deleteRegionDesc: {
            id: "app.setup.region.deleteRegionDesc",
            defaultMessage: "Are you sure you want to delete region",
         },
         regionNameValOne: {
            id: "app.setup.region.regionNameValOne",
            defaultMessage: "Region Name must be atleast 3 character long.",
         },
         regionNameValTwo: {
            id: "app.setup.region.regionNameValTwo",
            defaultMessage: "Region Name should not contain special characters",
         },
         descVal: {
            id: "app.setup.region.descVal",
            defaultMessage: "Description should not contain special characters",
         },
      },
      centralWarehouses: {
         centralWarehousesTitle: {
            id: "app.setup.centralWarehouses.centralWarehousesTitle",
            defaultMessage: "Central Warehouses",
            description: "Component Title",
         },
         centralWarehousesDescription: {
            id: "app.setup.centralWarehouses.centralWarehousesDescription",
            defaultMessage: "List Of all Central Warehouses.",
         },
         createCentralWarehousesTitle: {
            id: "app.setup.centralWarehouses.createCentralWarehousesTitle",
            defaultMessage: "Create Warehouse",
            description: "Component Title",
         },
         createCentralWarehousesDescription: {
            id: "app.setup.centralWarehouses.createCentralWarehousesDescription",
            defaultMessage: "Create Warehouse by providing below information. ",
         },
         editCentralWarehousesTitle: {
            id: "app.setup.centralWarehouses.editCentralWarehousesTitle",
            defaultMessage: "Edit Warehouse",
            description: "Component Title",
         },
         editCentralWarehousesDescription: {
            id: "app.setup.centralWarehouses.editCentralWarehousesDescription",
            defaultMessage: "Edit warehouse by updating the below information.",
         },
         viewCentralWarehousesTitle: {
            id: "app.setup.centralWarehouses.viewCentralWarehousesTitle",
            defaultMessage: "View Warehouse",
            description: "Component Title",
         },
         viewCentralWarehousesDescription: {
            id: "app.setup.centralWarehouses.viewCentralWarehousesDescription",
            defaultMessage: "Your warehouse information. ",
         },
         warehouseInformation: {
            id: "app.setup.centralWarehouses.warehouseInformation",
            defaultMessage: "Warehouse information. ",
         },
         create: {
            id: "app.setup.centralWarehouses.create",
            defaultMessage: "Create Warehouse",
         },
         delete: {
            id: "app.setup.centralWarehouses.delete",
            defaultMessage: "You want to delete Warehouse",
         },
         warehouseEmail: {
            id: "app.setup.centralWarehouses.warehouseEmail",
            defaultMessage: "Warehouse Email",
         },
         info: {
            id: "app.setup.centralWarehouses.info",
            defaultMessage: "Stock Request Notifications will be sent on entered email addresses",
         },
      },
      attributes: {
         attributesTitle: {
            id: "app.setup.attributes.attributesTitle",
            defaultMessage: "Attributes",
            description: "Component Title",
         },
         attributesDescription: {
            id: "app.setup.attributes.attributesDescription",
            defaultMessage: "List of all attributes. ",
         },
         createAttributesTitle: {
            id: "app.setup.attributes.createAttributesTitle",
            defaultMessage: "Create Attribute",
            description: "Component Title",
         },
         createAttributesDescription: {
            id: "app.setup.attributes.createAttributesDescription",
            defaultMessage: "Create attribute by providing below information.",
         },
         editAttributesTitle: {
            id: "app.setup.attributes.editAttributesTitle",
            defaultMessage: "Edit Attribute",
            description: "Component Title",
         },
         editAttributesDescription: {
            id: "app.setup.attributes.editAttributesDescription",
            defaultMessage: "Update attribute by providing below information.",
         },
         isMandatory: {
            id: "app.setup.attributes.isMandatory",
            defaultMessage: "Is Mandatory ?",
         },
         isUnique: {
            id: "app.setup.attributes.isUnique",
            defaultMessage: "Is Unique ?",
         },
         printOnBill: {
            id: "app.setup.attributes.printOnBill",
            defaultMessage: "Print on bill ?",
         },
         isActive: {
            id: "app.setup.attributes.isActive",
            defaultMessage: "Is Active ?",
         },
         customAttributeInformation: {
            id: "app.setup.attributes.customAttributeInformation",
            defaultMessage: "Custom Attribute Information",
         },
         productIdentifier: {
            id: "app.setup.attributes.productIdentifier",
            defaultMessage: "Product Identifier",
         },
         productIdentifierDesc: {
            id: "app.setup.attributes.productIdentifierDesc",
            defaultMessage:
               "Product identifier attributes distinguishes the product on the basis of physical properties of the product. For example a pendrive can have multiple capacity variants like 16GB, 32GB & 64 GB.",
         },
         stockIdentifier: {
            id: "app.setup.attributes.stockIdentifier",
            defaultMessage: "Stock Identifier",
         },
         stockIdentifierDesc: {
            id: "app.setup.attributes.stockIdentifierDesc",
            defaultMessage:
               "Stock identifier attributes distinguishes the product on the basis of stock operation properties like product's cost price, selling price, manufacturing date etc.",
         },
         attributeNameWhileBilling: {
            id: "app.setup.attributes.attributeNameWhileBilling",
            defaultMessage: "Enabling this would print the attribute name while billing.",
         },
         attributeNameWhileBillingLabel: {
            id: "app.setup.attributes.attributeNameWhileBillingLabel",
            defaultMessage: "Print this attribute on bill?",
         },
         isRequiredForPurchase: {
            id: "app.setup.attributes.isRequiredForPurchase",
            defaultMessage: "Enabling this will make the attribute  applicable for Purchase Order",
         },
         isRequiredForPurchaseLabel: {
            id: "app.setup.attributes.isRequiredForPurchaseLabel",
            defaultMessage: "Is Required For Purchase?",
         },
         attributeUniquelyApplicable: {
            id: "app.setup.attributes.attributeUniquelyApplicable",
            defaultMessage: "Enabling this will make the attribute uniquely applicable.",
         },
         attributeUniquelyApplicableLabel: {
            id: "app.setup.attributes.attributeUniquelyApplicableLabel",
            defaultMessage: "Should the attribute be Unique?",
         },
         attributeMandatoryApplicable: {
            id: "app.setup.attributes.attributeMandatoryApplicable",
            defaultMessage: "Enabling this will make the attribute mandatorily applicable.",
         },
         attributeMandatoryApplicableLabel: {
            id: "app.setup.attributes.attributeMandatoryApplicableLabel",
            defaultMessage: "Should the attribute be mandatory?",
         },
         attributeTypeNumeric: {
            id: "app.setup.attributes.attributeTypeNumeric",
            defaultMessage: "Numeric",
         },
         attributeTypeText: {
            id: "app.setup.attributes.attributeTypeText",
            defaultMessage: "Text",
         },
         attributeTypeList: {
            id: "app.setup.attributes.attributeTypeList",
            defaultMessage: "List",
         },
         attributeTypeDate: {
            id: "app.setup.attributes.attributeTypeDate",
            defaultMessage: "Date",
         },
         attributeTypeTime: {
            id: "app.setup.attributes.attributeTypeTime",
            defaultMessage: "Time",
         },
         attributeTypeDateTime: {
            id: "app.setup.attributes.attributeTypeDateTime",
            defaultMessage: "Date/Time",
         },
         attributeRequired: {
            id: "app.setup.attributes.attributeRequired",
            defaultMessage: "Attribute name is required",
         },
         optionLabel: {
            id: "app.setup.attributes.optionLabel",
            defaultMessage: "Options",
         },
         attributeOptionTooltip: {
            id: "app.setup.attributes.attributeOptionTooltip",
            defaultMessage: "You can add multiple options, type and press enter.",
         },
         deleteAttribute: {
            id: "app.setup.attributes.deleteAttribute",
            defaultMessage: "Delete Attribute",
         },
         deleteConfirmation: {
            id: "app.setup.attributes.deleteConfirmation",
            defaultMessage: "Are you sure you want to delete this attribute ?",
         },
         systemAttributesList: {
            id: "app.setup.attributes.systemAttributesList",
            defaultMessage: "List of all system attributes.",
         },
         emptySystemAttributes: {
            id: "app.setup.attributes.emptySystemAttributes",
            defaultMessage: "System attributes list empty",
         },
      },
      remarks: {
         remarksTitle: {
            id: "app.setup.remarks.remarksTitle",
            defaultMessage: "Remarks",
            description: "Component Title",
         },
         remarksDescription: {
            id: "app.setup.remarks.remarksDescription",
            defaultMessage: "List of all Remarks. ",
         },
         createRemarksTitle: {
            id: "app.setup.remarks.createRemarksTitle",
            defaultMessage: "Create Remark",
            description: "Component Title",
         },
         createRemarksDescription: {
            id: "app.setup.remarks.createRemarksDescription",
            defaultMessage: "Select remark type & create remarks.",
         },
         editRemarksTitle: {
            id: "app.setup.remarks.editRemarksTitle",
            defaultMessage: "Edit Remark",
            description: "Component Title",
         },
         editRemarksDescription: {
            id: "app.setup.remarks.editRemarksDescription",
            defaultMessage: "Update remark information. ",
         },
         createRemarksInformation: {
            id: "app.setup.remarks.createRemarksInformation",
            defaultMessage: "Remark information.",
         },
         sureDelete: {
            id: "app.setup.remarks.sureDelete",
            defaultMessage: "Are you sure you want to delete this remark?",
         },
         delete: { id: "app.setup.remarks.delete", defaultMessage: "Delete Remark" },
         sno: { id: "app.setup.remarks.sno", defaultMessage: "S.No" },
         remarkType: { id: "app.setup.remarks.remarkType", defaultMessage: "Remark Type" },
         remark: {
            id: "app.setup.remarks.remark",
            defaultMessage: "Remark Description",
         },
         remarkName: {
            id: "app.setup.remarks.remarkName",
            defaultMessage: "Remark Name",
         },
      },
      customizedPrintReceipt: {
         customizedPrintReceiptTitle: {
            id: "app.setup.customizedPrintReceipt.customizedPrintReceiptTitle",
            defaultMessage: "Customized Print Receipt",
            description: "Component Title",
         },
         customizedPrintReceiptDescription: {
            id: "app.setup.customizedPrintReceipt.customizedPrintReceiptDescription",
            defaultMessage: "Description of the Customized Receipt SMS is to mentioned here. ",
         },
         duplicateInvoiceLabel: {
            id: "app.setup.customizedPrintReceipt.duplicateInvoiceLabel",
            defaultMessage: "Duplicate Invoice Label is required!",
         },
         taxNumberLabel: {
            id: "app.setup.customizedPrintReceipt.taxNumberLabel",
            defaultMessage: "Tax Number Label is required!",
         },
         receiptInformation: {
            id: "app.setup.customizedPrintReceipt.receiptInformation",
            defaultMessage: "Receipt Information",
         },
         removeLogo: {
            id: "app.setup.customizedPrintReceipt.removeLogo",
            defaultMessage: "Remove Logo",
         },
         receiptHeading: {
            id: "app.setup.customizedPrintReceipt.receiptHeading",
            defaultMessage: "Receipt Heading",
         },
         receiptQRCode: {
            id: "app.setup.customizedPrintReceipt.receiptQRCode",
            defaultMessage: "Receipt QR Code",
         },
         receiptBarcode: {
            id: "app.setup.customizedPrintReceipt.receiptBarcode",
            defaultMessage: "Receipt Barcode",
         },
         receiptHeader: {
            id: "app.setup.customizedPrintReceipt.receiptHeader",
            defaultMessage: "Receipt Header",
         },
         receiptFooter: {
            id: "app.setup.customizedPrintReceipt.receiptFooter",
            defaultMessage: "Receipt Footer",
         },
         miscellaneousSettings: {
            id: "app.setup.customizedPrintReceipt.miscellaneousSettings",
            defaultMessage: "Miscellaneous Settings",
         },
         showCompanyName: {
            id: "app.setup.customizedPrintReceipt.showCompanyName",
            defaultMessage: "Show Company Name On Receipt",
         },
         showCompanyNameDesc: {
            id: "app.setup.customizedPrintReceipt.showCompanyNameDesc",
            defaultMessage: "Enable this setting to display company name on the receipt.",
         },
         showBrandName: {
            id: "app.setup.customizedPrintReceipt.showBrandName",
            defaultMessage: "Show Brand Name On Receipt",
         },
         showBrandNameDesc: {
            id: "app.setup.customizedPrintReceipt.showBrandNameDesc",
            defaultMessage: "Enable this setting to display brand name on the receipt.",
         },
         showStoreName: {
            id: "app.setup.customizedPrintReceipt.showStoreName",
            defaultMessage: "Show Store Name On Receipt",
         },
         showStoreNameDesc: {
            id: "app.setup.customizedPrintReceipt.showStoreNameDesc",
            defaultMessage: "Enable this setting to display store name on the receipt.",
         },
         showCompanyAddress: {
            id: "app.setup.customizedPrintReceipt.showCompanyAddress",
            defaultMessage: "Show Company Address On Receipt",
         },
         showCompanyAddressDesc: {
            id: "app.setup.customizedPrintReceipt.showCompanyAddressDesc",
            defaultMessage: "Enable this option to display points redeemed in the message.",
         },
         showStoreAddress: {
            id: "app.setup.customizedPrintReceipt.showStoreAddress",
            defaultMessage: "Show Store Address On Receipt",
         },
         showStoreAddressDesc: {
            id: "app.setup.customizedPrintReceipt.showStoreAddressDesc",
            defaultMessage: "Enable this setting to display store address on the receipt.",
         },
         showContactInfo: {
            id: "app.setup.customizedPrintReceipt.showContactInfo",
            defaultMessage: "Show Store Contact Info",
         },
         showContactInfoDesc: {
            id: "app.setup.customizedPrintReceipt.showContactInfoDesc",
            defaultMessage:
               "Enable this setting to display store contact information on the receipt.",
         },
         showSubTotal: {
            id: "app.setup.customizedPrintReceipt.showSubTotal",
            defaultMessage: "Show SubTotal",
         },
         showSubTotalDesc: {
            id: "app.setup.customizedPrintReceipt.showSubTotalDesc",
            defaultMessage: "Enable this setting to display subtotal information on the receipt.",
         },
         showItemPriceIncludesTaxes: {
            id: "app.setup.customizedPrintReceipt.showItemPriceIncludesTaxes",
            defaultMessage: "Show Item Prices Including Taxes",
         },
         showItemPriceIncludesTaxesDesc: {
            id: "app.setup.customizedPrintReceipt.showItemPriceIncludesTaxesDesc",
            defaultMessage:
               "Enable this setting to display item prices including taxes information on the receipt.",
         },
         showTotalMRPSaving: {
            id: "app.setup.customizedPrintReceipt.showTotalMRPSaving",
            defaultMessage: "Show Total MRP Saving",
         },
         showTotalMRPSavingDesc: {
            id: "app.setup.customizedPrintReceipt.showTotalMRPSavingDesc",
            defaultMessage:
               "Enable this setting to display total MRP saving information on the receipt.",
         },
         showTenderAmount: {
            id: "app.setup.customizedPrintReceipt.showTenderAmount",
            defaultMessage: "Show Tender Amount",
         },
         showTenderAmountDesc: {
            id: "app.setup.customizedPrintReceipt.showTenderAmountDesc",
            defaultMessage:
               "Enable this setting to display tender amount information on the receipt.",
         },
         showPrintTime: {
            id: "app.setup.customizedPrintReceipt.showPrintTime",
            defaultMessage: "Show Print Time",
         },
         showPrintTimeDesc: {
            id: "app.setup.customizedPrintReceipt.showPrintTimeDesc",
            defaultMessage: "Enable this to show Print Time on recipt.",
         },
         showOrderType: {
            id: "app.setup.customizedPrintReceipt.showOrderType",
            defaultMessage: "Show Order Type",
         },
         showOrderTypeDesc: {
            id: "app.setup.customizedPrintReceipt.showOrderTypeDesc",
            defaultMessage: "Enable this to show OrderType on recipt.",
         },
         showMRP: {
            id: "app.setup.customizedPrintReceipt.showMRP",
            defaultMessage: "Show MRP",
         },
         showMRPDesc: {
            id: "app.setup.customizedPrintReceipt.showMRPDesc",
            defaultMessage: "Enable this to show Product MRP on recipt.",
         },
         showProductSize: {
            id: "app.setup.customizedPrintReceipt.showProductSize",
            defaultMessage: "Show Product Size",
         },
         showProductSizeDesc: {
            id: "app.setup.customizedPrintReceipt.showProductSizeDesc",
            defaultMessage: "Enable this to show Product Size on recipt.",
         },
         showTaxSummary: {
            id: "app.setup.customizedPrintReceipt.showTaxSummary",
            defaultMessage: "Show Tax Summary",
         },
         showTaxSummaryDesc: {
            id: "app.setup.customizedPrintReceipt.showTaxSummaryDesc",
            defaultMessage: "Enable this to show Product Size on recipt.",
         },
         taxNumberLabel: {
            id: "app.setup.customizedPrintReceipt.taxNumberLabel",
            defaultMessage: "Tax Number Label",
         },
         showCinNumber: {
            id: "app.setup.customizedPrintReceipt.showCinNumber",
            defaultMessage: "Show CIN No. On Receipt",
         },
         showCinNumberDesc: {
            id: "app.setup.customizedPrintReceipt.showCinNumberDesc",
            defaultMessage: "Enable this setting to display CIN Number on the receipt.",
         },
         showTinNumber: {
            id: "app.setup.customizedPrintReceipt.showTinNumber",
            defaultMessage: "Show TIN No. On Receipt",
         },
         showTinNumberDesc: {
            id: "app.setup.customizedPrintReceipt.showTinNumberDesc",
            defaultMessage: "Enable this setting to display TIN Number on the receipt.",
         },
         showGSTNumber: {
            id: "app.setup.customizedPrintReceipt.showGSTNumber",
            defaultMessage: "Show GST No. On Receipt",
         },
         showGSTNumberDesc: {
            id: "app.setup.customizedPrintReceipt.showGSTNumberDesc",
            defaultMessage: "Enable this setting to display GST Number on the receipt.",
         },
         showChangeAmount: {
            id: "app.setup.customizedPrintReceipt.showChangeAmount",
            defaultMessage: "Show Change",
         },
         showChangeAmountDesc: {
            id: "app.setup.customizedPrintReceipt.showChangeAmountDesc",
            defaultMessage: "Enable this setting to display change on the receipt.",
         },
         printComboProductPrice: {
            id: "app.setup.customizedPrintReceipt.printComboProductPrice",
            defaultMessage: "Show Combo Product Price",
         },
         printComboProductPriceDesc: {
            id: "app.setup.customizedPrintReceipt.printComboProductPriceDesc",
            defaultMessage: "Enable this setting to display combo product price on the receipt.",
         },
         hideInvoiceNoOnBill: {
            id: "app.setup.customizedPrintReceipt.hideInvoiceNoOnBill",
            defaultMessage: "Hide Invoice Number on Bill",
         },
         hideInvoiceNoOnBillDesc: {
            id: "app.setup.customizedPrintReceipt.hideInvoiceNoOnBillDesc",
            defaultMessage: "Enable this setting to Hide Invoice Number on the receipt.",
         },
         showQrCode: {
            id: "app.setup.customizedPrintReceipt.showQrCode",
            defaultMessage: "Show QR Code On Receipt",
         },
         showQrCodeDesc: {
            id: "app.setup.customizedPrintReceipt.showQrCodeDesc",
            defaultMessage: "Enable this setting to display QR code on the receipt.",
         },
         showBarcode: {
            id: "app.setup.customizedPrintReceipt.showBarcode",
            defaultMessage: "Show Barcode On Receipt",
         },
         showBarcodeDesc: {
            id: "app.setup.customizedPrintReceipt.showBarcodeDesc",
            defaultMessage: "Enable this setting to display barcode on the receipt.",
         },
         printLogoInBottom: {
            id: "app.setup.customizedPrintReceipt.printLogoInBottom",
            defaultMessage: "Show Receipt Logo in Bottom",
         },
         printLogoInBottomDesc: {
            id: "app.setup.customizedPrintReceipt.printLogoInBottomDesc",
            defaultMessage: "Enable this setting to display Receipt Logo in Bottom.",
         },
         showBillingUser: {
            id: "app.setup.customizedPrintReceipt.showBillingUser",
            defaultMessage: "Show Billing User",
         },
         showBillingUserDesc: {
            id: "app.setup.customizedPrintReceipt.showBillingUserDesc",
            defaultMessage: "Enable this setting to show billing User on recipt.",
         },
         showProductHsnSacCode: {
            id: "app.setup.customizedPrintReceipt.showProductHsnSacCode",
            defaultMessage: "Show Product HsnSacCode",
         },
         showProductHsnSacCodeDesc: {
            id: "app.setup.customizedPrintReceipt.showProductHsnSacCodeDesc",
            defaultMessage: "Enable this to show Product HsnSacCode on recipt.",
         },
         showProductBarcode: {
            id: "app.setup.customizedPrintReceipt.showProductBarcode",
            defaultMessage: "Show Product Barcode",
         },
         showProductBarcodeDesc: {
            id: "app.setup.customizedPrintReceipt.showProductBarcodeDesc",
            defaultMessage: "Enable this to show Product Barcode on recipt.",
         },
         showProductWiseTaxes: {
            id: "app.setup.customizedPrintReceipt.showProductWiseTaxes",
            defaultMessage: "Show Product Wise Taxes",
         },
         showProductWiseTaxesDesc: {
            id: "app.setup.customizedPrintReceipt.showProductWiseTaxesDesc",
            defaultMessage: "Enable this to show Product Wise Taxes on recipt.",
         },
         showDiscountOnProduct: {
            id: "app.setup.customizedPrintReceipt.showDiscountOnProduct",
            defaultMessage: "Show Discount On Product",
         },
         showDiscountOnProductDesc: {
            id: "app.setup.customizedPrintReceipt.showDiscountOnProductDesc",
            defaultMessage: "Enable this to show Discount on recipt.",
         },
         printTaxInfoOnBill: {
            id: "app.setup.customizedPrintReceipt.printTaxInfoOnBill",
            defaultMessage: "Print Tax Information on Bill",
         },
         printTaxInfoOnBillDesc: {
            id: "app.setup.customizedPrintReceipt.printTaxInfoOnBillDesc",
            defaultMessage: "Enable this to show Tax info on bill.",
         },
         duplicateInvoiceLabel: {
            id: "app.setup.customizedPrintReceipt.duplicateInvoiceLabel",
            defaultMessage: "Duplicate Invoice Label",
         },
         bottom: {
            id: "app.setup.customizedPrintReceipt.bottom",
            defaultMessage: "Bottom",
         },
         top: {
            id: "app.setup.customizedPrintReceipt.top",
            defaultMessage: "Top",
         },
      },
      applicationDeviceSettings: {
         applicationDeviceSettingsTitle: {
            id: "app.setup.applicationDeviceSettings.applicationDeviceSettingsTitle",
            defaultMessage: "Application Device Settings",
            description: "Component Title",
         },
         applicationDeviceSettingsDescription: {
            id: "app.setup.applicationDeviceSettings.applicationDeviceSettingsDescription",
            defaultMessage: "List of Devices & Customise Application Device Settings. ",
         },
         editApplicationDeviceSettingsTitle: {
            id: "app.setup.applicationDeviceSettings.editApplicationDeviceSettingsTitle",
            defaultMessage: "Device",
            description: "Component Title",
         },
         editApplicationDeviceSettingsDescription: {
            id: "app.setup.applicationDeviceSettings.editApplicationDeviceSettingsDescription",
            defaultMessage: "Customize Device Settings.",
         },
         sno: {
            id: "app.setup.applicationDeviceSettings.sno",
            defaultMessage: "S.No",
         },
         deviceID: {
            id: "app.setup.applicationDeviceSettings.deviceID",
            defaultMessage: "Device Id",
         },
         printerName: {
            id: "app.setup.applicationDeviceSettings.printerName",
            defaultMessage: "Printer",
         },
         paymentTerminalName: {
            id: "app.setup.applicationDeviceSettings.paymentTerminalName",
            defaultMessage: "Payment Terminal",
         },
         doYouWanttoShowImages: {
            id: "app.setup.applicationDeviceSettings.doYouWanttoShowImages",
            defaultMessage: "Do You Want to Show Images ?",
         },
         doYouWanttoAskQuantity: {
            id: "app.setup.applicationDeviceSettings.doYouWanttoAskQuantity",
            defaultMessage: "Do You Want to Ask Quantity ?",
         },
         deviceStatus: {
            id: "app.setup.applicationDeviceSettings.deviceStatus",
            defaultMessage: "Device Status",
         },
         deviceSettings: {
            id: "app.setup.applicationDeviceSettings.deviceSettings",
            defaultMessage: "Device Settings",
         },
         printer: {
            id: "app.setup.applicationDeviceSettings.printer",
            defaultMessage: "Printer",
         },
         paymentTerminal: {
            id: "app.setup.applicationDeviceSettings.paymentTerminal",
            defaultMessage: "Payment Terminal",
         },
         showProductImagesonApp: {
            id: "app.setup.applicationDeviceSettings.showProductImagesonApp",
            defaultMessage: "Show Product Images on App",
         },
         showProductsOnHomepage: {
            id: "app.setup.applicationDeviceSettings.showProductsOnHomepage",
            defaultMessage: "Show Products On Homepage",
         },
         askQuantityOnProductClick: {
            id: "app.setup.applicationDeviceSettings.askQuantityOnProductClick",
            defaultMessage: "Ask Quantity On Product Click",
         },
         enableOneclickOdering: {
            id: "app.setup.applicationDeviceSettings.enableOneclickOdering",
            defaultMessage: "Enable One click Odering",
         },
      },
      softwareLicense: {
         softwareLicenseTitle: {
            id: "app.setup.softwareLicense.softwareLicenseTitle",
            defaultMessage: "Software License",
            description: "Component Title",
         },
         softwareLicenseDescription: {
            id: "app.setup.softwareLicense.softwareLicenseDescription",
            defaultMessage: "Description of the software license is to mentioned here. ",
         },
         paymentSoftwareLicense: {
            id: "app.setup.softwareLicense.paymentSoftwareLicense",
            defaultMessage: "Payment Software License.",
         },
         paymentSoftwareLicenseDescription: {
            id: "app.setup.softwareLicense.paymentSoftwareLicenseDescription",
            defaultMessage: "Description of the chain profile is to mentioned here.",
         },
         licenseRenewalConfirmation: {
            id: "app.setup.softwareLicense.licenseRenewalConfirmation",
            defaultMessage: "License Renewal Confirmation.",
         },
         licenseRenewalConfirmationDescription: {
            id: "app.setup.softwareLicense.licenseRenewalConfirmationDescription",
            defaultMessage: "Congratulations your licences have been renewed.",
         },
         licenseNumber: {
            id: "app.setup.softwareLicense.licenseNumber",
            defaultMessage: "License Number",
         },
         dueDate: {
            id: "app.setup.softwareLicense.dueDate",
            defaultMessage: "Due Date",
         },
         remainingDays: {
            id: "app.setup.softwareLicense.remainingDays",
            defaultMessage: "Remaining Days",
         },
         softwareLicenceSelected: {
            id: "app.setup.softwareLicense.softwareLicenceSelected",
            defaultMessage: "Software License selected",
         },
         selectOneOrMore: {
            id: "app.setup.softwareLicense.selectOneOrMore",
            defaultMessage: "Select one or more to extend or change plan",
         },
         selectPaymentPlan: {
            id: "app.setup.softwareLicense.selectPaymentPlan",
            defaultMessage: "Select Payment Plan",
         },
         newPlanAmount: {
            id: "app.setup.softwareLicense.newPlanAmount",
            defaultMessage: "New Plan Amount",
         },
         selectPaymentGateway: {
            id: "app.setup.softwareLicense.selectPaymentGateway",
            defaultMessage: "Select payment gateway",
         },
         havePromoCode: {
            id: "app.setup.softwareLicense.havePromoCode",
            defaultMessage: "Have a promo code ?",
         },
         addDiscount: {
            id: "app.setup.softwareLicense.addDiscount",
            defaultMessage: "Add Discount",
         },
         discountAppliedSuccessFully: {
            id: "app.setup.softwareLicense.discountAppliedSuccessFully",
            defaultMessage: "Have a promo code ?",
         },
         payNow: {
            id: "app.setup.softwareLicense.payNow",
            defaultMessage: "Pay Now",
         },
         rounding: {
            id: "app.setup.softwareLicense.rounding",
            defaultMessage: "Rounding",
         },
         nextDueDate: {
            id: "app.setup.softwareLicense.nextDueDate",
            defaultMessage: "Next Due Date",
         },
         renewedLicense: {
            id: "app.setup.softwareLicense.renewedLicense",
            defaultMessage: "Renewed license(s)",
         },
      },
      staffManagement: {
         staffManagementTitle: {
            id: "app.setup.staffManagement.staffManagementTitle",
            defaultMessage: "Staff Management",
            description: "Component Title",
         },
         staffManagementDescription: {
            id: "app.setup.staffManagement.staffManagementDescription",
            defaultMessage: "List of all the users and respective stores.",
         },
         createStaffTitle: {
            id: "app.setup.staffManagement.createStaffTitle",
            defaultMessage: "Create Staff",
            description: "Component Title",
         },
         staffListEmpty: {
            id: "app.setup.staffManagement.staffListEmpty",
            defaultMessage: "Staff list empty",
         },
         deleteUser: {
            id: "app.setup.staffManagement.deleteUser",
            defaultMessage: "Delete User",
         },
         confirmPassword: {
            id: "app.setup.staffManagement.confirmPassword",
            defaultMessage: "Please confirm password to delete the user.",
         },
         password: {
            id: "app.setup.staffManagement.password",
            defaultMessage: "Password",
         },
         cancel: { id: "app.setup.staffManagement.cancel", defaultMessage: "Cancel" },
         delete: { id: "app.setup.staffManagement.delete", defaultMessage: "Delete" },
         sno: { id: "app.setup.staffManagement.sno", defaultMessage: "S.No" },
         username: {
            id: "app.setup.staffManagement.username",
            defaultMessage: "Username",
         },
         name: { id: "app.setup.staffManagement.name", defaultMessage: "Name" },
         phoneNum: {
            id: "app.setup.staffManagement.phoneNum",
            defaultMessage: "Mobile Number",
         },
         rolename: { id: "app.setup.staffManagement.rolename", defaultMessage: "Role" },
         email: {
            id: "app.setup.staffManagement.email",
            defaultMessage: "Email Address",
         },

         createStaffDescription: {
            id: "app.setup.staffManagement.createStaffDescription",
            defaultMessage: "Create staff, set role & select permissions.",
         },
         editStaffTitle: {
            id: "app.setup.staffManagement.editStaffTitle",
            defaultMessage: "Edit Staff",
            description: "Component Title",
         },
         editStaffDescription: {
            id: "app.setup.staffManagement.editStaffDescription",
            defaultMessage: "Update staff profile, set role & select permissions",
         },
         save: { id: "app.setup.staffManagement.save", defaultMessage: "Save" },
         staffInformation: {
            id: "app.setup.staffManagement.staffInformation",
            defaultMessage: "Staff Information",
         },
         firstName: {
            id: "app.setup.staffManagement.firstName",
            defaultMessage: "First Name",
         },
         lastName: {
            id: "app.setup.staffManagement.lastName",
            defaultMessage: "Last Name",
         },
         confirmPass: {
            id: "app.setup.staffManagement.confirmPass",
            defaultMessage: "Confirm Password",
         },
         mobileNumber: {
            id: "app.setup.staffManagement.mobileNumber",
            defaultMessage: "Mobile Number",
         },
         emailAddress: {
            id: "app.setup.staffManagement.emailAddress",
            defaultMessage: "Email Address",
         },
         role: { id: "app.setup.staffManagement.role", defaultMessage: "Role" },
         permissions: {
            id: "app.setup.staffManagement.permissions",
            defaultMessage: "Permissions",
         },
         blueLabel1: {
            id: "app.setup.staffManagement.blueLabel1",
            defaultMessage: "* Select user's role first",
         },
         stores: { id: "app.setup.staffManagement.stores", defaultMessage: "Stores" },
         warehouses: {
            id: "app.setup.staffManagement.warehouses",
            defaultMessage: "Warehouses",
         },
         storeId: {
            id: "app.setup.staffManagement.storeId",
            defaultMessage: "Store ID",
         },
         storeName: {
            id: "app.setup.staffManagement.storeName",
            defaultMessage: "Store Name",
         },
         state: { id: "app.setup.staffManagement.state", defaultMessage: "State" },
         outletAddPincode: {
            id: "app.setup.staffManagement.outletAddPincode",
            defaultMessage: "Pin Code",
         },
         viewStoreTitle: {},
         viewStoreDescription: {},
         discountLimits: {
            id: "app.setup.staffManagement.discountLimits",
            defaultMessage: "Discount Limits",
         },
         discountLimitType: {
            id: "app.setup.staffManagement.discountLimitType",
            defaultMessage: "Discount Limit Type",
         },
         discountLimitValue: {
            id: "app.setup.staffManagement.discountLimitValue",
            defaultMessage: "Discount Limit Value",
         },
         maxDiscountAmt: {
            id: "app.setup.staffManagement.maxDiscountAmt",
            defaultMessage: "Maximum Discount Amount",
         }
      },
      editStaffManagement: {
         mobileNumber: {
            id: "app.setup.editStaffManagement.mobileNumber",
            defaultMessage: "Mobile Number",
         },
         email: {
            id: "app.setup.editStaffManagement.email",
            defaultMessage: "Email",
         },
         sendPassword: {
            id: "app.setup.editStaffManagement.sendPassword",
            defaultMessage: "Send Password to above contact details",
         },
         chooseFromBelowOptions: {
            id: "app.setup.editStaffManagement.chooseFromBelowOptions",
            defaultMessage: "Choose from below options",
         },
         useSystemGeneratedPassword: {
            id: "app.setup.editStaffManagement.useSystemGeneratedPassword.",
            defaultMessage: "Use system generated password.",
         },
         newPassword: {
            id: "app.setup.editStaffManagement.newPassword",
            defaultMessage: "New Password",
         },
         DoYouWanttoGenerateItYourself: {
            id: "app.setup.editStaffManagement.DoYouWanttoGenerateItYourself",
            defaultMessage: "Do you want to generate it yourself ?",
         },
         changePassword: {
            id: "app.setup.editStaffManagement.changePassword",
            defaultMessage: "Change Password",
         },
         password: {
            id: "app.setup.editStaffManagement.password",
            defaultMessage: "Password",
         },
         confirmPassword: {
            id: "app.setup.editStaffManagement.confirmPassword",
            defaultMessage: "Confirm Password",
         },
         back: { id: "app.setup.editStaffManagement.back", defaultMessage: "Back" },
         next: { id: "app.setup.editStaffManagement.next", defaultMessage: "Next" },
         updateMobileNumber: {
            id: "app.setup.editStaffManagement.updateMobileNumber",
            defaultMessage: "Update Mobile Number",
         },
         enterNewMobileNumber: {
            id: "app.setup.editStaffManagement.enterNewMobileNumber",
            defaultMessage: "Enter New Mobile Number",
         },
         enterOTP: {
            id: "app.setup.editStaffManagement.enterOTP",
            defaultMessage: "Enter OTP",
         },
         OTPsentNumber: {
            id: "app.setup.editStaffManagement.OTPsentNumber",
            defaultMessage: "OTP will be sent to the registered mobile number",
         },
         OTPEnteredInvalid: {
            id: "app.setup.editStaffManagement.OTPEnteredInvalid",
            defaultMessage: "OTP entered is invalid",
         },
         reSendOTP: {
            id: "app.setup.editStaffManagement.reSendOTP",
            defaultMessage: "Re-Send OTP",
         },
         OTPVerifiedMobileNumberUpdated: {
            id: "app.setup.editStaffManagement.OTPVerifiedMobileNumberUpdated",
            defaultMessage: "OTP Verified mobile number updated",
         },
         verify: {
            id: "app.setup.editStaffManagement.verify",
            defaultMessage: "Verify",
         },
         gender: {
            id: "app.setup.editStaffManagement.gender",
            defaultMessage: "Gender",
         },
         passwordCopied : {
            id: "app.setup.editStaffManagement.passwordCopied",
            defaultMessage: "Password Copied!!",
         },
         passwordAuthentication : {
            id: "app.setup.editStaffManagement.passwordAuthentication",
            defaultMessage: "Password Authentication",
         },
         wrongPassword : {
            id: "app.setup.editStaffManagement.wrongPassword",
            defaultMessage: "Wrong Password",
         },
      },

      currencyConversion: {
         title: {
            id: "app.setup.currencyConversion.title",
            defaultMessage: "Currency Conversions",
         },
         description: {
            id: "app.setup.currencyConversion.description",
            defaultMessage: "List of all currencies.",
         },
         createConversion: {
            id: "app.setup.currencyConversion.createConversion",
            defaultMessage: "Create Conversion",
         },
         sureDelete: {
            id: "app.setup.currencyConversion.sureDelete",
            defaultMessage: "Are you sure you want to delete :",
         },
         conversion: {
            id: "app.setup.currencyConversion.conversion",
            defaultMessage: "Conversion",
         },
         cancel: {
            id: "app.setup.currencyConversion.cancel",
            defaultMessage: "Cancel",
         },
         delete: {
            id: "app.setup.currencyConversion.delete",
            defaultMessage: "Delete",
         },
         sno: { id: "app.setup.currencyConversion.sno", defaultMessage: "S.No" },
         baseCurrencyCode: {
            id: "app.setup.currencyConversion.baseCurrencyCode",
            defaultMessage: "Base Currency Code",
         },
         baseCurrencyName: {
            id: "app.setup.currencyConversion.baseCurrencyName",
            defaultMessage: "Base Currency Name",
         },
         currencyCode: {
            id: "app.setup.currencyConversion.currencyCode",
            defaultMessage: "Target Currency Code",
         },
         currencyName: {
            id: "app.setup.currencyConversion.currencyName",
            defaultMessage: "Target Currency Name",
         },
         conversionFactor: {
            id: "app.setup.currencyConversion.conversionFactor",
            defaultMessage: "Conversion Factor",
         },
         createCurrencyConversions: {
            id: "app.setup.currencyConversion.createCurrencyConversions",
            defaultMessage: "Create Currency Conversions",
         },
         updateCurrencyConversions: {
            id: "app.setup.currencyConversion.updateCurrencyConversions",
            defaultMessage: "Update Currency Conversions",
         },
         filesuccessfullyuploaded: {
            id: "app.setup.currencyConversion.filesuccessfullyuploaded",
            defaultMessage: "File successfully uploaded",
         },
      },
      creatCurrencyConversion: {
         title: {
            id: "app.setup.creatCurrencyConversion.title",
            defaultMessage: "Create Currency Conversion",
         },
         description: {
            id: "app.setup.creatCurrencyConversion.description",
            defaultMessage: "Create currency conversion by providing below information.",
         },
      },
      updateCurrencyConversion: {
         title: {
            id: "app.setup.updateCurrencyConversion.title",
            defaultMessage: "Update Currency Conversion",
         },
         description: {
            id: "app.setup.updateCurrencyConversion.description",
            defaultMessage: "Update currency conversion by providing below information.",
         },
      },

      deviceSyncLogs: {
         deviceSyncLogsTitle: {
            id: "app.setup.deviceSyncLogs.deviceSyncLogsTitle",
            defaultMessage: "Device Sync Logs Report",
            description: "Component Title",
         },
         deviceSyncLogsDescription: {
            id: "app.setup.deviceSyncLogs.deviceSyncLogsDescription",
            defaultMessage: "Detail report for Device Sync Logs.",
         },
         id: { id: "app.setup.deviceSyncLogs.id", defaultMessage: "ID" },
         deviceID: {
            id: "app.setup.deviceSyncLogs.deviceID",
            defaultMessage: "Device ID",
         },
         entityType: {
            id: "app.setup.deviceSyncLogs.entityType",
            defaultMessage: "Sync Type",
         },
         storeName: {
            id: "app.setup.deviceSyncLogs.storeName",
            defaultMessage: "Store Name",
         },
         userName: {
            id: "app.setup.deviceSyncLogs.userName",
            defaultMessage: "User Name",
         },
      },
      floor: {
         floorTitle: {
            id: "app.setup.floor.floorTitle",
            defaultMessage: "Floor",
            description: "Component Title",
         },
         floorDiscription: {
            id: "app.setup.floor.floorDiscription",
            defaultMessage: "List Of Floors.",
         },
         createFloorTitle: {
            id: "app.setup.floor.createFloorTitle",
            defaultMessage: "Create Floor",
            description: "Component Title",
         },
         createFloorDiscription: {
            id: "app.setup.floor.createFloorDiscription",
            defaultMessage: "Create Floor",
         },
         createFloorDeleteMsg: {
            id: "app.setup.floor.createFloorDeleteMsg",
            defaultMessage: "Are you sure you want to delete floor",
         },
         createFloorTablesIn: {
            id: "app.setup.floor.createFloorTablesIn",
            defaultMessage: "Tables in",
         },
         createFloorRoomsIn: {
            id: "app.setup.floor.createFloorRoomsIn",
            defaultMessage: "Rooms in",
         },
         createFloorName: {
            id: "app.setup.floor.createFloorName",
            defaultMessage: "Floor Name",
         },
         createFloorPerformTableOperation: {
            id: "app.setup.floor.createFloorPerformTableOperation",
            defaultMessage: "Perform Table Operation",
         },
         createFloorIsPrimaryFloor: {
            id: "app.setup.floor.createFloorIsPrimaryFloor",
            defaultMessage: "Is Primary Floor",
         },
         createFloorFloorStatus: {
            id: "app.setup.floor.createFloorFloorStatus",
            defaultMessage: "Floor Status",
         },
         createFloorViewRooms: {
            id: "app.setup.floor.createFloorViewRooms",
            defaultMessage: "View Rooms",
         },
         createFloorViewTables: {
            id: "app.setup.floor.createFloorViewTables",
            defaultMessage: "View Tables",
         },
         createFloorRoomName: {
            id: "app.setup.floor.createFloorRoomName",
            defaultMessage: "Room Name",
         },
         createFloorRoomSize: {
            id: "app.setup.floor.createFloorRoomSize",
            defaultMessage: "Room Size",
         },
         createFloorTableName: {
            id: "app.setup.floor.createFloorTableName",
            defaultMessage: "Table Name",
         },
         createFloorTableType: {
            id: "app.setup.floor.createFloorTableType",
            defaultMessage: "Table Type",
         },
         createFloorMinSeatCount: {
            id: "app.setup.floor.createFloorMinSeatCount",
            defaultMessage: "Min. Seat Count",
         },
         createFloorMaxSeatCount: {
            id: "app.setup.floor.createFloorMaxSeatCount",
            defaultMessage: "Max. Seat Count",
         },
         createFloorPerformTableManagementOperation: {
            id: "app.setup.floor.createFloorPerformTableManagementOperation",
            defaultMessage: "Perform Table Management Operation",
         },
         createFloorFloorInformation: {
            id: "app.setup.floor.createFloorFloorInformation",
            defaultMessage: "Floor Information",
         },
         createFloorSelectFloorStatus: {
            id: "app.setup.floor.createFloorSelectFloorStatus",
            defaultMessage: "Select Floor Status",
         },
         editFloorTitle: {
            id: "app.setup.floor.editFloorTitle",
            defaultMessage: "Edit Floor",
            description: "Component Title",
         },
         editFloorDiscription: {
            id: "app.setup.floor.editFloorDiscription",
            defaultMessage: "Edit Floors.",
         },
      },
      rooms: {
         roomsTitle: {
            id: "app.setup.rooms.roomsTitle",
            defaultMessage: "Rooms",
            description: "Component Title",
         },
         roomsDiscription: {
            id: "app.setup.rooms.roomsDiscription",
            defaultMessage: "List Of Rooms.",
         },
         createRoomTitle: {
            id: "app.setup.rooms.createRoomTitle",
            defaultMessage: "Create Rooms",
            description: "Component Title",
         },
         createRoomDiscription: {
            id: "app.setup.rooms.createRoomDiscription",
            defaultMessage: "Create Rooms.",
         },
         editRoomTitle: {
            id: "app.setup.rooms.editRoomTitle",
            defaultMessage: "Edit Rooms",
            description: "Component Title",
         },
         editRoomDiscription: {
            id: "app.setup.rooms.editRoomDiscription",
            defaultMessage: "Edit Rooms.",
         },
      },
      table: {
         tableTitle: {
            id: "app.setup.table.tableTitle",
            defaultMessage: "Tables",
            description: "Component Title",
         },
         tableDiscription: {
            id: "app.setup.table.tableDiscription",
            defaultMessage: "List Of Tables.",
         },
         createTableTitle: {
            id: "app.setup.table.createTableTitle",
            defaultMessage: "Create Tables",
            description: "Component Title",
         },
         createTableDiscription: {
            id: "app.setup.table.createTableDiscription",
            defaultMessage: "Create Tables",
         },
         editTableTitle: {
            id: "app.setup.table.editTableTitle",
            defaultMessage: "Edit Tables",
            description: "Component Title",
         },
         editTableDiscription: {
            id: "app.setup.table.editTableDiscription",
            defaultMessage: "Edit Tables",
         },
      },
      globalCurrencies: {
         title: {
            id: "app.setup.globalCurrencies.title",
            defaultMessage: "Global Currencies",
         },
         description: {
            id: "app.setup.globalCurrencies.description",
            defaultMessage: "List of all currencies.",
         },
         sno: { id: "app.setup.globalCurrencies.sno", defaultMessage: "S.No." },
         currencyCode: {
            id: "app.setup.globalCurrencies.currencyCode",
            defaultMessage: "Currency Code.",
         },
         currencyName: {
            id: "app.setup.globalCurrencies.currencyName",
            defaultMessage: "Currency Name.",
         },
      },
      salesAssesmentPeriod: {
         title: {
            id: "app.setup.salesAssesmentPeriod.title",
            defaultMessage: "Sales Assessment Periods",
         },
         description: {
            id: "app.setup.salesAssesmentPeriod.description",
            defaultMessage: " Description of all sales assessment periods",
         },
         create: {
            id: "app.setup.salesAssesmentPeriod.create",
            defaultMessage: "Create",
         },
         deleteMsg: {
            id: "app.setup.salesAssesmentPeriod.deleteMsg",
            defaultMessage: "Are you sure you want to delete this Sale Assesment Period",
         },
         id: { id: "app.setup.salesAssesmentPeriod.id", defaultMessage: "ID" },
         periodName: {
            id: "app.setup.salesAssesmentPeriod.periodName",
            defaultMessage: "Period Name",
         },
         periodDescription: {
            id: "app.setup.salesAssesmentPeriod.periodDescription",
            defaultMessage: "Description",
         },
         startDate: {
            id: "app.setup.salesAssesmentPeriod.startDate",
            defaultMessage: "Start Date",
         },
         endDate: {
            id: "app.setup.salesAssesmentPeriod.endDate",
            defaultMessage: "End Date",
         },
      },
      createSalesAssesmentPeriod: {
         updateTitle: {
            id: "app.setup.createSalesAssesmentPeriod.updateTitle",
            defaultMessage: "Update Sales Assessment",
         },
         createTitle: {
            id: "app.setup.createSalesAssesmentPeriod.createTitle",
            defaultMessage: "Create Sales Assessment",
         },
         description: {
            id: "app.setup.createSalesAssesmentPeriod.description",
            defaultMessage: "Sales Assessment Period by providing below information.",
         },
         save: {
            id: "app.setup.createSalesAssesmentPeriod.save",
            defaultMessage: "Save",
         },
         periodName: {
            id: "app.setup.createSalesAssesmentPeriod.periodName",
            defaultMessage: "Period Name",
         },
         dateRange: {
            id: "app.setup.createSalesAssesmentPeriod.dateRange",
            defaultMessage: "Date Range",
         },
         desLabel: {
            id: "app.setup.createSalesAssesmentPeriod.desLabel",
            defaultMessage: "Description",
         },
      },

      salesTarget: {
         title: {
            id: "app.setup.salesTarget.title",
            defaultMessage: "Sales Target Configuration",
         },
         description: {
            id: "app.setup.salesTarget.description",
            defaultMessage: "Description of all sales target configuration",
         },
         create: { id: "app.setup.salesTarget.create", defaultMessage: "Create" },
         fileName: {
            id: "app.setup.salesTarget.fileName",
            defaultMessage: "SalesTargetConfigs",
         },
         deletemsg: {
            id: "app.setup.salesTarget.deletemsg",
            defaultMessage: "Are you sure you want to delete this Sales Target Configuration",
         },
         id: { id: "app.setup.salesTarget.id", defaultMessage: "ID" },
         configurationName: {
            id: "app.setup.salesTarget.configurationName",
            defaultMessage: "Configuration Name",
         },
         source: { id: "app.setup.salesTarget.source", defaultMessage: "Source" },
         sourceName: {
            id: "app.setup.salesTarget.sourceName",
            defaultMessage: "Source Name",
         },
         entity: { id: "app.setup.salesTarget.entity", defaultMessage: "Entity" },
         entityCount: {
            id: "app.setup.salesTarget.entityCount",
            defaultMessage: "Entity Count",
         },
         combinedTarget: {
            id: "app.setup.salesTarget.combinedTarget",
            defaultMessage: "Combined Target",
         },
         targetAchieved: {
            id: "app.setup.salesTarget.targetAchieved",
            defaultMessage: "Target Achieved",
         },
      },
      createSalesTarget: {
         title: {
            id: "app.setup.createSalesTarget.title",
            defaultMessage: "Create Target",
         },
         description: {
            id: "app.setup.createSalesTarget.description",
            defaultMessage: "Create Target by providing below information.",
         },
         salesAssesmentPeriod: {
            id: "app.setup.createSalesTarget.salesAssesmentPeriod",
            defaultMessage: "Sales Assesment Period",
         },
         selectSalesPeriod: {
            id: "app.setup.createSalesTarget.selectSalesPeriod",
            defaultMessage: "Select Sales Period",
         },
         createConfiguration: {
            id: "app.setup.createSalesTarget.createConfiguration",
            defaultMessage: "Create Configuration",
         },
         configurationName: {
            id: "app.setup.createSalesTarget.configurationName",
            defaultMessage: "Configuration Name",
         },
         remarks: {
            id: "app.setup.createSalesTarget.remarks",
            defaultMessage: "Remarks",
         },
         targetSourceLevel: {
            id: "app.setup.createSalesTarget.targetSourceLevel",
            defaultMessage: "Target Source Level",
         },
         selectSourcelevel: {
            id: "app.setup.createSalesTarget.selectSourcelevel",
            defaultMessage: "Select Source level",
         },
         selectEntity: {
            id: "app.setup.createSalesTarget.selectEntity",
            defaultMessage: "Select Entity",
         },
         select: {
            id: "app.setup.createSalesTarget.select",
            defaultMessage: "Select",
         },
         source: {
            id: "app.setup.createSalesTarget.source",
            defaultMessage: "Source",
         },
         entityCount: {
            id: "app.setup.createSalesTarget.entityCount",
            defaultMessage: "Entity Count",
         },
         totalTargetValue: {
            id: "app.setup.createSalesTarget.totalTargetValue",
            defaultMessage: "Total Target Value",
         },
         useBulkUpload: {
            id: "app.setup.createSalesTarget.useBulkUpload",
            defaultMessage: "Use Bulk Upload",
         },
         id: { id: "app.setup.createSalesTarget.id", defaultMessage: "ID" },
         entityName: {
            id: "app.setup.createSalesTarget.entityName",
            defaultMessage: "Entity Name",
         },
         targetValue: {
            id: "app.setup.createSalesTarget.targetValue",
            defaultMessage: "Target Value",
         },
         uploadExcel: {
            id: "app.setup.createSalesTarget.uploadExcel",
            defaultMessage: "Click here to download excel template & upload it to multiple",
         },
         uploadTemplate: {
            id: "app.setup.createSalesTarget.uploadTemplate",
            defaultMessage: "Upload Template",
         },
      },
      updateSalesTarget: {
         updateTitle: {
            id: "app.setup.updateSalesTarget.updateTitle",
            defaultMessage: "Update Target",
         },
         description: {
            id: "app.setup.updateSalesTarget.description",
            defaultMessage: " Update Target by providing below information.",
         },
         updateConfiguration: {
            id: "app.setup.updateSalesTarget.updateConfiguration",
            defaultMessage: "Update Configuration",
         },
      },

      viewSalesTarget: {
         targetAchieved: {
            id: "app.setup.viewSalesTarget.targetAchieved",
            defaultMessage: "Target Achieved",
         },
         configurationDetails: {
            id: "app.setup.viewSalesTarget.configurationDetails",
            defaultMessage: "Configuration Details",
         },
         description: {
            id: "app.setup.viewSalesTarget.description",
            defaultMessage: "Description of sales target configuration",
         },
         salesAssesmentPeriod: {
            id: "app.setup.viewSalesTarget.salesAssesmentPeriod",
            defaultMessage: "Sales Assesment Period",
         },
         sourceLevel: {
            id: "app.setup.viewSalesTarget.sourceLevel",
            defaultMessage: "Source Level",
         },
         sourceName: {
            id: "app.setup.viewSalesTarget.sourceName",
            defaultMessage: "Source Name",
         },
         targetEntity: {
            id: "app.setup.viewSalesTarget.targetEntity",
            defaultMessage: "Target Entity",
         },
         combinedTarget: {
            id: "app.setup.viewSalesTarget.combinedTarget",
            defaultMessage: "Combined Target",
         },
      },

      channel: {
         channelTitle: { id: "app.setup.channel.channelTitle", defaultMessage: "Channels" },
         channelDescription: {
            id: "app.setup.channel.channelDescription",
            defaultMessage: "List of Channels",
         },
         channelId: { id: "app.setup.channel.channelId", defaultMessage: "Channel ID" },
         createChannel: {
            id: "app.setup.channel.createChannel",
            defaultMessage: "Create Channel",
         },
         channelName: {
            id: "app.setup.channel.channelName",
            defaultMessage: "Channel Name",
         },
         channelType: {
            id: "app.setup.channel.channelType",
            defaultMessage: "Channel Type",
         },
         channelCode: {
            id: "app.setup.channel.channelCode",
            defaultMessage: "Channel Code",
         },
         priceListId: {
            id: "app.setup.channel.priceListId",
            defaultMessage: "Price List ID",
         },
         channelPhone: {
            id: "app.setup.channel.channelPhone",
            defaultMessage: "Channel Phone",
         },
         countryCode: {
            id: "app.setup.channel.countryCode",
            defaultMessage: "Country Code",
         },
         channelEmail: {
            id: "app.setup.channel.channelEmail",
            defaultMessage: "Channel Email",
         },
         startDateUTC: {
            id: "app.setup.channel.startDateUTC",
            defaultMessage: "Start Date UTC",
         },
         startDateLocal: {
            id: "app.setup.channel.startDateLocal",
            defaultMessage: "Start Date Local",
         },
         endDateUTC: {
            id: "app.setup.channel.endDateUTC",
            defaultMessage: "End Date UTC",
         },
         endDateLocal: {
            id: "app.setup.channel.endDateLocal",
            defaultMessage: "End Date Local",
         },
         timezone: { id: "app.setup.channel.timezone", defaultMessage: "Timezone" },
         tinNumber: { id: "app.setup.channel.tinNumber", defaultMessage: "Tin Number" },
         panNumber: { id: "app.setup.channel.panNumber", defaultMessage: "Pan Number" },
         cinNumber: { id: "app.setup.channel.cinNumber", defaultMessage: "Cin Number" },
         serviceTaxNumber: {
            id: "app.setup.channel.serviceTaxNumber",
            defaultMessage: "Service Tax Number",
         },
         gstNumber: { id: "app.setup.channel.gstNumber", defaultMessage: "Gst Number" },
         delete: {
            id: "app.setup.channel.delete",
            defaultMessage: "Are you sure you want to delete Channel",
         },
         channelCreationDate: {
            id: "app.setup.channel.channelCreationDate",
            defaultMessage: "Channel Creation Date",
         },
         applyCharges: {
            id: "app.setup.channel.applyCharges",
            defaultMessage: "Applied Charges",
         },
         applyTaxes: {
            id: "app.setup.channel.applyTaxes",
            defaultMessage: "Applied Taxes",
         },
         applyDiscounts: {
            id: "app.setup.channel.applyDiscounts",
            defaultMessage: "Applied Discounts",
         },
      },
      createChannel: {
         title: {
            id: "app.setup.createChannel.title",
            defaultMessage: "channel by providing information below.",
         },
         channelType: {
            id: "app.setup.createChannel.channelType",
            defaultMessage: "Channel Type ",
         },
         channelName: {
            id: "app.setup.createChannel.channelName",
            defaultMessage: "Channel Name ",
         },
         priceList: {
            id: "app.setup.createChannel.priceList",
            defaultMessage: "Price List",
         },
         channelCode: {
            id: "app.setup.createChannel.channelCode",
            defaultMessage: "Channel Code",
         },
         addressLine1: {
            id: "app.setup.createChannel.addressLine1",
            defaultMessage: "Address Line 1",
         },
         addressLine2: {
            id: "app.setup.createChannel.addressLine2",
            defaultMessage: "Address Line 2",
         },
         city: { id: "app.setup.createChannel.city", defaultMessage: "City" },
         state: { id: "app.setup.createChannel.state", defaultMessage: "State" },
         pincode: { id: "app.setup.createChannel.pincode", defaultMessage: "Pincode" },
         country: { id: "app.setup.createChannel.country", defaultMessage: "Country" },
         contactInformation: {
            id: "app.setup.createChannel.contactInformation",
            defaultMessage: "Contact Information",
         },
         mobileNumber: {
            id: "app.setup.createChannel.mobileNumber",
            defaultMessage: "Mobile Number",
         },
         eamilAddress: {
            id: "app.setup.createChannel.eamilAddress",
            defaultMessage: "E-mail Address",
         },
         eamilAddressErrMsg: {
            id: "app.setup.createChannel.eamilAddressErrMsg",
            defaultMessage: "Channel Email must be a valid email",
         },
         currency: {
            id: "app.setup.createChannel.currency",
            defaultMessage: "Currency",
         },
         recieptSetting: {
            id: "app.setup.createChannel.recieptSetting",
            defaultMessage: "Reciept Settings",
         },
         cin: { id: "app.setup.createChannel.cin", defaultMessage: "CIN" },
         tin: { id: "app.setup.createChannel.tin", defaultMessage: "TIN" },
         serviceTaxNumber: {
            id: "app.setup.createChannel.serviceTaxNumber",
            defaultMessage: "Service Tax Number",
         },
         gstNumber: {
            id: "app.setup.createChannel.gstNumber",
            defaultMessage: "GST Number",
         },
      },

      PaymentSoftwareLicense: {
         id: "app.setup.PaymentSoftwareLicense",
         defaultMessage: "Payment Software License",
         description: "Description of the page is to mentioned here.",
      },
   },
   common: {
      selectLocation:{
         id: "app.common.selectLocation",
         defaultMessage: "Select Location",
      },
      duplicateEntity: {
         id: "app.common.duplicateEntity",
         defaultMessage: "Following entities are duplicate.",
      },
      noRecordsFound: {
         id: "app.common.noRecordsFound",
         defaultMessage: "No Records Found",
         description: "",
      },
      needHelp: {
         id: "app.common.needHelp",
         defaultMessage: "Need Help?",
         description: "",
      },
      bulkOperations: {
         id: "app.common.bulkOperations",
         defaultMessage: "Bulk Operations",
         description: "",
      },
      step1of2: {
         id: "app.common.step1of2",
         defaultMessage: "Step 1 of 2",
         description: "",
      },
      step2of2: {
         id: "app.common.step2of2",
         defaultMessage: "Step 2 of 2",
         description: "",
      },
      surityForDelete: {
         id: "app.common.surityForDelete",
         defaultMessage: "Are you sure you want to delete",
         description: "",
      },
      viewStores: {
         id: "app.common.viewStores",
         defaultMessage: "View Stores",
         description: "",
      },
      store: {
         viewStoresTitle: {
            id: "app.common.store.viewStoresTitle",
            defaultMessage: "View Stores",
            description: "",
         },
         viewStoresDescription: {
            id: "app.common.store.viewStoresDescription",
            defaultMessage: "List Of All Respected Stores",
            description: "",
         },
      },
      save: {
         id: "app.common.save",
         defaultMessage: "Save",
         description: "",
      },
      basicInformation: {
         id: "app.header",
         defaultMessage: "Basic Information",
         description: "",
      },
      companyName: {
         id: "app.common.companyName",
         defaultMessage: "Company Name",
         description: "",
      },
      address1: {
         id: "app.common.address1",
         defaultMessage: "Address Line 1",
         description: "",
      },
      address2: {
         id: "app.common.address2",
         defaultMessage: "Address Line 2",
         description: "",
      },
      city: {
         id: "app.common.city",
         defaultMessage: "City",
         description: "",
      },
      state: {
         id: "app.common.state",
         defaultMessage: "State",
         description: "",
      },
      pin: {
         id: "app.common.pin",
         defaultMessage: "Pincode",
         description: "",
      },
      country: {
         id: "app.common.country",
         defaultMessage: "Country",
         description: "",
      },
      brand: {
         id: "app.common.brand",
         defaultMessage: "Brand Information",
         description: "",
      },
      brandName: {
         id: "app.common.brandName",
         defaultMessage: "Brand Name",
         description: "",
      },
      mail: {
         id: "app.common.mail",
         defaultMessage: "E-mail Address",
         description: "",
      },
      phone: {
         id: "app.common.phone",
         defaultMessage: "Mobile Number",
         description: "",
      },
      mobileNumber: {
         id: "app.common.mobileNumber",
         defaultMessage: "Mobile Number",
         description: "",
      },
      currency: {
         id: "app.common.currency",
         defaultMessage: "Currency",
         description: "",
      },
      complianceInfo: {
         id: "app.common.complianceInfo",
         defaultMessage: "Compliance Information",
         description: "",
      },
      cin: {
         id: "app.common.cin",
         defaultMessage: "CIN",
         description: "",
      },
      tin: {
         id: "app.common.tin",
         defaultMessage: "TIN",
         description: "",
      },
      serviceTaxNo: {
         id: "app.common.serviceTaxNo",
         defaultMessage: "Service Tax Number",
         description: "",
      },
      gst: {
         id: "app.common.gst",
         defaultMessage: "GST Number",
         description: "",
      },
      miscellaneous: {
         id: "app.common.miscellaneous",
         defaultMessage: "Miscellaneous Settings",
         description: "",
      },
      billingAddress: {
         id: "app.common.billingAddress",
         defaultMessage: "Billing Address",
         description: "",
      },
      billingAddressDiscription: {
         id: "app.common.billingAddressDiscription",
         defaultMessage: "Add or edit billing address.",
         description: "",
      },
      sno: {
         id: "app.common.sno",
         defaultMessage: "S.NO",
         description: "",
      },
      name: {
         id: "app.common.name",
         defaultMessage: "Name",
         description: "",
      },
      address: {
         id: "app.common.address",
         defaultMessage: "Address",
         description: "",
      },
      addressLine1: {
         id: "app.common.addressLine1",
         defaultMessage: "Address 1",
         description: "",
      },
      addressLine2: {
         id: "app.common.addressLine2",
         defaultMessage: "Address 2",
         description: "",
      },
      back: {
         id: "app.common.back",
         defaultMessage: "Back",
      },
      next: {
         id: "app.common.next",
         defaultMessage: "Next",
      },
      save: {
         id: "app.common.save",
         defaultMessage: "Save",
      },
      cancel: {
         id: "app.common.cancel",
         defaultMessage: "Cancel",
      },
      apply: {
         id: "app.common.apply",
         defaultMessage: "Apply",
      },
      delete: {
         id: "app.common.delete",
         defaultMessage: "Delete",
      },
      storesPrice: {
         id: "app.common.storesPrice",
         defaultMessage: "Stores Price",
      },
      duration: {
         id: "app.common.duration",
         defaultMessage: "Duration",
      },
      price: {
         id: "app.common.price",
         defaultMessage: "Price",
      },
      qty: {
         id: "app.common.qty",
         defaultMessage: "Quantity",
      },
      taxes: {
         id: "app.common.taxes",
         defaultMessage: "Taxes",
      },
      charges: {
         id: "app.common.charges",
         defaultMessage: "Charges",
      },
      HSN_SAC_Code: {
         id: "app.common.HSN_SAC_Code",
         defaultMessage: "HSN/SAC Code",
      },
      downloadTemplate: {
         id: "app.common.downloadTemplate",
         defaultMessage: "Download Template",
      },
      uploadTemplate: {
         id: "app.common.uploadTemplate",
         defaultMessage: "Upload Template",
      },
      downloadExcelDocument: {
         id: "app.common.downloadExcelDocument",
         defaultMessage: "Download Excel Document",
      },
      uploadExcelDocument: {
         id: "app.common.uploadExcelDocument",
         defaultMessage: "Upload Excel Document",
      },
      clickToUpload: {
         id: "app.common.clickToUpload",
         defaultMessage: "Click To Upload",
      },
      successfulUpload: {
         id: "app.common.successfulUpload",
         defaultMessage: "File successfully uploaded",
      },
      invalidExcelFile: {
         id: "app.common.invalidExcelFile",
         defaultMessage: "Please Upload Valid Excel File!",
      },
      viewProducts: {
         id: "app.common.viewProducts",
         defaultMessage: "View Products",
      },
      productInTaxes: {
         id: "app.common.productInTaxes",
         defaultMessage: "Product in Taxes",
      },
      selectStores: {
         id: "app.common.selectStores",
         defaultMessage: "Select Stores",
      },
      warehouse: {
         id: "app.common.warehouse",
         defaultMessage: "Warehouse",
      },
      selectApplicableProducts: {
         id: "app.common.selectApplicableProducts",
         defaultMessage: "Select Applicable Products",
      },
      discountType: {
         id: "app.common.discountType",
         defaultMessage: "Discount Type",
      },
      discountValue: {
         id: "app.common.discountValue",
         defaultMessage: "Discount Value",
      },
      discountBuyProducts: {
         id: "app.common.discountBuyProducts",
         defaultMessage: "Base Products (Purchased)",
      },
      discountAppliedOn: {
         id: "app.common.discountAppliedOn",
         defaultMessage: "Discount Applied On",
      },
      discountID: {
         id: "app.common.discountID",
         defaultMessage: "Discount ID",
      },
      discountName: {
         id: "app.common.discountName",
         defaultMessage: "Discount Name",
      },
      discountLevel: {
         id: "app.common.discountLevel",
         defaultMessage: "Discount Level",
      },
      discountTotalValue: {
         id: "app.common.discountTotalValue",
         defaultMessage: "Discount Total Value",
      },
      orderIDsUsedOn: {
         id: "app.common.orderIDsUsedOn",
         defaultMessage: "Order IDs Used On",
      },
      storePrice: {
         id: "app.common.storePrice",
         defaultMessage: "Store Price",
      },
      taxAndChargesInformation: {
         id: "app.common.taxAndChargesInformation",
         defaultMessage: "Tax & Charges Information      ",
      },

      noidaStore: {
         id: "app.common.noidaStore",
         defaultMessage: "Noida Store",
         description: "",
      },
      storeInformation: {
         id: "app.common.storeInformation",
         defaultMessage: "Store Information",
         description: "",
      },
      openTime: {
         id: "app.common.openTime",
         defaultMessage: "Opening Time",
         description: "",
      },
      closingTime: {
         id: "app.common.closingTime",
         defaultMessage: "Closing Time",
         description: "",
      },
      users: {
         id: "app.common.users",
         defaultMessage: "Users",
         description: "",
      },
      storeName: {
         id: "app.common.storeName",
         defaultMessage: "Store Name",
         description: "",
      },
      storeID: {
         id: "app.common.storeID",
         defaultMessage: "Store ID",
         description: "",
      },
      activeLicense: {
         id: "app.common.activeLicense",
         defaultMessage: "Active License",
         description: "",
      },
      liveSince: {
         id: "app.common.liveSince",
         defaultMessage: "Live Since",
         description: "",
      },
      or: {
         id: "app.common.or",
         defaultMessage: "or",
         description: "",
      },
      call: {
         id: "app.common.call",
         defaultMessage: "Call Us",
         description: "",
      },
      yes: {
         id: "app.common.yes",
         defaultMessage: "Yes",
         description: "",
      },
      no: {
         id: "app.common.no",
         defaultMessage: "No",
         description: "",
      },
      fssaiLicenseNumber: {
         id: "app.common.fssaiLicenseNumber",
         defaultMessage: "FSSAI License Number",
         description: "",
      },
      urbanPiper: {
         id: "app.common.urbanPiper",
         defaultMessage: "Urban Piper Settings",
         description: "",
      },
      allowOrder: {
         id: "app.common.allowOrder",
         defaultMessage: "Allow Ordering",
         description: "",
      },
      allowDesc: {
         id: "app.common.allowDesc",
         defaultMessage: "This allows user to enable order placing.",
         description: "",
      },
      minOrderVal: {
         id: "app.common.minOrderVal",
         defaultMessage: "Minimum Order Value",
         description: "",
      },
      minDeliveryTime: {
         id: "app.common.minDeliveryTime",
         defaultMessage: "Minimum Delivery Time",
         description: "",
      },
      minPickTime: {
         id: "app.common.minPickTime",
         defaultMessage: "Minimum Pickup Time",
         description: "",
      },
      deliveryPin: {
         id: "app.common.deliveryPin",
         defaultMessage: "Delivery Pin Code",
         description: "",
      },
      deliveryPlatform: {
         id: "app.common.deliveryPlatform",
         defaultMessage: "Delivery Platform",
         description: "",
      },
      notificationEmails: {
         id: "app.common.notificationEmails",
         defaultMessage: "Notification Emails",
         description: "",
      },
      notificationPhone: {
         id: "app.common.notificationPhone",
         defaultMessage: "Notification Phones",
         description: "",
      },
      multiTootlTipMails: {
         id: "app.common.multiTootlTipMails",
         defaultMessage: "You can add multiple emails. Add an email & press enter.",
         description: "",
      },
      cityTootlTipText: {
         id: "app.common.cityTootlTipText",
         defaultMessage: "Please enter the city name..",
         description: "",
      },
      multiUsers: {
         id: "app.common.multiUsers",
         defaultMessage: "You can assign multiple users to the store.",
         description: "",
      },
      selectstore: {
         id: "app.common.selectstore",
         defaultMessage: "Select Store",
         description: "",
      },
      description: {
         id: "app.common.description",
         defaultMessage: "Description",
         description: "",
      },
      createdBy: {
         id: "app.common.createdBy",
         defaultMessage: "Created By",
         description: "",
      },
      warehouseName: {
         id: "app.common.warehouseName",
         defaultMessage: "Warehouse Name",
         description: "",
      },
      viewUsers: {
         id: "app.common.viewUsers",
         defaultMessage: "View Users",
         description: "",
      },
      warehouse: {
         id: "app.common.warehouse",
         defaultMessage: "Warehouse",
         description: "",
      },
      email: {
         id: "app.common.email",
         defaultMessage: "Email Address",
         description: "",
      },
      SubCategoryName: {
         id: "app.common.SubCategoryName",
         defaultMessage: "Sub Category Name",
         description: "",
      },
      CategoryName: {
         id: "app.common.CategoryName",
         defaultMessage: "Category Name",
         description: "",
      },
      SortSequence: {
         id: "app.common.SortSequence",
         defaultMessage: "Sort Sequence",
         description: "",
      },
      Product: {
         id: "app.common.Product",
         defaultMessage: "Product",
         description: "",
      },
      SKU: {
         id: "app.common.SKU",
         defaultMessage: "SKU",
         description: "",
      },
      Brand: {
         id: "app.common.Brand",
         defaultMessage: "Brand",
         description: "",
      },
      Category: {
         id: "app.common.Category",
         defaultMessage: "Category",
         description: "",
      },
      subCategory: {
         id: "app.common.subCategory",
         defaultMessage: "Sub-Category",
         description: "",
      },
      storeDetails: {
         id: "app.common.storeDetails",
         defaultMessage: "Store Details",
         description: "",
      },
      weight: {
         id: "app.common.weight",
         defaultMessage: "Weight",
         description: "",
      },
      height: {
         id: "app.common.height",
         defaultMessage: "Height",
         description: "",
      },
      length: {
         id: "app.common.length",
         defaultMessage: "Length",
         description: "",
      },
      width: {
         id: "app.common.width",
         defaultMessage: "Width",
         description: "",
      },
      MRP: {
         id: "app.common.MRP",
         defaultMessage: "M.R.P",
         description: "",
      },
      lowStockValue: {
         id: "app.common.lowStockValue",
         defaultMessage: "Low Stock Value",
      },
      region: {
         id: "app.common.region",
         defaultMessage: "Region",
         description: "",
      },
      addbillingAddress: {
         id: "app.common.addbillingAddress",
         defaultMessage: "Add Address",
         description: "",
      },
      editbillingAddress: {
         id: "app.common.addbillingAddress",
         defaultMessage: "Edit Address",
         description: "",
      },
      deletAddress: {
         id: "app.common.deletAddress",
         defaultMessage: "Are you sure you want to delete address",
         description: "",
      },
      close: {
         id: "app.common.close",
         defaultMessage: "Close",
         description: "",
      },
      copy: {
         id: "app.common.copy",
         defaultMessage: "Copy",
         description: "",
      },
      customerType: {
         id: "app.common.type",
         defaultMessage: "Type",
      },
      type: {
         id: "app.common.type",
         defaultMessage: "Type",
      },
      typeID: {
         id: "app.common.typeID",
         defaultMessage: "Type ID",
      },
      syncStatus: {
         id: "app.common.syncStatus",
         defaultMessage: "Sync Status",
      },
      logsDetail: {
         id: "app.common.logsDetail",
         defaultMessage: "Logs Detail",
      },
      transactionType: {
         id: "app.common.transactionType",
         defaultMessage: "Tranascton Type",
      },
      gender: {
         id: "app.common.gender",
         defaultMessage: "Gender",
      },
      landmark: {
         id: "app.common.landmark",
         defaultMessage: "Landmark",
      },
      notes: {
         id: "app.common.notes",
         defaultMessage: "Notes",
      },
      note: {
         id: "app.common.note",
         defaultMessage: "Note",
      },
      fullDay: {
         id: "app.common.fullDay",
         defaultMessage: "Full Day",
      },
      discount: {
         id: "app.common.discount",
         defaultMessage: "Discount",
      },
      birthday: {
         id: "app.common.birthday",
         defaultMessage: "Birthday",
      },
      anniversary: {
         id: "app.common.anniversary",
         defaultMessage: "Anniversary",
      },
      gstNumber: {
         id: "app.common.gstNumber",
         defaultMessage: "GST Number",
      },
      aadharNumber: {
         id: "app.common.aadharNumber",
         defaultMessage: "Aadhar Number",
      },
      panNumber: {
         id: "app.common.panNumber",
         defaultMessage: "PAN Number",
      },
      close: {
         id: "app.common.close",
         defaultMessage: "Close",
      },
      id: {
         id: "app.common.id",
         defaultMessage: "ID",
      },
      creationTimeLocal: {
         id: "app.common.creationTimeLocal",
         defaultMessage: "Creation Time Local",
      },
      pointsExpiryDate: {
         id: "app.common.pointsExpiryDate",
         defaultMessage: "Points Expiry Date",
      },
      transactionRemarks: {
         id: "app.common.transactionRemarks",
         defaultMessage: "Remarks",
      },
      downLoadTemplate: {
         id: "app.common.downLoadTemplate",
         defaultMessage: "DownLoad Template",
      },
      uploadTemplate: {
         id: "app.common.uploadTemplate",
         defaultMessage: "Upload Template",
      },
      downLoadExcelDoc: {
         id: "app.common.downLoadExcelDoc",
         defaultMessage: "Download Excel Document",
      },
      uploadExcelDoc: {
         id: "app.common.uploadExcelDoc",
         defaultMessage: "Upload Excel Document",
      },
      dropToDownload: {
         id: "app.common.dropToDownload",
         defaultMessage:
            "Click here to download excel template & upload it to create multiple customers.",
      },
      dropToUpload: {
         id: "app.common.dropToUpload",
         defaultMessage:
            "Click here to download excel template & upload it to create multiple customers.",
      },
      fileUploadedSuccessful: {
         id: "app.common.fileUploadedSuccessful",
         defaultMessage: "File successfully uploaded",
      },
      fName: {
         id: "app.common.fName",
         defaultMessage: "First Name",
      },
      lName: {
         id: "app.common.lName",
         defaultMessage: "Last Name",
      },
      customers: {
         id: "app.common.customers",
         defaultMessage: "Customers",
      },
      tableRecordsStatus: {
         id: "app.common.tableRecordsStatus",
         defaultMessage: "Showing 1 to 2 of 3 4",
      },
      miscellaneousInformation: {
         id: "app.common.miscellaneousInformation",
         defaultMessage: "Miscellaneous Information",
      },
      id: {
         id: "app.common.id",
         defaultMessage: "ID",
      },
      noMatchingRecordFound: {
         id: "app.common.noMatchingRecordFound",
         defaultMessage: "No matching record found",
      },
      productName: {
         id: "app.common.productName",
         defaultMessage: "Product Name",
      },
      barcode: {
         id: "app.common.barcode",
         defaultMessage: "Barcode",
      },
      taxID: {
         id: "app.common.taxID",
         defaultMessage: "Tax ID",
      },
      parentTaxID: {
         id: "app.common.parentTaxID",
         defaultMessage: "Parent Tax ID",
      },
      productListEmpty: {
         id: "app.common.productListEmpty",
         defaultMessage: "Product List Empty",
      },
      StepOneOfTwo: {
         id: "app.common.StepOneOfTwo",
         defaultMessage: "Step 1 of 2",
      },
      next: {
         id: "app.common.next",
         defaultMessage: "Next",
      },
      Stores: {
         id: "app.common.Stores",
         defaultMessage: "Store(s)",
      },
      category: {
         id: "app.common.category",
         defaultMessage: "Category",
      },
      product: {
         id: "app.common.product",
         defaultMessage: "Product",
      },
      Products: {
         id: "app.common.Products",
         defaultMessage: "Product(s)",
      },
      unitName: {
         id: "app.common.unitName",
         defaultMessage: "Unit Name",
      },
      productID: {
         id: "app.common.productID",
         defaultMessage: "Product ID",
      },
      addInBulk: {
         id: "app.common.addInBulk",
         defaultMessage: "Add In Bulk (Excel)",
      },
      noRecordFound: {
         id: "app.common.noRecordFound",
         defaultMessage: "No Records Found",
      },
      quantity: {
         id: "app.common.quantity",
         defaultMessage: "Quantity",
      },
      "cost/unit": {
         id: "app.common.cost/unit",
         defaultMessage: "Cost/Unit",
      },
      totalCost: {
         id: "app.common.totalCost",
         defaultMessage: "Total Cost",
      },
      validFileValidation: {
         id: "app.common.validFileValidation",
         defaultMessage: "Please upload a valid file",
      },
      productInCharges: {
         id: "app.common.productInCharges",
         defaultMessage: "Products in Charges",
      },
      order: {
         id: "app.common.order",
         defaultMessage: "Order",
      },
      percentage: {
         id: "app.common.percentage",
         defaultMessage: "Percentage",
      },
      absolute: {
         id: "app.common.absolute",
         defaultMessage: "Absolute",
      },

      inventory: {
         id: "app.common.inventory",
         defaultMessage: "Inventory",
      },
      selectCategory: {
         id: "app.common.selectCategory",
         defaultMessage: "Select Category",
      },
      selectBrand: {
         id: "app.common.selectBrand",
         defaultMessage: "Select Brand",
      },
      brands: {
         id: "app.common.brands",
         defaultMessage: "Brand",
      },
      soldIn: {
         id: "app.common.soldIn",
         defaultMessage: "Unit",
      },
      setItem: {
         id: "app.common.setItem",
         defaultMessage: "Set Item",
      },
      selectUnitErr: {
         id: "app.common.selectUnitErr",
         defaultMessage: "Please select product unit.",
      },
      add: {
         id: "app.common.add",
         defaultMessage: "Add",
      },
      selectUnit: {
         id: "app.common.selectUnit",
         defaultMessage: "Select Unit",
      },
      selectProduct: {
         id: "app.common.selectProduct",
         defaultMessage: "Select The Product",
      },
      variantName: {
         id: "app.common.variantName",
         defaultMessage: "Size",
      },
      HSNOrSACCode: {
         id: "app.common.HSNOrSACCode",
         defaultMessage: "HSN Or SAC Code",
      },
      stockType: {
         id: "app.common.stockType",
         defaultMessage: "Stock Type",
      },
      stockCost: {
         id: "app.common.stockCost",
         defaultMessage: "Stock Cost",
      },
      avgCostPrice: {
         id: "app.common.avgCostPrice",
         defaultMessage: "Avg. Cost Price",
      },
      value: {
         id: "app.common.value",
         defaultMessage: "Value",
      },
      baseProducts: {
         id: "app.common.baseProducts",
         defaultMessage: "Base Products",
      },
      selectBaseProducts: {
         id: "app.common.selectBaseProducts",
         defaultMessage: "Select Base Products",
      },
      manual: {
         id: "app.common.manual",
         defaultMessage: "Manual",
      },
      importExcel: {
         id: "app.common.importExcel",
         defaultMessage: "Import Excel",
      },
      couponCode: {
         id: "app.common.couponCode",
         defaultMessage: "Coupon Code",
      },
      day: {
         id: "app.common.day",
         defaultMessage: "Day",
      },
      createdAt: {
         id: "app.common.createdAt",
         defaultMessage: "Created At",
      },
      appliedOnLevel: {
         id: "app.common.appliedOnLevel",
         defaultMessage: "Applied on level",
      },
      viewCoupons: {
         id: "app.common.viewCoupons",
         defaultMessage: "View Coupons",
      },
      productsInDiscount: {
         id: "app.common.productsInDiscount",
         defaultMessage: "Products in Discount",
      },
      coupons: {
         id: "app.common.coupons",
         defaultMessage: "Coupons",
      },
      couponID: {
         id: "app.common.couponID",
         defaultMessage: "Coupon ID",
      },
      clickToDownloadProduct: {
         id: "app.common.clickToDownloadProduct",
         defaultMessage: "Click download to download template of products.",
      },
      clickToDownloadProduct: {
         id: "app.common.uploadTheFilled",
         defaultMessage: "Upload the filled template.",
      },
      storeSelectionValidation: {
         id: "app.common.storeSelectionValidation",
         defaultMessage: "Store selection is required",
      },
      basic: {
         id: "app.common.basic",
         defaultMessage: "Basic",
      },
      conditional: {
         id: "app.common.conditional",
         defaultMessage: "Conditional",
      },
      reprice: {
         id: "app.common.reprice",
         defaultMessage: "Reprice",
      },
      maxDiscountValue: {
         id: "app.common.maxDiscountValue",
         defaultMessage: "Maximum discount value",
      },
      targetProduct: {
         id: "app.common.targetProduct",
         defaultMessage: "Target Products",
      },
      clickToDownloadExcel: {
         id: "app.common.clickToDownloadExcel",
         defaultMessage: "Click here to download excel template & upload",
      },
      autoApplied: {
         id: "app.common.autoApplied",
         defaultMessage: "Auto Applied",
      },
      appliedOn: {
         id: "app.common.appliedOn",
         defaultMessage: "Applied On",
      },
      free: {
         id: "app.common.free",
         defaultMessage: "Free",
      },
      deleteModelText: {
         id: "app.common.deleteModelText",
         defaultMessage: " Are you sure you want to delete",
      },
      productsSelected: {
         id: "app.common.productsSelected",
         defaultMessage: "Products Selected",
      },
      subTotal: {
         id: "app.common.subTotal",
         defaultMessage: "Subtotal",
      },
      taxes: {
         id: "app.common.taxes",
         defaultMessage: "Taxes",
      },
      rounding: {
         id: "app.common.rounding",
         defaultMessage: "Rounding",
      },
      roundingWithSign: {
         id: "app.common.roundingWithSign",
         defaultMessage: "(+)Rounding (₹)",
      },
      total: {
         id: "app.common.total",
         defaultMessage: "Total",
      },
      buy: {
         id: "app.common.buy",
         defaultMessage: "Buy",
      },
      cancel: {
         id: "app.common.cancel",
         defaultMessage: "Cancel",
      },
      totalItems: {
         id: "app.common.totalItems",
         defaultMessage: "Total Items : ",
      },
      totalItem: {
         id: "app.common.totalItem",
         defaultMessage: "Total Items",
      },
      addProds: {
         id: "app.common.addProds",
         defaultMessage: "Add Products",
      },
      productGroupName: {
         id: "app.common.productGroupName",
         defaultMessage: "Product Group Name",
      },
      edit: {
         id: "app.common.edit",
         defaultMessage: "Edit",
      },
      confirmTransaction: {
         id: "app.common.confirmTransaction",
         defaultMessage: "Confirm Transaction",
      },
      createBatch: {
         id: "app.common.createBatch",
         defaultMessage: "Create Batch",
      },
      updateBatch: {
         id: "app.common.updateBatch",
         defaultMessage: "Update Batch",
      },
      costPrice: {
         id: "app.common.costPrice",
         defaultMessage: "Cost Price",
      },
      totalTaxValue: {
         id: "app.common.totalTaxValue",
         defaultMessage: "Total Tax Value",
      },
      totalPrice: {
         id: "app.common.totalPrice",
         defaultMessage: "Total Price",
      },
      poQty: {
         id: "app.common.poQty",
         defaultMessage: "PO Quantity",
      },
      deleteProdQn: {
         id: "app.common.deleteProdQn",
         defaultMessage: "Are you sure you want to remove the Product",
      },
      prodDelete: {
         id: "app.common.prodDelete",
         defaultMessage: "Product Deleted Successfully.",
      },
      vendorName: {
         id: "app.common.vendorName",
         defaultMessage: "Vendor Name",
      },
      otherCharges: {
         id: "app.common.otherCharges",
         defaultMessage: "Other Charges",
      },
      mailA: {
         id: "app.common.mailA",
         defaultMessage: "Email",
      },
      expectedQty: {
         id: "app.common.expectedQty",
         defaultMessage: "Expected Quantity",
      },
      actualtQty: {
         id: "app.common.actualtQty",
         defaultMessage: "Actual Quantity",
      },
      variance: {
         id: "app.common.variance",
         defaultMessage: "Variance",
      },
      someThingWentWrong: {
         id: "app.common.someThingWentWrong",
         defaultMessage: "Something went wrong!",
      },
      deleteBatchQn: {
         id: "app.common.deleteBatchQn",
         defaultMessage: "Are you sure you want to delete the Batch",
      },
      batchID: {
         id: "app.common.batchID",
         defaultMessage: "Batch ID",
      },
      productGroupID: {
         id: "app.common.productGroupID",
         defaultMessage: "Product Group ID",
      },
      expiryDate: {
         id: "app.common.expiryDate",
         defaultMessage: "Expiry Date",
      },
      inventoryCost: {
         id: "app.common.inventoryCost",
         defaultMessage: "Inventory Cost",
      },
      sellingPrice: {
         id: "app.common.sellingPrice",
         defaultMessage: "Selling Price",
      },
      sourceID: {
         id: "app.common.sourceID",
         defaultMessage: "Source ID",
      },
      transactionType: {
         id: "app.common.transactionType",
         defaultMessage: "Transaction Type",
      },
      action: {
         id: "app.common.action",
         defaultMessage: "Action",
      },
      cost: {
         id: "app.common.cost",
         defaultMessage: "Cost",
      },
      download: {
         id: "app.common.download",
         defaultMessage: "Download",
      },
      upload: {
         id: "app.common.upload",
         defaultMessage: "Upload",
      },
      daysToExpiry: {
         id: "app.common.daysToExpiry",
         defaultMessage: "Days to Expire",
      },
      amount: {
         id: "app.common.amount",
         defaultMessage: "Amount",
      },
      allCategory: {
         id: "app.common.allCategory",
         defaultMessage: "All categories",
      },
      allBrand: {
         id: "app.common.allBrand",
         defaultMessage: "All brands",
      },
      colour: {
         id: "app.common.colour",
         defaultMessage: "Colour",
      },
      operationID: {
         id: "app.common.operationID",
         defaultMessage: "Operation ID",
      },
      operation: {
         id: "app.common.operation",
         defaultMessage: "Operation",
      },
      parentID: {
         id: "app.common.parentID",
         defaultMessage: "Parent ID",
      },
      freightCharge: {
         id: "app.common.freightCharge",
         defaultMessage: "Freight Charge",
      },
      inventoryUpdateStatus: {
         id: "app.common.inventoryUpdateStatus",
         defaultMessage: "Inventory Update Status",
      },
      stockOperations: {
         id: "app.common.stockOperations",
         defaultMessage: "Stock Operations",
      },
      dateRange: {
         id: "app.common.dateRange",
         defaultMessage: "Date Range",
      },
      stockOpening: {
         id: "app.common.stockOpening",
         defaultMessage: "Stock Opening",
      },
      stockIn: {
         id: "app.common.stockIn",
         defaultMessage: "Stock In",
      },
      stockTransfer: {
         id: "app.common.stockTransfer",
         defaultMessage: "Stock Transfer",
      },
      stockOrder: {
         id: "app.common.stockOrder",
         defaultMessage: "Stock Sold",
      },
      stockOut: {
         id: "app.common.stockOut",
         defaultMessage: "Stock Out",
      },
      stockValidation: {
         id: "app.common.stockValidation",
         defaultMessage: "Stock Validation",
      },
      stockReceivedViaPurchase: {
         id: "app.common.stockReceivedViaPurchase",
         defaultMessage: "Stock Received Via Purchase",
      },
      stockReceivedViaTransfer: {
         id: "app.common.stockReceivedViaTransfer",
         defaultMessage: "Stock Received Via Transfer",
      },
      stockReceivedViaAdjustment: {
         id: "app.common.stockReceivedViaAdjustment",
         defaultMessage: "Stock Received Via Adjustment",
      },
      stockInTransit: {
         id: "app.common.stockInTransit",
         defaultMessage: "Stock InTransit",
      },
      stockReturnedViaStockIn: {
         id: "app.common.stockReturnedViaStockIn",
         defaultMessage: "Stock Returned Via Stock In",
      },
      stockReturnedViaStockOut: {
         id: "app.common.stockReturnedViaStockOut",
         defaultMessage: "Stock Returned Via Stock Out",
      },
      stockNet: {
         id: "app.common.stockNet",
         defaultMessage: "Stock Net",
      },
      stockClosing: {
         id: "app.common.stockClosing",
         defaultMessage: "Stock Closing",
      },
      stockClosingCost: {
         id: "app.common.stockClosingCost",
         defaultMessage: "Avg. Stock Closing Cost",
      },
      itr: {
         id: "app.common.itr",
         defaultMessage: "Inventory Turn Around Ratio",
      },
      dsi: {
         id: "app.common.dsi",
         defaultMessage: "Days Inventory",
      },
      orderTime: {
         id: "app.common.orderTime",
         defaultMessage: "Order Time",
      },
      basePrice: {
         id: "app.common.basePrice",
         defaultMessage: "Base Price",
      },
      totalItemNumber_Req: {
         id: "app.common.totalItemNumber_Req",
         defaultMessage: "Stock Request Count",
      },
      reqDate: {
         id: "app.common.reqDate",
         defaultMessage: "Requisition Date",
      },
      reqStatus: {
         id: "app.common.reqStatus",
         defaultMessage: "Requisition Status",
      },
      costMarginAmount: {
         id: "app.common.costMarginAmount",
         defaultMessage: "Cost Margin Amount",
      },
      costMargin: {
         id: "app.common.costMargin",
         defaultMessage: "Cost Margin(%)",
      },
      stockTransactionID: {
         id: "app.common.stockTransactionID",
         defaultMessage: "Stock Transaction ID",
      },
      PONumber: {
         id: "app.common.PONumber",
         defaultMessage: "PO Number",
      },
      taxableValue: {
         id: "app.common.taxableValue",
         defaultMessage: "Taxable Value",
      },
      currenctStockLevel: {
         id: "app.common.currenctStockLevel",
         defaultMessage: "Taxable Value",
      },
      searchStore: {
         id: "app.common.searchStore",
         defaultMessage: "Search Store",
      },
      products: {
         id: "app.common.products",
         defaultMessage: "Products",
      },
      totalProductsSelected: {
         id: "app.common.totalProductsSelected",
         defaultMessage: "Total Products Selected",
      },
      totalStore: {
         id: "app.common.totalStore",
         defaultMessage: "Total Store",
      },
      totalProducts: {
         id: "app.common.totalProducts",
         defaultMessage: "Total Products",
      },
      totalCostPrice: {
         id: "app.common.totalCostPrice",
         defaultMessage: "Total Cost Price",
      },
      vendorID: {
         id: "app.common.vendorID",
         defaultMessage: "Vendor ID",
      },
      totalAmount: {
         id: "app.common.totalAmount",
         defaultMessage: "Total Amount",
      },
      fulfillmentAmount: {
         id: "app.common.fulfillmentAmount",
         defaultMessage: "Fulfillment Amount",
      },
      stockRequested: {
         id: "app.common.stockRequested",
         defaultMessage: "Stock Requested",
      },
      stockFulfilled: {
         id: "app.common.stockFulfilled",
         defaultMessage: "Stock Fulfilled",
      },
      stockValue: {
         id: "app.common.stockValue",
         defaultMessage: "Stock Value",
      },
      taxValue: {
         id: "app.common.taxValue",
         defaultMessage: "Tax Value",
      },
      userName: {
         id: "app.common.userName",
         defaultMessage: "User Name",
      },
      userName2: {
         id: "app.common.userName2",
         defaultMessage: "Username",
      },
      totalItemCount: {
         id: "app.common.totalItemCount",
         defaultMessage: "Total Item Count",
      },
      totalItemQuantity: {
         id: "app.common.totalItemQuantity",
         defaultMessage: "Total Item Quantity",
      },
      batchVariantID: {
         id: "app.common.batchVariantID",
         defaultMessage: "Batch Variant ID",
      },
      unitID: {
         id: "app.common.unitID",
         defaultMessage: "Unit ID",
      },
      timezone: {
         id: "app.common.timezone",
         defaultMessage: "Timezone",
      },
      variantID: {
         id: "app.common.variantID",
         defaultMessage: "Variant ID",
      },
      storeType: {
         id: "app.common.storeType",
         defaultMessage: "Store Type",
      },
      quantityFulfilled: {
         id: "app.common.quantityFulfilled",
         defaultMessage: "Quantity Fulfilled",
      },
      quantityReturned: {
         id: "app.common.quantityReturned",
         defaultMessage: "Quantity Returned",
      },
      filfillmentStatus: {
         id: "app.common.filfillmentStatus",
         defaultMessage: "Fulfillment Status",
      },
      requisitionSummary: {
         id: "app.common.requisitionSummary",
         defaultMessage: "Requisition Summary",
      },
      serialNumber: {
         id: "app.common.serialNumber",
         defaultMessage: "Serial Number",
      },
      productInformation: {
         id: "app.common.productInformation",
         defaultMessage: "Product Information",
      },
      notSpecified: {
         id: "app.common.notSpecified",
         defaultMessage: "Not specified",
      },
      vegetarian: {
         id: "app.common.vegetarian",
         defaultMessage: "Vegetarian",
      },
      nonVegetarian: {
         id: "app.common.nonVegetarian",
         defaultMessage: "Non Vegetarian",
      },
      eggetarian: {
         id: "app.common.eggetarian",
         defaultMessage: "Eggetarian",
      },
      noStoreFound: {
         id: "app.common.noStoreFound",
         defaultMessage: "No Store Found",
      },
      regionAvailable: {
         id: "app.common.noStoreFound",
         defaultMessage: "Region(s) available",
      },
      productDimensions: {
         id: "app.common.productDimensions",
         defaultMessage: "Product Dimensions",
      },
      unitAndDimensions: {
         id: "app.common.unitAndDimensions",
         defaultMessage: "Unit & Dimensions",
      },
      piece: {
         id: "app.common.piece",
         defaultMessage: "Piece",
      },
      kilogram: {
         id: "app.common.kilogram",
         defaultMessage: "Kilogram",
      },
      Litre: {
         id: "app.common.Litre",
         defaultMessage: "Litre",
      },
      Metre: {
         id: "app.common.Metre",
         defaultMessage: "Metre",
      },
      usingBulkUpload: {
         id: "app.common.usingBulkUpload",
         defaultMessage: "Using Bulk Upload",
      },
      usingBulkUploadDes: {
         id: "app.common.usingBulkUpload",
         defaultMessage: "Add products using Excel file",
      },
      multipleProduct: {
         id: "app.common.multipleProduct",
         defaultMessage: "add multiple Product",
      },
      multipleModifiers: {
         id: "app.common.multipleModifiers",
         defaultMessage: "add multiple Modifiers",
      },
      includesTax: {
         id: "app.common.includesTax",
         defaultMessage: "Includes Tax?",
      },
      modifierID: {
         id: "app.common.modifierID",
         defaultMessage: "Modifier ID",
      },
      addMore: {
         id: "app.common.addMore",
         defaultMessage: "Add More",
      },
      priceIncludesTaxes: {
         id: "app.common.priceIncludesTaxes",
         defaultMessage: "Price Includes Taxes",
      },
      enableLowStockAlert: {
         id: "app.common.enableLowStockAlert",
         defaultMessage: "Enable Low Stock Alert",
      },
      defaultPrice: {
         id: "app.common.defaultPrice",
         defaultMessage: "Default Price",
      },
      storeMRP: {
         id: "app.common.storeMRP",
         defaultMessage: "Store MRP",
      },
      Submit: {
         id: "app.common.Submit",
         defaultMessage: "Submit",
      },
      Print: {
         id: "app.common.Print",
         defaultMessage: "Print",
      },
      costByunit: {
         id: "app.common.costByunit",
         defaultMessage: "Cost/Unit",
      },
      view: {
         id: "app.common.view",
         defaultMessage: "View",
      },
      edit: {
         id: "app.common.edit",
         defaultMessage: "Edit",
      },
      delete: {
         id: "app.common.delete",
         defaultMessage: "Delete",
      },
      storeID: {
         id: "app.common.storeID",
         defaultMessage: "Store ID",
      },
      storeName: {
         id: "app.common.storeName",
         defaultMessage: "Store Name",
      },
      storeType: {
         id: "app.common.storeType",
         defaultMessage: "Store Type",
      },
      productID: {
         id: "app.common.productID",
         defaultMessage: "Product ID",
      },
      productName: {
         id: "app.common.productName",
         defaultMessage: "Product Name",
      },
      basicInformation: {
         id: "app.common.basicInformation",
         defaultMessage: "Basic Information",
      },
      basicInformation: {
         id: "app.common.basicInformation",
         defaultMessage: "Basic Information",
      },
      addressInformation: {
         id: "app.common.addressInformation",
         defaultMessage: "Address Information",
      },
      otherInformation: {
         id: "app.common.otherInformation",
         defaultMessage: "Other Information",
      },

      posDate: {
         id: "app.common.posDate",
         defaultMessage: "POS Date",
      },
      fromDate: {
         id: "app.common.fromDate",
         defaultMessage: "From Date",
      },
      toDate: {
         id: "app.common.toDate",
         defaultMessage: "To Date",
      },
      auditTimeLocal: {
         id: "app.common.auditTimeLocal",
         defaultMessage: "Audit Time Local",
      },
      auditTimeUTC: {
         id: "app.common.auditTimeUTC",
         defaultMessage: "Audit Time UTC",
      },
      variantName2: {
         id: "app.common.variantName2",
         defaultMessage: "Variant Name",
      },
      stockReceived: {
         id: "app.common.stockReceived",
         defaultMessage: "Stock Received",
      },
      stockConsumed: {
         id: "app.common.stockConsumed",
         defaultMessage: "Stock Consumed",
      },
      stockvariance: {
         id: "app.common.stockvariance",
         defaultMessage: "Stock Variance",
      },
      dumpQunatity: {
         id: "app.common.dumpQunatity",
         defaultMessage: "Dump Quantity",
      },
      wastageQunatity: {
         id: "app.common.wastageQunatity",
         defaultMessage: "Wastage Quantity",
      },
      wastageCost: {
         id: "app.common.wastageCost",
         defaultMessage: "Wastage Cost",
      },
      salesValue: {
         id: "app.common.salesValue",
         defaultMessage: "Sales Value",
      },
      wastagePercentage: {
         id: "app.common.wastagePercentage",
         defaultMessage: "Wastage Percentage",
      },
      negativeGRN: {
         id: "app.common.negativeGRN",
         defaultMessage: "Negative GRN",
      },
      stockOutCost: {
         id: "app.common.stockOutCost",
         defaultMessage: "Stock Out Cost",
      },
      stockClosingCost2: {
         id: "app.common.stockClosingCost2",
         defaultMessage: "Stock Closing Cost",
      },
      dumpCost: {
         id: "app.common.dumpCost",
         defaultMessage: "Dump Cost",
      },
      billableQuantity: {
         id: "app.common.billableQuantity",
         defaultMessage: "Billable Quantity",
      },
      stockOrder2: {
         id: "app.common.stockOrder2",
         defaultMessage: "Stock Order",
      },
      availablePoints: {
         id: "app.common.availablePoints",
         defaultMessage: "Available Points",
      },
      dump: {
         id: "app.common.dump",
         defaultMessage: "Dump",
      },
      wastage: {
         id: "app.common.wastage",
         defaultMessage: "Wastage",
      },
      sourceOrDestination: {
         id: "app.common.sourceOrDestination",
         defaultMessage: "Source/Destination",
      },
      status: {
         id: "app.common.status",
         defaultMessage: "Status",
      },
      oldQuantity: {
         id: "app.common.oldQuantity",
         defaultMessage: "Old Quantity",
      },
      transactionQunatity: {
         id: "app.common.transactionQunatity",
         defaultMessage: "Transaction Quantity",
      },
      new: {
         id: "app.common.new",
         defaultMessage: "New",
      },
      newQuantity: {
         id: "app.common.newQuantity",
         defaultMessage: "New Quantity",
      },
      systemStockLevel: {
         id: "app.common.systemStockLevel",
         defaultMessage: "Current System Stock Level",
      },
      stockRemarks: {
         id: "app.common.stockRemarks",
         defaultMessage: "Stock Remarks",
      },
      transactionSummary: {
         id: "app.common.transactionSummary",
         defaultMessage: "Transaction Summary",
      },
      requestID: {
         id: "app.common.requestID",
         defaultMessage: "Request ID",
      },
      date: {
         id: "app.common.date",
         defaultMessage: "Date",
      },
      sales: {
         id: "app.common.sales",
         defaultMessage: "Sales",
      },
      discounts: {
         id: "app.common.discounts",
         defaultMessage: "Discounts",
      },
      charges2: {
         id: "app.common.charges2",
         defaultMessage: "Charges",
      },
      netSales: {
         id: "app.common.netSales",
         defaultMessage: "(=) Net Sales",
      },
      taxes2: {
         id: "app.common.taxes2",
         defaultMessage: "Taxes",
      },
      rounding: {
         id: "app.common.rounding",
         defaultMessage: "Rounding",
      },
      grossSales: {
         id: "app.common.grossSales",
         defaultMessage: "(=) Gross Sales",
      },
      voidAmount: {
         id: "app.common.voidAmount",
         defaultMessage: "Void Amount",
      },
      finalSales: {
         id: "app.common.finalSales",
         defaultMessage: "(+) Final Sales",
      },
      ncAmount: {
         id: "app.common.ncAmount",
         defaultMessage: "NC Amount",
      },
      totalNcVoid: {
         id: "app.common.totalNcVoid",
         defaultMessage: "Total NC Void",
      },
      finalNcAmount: {
         id: "app.common.finalNcAmount",
         defaultMessage: "Final NC Amount",
      },
      totalOrders: {
         id: "app.common.totalOrders",
         defaultMessage: "Total Orders",
      },
      storeCollection: {
         id: "app.common.storeCollection",
         defaultMessage: "(=) Store Collection",
      },
      voidOrders: {
         id: "app.common.voidOrders",
         defaultMessage: "Void Orders",
      },
      ncOrders: {
         id: "app.common.ncOrders",
         defaultMessage: "NC Orders",
      },
      NCSales: {
         id: "app.common.NCSales",
         defaultMessage: "NC Sales",
      },
      UPT: {
         id: "app.common.UPT",
         defaultMessage: "UPT",
      },
      ATV: {
         id: "app.common.ATV",
         defaultMessage: "ATV",
      },
      orderID: {
         id: "app.common.orderID",
         defaultMessage: "Order ID",
      },
      orderSubID: {
         id: "app.common.orderSubID",
         defaultMessage: "Order Sub ID",
      },
      itemSales: {
         id: "app.common.itemSales",
         defaultMessage: "Item Sales",
      },
      productLevelCharges: {
         id: "app.common.productLevelCharges",
         defaultMessage: "(+) Charges (Product Level)",
      },
      orderLevelCharges: {
         id: "app.common.orderLevelCharges",
         defaultMessage: "(+) Charges (Order Level)",
      },
      netBill: {
         id: "app.common.netBill",
         defaultMessage: "(=) Net Bill",
      },
      grossBill: {
         id: "app.common.grossBill",
         defaultMessage: "(=) Gross Bill",
      },
      paymentStatus: {
         id: "app.common.paymentStatus",
         defaultMessage: "Payment Status",
      },
      cash: {
         id: "app.common.cash",
         defaultMessage: "Cash",
      },
      visaPayment: {
         id: "app.common.visaPayment",
         defaultMessage: "Visa",
      },
      card: {
         id: "app.common.card",
         defaultMessage: "Card",
      },
      dd: {
         id: "app.common.dd",
         defaultMessage: "Demand Draft",
      },
      wallet: {
         id: "app.common.wallet",
         defaultMessage: "Wallet",
      },
      other: {
         id: "app.common.other",
         defaultMessage: "Other",
      },
      masterCardPayment: {
         id: "app.common.masterCardPayment",
         defaultMessage: "Master Card",
      },
      maestroCardPayment: {
         id: "app.common.maestroCardPayment",
         defaultMessage: "Maestro Card",
      },
      amexPayment: {
         id: "app.common.amexPayment",
         defaultMessage: "Amex Card",
      },
      otherCardPayment: {
         id: "app.common.otherCardPayment",
         defaultMessage: "Other Card",
      },
      paytm: {
         id: "app.common.paytm",
         defaultMessage: "Paytm",
      },
      mobikwik: {
         id: "app.common.mobikwik",
         defaultMessage: "Mobikwik",
      },
      freecharge: {
         id: "app.common.freecharge",
         defaultMessage: "Freecharge",
      },
      oxigen: {
         id: "app.common.oxigen",
         defaultMessage: "Oxigen",
      },
      PhonePe: {
         id: "app.common.PhonePe",
         defaultMessage: "PhonePe",
      },
      Gpay: {
         id: "app.common.Gpay",
         defaultMessage: "Gpay",
      },
      BharatPe: {
         id: "app.common.BharatPe",
         defaultMessage: "BharatPe",
      },
      AmazonPay: {
         id: "app.common.AmazonPay",
         defaultMessage: "AmazonPay",
      },
      Sodexo: {
         id: "app.common.Sodexo",
         defaultMessage: "Sodexo",
      },
      otherWallet: {
         id: "app.common.otherWallet",
         defaultMessage: "Other Wallet",
      },
      freecharge: {
         id: "app.common.freecharge",
         defaultMessage: "Freecharge",
      },
      Cheque: {
         id: "app.common.Cheque",
         defaultMessage: "Cheque",
      },
      chequeNo: {
         id: "app.common.chequeNo",
         defaultMessage: "Cheque No",
      },
      Credit: {
         id: "app.common.Credit",
         defaultMessage: "Credit",
      },
      Voucher: {
         id: "app.common.Voucher",
         defaultMessage: "Voucher",
      },
      voucherID: {
         id: "app.common.voucherID",
         defaultMessage: "Voucher ID",
      },
      voucherName: {
         id: "app.common.voucherName",
         defaultMessage: "Voucher Name",
      },
      voucherValue: {
         id: "app.common.voucherValue",
         defaultMessage: "Voucher Name",
      },
      voucherType: {
         id: "app.common.voucherType",
         defaultMessage: "Voucher Type",
      },
      validityDays: {
         id: "app.common.validityDays",
         defaultMessage: "Validity (Days)",
      },
      PartyMaster: {
         id: "app.common.PartyMaster",
         defaultMessage: "Party Master",
      },
      ABPL: {
         id: "app.common.ABPL",
         defaultMessage: "ABPL",
      },
      BankDiscount: {
         id: "app.common.BankDiscount",
         defaultMessage: "Bank Discount",
      },
      BharatQR: {
         id: "app.common.BharatQR",
         defaultMessage: "Bharat QR",
      },
      BrandEMI: {
         id: "app.common.BrandEMI",
         defaultMessage: "Brand EMI",
      },
      CardPayment: {
         id: "app.common.CardPayment",
         defaultMessage: "Card Payment",
      },
      CreditNotePayment: {
         id: "app.common.CreditNotePayment",
         defaultMessage: "Credit Note Payment",
      },
      DDPayment: {
         id: "app.common.DDPayment",
         defaultMessage: "DD Payment",
      },
      DineOut: {
         id: "app.common.DineOut",
         defaultMessage: "DineOut",
      },
      DotPe: {
         id: "app.common.DotPe",
         defaultMessage: "DotPe",
      },
      Dunzo: {
         id: "app.common.Dunzo",
         defaultMessage: "Dunzo",
      },
      EMI: {
         id: "app.common.EMI",
         defaultMessage: "EMI",
      },
      EZETAP: {
         id: "app.common.EZETAP",
         defaultMessage: "EZETAP",
      },
      FonePayQR: {
         id: "app.common.FonePayQR",
         defaultMessage: "FonePay QR",
      },
      FoodPanda: {
         id: "app.common.FoodPanda",
         defaultMessage: "Food Panda",
      },
      GiftIicon: {
         id: "app.common.GiftIicon",
         defaultMessage: "Gift Icon",
      },
      HungryBox: {
         id: "app.common.HungryBox",
         defaultMessage: "Hungry Box",
      },
      KhaltiQR: {
         id: "app.common.KhaltiQR",
         defaultMessage: "Khalti QR",
      },
      LoyaltyPayment: {
         id: "app.common.LoyaltyPayment",
         defaultMessage: "Loyalty Payment",
      },
      MealCoupon: {
         id: "app.common.MealCoupon",
         defaultMessage: "Meal Coupon",
      },
      MySquare: {
         id: "app.common.MySquare",
         defaultMessage: "My Square",
      },
      NBFC: {
         id: "app.common.NBFC",
         defaultMessage: "NBFC",
      },
      NetBanking: {
         id: "app.common.NetBanking",
         defaultMessage: "Net Banking",
      },
      NoCharge: {
         id: "app.common.NoCharge",
         defaultMessage: "No Charge",
      },
      PaytmDQR: {
         id: "app.common.PaytmDQR",
         defaultMessage: "Paytm DQR",
      },
      PayuSMS: {
         id: "app.common.PayuSMS",
         defaultMessage: "Payu SMS Link",
      },
      PocketPayment: {
         id: "app.common.PocketPayment",
         defaultMessage: "Pocket Payment",
      },
      Swiggy: {
         id: "app.common.Swiggy",
         defaultMessage: "Swiggy",
      },
      UberEats: {
         id: "app.common.UberEats",
         defaultMessage: "Uber Eats",
      },
      Unipay: {
         id: "app.common.Unipay",
         defaultMessage: "Unipay",
      },
      UPI: {
         id: "app.common.UPI",
         defaultMessage: "UPI Payment",
      },
      WalletPayment: {
         id: "app.common.WalletPayment",
         defaultMessage: "Wallet Payment",
      },
      Zomato: {
         id: "app.common.Zomato",
         defaultMessage: "Zomato",
      },
      Employee: {
         id: "app.common.Employee",
         defaultMessage: "Employee",
      },
      Customer: {
         id: "app.common.Customer",
         defaultMessage: "Customer",
      },
      customerID: {
         id: "app.common.customerID",
         defaultMessage: "Customer ID",
      },
      customerIDs: {
         id: "app.common.customerIDs",
         defaultMessage: "Customer IDs",
      },
      orderType: {
         id: "app.common.orderType",
         defaultMessage: "Order Type",
      },
      paymentType: {
         id: "app.common.paymentType",
         defaultMessage: "Payment Type",
      },
      invoiceID: {
         id: "app.common.invoiceID",
         defaultMessage: "Invoice ID",
      },
      invoiceNumber: {
         id: "app.common.invoiceNumber",
         defaultMessage: "Invoice Number",
      },
      invoiceDate: {
         id: "app.common.invoiceDate",
         defaultMessage: "Invoice Date",
      },
      invoiceTime: {
         id: "app.common.invoiceTime",
         defaultMessage: "Invoice Time",
      },
      vendor: {
         id: "app.common.vendorName",
         defaultMessage: "Vendor Name",
      },
      vendorName: {
         id: "app.common.vendorName",
         defaultMessage: "Vendor Name",
      },
      remarks: {
         id: "app.common.remarks",
         defaultMessage: "Remarks",
      },
      amount: {
         id: "app.common.amount",
         defaultMessage: "Amount",
      },
      amountDue: {
         id: "app.common.amountDue",
         defaultMessage: "Amount Due",
      },
      amountPaid: {
         id: "app.common.amountPaid",
         defaultMessage: "Amount Paid",
      },
      amountLeft: {
         id: "app.common.amountLeft",
         defaultMessage: "Amount Left",
      },
      invoiceCreationDate: {
         id: "app.common.invoiceCreationDate",
         defaultMessage: "Invoice Creation Date",
      },
      invoiceDueDate: {
         id: "app.common.invoiceDueDate",
         defaultMessage: "Invoice Due Date",
      },
      invoiceAmount: {
         id: "app.common.invoiceAmount",
         defaultMessage: "Invoice Amount",
      },
      invoiceStatus: {
         id: "app.common.invoiceStatus",
         defaultMessage: "Invoice Status",
      },
      amountLeft: {
         id: "app.common.amountLeft",
         defaultMessage: "Amount Left",
      },
      listOfAllPayments: {
         id: "app.common.listOfAllPayments",
         defaultMessage: "List of all payment",
      },
      payment: {
         id: "app.common.payment",
         defaultMessage: "payment",
      },
      paymentDate: {
         id: "app.common.paymentDate",
         defaultMessage: "paymentDate",
      },
      user: {
         id: "app.common.user",
         defaultMessage: "User",
      },
      viewPayments: {
         id: "app.common.viewPayments",
         defaultMessage: "View Payment",
      },
      vendor: {
         id: "app.common.vendor",
         defaultMessage: "Vendor",
      },
      status: {
         id: "app.common.status",
         defaultMessage: "Status",
      },
      makePayment: {
         id: "app.common.makePayment",
         defaultMessage: "Make Payment",
      },
      createInvoice: {
         id: "app.common.createInvoice",
         defaultMessage: "Create Invoice",
      },
      paymentMode: {
         id: "app.common.paymentMode",
         defaultMessage: "Payment Mode",
      },

      confirm: {
         id: "app.common.confirm",
         defaultMessage: "Confirm",
      },
      count: {
         id: "app.common.count",
         defaultMessage: "Count",
      },
      averagePrice: {
         id: "app.common.averagePrice",
         defaultMessage: "Average Price",
      },
      voidCount: {
         id: "app.common.voidCount",
         defaultMessage: "Void Count",
      },
      NCCount: {
         id: "app.common.NCCount",
         defaultMessage: "NC Count",
      },
      grossProfit: {
         id: "app.common.grossProfit",
         defaultMessage: "Gross profit",
      },
      grossProfitPerUnit: {
         id: "app.common.grossProfitPerUnit",
         defaultMessage: "Gross Profit Per Unit",
      },
      avgVariantProfitPerc: {
         id: "app.common.avgVariantProfitPerc",
         defaultMessage: "Gross Profit (%)",
      },
      orders: {
         id: "app.common.orders",
         defaultMessage: "Orders",
      },
      voidSales: {
         id: "app.common.voidSales",
         defaultMessage: "Void Sales",
      },
      unitsSold: {
         id: "app.common.unitsSold",
         defaultMessage: "Units Sold",
      },
      weightSold: {
         id: "app.common.weightSold",
         defaultMessage: "Total Weight Sold",
      },
      weightUnit: {
         id: "app.common.weightUnit",
         defaultMessage: "Weight Unit",
      },
      uniqueProduct: {
         id: "app.common.uniqueProduct",
         defaultMessage: "Unique Product",
      },
      totalCharges: {
         id: "app.common.totalCharges",
         defaultMessage: "Total Charges",
      },
      totalDiscounts: {
         id: "app.common.totalDiscounts",
         defaultMessage: "Total Discounts",
      },
      grossSale: {
         id: "app.common.grossSale",
         defaultMessage: "Gross Sales",
      },
      netSale: {
         id: "app.common.netSale",
         defaultMessage: "Net Sales",
      },
      orderCount: {
         id: "app.common.orderCount",
         defaultMessage: "Order Count",
      },
      deviceID: {
         id: "app.common.deviceID",
         defaultMessage: "Device ID",
      },
      customerType: {
         id: "app.common.customerType",
         defaultMessage: "Customer Type",
      },
      customerPhone: {
         id: "app.common.customerPhone",
         defaultMessage: "Customer Phone",
      },
      employeeName: {
         id: "app.common.employeeName",
         defaultMessage: "Employee Name",
      },
      employee: {
         id: "app.common.employee",
         defaultMessage: "Employee",
      },
      itemSold: {
         id: "app.common.itemSold",
         defaultMessage: "Item Sold",
      },
      sales2: {
         id: "app.common.sales2",
         defaultMessage: "(+) Sales",
      },
      hourRange: {
         id: "app.common.hourRange",
         defaultMessage: "Hour Range",
      },
      total: {
         id: "app.common.total",
         defaultMessage: "Total",
      },
      noCharge: {
         id: "app.common.noCharge",
         defaultMessage: "No Charge",
      },
      refundedOrderID: {
         id: "app.common.refundedOrderID",
         defaultMessage: "Refund Order ID",
      },
      voidDate: {
         id: "app.common.voidDate",
         defaultMessage: "Void Date",
      },
      voidTime: {
         id: "app.common.voidTime",
         defaultMessage: "Void Time",
      },
      voidUser: {
         id: "app.common.voidUser",
         defaultMessage: "Void User",
      },
      originalInvoiceAmount: {
         id: "app.common.originalInvoiceAmount",
         defaultMessage: "Original Invoice Amount",
      },
      partyName: {
         id: "app.common.partyName",
         defaultMessage: "Party Name",
      },
      lastFourDigits: {
         id: "app.common.lastFourDigits",
         defaultMessage: "Last Four Digits",
      },
      cardType: {
         id: "app.common.cardType",
         defaultMessage: "Card Type",
      },
      approvalCode: {
         id: "app.common.approvalCode",
         defaultMessage: "Approval Code",
      },
      acquiringBankCode: {
         id: "app.common.acquiringBankCode",
         defaultMessage: "Acquiring Bank Code",
      },
      cardHolderName: {
         id: "app.common.cardHolderName",
         defaultMessage: "Card Holder Name",
      },
      referenceID: {
         id: "app.common.referenceID",
         defaultMessage: "Reference ID",
      },
      costPricePerUnit: {
         id: "app.common.costPricePerUnit",
         defaultMessage: "Cost Price per Unit",
      },
      grossBill2: {
         id: "app.common.grossBill2",
         defaultMessage: "Gross Bill",
      },
      configurationName: {
         id: "app.common.configurationName",
         defaultMessage: "Configuration Name",
      },
      entity: {
         id: "app.common.entity",
         defaultMessage: "Entity",
      },
      entityCount: {
         id: "app.common.entityCount",
         defaultMessage: "Entity Count",
      },
      combinedTarget: {
         id: "app.common.combinedTarget",
         defaultMessage: "Combined Target",
      },
      targetAchieved: {
         id: "app.common.targetAchieved",
         defaultMessage: "Target Achieved",
      },
      margin: {
         id: "app.common.margin",
         defaultMessage: "Margin",
      },
      bundleName: {
         id: "app.common.bundleName",
         defaultMessage: "Bundle Name",
      },
      regionName: {
         id: "app.common.regionName",
         defaultMessage: "Region Name",
      },
      voidQuantity: {
         id: "app.common.voidQuantity",
         defaultMessage: "Void Quantity",
      },
      NCQuantity: {
         id: "app.common.NCQuantity",
         defaultMessage: "NC Quantity",
      },
      productLevelTax: {
         id: "app.common.productLevelTax",
         defaultMessage: "(+) Taxes (Product Level)",
      },
      orderLevelTax: {
         id: "app.common.orderLevelTax",
         defaultMessage: "(+) Taxes (Order Level)",
      },
      netPrice: {
         id: "app.common.netPrice",
         defaultMessage: "(=) Net Price",
      },
      discountNet: {
         id: "app.common.discountNet",
         defaultMessage: "Discount Net",
      },
      pinCodeLengthErr: {
         id: "app.common.pinCodeLengthErr",
         defaultMessage: "Pincode length should be 6 digits.",
      },
      shortAmount: {
         id: "app.common.shortAmount",
         defaultMessage: "Short Amount",
      },
      tenderedAmount: {
         id: "app.common.tenderedAmount",
         defaultMessage: "Tendered Amount",
      },
      tenderedAmountCurrency: {
         id: "app.common.tenderedAmountCurrency",
         defaultMessage: "Tendered Amount Currency",
      },
      tenderedCurrencyCF: {
         id: "app.common.tenderedCurrencyCF",
         defaultMessage: "Tendered Currency CF",
      },
      changeAmount: {
         id: "app.common.changeAmount",
         defaultMessage: "Change Amount",
      },
      changeAmountCurrency: {
         id: "app.common.changeAmountCurrency",
         defaultMessage: "Change Amount Currency",
      },
      productDetails: {
         id: "app.common.productDetails",
         defaultMessage: "Product Details",
      },
      invoiceNumberRequired: {
         id: "app.common.invoiceNumberRequired",
         defaultMessage: "Invoice Number is required",
      },
      requisitionID: {
         id: "app.common.requisitionID",
         defaultMessage: "RequisitionID",
      },
      allFilters: {
         id: "app.common.allFilters",
         defaultMessage: "All Filters",
      },
      destination: {
         id: "app.common.destination",
         defaultMessage: "Destination",
      },
      paymentStatus: {
         id: "app.common.paymentStatus",
         defaultMessage: "Payment Status",
      },
      vendor: {
         id: "app.common.vendor",
         defaultMessage: "Vendor",
      },
      expectedDeliveryDate: {
         id: "app.common.expectedDeliveryDate",
         defaultMessage: "Expected delivery date",
      },
      shipmentMode: {
         id: "app.common.shipmentMode",
         defaultMessage: "Shipment Mode",
      },
      tnc: {
         id: "app.common.tnc",
         defaultMessage: "Terms & Conditions",
      },
      ccMail: {
         id: "app.common.ccMail",
         defaultMessage: "CC Email",
      },
      addMuliEmailAndPressEnter: {
         id: "app.common.addMuliEmailAndPressEnter",
         defaultMessage: "You can add multiple emails. Add an email & press enter.",
      },
      removeAllTaxes: {
         id: "app.common.removeAllTaxes",
         defaultMessage: "Remove All Taxes",
      },
      removeAllTaxesDesc: {
         id: "app.common.removeAllTaxesDesc",
         defaultMessage: "If true no taxes can be applied on products.",
      },
      unit: {
         id: "app.common.unit",
         defaultMessage: "Unit",
      },
      totalTax: {
         id: "app.common.totalTax",
         defaultMessage: "Total Tax",
      },
      addProducts: {
         id: "app.common.addProducts",
         defaultMessage: "Add Products",
      },
      sub_Total: {
         id: "app.common.sub_Total",
         defaultMessage: "Sub Total",
      },
      total_Rounding: {
         id: "app.common.total_Rounding",
         defaultMessage: "Total Rounding",
      },
      total_Amount: {
         id: "app.common.total_Amount",
         defaultMessage: "Total Amount",
      },
      rate: {
         id: "app.common.rate",
         defaultMessage: "Rate",
      },
      tax: {
         id: "app.common.tax",
         defaultMessage: "Tax",
      },
      rateRequired: {
         id: "app.common.rateRequired",
         defaultMessage: "Rate is Required",
      },
      rateCantLessthan0: {
         id: "app.common.rateCantLessthan0",
         defaultMessage: "Rate can't be less than 0",
      },
      quantityRequired: {
         id: "app.common.quantityRequired",
         defaultMessage: "Quantity is Required",
      },
      quantityCantLessthan0: {
         id: "app.common.quantityCantLessthan0",
         defaultMessage: "Quantity can't be less than 1",
      },
      source: {
         id: "app.common.source",
         defaultMessage: "Source",
      },
      vendorBillID: {
         id: "app.common.vendorBillID",
         defaultMessage: "Vendor Bill ID",
      },
      collect: {
         id: "app.common.collect",
         defaultMessage: "Collect",
      },
      destinationName: {
         id: "app.common.destinationName",
         defaultMessage: "Destination Name",
      },
      destinationType: {
         id: "app.common.destinationType",
         defaultMessage: "Destination Type",
      },
      time: {
         id: "app.common.time",
         defaultMessage: "Time",
      },
      quantityOrdered: {
         id: "app.common.quantityOrdered",
         defaultMessage: "Quantity Ordered",
      },
      size: {
         id: "app.common.size",
         defaultMessage: "Size",
      },
      taxNames: {
         id: "app.common.taxNames",
         defaultMessage: "Tax Names",
      },
      productValue: {
         id: "app.common.productValue",
         defaultMessage: "Product Value (₹)",
      },
      generate: {
         id: "app.common.generate",
         defaultMessage: "Generate",
      },
      autogenerate: {
         id: "app.common.autogenerate",
         defaultMessage: "Auto Generate",
      },
      deliveryStore: {
         id: "app.common.deliveryStore",
         defaultMessage: "Delivery Store",
      },
      expectedDate: {
         id: "app.common.expectedDate",
         defaultMessage: "Expected Date",
      },
      dateTime: {
         id: "app.common.dateTime",
         defaultMessage: "Date | Time",
      },
      vendorPhone: {
         id: "app.common.vendorPhone",
         defaultMessage: "Vendor Phone",
      },
      vendorPhone: {
         id: "app.common.vendorPhone",
         defaultMessage: "Vendor Phone",
      },
      vendorEmail: {
         id: "app.common.vendorEmail",
         defaultMessage: "Vendor Email",
      },
      vendorAddress: {
         id: "app.common.vendorAddress",
         defaultMessage: "Vendor Address",
      },
      vendorGst: {
         id: "app.common.vendorGst",
         defaultMessage: "Vendor GST",
      },
      transactionNumber: {
         id: "app.common.transactionNumber",
         defaultMessage: "Transaction Number",
      },
      cardDetails: {
         id: "app.common.cardDetails",
         defaultMessage: "Card Details",
      },
      sno: {
         id: "app.common.sno",
         defaultMessage: "S. No.",
      },
      paymentTime: {
         id: "app.common.paymentTime",
         defaultMessage: "Payment Time",
      },
      taxesWithSign: {
         id: "app.common.taxesWithSign",
         defaultMessage: "(+)Taxes",
      },
      paidAmount: {
         id: "app.common.paidAmount",
         defaultMessage: "(=) Paid Amount",
      },
      discountNames: {
         id: "app.common.discountNames",
         defaultMessage: "Discount Name",
      },
      discountValues: {
         id: "app.common.discountValues",
         defaultMessage: "Discount Value",
      },
      batchVariantName: {
         id: "app.common.batchVariantName",
         defaultMessage: "Batch Variant Name",
      },
      purchaseReturnID: {
         id: "app.common.purchaseReturnID",
         defaultMessage: "Purchase Return ID",
      },
      sourceName: {
         id: "app.common.sourceName",
         defaultMessage: "Source Name",
      },
      inventorySourceName: {
         id: "app.common.inventorySourceName",
         defaultMessage: "Inventory Source Name",
      },
      taxPercentages: {
         id: "app.common.taxPercentages",
         defaultMessage: "Tax Percentages",
      },
      taxValues: {
         id: "app.common.taxValues",
         defaultMessage: "Tax Values",
      },
      proformaInvoiceID: {
         id: "app.common.proformaInvoiceID",
         defaultMessage: "Proforma Invoice ID",
      },
      store: {
         id: "app.common.store",
         defaultMessage: "Store",
      },
      customerName: {
         id: "app.common.customerName",
         defaultMessage: "Customer Name",
      },
      customerPhoneNo: {
         id: "app.common.customerPhoneNo",
         defaultMessage: "Customer Phone No.",
      },
      summary: {
         id: "app.common.summary",
         defaultMessage: "Summary",
      },
      details: {
         id: "app.common.details",
         defaultMessage: "Details",
      },
      deliveryStatus: {
         id: "app.common.deliveryStatus",
         defaultMessage: "Delivery Status",
      },
      discountNet: {
         id: "app.common.discountNet",
         defaultMessage: "Discount Net",
      },
      chargeValues: {
         id: "app.common.chargeValues",
         defaultMessage: "Charge Values",
      },
      chargeNet: {
         id: "app.common.chargeNet",
         defaultMessage: "Charge Net",
      },
      taxNet: {
         id: "app.common.taxNet",
         defaultMessage: "Tax Net",
      },
      monthly: {
         id: "app.common.monthly",
         defaultMessage: "Monthly",
      },
      halfYearly: {
         id: "app.common.halfYearly",
         defaultMessage: "Half Yearly",
      },
      annually: {
         id: "app.common.annually",
         defaultMessage: "Annually",
      },
      threeYears: {
         id: "app.common.threeYears",
         defaultMessage: "Three Year",
      },
      fiveYears: {
         id: "app.common.fiveYears",
         defaultMessage: "Five Year",
      },
      proceed: {
         id: "app.common.proceed",
         defaultMessage: "Proceed",
      },
      expired: {
         id: "app.common.expired",
         defaultMessage: "Expired",
      },
      active: {
         id: "app.common.active",
         defaultMessage: "Active",
      },
      step2: {
         id: "app.common.step2",
         defaultMessage: "Step 2: Fill Details",
      },
      accountID : {
         id: "app.common.accountID",
         defaultMessage: "Account ID",
      },
      bankRrn: {
         id: "app.common.bankRrn",
         defaultMessage: "Bank Rrn",
      },
      RRN: {
         id: "app.common.RRN",
         defaultMessage: "RRN",
      },
      logs: {
         id: "app.common.logs",
         defaultMessage: "Logs",
      },
      grossMRP: {
         id: "app.common.grossMRP",
         defaultMessage: "Gross MRP",
      },
      uploadImage: {
         id: "app.common.uploadImage",
         defaultMessage: "Upload Image",
      },
      removeImage: {
         id: "app.common.removeImage",
         defaultMessage: "Remove Image",
      },
      maxSize: {
         id: "app.common.maxSize",
         defaultMessage: "*Max Size Upto 10 Mb",
      },
      totalValue : {
         id: "app.common.totalValue",
         defaultMessage: "Total Value",
      },
      checkInTime : {
         id: "app.common.checkInTime",
         defaultMessage: "Check In Time",
      },
      checkOutTime:{
         id: "app.common.checkOutTime",
         defaultMessage: "Check Out Time",
      },
      vehicleNumber:{
         id: "app.common.vehicleNumber",
         defaultMessage: "Vehicle Number",
      },
      vehicleType:{
         id: "app.common.vehicleType",
         defaultMessage: "Vehicle Type",
      },
      recipients:{
         id: "app.common.recipients",
         defaultMessage: "Recipients",
      },
      noDataFound:{
         id: "app.common.noDataFound",
         defaultMessage: "No Data Found",
      },
      noProducts:{
         id: "app.common.noProducts",
         defaultMessage: "No Products",
      },
      storeListEmpty:{
         id: "app.common.storeListEmpty",
         defaultMessage: "Stores list empty",
      },

      onlineOrderID: {
         id: "app.common.onlineOrderID",
         defaultMessage: "Online Order ID",
      },
      modelNumber: {
         id: "app.common.modelNumber",
         defaultMessage: "Model Number",
      },
      deviceSettlementLogs: {
         id: "app.common.deviceSettlementLogs",
         defaultMessage: "Device Settlement Logs",
      },
      deviceSettlementLogsDesc: {
         id: "app.common.deviceSettlementLogsDesc",
         defaultMessage: "Details reports available for Device Settlement Log.",
      },
      netProfitAmount: {
         id: "app.common.netProfitAmount",
         defaultMessage: "Net Profit Amount",
      },
      netProfit: {
         id: "app.common.netProfit",
         defaultMessage: "Net Profit(%)",
      },
      redeemed: {
         id: "app.common.redeemed",
         defaultMessage: "Redeemed",
      },
      create: {
         id: "app.common.create",
         defaultMessage: "Create",
      },
      update: {
         id: "app.common.update",
         defaultMessage: "Update",
      },
      creditLimit: {
         id: "app.common.creditLimit",
         defaultMessage: "Cedit Limit",
      },
      changeColor: {
         id: "app.common.changeColor",
         defaultMessage: "Change Color",
      },
      modifierCreated: {
         id: "app.common.modifierCreated",
         defaultMessage: "Modifier Created Successfully.",
      },
      defaultSuccessToast:{
         id: "app.common.defaultSuccessToast",
         defaultMessage: "Action completed successfully",
      },
      defaultErrorToast:{
         id: "app.common.defaultErrorToast",
         defaultMessage: "There was an error with your request. Please try again",
      },
      ipAddresses: {
         id: "app.common.ipAddresses",
         defaultMessage: "IP Addresses",
      },
      oldPassword: {
         id: "app.common.oldPassword",
         defaultMessage: "Old Password",
      },
      newPassword:{
         id: "app.common.newPassword",
         defaultMessage: "New Password",
      },
      confirmNewPassword:{
         id: "app.common.confirmNewPassword",
         defaultMessage: "Confirm New Password",
      },
      accept: {
         id: "app.common.accept",
         defaultMessage: "Accept",
      },
      reject: {
         id: "app.common.reject",
         defaultMessage: "Reject",
      },
      filterChannels: {
         id: "app.common.filterChannels",
         defaultMessage: "Filter Channels",
      },
      deviceDetail: {
         id: "app.common.deviceDetail",
         defaultMessage: "Device detail",
      },
      deviceId: {
         id: "app.common.deviceId",
         defaultMessage: "Device Id",
      },
      salesType: {
         id: "app..common.salesType",
         defaultMessage: "Sales Type",
      },
      footer: {
         products: {
            id: "app.common.footer.products",
            defaultMessage: "Product(s)",
         },
         category: {
            id: "app.common.footer.category",
            defaultMessage: "Category(s)",
         },
         charge: {
            id: "app.common.footer.charge",
            defaultMessage: "Charge(s)",
         },
         incomeHead: {
            id: "app.common.footer.incomeHead",
            defaultMessage: "Income Head(s)",
         },
         membership: {
            id: "app.common.footer.membership",
            defaultMessage: "Membership(s)",
         },
         modifier: {
            id: "app.common.footer.modifier",
            defaultMessage: "Modifier(s)",
         },
         modifierGroup: {
            id: "app.common.footer.modifierGroup",
            defaultMessage: "Modifier Group(s)",
         },
         customer: {
            id: "app.common.footer.customer",
            defaultMessage: "Customer(s)",
         },
         channel: {
            id: "app.common.footer.channel",
            defaultMessage: "Channel(s)",
         },
         priceList: {
            id: "app.common.footer.priceList",
            defaultMessage: "Price List(s)",
         },
         productGroup: {
            id: "app.common.footer.productGroup",
            defaultMessage: "Product Group(s)",
         },
         coupon: {
            id: "app.common.footer.coupon",
            defaultMessage: "Coupon(s)",
         },
         promotion: {
            id: "app.common.footer.promotion",
            defaultMessage: "Promotion(s)",
         },
         service: {
            id: "app.common.footer.service",
            defaultMessage: "Service(s)",
         },
         serviceGroup: {
            id: "app.common.footer.serviceGroup",
            defaultMessage: "Service Group(s)",
         },
         subCategory: {
            id: "app.common.footer.subCategory",
            defaultMessage: "Sub Category(s)",
         },
         tax: {
            id: "app.common.footer.tax",
            defaultMessage: "Tax(s)",
         },
         voucher: {
            id: "app.common.footer.voucher",
            defaultMessage: "Voucher(s)",
         },
      },
      Create: {
         id: "app.common.Create",
         defaultMessage: "Create",
      },
      Update: {
         id: "app.common.Update",
         defaultMessage: "Update",
      },
      Edit: {
         id: "app.common.Edit",
         defaultMessage: "Edit",
      },
      selected: {
         id: "app.common.selected",
         defaultMessage: "Selected",
      },
      select: {
         id: "app.common.select",
         defaultMessage: "Select",
      },
      rePrice: {
         id: "app.common.rePrice",
         defaultMessage: "RePrice",
      },
      monday: {
         id: "app.common.monday",
         defaultMessage: "Monday",
      },
      tuesday: {
         id: "app.common.tuesday",
         defaultMessage: "Tuesday",
      },
      wednesday: {
         id: "app.common.wednesday",
         defaultMessage: "Wednesday",
      },
      thursday: {
         id: "app.common.thursday",
         defaultMessage: "Thursday",
      },
      friday: {
         id: "app.common.friday",
         defaultMessage: "Friday",
      },
      saturday: {
         id: "app.common.saturday",
         defaultMessage: "Saturday",
      },
      sunday: {
         id: "app.common.sunday",
         defaultMessage: "Sunday",
      },
      BATCHED: {
         id: "app.common.BATCHED",
         defaultMessage: "BATCHED",
      },
      UNBATCHED: {
         id: "app.common.UNBATCHED",
         defaultMessage: "UNBATCHED",
      },
      showing:{
         id: "app.common.showing",
         defaultMessage: "Showing",
      },
      to:{
         id: "app.common.to",
         defaultMessage: "to",
      },
      of:{
         id: "app.common.of",
         defaultMessage: "of",
      },
      defaultmanufacturerNone:{
         id:"app.common.defaultmanufacturerNone",
         defaultMessage:"Default manufacturer (none)"
      },
      successfullyDeleted : {
         id: "app.common.successfullyDeleted",
         defaultMessage: "Successfully Deleted",
      },
      areUsure : {
         id: "app.common.areUsure",
         defaultMessage: "Are you sure you want to delete this item ?"
      },
      today : {
         id: "app.common.today",
         defaultMessage: "Today"
      },
      yesterday : {
         id: "app.common.yesterday",
         defaultMessage: "Yesterday"
      },
      thisWeek : {
         id: "app.common.thisWeek",
         defaultMessage: "This Week"
      },
      lastWeek : {
         id: "app.common.lastWeek",
         defaultMessage: "Last Week"
      },
      thisMonth :  {
         id: "app.common.thisMonth",
         defaultMessage: "This Month"
      },
      lastMonth :  {
         id: "app.common.lastMonth",
         defaultMessage: "Last Month"
      },
      daysUptoToday : {
         id: "app.common.daysUptoToday",
         defaultMessage: "days up to today"
      },
      daysStartingToday : {
         id: "app.common.daysStartingToday",
         defaultMessage: "days starting today"
      },
      easyID: {
         id: "app.common.easyID",
         defaultMessage: "Easy ID",
      },
      manufacturerID: {
         id: 'app.common.manufacturerID',
         defaultMessage: 'Manufacturer ID',
         description: ''
       },
       manufacturerName: {
         id: 'app.common.manufacturerName',
         defaultMessage: 'Manufacturer Name',
         description: ''
       },
       incomeHead: {
         id: 'app.common.incomeHead',
         defaultMessage: 'Income Head',
         description: ''
       },
       weightUnit: {
         id: 'app.common.weightUnit',
         defaultMessage: 'Weight Unit',
         description: ''
       },
       orderCount: {
         id: 'app.common.orderCount',
         defaultMessage: 'Order Count',
         description: ''
       },
       quantity: {
         id: 'app.common.quantity',
         defaultMessage: 'Quantity',
         description: ''
       },
       totalWeightSold: {
         id: 'app.common.totalWeightSold',
         defaultMessage: 'Total Weight Sold',
         description: ''
       },
       taxes: { id: 'app.common.taxes', defaultMessage: 'Taxes', description: '' },
       charges: {
         id: 'app.common.charges',
         defaultMessage: 'Charges',
         description: ''
       },
       discounts: {
         id: 'app.common.discounts',
         defaultMessage: 'Discounts',
         description: ''
       },
       netSales: {
         id: 'app.common.netSales',
         defaultMessage: 'Net Sales',
         description: ''
       },
       sales: { id: 'app.common.sales', defaultMessage: 'Sales', description: '' },
       grossSales: {
         id: 'app.common.grossSales',
         defaultMessage: 'Gross Sales',
         description: ''
       },
       voidQuantity: {
         id: 'app.common.voidQuantity',
         defaultMessage: 'Void Quantity',
         description: ''
       },
       voidAmount: {
         id: 'app.common.voidAmount',
         defaultMessage: 'Void Amount',
         description: ''
       },
       netQuantity: {
         id: 'app.common.netQuantity',
         defaultMessage: 'Net Quantity',
         description: ''
       },
       totalVoidWeightSold: {
         id: 'app.common.totalVoidWeightSold',
         defaultMessage: 'Total Void Weight Sold',
         description: ''
       },
       netWeightSold: {
         id: 'app.common.netWeightSold',
         defaultMessage: 'Net Weight Sold',
         description: ''
       },
       unitName: {
         id: 'app.common.unitName',
         defaultMessage: 'Unit Name',
         description: ''
       },
       totalProducts: {
         id: 'app.common.totalProducts',
         defaultMessage: 'Total Products',
         description: ''
       },
       brandID: {
         id: 'app.common.brandID',
         defaultMessage: 'Brand ID',
         description: ''
       },
       brandName: {
         id: 'app.common.brandName',
         defaultMessage: 'Brand Name',
         description: ''
       },
       categoryID: {
         id: 'app.common.categoryID',
         defaultMessage: 'Category ID',
         description: ''
       },
       categoryName: {
         id: 'app.common.categoryName',
         defaultMessage: 'Category Name',
         description: ''
       },
       subCategoryID: {
         id: 'app.common.subCategoryID',
         defaultMessage: 'Sub Category ID',
         description: ''
       },
       subCategoryName: {
         id: 'app.common.subCategoryName',
         defaultMessage: 'Sub Category',
         description: ''
       },
       sizeName: {
         id: 'app.common.sizeName',
         defaultMessage: 'Size Name',
         description: ''
       },
       colourName: {
         id: 'app.common.colourName',
         defaultMessage: 'Colour Name',
         description: ''
       },
       sku: { id: 'app.common.sku', defaultMessage: 'SKU', description: '' },
       billingUsername:{
         id: 'app.common.billingUsername',
         defaultMessage: 'Billing User Name',
       },
       ProductCount:{
         id: 'app.common.ProductCount',
         defaultMessage: 'Product Count ',
       },
       NCAmount:{
         id: 'app.common.NCAmount',
         defaultMessage: 'NC Amount',
       },
       brandCount:{
         id: 'app.common.brandCount',
         defaultMessage: 'Brand Count',
       },
       catCount:{
         id: 'app.common.catCount',
         defaultMessage: 'Cat Count',
       },
       prodCount:{
         id: 'app.common.prodCount',
         defaultMessage: 'Prod Count',
       },
       totalTaxes:{
         id: 'app.common.totalTaxes',
         defaultMessage: 'Total Taxes',
       },
       totalVoidAmount:{
         id: 'app.common.totalVoidAmount',
         defaultMessage: 'Total Void Amount',
       },
      easyID: {
         id: "app.common.easyID",
         defaultMessage: "Easy ID",
      },
      hideBatchedProduct: {
         id: "app.common.hideBatchedProduct",
         defaultMessage: "Hide Batched Product",
      },
      storeWiseProductGroupStock: {
         id: "app.common.storeWiseProductGroupStock",
         defaultMessage: "Store wise product group stock level"
      },
      stockCustomAttributes: {
         id: "app.common.stockCustomAttributes",
         defaultMessage: "Stock Custom Attributes",
      },
      stockSystemAttributes: {
         id: "app.common.stockSystemAttributes",
         defaultMessage: "Stock System Attributes",
      },
      NCOrder: {
         id: "app.common.NCOrder",
         defaultMessage: "NC Order",
      },
      totalOrder: {
         id: "app.common.totalOrder",
         defaultMessage: "Total Order",
      },
      discountsWithSign: {
         id: "app.common.discountsWithSign",
         defaultMessage: "(-) Discounts",
      },
      chargesWithSign: {
         id: "app.common.chargesWithSign",
         defaultMessage: "(+) Charges",
      },
      reservationInfo: {
         id: "app.common.reservationInfo",
         defaultMessage: "Reservation Information",
      },
      counter: {
         title: {
            id: "app.common.counter.title",
            defaultMessage: "Counter Wise Sales",
         },
         desc: {
            id: "app.common.counter.desc",
            defaultMessage: "Detailed report of Counter-wise Sales.",
         },
      },
      countryOfOrigin : {
         id: "app.common.countryOfOrigin",
         defaultMessage: "Country of Origin"
      },
      viewPaymentModes: {
         id: "app.common.viewPaymentModes",
         defaultMessage: "View Payment Modes",
      },
      paymentID: {
         id: "app.common.paymentID",
         defaultMessage: "Payment ID",
      },
      alert : {
         id: "app.common.alert",
         defaultMessage: "Alert"
      }
   },
   inventory: {
      common: {
         vendorBillID: {
            id: "app.inventory.common.vendorBillID",
            defaultMessage: "Vendor Bill ID",
         },
         transactionID: {
            id: "app.inventory.common.transactionID",
            defaultMessage: "Transaction ID",
         },
         invoiceNumber: {
            id: "app.inventory.common.invoiceNumber",
            defaultMessage: "Invoice Number",
         },
         sourceName: {
            id: "app.inventory.common.sourceName",
            defaultMessage: "Destination",
         },
         invoiceDate: {
            id: "app.inventory.common.invoiceDate",
            defaultMessage: "Invoice Date",
         },
         totalItemNumber: {
            id: "app.inventory.common.totalItemNumber",
            defaultMessage: "Total Item Number",
         },
         totalCost: {
            id: "app.inventory.common.totalCost",
            defaultMessage: "Cost",
         },
         transactionSourceType: {
            id: "app.inventory.common.transactionSourceType",
            defaultMessage: "Reference Transaction Type",
         },
         transactionSourceID: {
            id: "app.inventory.common.transactionSourceID",
            defaultMessage: "Reference ID",
         },
         requisitionID: {
            id: "app.inventory.common.requisitionID",
            defaultMessage: "Requisition ID",
         },
         requisitionTime: {
            id: "app.inventory.common.requisitionTime",
            defaultMessage: "Requisition Time",
         },
         userName: {
            id: "app.inventory.common.userName",
            defaultMessage: "User",
         },
         email: {
            id: "app.inventory.common.email",
            defaultMessage: "Mail To",
         },
         remarks: {
            id: "app.inventory.common.remarks",
            defaultMessage: "Remarks",
         },
         irnNumber : {
            id: "app.inventory.common.irnNumber",
            defaultMessage: "IRN Number",
         },
         genIRN: {
            id: "app.inventory.common.genIRN",
            defaultMessage: "Generate IRN",
         },
         cancelIrn : {
            id: "app.inventory.common.cancelIrn",
            defaultMessage: "Cancel IRN",
         },
         cancelIrnQn : {
            id: "app.inventory.common.deletIrnQn",
            defaultMessage: "Are you sure you want to cancel IRN",
         },
         cancleIrnSuccess : {
            id: "app.inventory.common.cancleIrnSuccess",
            defaultMessage: "IRN cancelled successfully.",
         },
         cancleStockTransferSuccess : {
            id: "app.inventory.common.cancleStockTransferSuccess",
            defaultMessage: "Stock Transfer cancelled successfully.",
         },
         purchaseOrder: {
            id: "app.inventory.common.purchaseOrder",
            defaultMessage: " Purchase Order",
         },
         purchaseOrderID: {
            id: "app.inventory.common.purchaseOrderID",
            defaultMessage: " Purchase Order ID",
         },
         purchaseOrderSubID: {
            id: "app.inventory.common.purchaseOrderSubID",
            defaultMessage: " Purchase Order Sub ID",
         },
         requisition: {
            id: "app.inventory.common.requisition",
            defaultMessage: "Stock Requisition",
         },
         destination: {
            id: "app.inventory.common.destination",
            defaultMessage: "Destination",
         },
         destinationPlaceholder: {
            id: "app.inventory.common.destinationPlaceholder",
            defaultMessage: "Select Destination",
         },
         applyTaxes: {
            id: "app.inventory.common.applyTaxes",
            defaultMessage: "Apply Taxes On This Transaction",
         },
         purchaseID: {
            id: "app.inventory.common.purchaseID",
            defaultMessage: "Purchase order ID",
         },
         purchaseIdPlaceholder: {
            id: "app.inventory.common.purchaseIdPlaceholder",
            defaultMessage: "Enter Purchase Order ID",
         },
         purchaseNote: {
            id: "app.inventory.common.purchaseNote",
            defaultMessage: "Search either by Purchase Order ID or Invoice Number",
         },
         destinationErr: {
            id: "app.inventory.common.destinationErr",
            defaultMessage: "Please select a destination.",
         },
         vendorBillIDError: {
            id: "app.inventory.common.vendorBillIDError",
            defaultMessage: "Please select a Vendor & Vendor Bill ID.",
         },
         purchaseExistErr: {
            id: "app.inventory.common.purchaseExistErr",
            defaultMessage: "Sorry no Purchase Order exist.",
         },
         requisitionIDErr: {
            id: "app.inventory.common.requisitionIDErr",
            defaultMessage: "Please select a Requisition ID.",
         },
         POfulfilledErr: {
            id: "app.inventory.common.POfulfilledErr",
            defaultMessage: "Purchase Order already fulfilled!",
         },
         POapprovalErr: {
            id: "app.inventory.common.POapprovalErr",
            defaultMessage: "Purchase Order not  approved!",
         },
         VBfulfilledErr: {
            id: "app.inventory.common.VBfulfilledErr",
            defaultMessage: "Vendor Bill already fulfilled!",
         },
         requisitionFulfilledErr: {
            id: "app.inventory.common.requisitionFulfilledErr",
            defaultMessage: "Requisition ID already fulfilled!",
         },
         sourceType: {
            id: "app.inventory.common.sourceType",
            defaultMessage: "Source Type",
         },
         orderDate: {
            id: "app.inventory.common.orderDate",
            defaultMessage: "Order Date",
         },
         vendorName: {
            id: "app.inventory.common.vendorName",
            defaultMessage: "Vendor",
         },
         fulfillmentStatus: {
            id: "app.inventory.common.fulfillmentStatus",
            defaultMessage: "FullFillment Status",
         },
         purchaseQty: {
            id: "app.inventory.common.purchaseQty",
            defaultMessage: "Purchase Quantity",
         },
         purchasefulfilledQty: {
            id: "app.inventory.common.purchasefulfilledQty",
            defaultMessage: "Purchase Fulfilled Quantity",
         },
         qtyNegativeErr: {
            id: "app.inventory.common.qtyNegativeErr",
            defaultMessage: "Quantity cannot be negative!",
         },
         stockQty: {
            id: "app.inventory.common.stockQty",
            defaultMessage: "Transaction Quantity",
         },
         cost_unitErr: {
            id: "app.inventory.common.cost_unitErr",
            defaultMessage: "Cost/Unit can not be negative",
         },
         qtyRequired: {
            id: "app.inventory.common.qtyRequired",
            defaultMessage: "Quantity is required.",
         },
         remainingQty: {
            id: "app.inventory.common.remainingQty",
            defaultMessage: "Remaining Quantity",
         },
         returnQty: {
            id: "app.el",
            defaultMessage: "Return Quantity",
         },
         noProdsAvailable: {
            id: "app.inventory.common.noProdsAvailable",
            defaultMessage: "No products available in the list.",
         },
         stockInSuccesMsg: {
            id: "app.inventory.common.stockInSuccesMsg",
            defaultMessage: "Stock Successfully Updated",
         },
         batchupdateErr: {
            id: "app.inventory.common.batchupdateErr",
            defaultMessage: "Unable to update Batch , please try again..!",
         },
         otherInfo: {
            id: "app.inventory.common.otherInfo",
            defaultMessage: "Other Information",
         },
         vendorInfo: {
            id: "app.inventory.common.vendorInfo",
            defaultMessage: "Vendor Information",
         },
         selectVendor: {
            id: "app.inventory.common.selectVendor",
            defaultMessage: "Select Vendor",
         },
         invalidSourceID: {
            id: "app.inventory.common.invalidSourceID",
            defaultMessage: "Invalid Source Id. Please Select Correct Source..!",
         },
         uploadValidExcel: {
            id: "app.inventory.common.uploadValidExcel",
            defaultMessage: "Please Upload  Excel file with valid records!",
         },
         enterRemarksErr: {
            id: "app.inventory.common.enterRemarksErr",
            defaultMessage: "Please enter the remarks for all selected products.",
         },
         bulkProduct: {
            id: "app.inventory.common.bulkProduct",
            defaultMessage: "Bulk Product",
         },
         inventoryBatch: {
            id: "app.inventory.common.inventoryBatch",
            defaultMessage: "Inventory Batch",
         },
         req: {
            id: "app.inventory.common.req",
            defaultMessage: "Requisition",
         },
         prodGroup: {
            id: "app.inventory.common.prodGroup",
            defaultMessage: "Product Group",
         },
         storeWareNotSame: {
            id: "app.inventory.common.storeWareNotSame",
            defaultMessage: "Store and Warehouse should not be same.",
         },
         noOfStores: {
            id: "app.inventory.common.noOfStores",
            defaultMessage: "The number of selected destinations should not be greater-than 25",
         },
         selectStoreErr: {
            id: "app.inventory.common.selectStoreErr",
            defaultMessage: "Please select a Store!",
         },
         download: {
            id: "app.inventory.common.download",
            defaultMessage: "Download Template",
         },
         requisitionDescInModal: {
            id: "app.inventory.common.requisitionDescInModal",
            defaultMessage:
               "The excel template to request the products can be downloaded by first selecting the store or warehouse and then by clicking the download button given below.",
         },
         selectStoreWareAndDownload: {
            id: "app.inventory.common.selectStoreWareAndDownload",
            defaultMessage: "Select store or warehouse. Click download.",
         },
         selectRequisitionId: {
            id: "app.inventory.common.selectRequisitionId",
            defaultMessage: "Select Requistion ID. Click download.",
         },
         selectSourceAndDestination: {
            id: "app.inventory.common.selectSourceAndDestination",
            defaultMessage: "Select source and destinations. Click download.",
         },
         selectSource: {
            id: "app.inventory.common.selectSource",
            defaultMessage: "Select Source",
         },
         selectDestination: {
            id: "app.inventory.common.selectDestination",
            defaultMessage: "Select Destination",
         },
         selectSourceErr: {
            id: "app.inventory.common.selectSourceErr",
            defaultMessage: "Please Select a Source.",
         },
         selectDestinationErr: {
            id: "app.inventory.common.selectDestinationErr",
            defaultMessage: "Please Select a Destination.",
         },
         plsSelectReqID: {
            id: "app.inventory.common.plsSelectReqID",
            defaultMessage: "Please Select Requisition ID",
         },
         uploadDescription: {
            id: "app.inventory.common.uploadDescription",
            defaultMessage:
               "Upload the filled template. A preview screen will show the content of uploaded file for verification. Verify the data and press Upload.",
         },
         uploadedSucessfully: {
            id: "app.inventory.common.uploadedSucessfully",
            defaultMessage: "File Successfully Uploaded.",
         },
         applyTaxesOnTheTransaction: {
            id: "app.inventory.common.applyTaxesOnTheTransaction",
            defaultMessage: "Apply Taxes On This Transaction",
         },
         fulfillmentCenter: {
            id: "app.inventory.common.fulfillmentCenter",
            defaultMessage: "Fullfillment Center",
         },
         fulfillmentCenterErr: {
            id: "app.inventory.common.fulfillmentCenterErr",
            defaultMessage: "Please Select Fullfillment center",
         },
         trackRequisitionStatus: {
            id: "app.inventory.common.trackRequisitionStatus",
            defaultMessage: "Track requisition status ?",
         },
         selectCategoryErr: {
            id: "app.inventory.common.selectCategoryErr",
            defaultMessage: "Please Select a Category.",
         },
         selectBrandErr: {
            id: "app.inventory.common.selectBrandErr",
            defaultMessage: "Please Select a Brand.",
         },
         drag: {
            id: "app.inventory.common.drag",
            defaultMessage: "Drop Files or Click to upload",
         },
         transactionDate: {
            id: "app.inventory.common.transactionDate",
            defaultMessage: "Transaction Date",
         },
         transactionTime: {
            id: "app.inventory.common.transactionTime",
            defaultMessage: "Transaction Time",
         },
         noProdsFoundAsFullfilled: {
            id: "app.inventory.common.noProdsFoundAsFullfilled",
            defaultMessage: "No Products Found as status Fullfilled or Partially fullfilled.",
         },
         sourceDestinationCommonErr: {
            id: "app.inventory.common.sourceDestinationCommonErr",
            defaultMessage: "Source and destination can't be same!",
         },
         selectReqID: {
            id: "app.inventory.common.selectReqID",
            defaultMessage: "Select Requisition ID",
         },
         batchVariantCheck:{
            id: "app.inventory.common.batchVariantCheck",
            defaultMessage: "Duplicate Product groups found, Please check.",
         }
      },
      stockin: {
         title: {
            id: "app.inventory.stockin.title",
            defaultMessage: "Stock In",
         },
         description: {
            id: "app.inventory.stockin.description",
            defaultMessage: "Stock In transaction history of last 7 days.",
         },
         addStock: {
            id: "app.inventory.stockin.addStock",
            defaultMessage: "Add Stock",
         },
         step1: {
            id: "app.inventory.stockin.step1",
            defaultMessage: "Step 1 : Stock In Method",
         },
         newStock: {
            id: "app.inventory.stockin.newStock",
            defaultMessage: "New Stock Received",
         },
         inventoryBatches: {
            id: "app.inventory.stockin.inventoryBatches",
            defaultMessage: "Inventory Batches",
         },
         vendorBill: {
            id: "app.inventory.stockin.vendorBill",
            defaultMessage: "Vendor Bill",
         },
      },
      stockout: {
         description: {
            id: "app.inventory.stockout.description",
            defaultMessage: "Stock Out transaction history of last 7 days.",
         },
         removeStock: {
            id: "app.inventory.stockout.removeStock",
            defaultMessage: "Remove Stock",
         },
         removeInBulk: {
            id: "app.inventory.stockout.removeInBulk",
            defaultMessage: "Remove In Bulk (Excel)",
         },
      },
      stockrequisition: {
         title: {
            id: "app.inventory.stockrequisition.title",
            defaultMessage: "Stock Requisition",
         },
         description: {
            id: "app.inventory.stockrequisition.description",
            defaultMessage: "Stock Requisition transaction history of last 7 days.",
         },
         requestStocks: {
            id: "app.inventory.stockrequisition.requestStocks",
            defaultMessage: "Request Stocks",
         },
         bulk: {
            id: "app.inventory.stockrequisition.bulk",
            defaultMessage: "Requisition (Excel)",
         },
         ccMail: {
            id: "app.inventory.stockrequisition.ccMail",
            defaultMessage: "CC Mail",
         },
         sendToSelf: {
            id: "app.inventory.stockrequisition.sendToSelf",
            defaultMessage: "Send Notification To Self",
         },
      },
      stockTransfer: {
         title: {
            id: "app.inventory.stockTransfer.title",
            defaultMessage: "Stock Transfer",
         },
         description: {
            id: "app.inventory.stockTransfer.description",
            defaultMessage: "Stock Transfer transaction history of last 7 days.",
         },
         transferStocks: {
            id: "app.inventory.stockTransfer.transferStocks",
            defaultMessage: "Stock Transfer",
         },
         single: {
            id: "app.inventory.stockTransfer.single",
            defaultMessage: "Stock Transfer (Single Destination)",
         },
         multiple: {
            id: "app.inventory.stockTransfer.multiple",
            defaultMessage: "Stock Transfer (Multiple Destination)",
         },
         sourceName: {
            id: "app.inventory.stockTransfer.sourceName",
            defaultMessage: "Source Name",
         },
         destinationName: {
            id: "app.inventory.stockTransfer.destinationName",
            defaultMessage: "Destination Name",
         },
         eWayBill: {
            id: "app.inventory.stockTransfer.eWayBill",
            defaultMessage: "E-Way Bill Information",
         },
         customerName: {
            id: "app.inventory.stockTransfer.customerName",
            defaultMessage: "Customer Name",
         },
         customerGst: {
            id: "app.inventory.stockTransfer.customerGst",
            defaultMessage: "Customer GST Number",
         },
         customerAddress: {
            id: "app.inventory.stockTransfer.customerAddress",
            defaultMessage: "Customer Address",
         },
         ewayBillPdf: {
            id: "app.inventory.stockTransfer.ewayBillPdf",
            defaultMessage: "Eway Bill Pdf Url",
         },
         customerState: {
            id: "app.inventory.stockTransfer.customerState",
            defaultMessage: "Customer State",
         },
         sellerName: {
            id: "app.inventory.stockTransfer.sellerName",
            defaultMessage: "Seller Name",
         },
         sellerGst: {
            id: "app.inventory.stockTransfer.sellerGst",
            defaultMessage: "Seller GST Number",
         },
         sellerAddress: {
            id: "app.inventory.stockTransfer.sellerAddress",
            defaultMessage: "Seller Address",
         },
         sellerState: {
            id: "app.inventory.stockTransfer.sellerState",
            defaultMessage: "Seller State",
         },
         docNum: {
            id: "app.inventory.stockTransfer.docNum",
            defaultMessage: "Document Number",
         },
         docType: {
            id: "app.inventory.stockTransfer.docType",
            defaultMessage: "Document Type",
         },
         transportDate: {
            id: "app.inventory.stockTransfer.transportDate",
            defaultMessage: "Transporter Document Date",
         },
         transporterName: {
            id: "app.inventory.stockTransfer.transporterName",
            defaultMessage: "Transporter Name",
         },
         transporterMode: {
            id: "app.inventory.stockTransfer.transporterMode",
            defaultMessage: "Transport Mode",
         },
         transporterDocNumber: {
            id: "app.inventory.stockTransfer.transporterDocNumber",
            defaultMessage: "Transporter Document Number",
         },
         transporterDocNumberShort: {
            id: "app.inventory.stockTransfer.transporterDocNumberShort",
            defaultMessage: "Transporter Doc Number",
         },
         transporterGst: {
            id: "app.inventory.stockTransfer.transporterGst",
            defaultMessage: "Transporter GST Number",
         },
         vehicleNumber: {
            id: "app.inventory.stockTransfer.vehicleNumber",
            defaultMessage: "Vehicle Number",
         },
         distance: {
            id: "app.inventory.stockTransfer.distance",
            defaultMessage: "Distance",
         },
         supplyType: {
            id: "app.inventory.stockTransfer.supplyType",
            defaultMessage: "Supply Type",
         },
         subSupplyType: {
            id: "app.inventory.stockTransfer.subSupplyType",
            defaultMessage: "SubSupply Type",
         },
         subSupplyDesc: {
            id: "app.inventory.stockTransfer.subSupplyDesc",
            defaultMessage: "SubSupply Description",
         },
      },
      stockValidation: {
         title: {
            id: "app.inventory.stockValidation.title",
            defaultMessage: "Stock Validation",
         },
         description: {
            id: "app.inventory.stockValidation.description",
            defaultMessage: "Stock Validation transaction history of last 7 days.",
         },
         audit: {
            id: "app.inventory.stockValidation.audit",
            defaultMessage: "Audit",
         },
         bulk: {
            id: "app.inventory.stockValidation.bulk",
            defaultMessage: "Audit In Bulk (Excel)",
         },
         sourceName: {
            id: "app.inventory.stockValidation.sourceName",
            defaultMessage: "Source Name",
         },
         enterRemarks: {
            id: "app.inventory.stockValidation.enterRemarks",
            defaultMessage: "Please enter the remarks for discrepancy",
         },
         remarksRecorded: {
            id: "app.inventory.stockValidation.remarksRecorded",
            defaultMessage: " Remarks Successfully recorded",
         },
         remarksRecordErr: {
            id: "app.inventory.stockValidation.remarksRecordErr",
            defaultMessage: "Error while adding remarks",
         },
         descripancyTitle: {
            id: "app.inventory.stockValidation.descripancyTitle",
            defaultMessage: "Reason For Discrepancy",
         },
         descripancyDesc: {
            id: "app.inventory.stockValidation.descripancyDesc",
            defaultMessage:
               "The physical inventory level entered by you in the last step does not match with the inventory level recorded by the system. Please enter the reason for the same.",
         },
      },
      batches: {
         title: {
            id: "app.inventory.batches.title",
            defaultMessage: "Batches",
         },
         description: {
            id: "app.inventory.batches.description",
            defaultMessage: "List of all batches",
         },
         batchName: {
            id: "app.inventory.batches.batchName",
            defaultMessage: "Batch Name",
         },
         barcode: {
            id: "app.inventory.batches.barcode",
            defaultMessage: "Barcode",
         },
         transactionDate: {
            id: "app.inventory.batches.transactionDate",
            defaultMessage: "Date",
         },
         transactionTime: {
            id: "app.inventory.batches.transactionTime",
            defaultMessage: "Time",
         },
         viewProducts: {
            id: "app.inventory.batches.viewProducts",
            defaultMessage: "View Products",
         },
         totalItemNumber: {
            id: "app.inventory.batches.totalItemNumber",
            defaultMessage: "Items",
         },
         editBatch: {
            id: "app.inventory.batches.editBatch",
            defaultMessage: "Edit Batch",
         },
         uploadDesc: {
            id: "app.inventory.batches.uploadDesc",
            defaultMessage:
               "Upload the filled template. A preview screen will show the content of uploaded file for verification. Verify the data andpress Upload.",
         },
         productsInBatch: {
            id: "app.inventory.batches.productsInBatch",
            defaultMessage: "Products in Batch",
         },
         modeSelection: {
            id: "app.inventory.batches.modeSelection",
            defaultMessage: "Select the mode of edit.",
         },
         form: {
            id: "app.inventory.batches.form",
            defaultMessage: "Edit (Form)",
         },
         excel: {
            id: "app.inventory.batches.excel",
            defaultMessage: "Edit (Excel)",
         },
         enterBatchNameErr: {
            id: "app.inventory.batches.enterBatchNameErr",
            defaultMessage: "Please Enter the Name of Batch",
         },
         step1: {
            id: "app.inventory.batches.step1",
            defaultMessage: " Step 1: Fill Details",
         },
      },
      allItems: {
         description: {
            id: "app.inventory.allItems.description",
            defaultMessage: "Select desired products & proceed.",
         },
         generatePurchase: {
            id: "app.inventory.allItems.generatePurchase",
            defaultMessage: "Generate Purchase Return",
         },
         batchInformation: {
            id: "app.inventory.allItems.batchInformation",
            defaultMessage: "Batch Information",
         },
         batch: {
            id: "app.inventory.allItems.batch",
            defaultMessage: "Batch",
         },
         addBatchs: {
            id: "app.inventory.allItems.addBatchs",
            defaultMessage: "Add More Batches",
         },
         stockOutPoErr: {
            id: "app.inventory.allItems.stockOutPoErr",
            defaultMessage: "Transaction Quantity should not be greater than Quantity Fullfilled.",
         },
         poReturnErr: {
            id: "app.inventory.allItems.poReturnErr",
            defaultMessage: "Transaction Quantity should not be greater than Remaining Quantity.",
         },
         fillRequiredFields: {
            id: "app.inventory.allItems.fillRequiredFields",
            defaultMessage: "Please fill the all required fields.",
         },
         batchDelete: {
            id: "app.inventory.allItems.batchDelete",
            defaultMessage: "Batch deleted successfully.",
         },
         oneBatchErr: {
            id: "app.inventory.allItems.oneBatchErr",
            defaultMessage: "User should have atleast one batch.",
         },
         productNotFound: {
            id: "app.inventory.allItems.productNotFound",
            defaultMessage: "Product Not Found...",
         },
         stockQtyNegativeErr: {
            id: "app.inventory.allItems.stockQtyNegativeErr",
            defaultMessage: "Transaction Quantity should not be negative.",
         },
         transactionQtyErr: {
            id: "app.inventory.allItems.transactionQtyErr",
            defaultMessage: "Transaction Quantity should not be greater than Quantity Fullfilled",
         },
         transactionQtyPurchaseQtyErr: {
            id: "app.inventory.allItems.transactionQtyPurchaseQtyErr",
            defaultMessage: "Transaction Quantity should not be greater than Remaining Quantity",
         },
         Total_Quantity_of_batches_should_not_be_graterthan_Purchase_Quantity: {
            id: "app.inventory.allItems.Total_Quantity_of_batches_should_not_be_graterthan_Purchase_Quantity",
            defaultMessage: "Total Quantity of batches should not be graterthan Remaining Quantity",
         },
         Total_Quantity_of_batches_should_not_be_graterthan_quantity_orderd: {
            id: "app.inventory.allItems.Total_Quantity_of_batches_should_not_be_graterthan_quantity_orderd",
            defaultMessage: "Total Quantity of batches should not be graterthan quantity orderd",
         },
         Total_sum_of_Stock_Qunatity_Return_Qunatity_should_not_be_graterthan_the_Purchase_Quantity:
         {
            id: "app.inventory.allItems.Total_sum_of_Stock_Qunatity_Return_Qunatity_should_not_be_graterthan_the_Purchase_Quantity",
            defaultMessage:
               "Total sum of Transaction Quantity + Return Quantity should not be graterthan the Remaining Quantity.",
         },
      },
      newCart: {
         documentRequired: {
            id: "app.inventory.newCart.documentRequired",
            defaultMessage: "Document Number is required.",
         },
         documentTypeRequired: {
            id: "app.inventory.newCart.documentTypeRequired",
            defaultMessage: "Document Type is required.",
         },
         transportDateRequired: {
            id: "app.inventory.newCart.transportDateRequired",
            defaultMessage: "Please Select The Transporter Document Date.",
         },
         supplyType: {
            id: "app.inventory.newCart.supplyType",
            defaultMessage: "Supply Type is required.",
         },
         subSupplyType: {
            id: "app.inventory.newCart.subSupplyType",
            defaultMessage: "SubSupply Type is required.",
         },
         stockTransferSuccess: {
            id: "app.inventory.newCart.stockTransferSuccess",
            defaultMessage: "Stock Transferred Successfully",
         },
         stockTransferFailed: {
            id: "app.inventory.newCart.stockTransferFailed",
            defaultMessage: "Stock Transfer Failed.",
         },
         discrepancyFound: {
            id: "app.inventory.newCart.discrepancyFound",
            defaultMessage: "Stock Successfully Updated.  discrepancy found!",
         },
         discrepancyNotFound: {
            id: "app.inventory.newCart.discrepancyNotFound",
            defaultMessage: "Stock Successfully Updated. No discrepancy found!",
         },
         stockRemoved: {
            id: "app.inventory.newCart.stockRemoved",
            defaultMessage: "Stock Removed Successfully.",
         },
         stockOutErrMsg: {
            id: "app.inventory.newCart.stockOutErrMsg",
            defaultMessage: "Unable To Perform Stock Out.",
         },
         provideEmail: {
            id: "app.inventory.newCart.provideEmail",
            defaultMessage: "Please provide an e-mail.",
         },
         provideValidEmail: {
            id: "app.inventory.newCart.provideValidEmail",
            defaultMessage: "Please provide a valid e-mail.",
         },
         requisitionCreated: {
            id: "app.inventory.newCart.requisitionCreated",
            defaultMessage: "Stock Requisition Created Successfully",
         },
         requisitionFailed: {
            id: "app.inventory.newCart.requisitionFailed",
            defaultMessage: "Unable To Create Stock Requisition",
         },
         description: {
            id: "app.inventory.newCart.description",
            defaultMessage: "Your Cart is ready, please review & proceed.",
         },
         purchaseReturnInfo: {
            id: "app.inventory.newCart.purchaseReturnInfo",
            defaultMessage: "Purchase Return Information",
         },
         purchaseReturnCost: {
            id: "app.inventory.newCart.purchaseReturnCost",
            defaultMessage: "Total Purchase Return Cost",
         },
         purchaseReturnItems: {
            id: "app.inventory.newCart.purchaseReturnItems",
            defaultMessage: "Total Purchase Return Items",
         },
      },
      excelPreview: {
         stockUpdated: {
            id: "app.inventory.excelPreview.stockUpdated",
            defaultMessage: "Stock Successfully Updated.",
         },
         errorOnRemarksSaving: {
            id: "app.inventory.excelPreview.errorOnRemarksSaving",
            defaultMessage: "Error while saving remarks",
         },
         stockAdded: {
            id: "app.inventory.excelPreview.stockAdded",
            defaultMessage: "Stock Added Successfully",
         },
         batchRemoved: {
            id: "app.inventory.excelPreview.batchRemoved",
            defaultMessage: "Batch Successfully Removed",
         },
         prodsUploaded: {
            id: "app.inventory.excelPreview.prodsUploaded",
            defaultMessage: "Products Successfully Uploaded",
         },
         batchTransfered: {
            id: "app.inventory.excelPreview.batchTransfered",
            defaultMessage: "Batch Transferred Successfully",
         },
         userShouldHaveOneBatch: {
            id: "app.inventory.excelPreview.userShouldHaveOneBatch",
            defaultMessage: "Atleast user should have one product for transaction",
         },
         storeWareDetails: {
            id: "app.inventory.storeWareDetails.storeWareDetails",
            defaultMessage: "Store/Warehouse Details",
         },
         description: {
            id: "app.inventory.excelPreview.description",
            defaultMessage: "data is ready, please review & proceed.",
         },
      },
      stockApproval: {
         approved: {
            id: "app.inventory.stockApproval.approved",
            defaultMessage: "APPROVE",
         },
         title: {
            id: "app.inventory.stockApproval.title",
            defaultMessage: "Pending Stock Transactions",
         },
         description: {
            id: "app.inventory.stockApproval.description",
            defaultMessage: "List Of Pending Stock Transactions",
         },
         approvalStatus: {
            id: "app.inventory.stockApproval.approvalStatus",
            defaultMessage: "Approval Status",
         },
         transactionQuantity: {
            id: "app.inventory.stockApproval.transactionQuantity",
            defaultMessage: "Transaction Quantity",
         },
         itemPrice: {
            id: "app.inventory.stockApproval.itemPrice",
            defaultMessage: "Item Price",
         },
         totalItemValue: {
            id: "app.inventory.stockApproval.totalItemValue",
            defaultMessage: "Total Item Value",
         },
         approvedQuantity: {
            id: "app.inventory.stockApproval.approvedQuantity",
            defaultMessage: "Approved Quantity",
         },
         approvedQuantityErr: {
            id: "app.inventory.stockApproval.approvedQuantityErr",
            defaultMessage: "Approved Quantity can't be more than Transaction Quantity",
         },
         approvedQuantityErr2: {
            id: "app.inventory.stockApproval.approvedQuantityErr2",
            defaultMessage: "Approved Quantity can't be less than 0",
         },
         pleaseSelectMorePrducts: {
            id: "app.inventory.stockApproval.pleaseSelectMorePrducts",
            defaultMessage: "Please Select some product!",
         },
         excelcantbeempty: {
            id: "app.inventory.stockApproval.excelcantbeempty",
            defaultMessage: "Excel Sheets can't be empty.",
         },
         title2: {
            id: "app.inventory.stockApproval.title2",
            defaultMessage: "Transaction Details",
         },
         description2: {
            id: "app.inventory.stockApproval.description2",
            defaultMessage: "Transaction Details for selected Transaction",
         },
         productsList: {
            id: "app.inventory.stockApproval.productsList",
            defaultMessage: "Products List",
         },
         productsListDesc: {
            id: "app.inventory.stockApproval.productsListDesc",
            defaultMessage: "List Of Products On Selected Transaction",
         },
         title3: {
            id: "app.inventory.stockApproval.title3",
            defaultMessage: "Stock Approval Preview",
         },
         description3: {
            id: "app.inventory.stockApproval.description3",
            defaultMessage: "Preview of stock approval.",
         },
         totalApprovedQty: {
            id: "app.inventory.stockApproval.totalApprovedQty",
            defaultMessage: "Total Approved Quantity",
         },
         totalApprovedCost: {
            id: "app.inventory.stockApproval.totalApprovedCost",
            defaultMessage: "Total Approved Cost",
         },
         totalRejectedQty: {
            id: "app.inventory.stockApproval.totalRejectedQty",
            defaultMessage: "Total Rejected Quantity",
         },
         totalRejectedCost: {
            id: "app.inventory.stockApproval.totalRejectedCost",
            defaultMessage: "Total Rejected Cost",
         },
         approve: {
            id: "app.inventory.stockApproval.approve",
            defaultMessage: "APPROVED",
         },
         partiallyApproved: {
            id: "app.inventory.stockApproval.partiallyApproved",
            defaultMessage: "PARTIALLY-APPROVED",
         },
         rejected: {
            id: "app.inventory.stockApproval.rejected",
            defaultMessage: "REJECTED",
         },
         youHaveSomeRemainingStockHeader: {
            id: "app.inventory.stockApproval.youHaveSomeRemainingStockHeader",
            defaultMessage: "You have some Remaining stock",
         },
         returnRemainingToSource: {
            id: "app.inventory.stockApproval.returnRemainingToSource",
            defaultMessage: "Return Remaining to the Source",
         },
         returnRemainingToSourceAndMarkWaste: {
            id: "app.inventory.stockApproval.returnRemainingToSourceAndMarkWaste",
            defaultMessage: " Return and Create Stock Wastage To the Source",
         },

      },
      dashBoard: {
         totalLocations: {
            id: "inventory.dashBoard.totalLocations",
            defaultMessage: "Total Locations",
         },
         totalStocks: {
            id: "inventory.dashBoard.totalStocks",
            defaultMessage: "Total Stocks",
         },
         TotalStockValue: {
            id: "inventory.dashBoard.TotalStockValue",
            defaultMessage: "Total Stock Value",
         },
         PurchaseOrder: {
            id: "inventory.dashBoard.PurchaseOrder",
            defaultMessage: "Purchase Order",
         },
         stockRequests: {
            id: "inventory.dashBoard.stockRequests",
            defaultMessage: "Stock Requests",
         },
         totalVendors: {
            id: "inventory.dashBoard.totalVendors",
            defaultMessage: "Total Vendors",
         },
         unpaidVendor: {
            id: "inventory.dashBoard.unpaidVendor",
            defaultMessage: "Unpaid Vendor Invoices",
         },
         totalDueAmount: {
            id: "inventory.dashBoard.totalDueAmount",
            defaultMessage: "Total Due Amount",
         },
         title: {
            id: "inventory.dashBoard.title",
            defaultMessage: "Inventory Insights",
         },
         description: {
            id: "inventory.dashBoard.description",
            defaultMessage: "Here's the overview of your Inventory",
         },
         expiryStock: {
            id: "inventory.dashBoard.expiryStock",
            defaultMessage: "Expiring Stock",
         },
         viewReport: {
            id: "inventory.dashBoard.viewReport",
            defaultMessage: "View Report",
         },
         recentTransaction: {
            id: "inventory.dashBoard.recentTransaction",
            defaultMessage: "Recent Transaction",
         },
      },
   },
   reports: {
      inventory: {
         listOfPo: {
            title: {
               id: "app.reports.inventory.listOfPo.title",
               defaultMessage: "List of Purchase Orders",
            },
            desc: {
               id: "app.reports.inventory.listOfPo.desc",
               defaultMessage:
                  "List of all purchase orders for a set time range, stores, category and brand are mentioned here",
            },
         },
         prodInPo: {
            title: {
               id: "app.reports.inventory.prodInPo.title",
               defaultMessage: "Product In Purchase Orders",
            },
            desc: {
               id: "app.reports.inventory.prodInPo.desc",
               defaultMessage:
                  "Details of all the products available for the product in order for a set time range, stores, category and brand are mentioned here.",
            },
         },
         poDetails: {
            title: {
               id: "app.reports.inventory.poDetails.title",
               defaultMessage: "Purchase Order Detail",
            },
            desc: {
               id: "app.reports.inventory.poDetails.desc",
               defaultMessage:
                  "Purchase Order Detail information including the status of the payment, details and summary .",
            },
            basicInfoDesc: {
               id: "app.reports.inventory.poDetails.basicInfoDesc",
               defaultMessage:
                  "Enter Purchase ID or Invoice Number to generate Purchase Order Details.",
            },
            poSummary: {
               id: "app.reports.inventory.poDetails.poSummary",
               defaultMessage: "Purchase Order Summary",
            },
            vendorDetails: {
               id: "app.reports.inventory.poDetails.vendorDetails",
               defaultMessage: "Vendor Details",
            },
            paymentDetails: {
               id: "app.reports.inventory.poDetails.paymentDetails",
               defaultMessage: "Payment Details",
            },
            productDetails: {
               id: "app.reports.inventory.poDetails.productDetails",
               defaultMessage: "Product Details",
            },
            poList: {
               id: "app.reports.inventory.poDetails.poList",
               defaultMessage: "Purchase Order List",
            },
            summaryCol: {
               discountRupee: {
                  id: "app.reports.inventory.poDetails.summaryCol.discountRupee",
                  defaultMessage: "(-)Discounts (₹)",
               },
               taxRupee: {
                  id: "app.reports.inventory.poDetails.summaryCol.taxRupee",
                  defaultMessage: "(+)Taxes (₹)",
               },
               roundingRupee: {
                  id: "app.reports.inventory.poDetails.summaryCol.roundingRupee",
                  defaultMessage: "(+)Rounding (₹)",
               },
            },
         },
         pi: {
            title: {
               id: "app.reports.inventory.pi.title",
               defaultMessage: "List of Proforma Invoices",
            },
            desc: {
               id: "app.reports.inventory.pi.desc",
               defaultMessage:
                  "Get a List of all the invoives generated for selected stores and timeframe",
            },
            cols: {
               chargesProdLevel: {
                  id: "app.reports.inventory.pi.cols.chargesProdLevel",
                  defaultMessage: "(+) Charges Product Level (₹)",
               },
               chargesOrderLevel: {
                  id: "app.reports.inventory.pi.cols.chargesOrderLevel",
                  defaultMessage: "(+) Charges Order Level (₹)",
               },
            },
         },
         piDetails: {
            title: {
               id: "app.reports.inventory.piDetails.title",
               defaultMessage: "Proforma Invoice Details",
            },
            desc: {
               id: "app.reports.inventory.piDetails.desc",
               defaultMessage:
                  "You can find a detailed breakdown of a particular Proforma invoice here",
            },
            basicInfoDesc: {
               id: "app.reports.inventory.piDetails.desc",
               defaultMessage:
                  "Enter Proforma Invoice ID or Invoice Number to generate Proforma Invoice Order Details.",
            },
            fullfillmentOrderID: {
               id: "app.reports.inventory.piDetails.fullfillmentOrderID",
               defaultMessage: "Fulfillment Order ID",
            },
         },
         prodInPi: {
            title: {
               id: "app.reports.inventory.prodInPi.title",
               defaultMessage: "Product in PI",
            },
            desc: {
               id: "app.reports.inventory.prodInPi.desc",
               defaultMessage:
                  "Here you can find details of all the products for Proforma invoice for a particular set time range, store, category, and brand.",
            },
            PISubID: {
               id: "app.reports.inventory.prodInPi.PISubID",
               defaultMessage: "PI Sub ID",
            },
         },
         pr: {
            title: {
               id: "app.reports.inventory.pr.title",
               defaultMessage: "Purchase Returns",
            },
            desc: {
               id: "app.reports.inventory.pr.desc",
               defaultMessage: "List of Purchase Returns",
            },
            cols: {
               returnDate: {
                  id: "app.reports.inventory.pr.cols.returnDate",
                  defaultMessage: "Return Date",
               },
               returnTime: {
                  id: "app.reports.inventory.pr.cols.returnTime",
                  defaultMessage: "Return Time",
               },
               totalProductLevelAdditionalCharges: {
                  id: "app.reports.inventory.pr.cols.totalProductLevelAdditionalCharges",
                  defaultMessage: "Total Product Level Additional Charges",
               },
            },
         },
         prodInpr: {
            title: {
               id: "app.reports.inventory.prodInpr.title",
               defaultMessage: "Product In Purchase Returns",
            },
            desc: {
               id: "app.reports.inventory.prodInpr.desc",
               defaultMessage: "List of Products in Purchase Returns",
            },
            prSubID: {
               id: "app.reports.inventory.prodInpr.prSubID",
               defaultMessage: "PR Sub ID",
            },
         },
         prDetails: {
            title: {
               id: "app.reports.inventory.prDetails.title",
               defaultMessage: "Purchase Return Detail",
            },
            desc: {
               id: "app.reports.inventory.prDetails.desc",
               defaultMessage:
                  "Purchase Return Detail information including the status of the payment, details and summary",
            },
            basicInfoDesc: {
               id: "app.reports.inventory.prDetails.basicInfoDesc",
               defaultMessage: "Enter Purchase Return ID to generate Purchase Return Details.",
            },
            prSummary: {
               id: "app.reports.inventory.prDetails.prSummary",
               defaultMessage: "Purchase Return Summary",
            },
            vendorDetails: {
               id: "app.reports.inventory.prDetails.vendorDetails",
               defaultMessage: "Vendor Details",
            },
            returnSource: {
               id: "app.reports.inventory.prDetails.returnSource",
               defaultMessage: "Return Source",
            },
            prSubID: {
               id: "app.reports.inventory.prDetails.prSubID",
               defaultMessage: "PR Sub ID",
            },
         },
         stockLevel: {
            title: {
               id: "app.reports.inventory.stockLevel.title",
               defaultMessage: "Stock Level",
            },
            description: {
               id: "app.reports.inventory.stockLevel.description",
               defaultMessage:
                  "Product SKU wise stock level segregated as Available, Restricted, Damaged, and Expired for selected location.",
            },
         },
         storeWiseStockLevel: {
            title: {
               id: "app.reports.inventory.storeWiseStockLevel.title",
               defaultMessage: "Store Wise Stock Level",
            },
            description: {
               id: "app.reports.inventory.storeWiseStockLevel.description",
               defaultMessage: "Store Wise Stock Level detailed report.",
            },
         },
         productGroupStockLevel: {
            title: {
               id: "app.reports.inventory.productGroupStockLevel.title",
               defaultMessage: "Product Group Stock Level",
            },
            description: {
               id: "app.reports.inventory.productGroupStockLevel.description",
               defaultMessage: "Product Group Stock Level report.",
            },
         },
         stockOperations: {
            description: {
               id: "app.reports.inventory.stockOperations.description",
               defaultMessage:
                  "List of all stock operations i.e. Stock In, Out, Transfer, Validation, and Sales for selected location and timeframe.",
            },
            irnTitle: {
               id: "app.reports.inventory.stockOperations.irnTitle",
               defaultMessage:
                  "IRN Details",
            },
            eWayBillTitle: {
               id: "app.reports.inventory.stockOperations.eWayBillTitle",
               defaultMessage:
                  "E-Way Bill Details",
            },
            eWayBillIRNTitle: {
               id: "app.reports.inventory.stockOperations.eWayBillIRNTitle",
               defaultMessage:
                  "Generate E-Way Bill / IRN",
            },
            eWayBillIRNDescription: {
               id: "app.reports.inventory.stockOperations.eWayBillIRNDescription",
               defaultMessage:
                  "Fill below details to generate E-Way Bill / IRN",
            }
         },
         stockOperationDetails: {
            title: {
               id: "app.reports.inventory.stockOperationDetails.title",
               defaultMessage: "Stock Operations Detail",
            },
            description: {
               id: "app.reports.inventory.stockOperationDetails.description",
               defaultMessage: "Details of Stock Operations.",
            },
         },
         stockMovement: {
            title: {
               id: "app.reports.inventory.stockMovement.title",
               defaultMessage: "Stock Movement",
            },
            description: {
               id: "app.reports.inventory.stockMovement.description",
               defaultMessage:
                  "Cumulative stock transaction of a product for selected location and timeframe segregated under Stock In, Out, Transfer, Validation, and Sales.",
            },
         },
         stockMovementDetails: {
            title: {
               id: "app.reports.inventory.stockMovementDetails.title",
               defaultMessage: "Stock Movement Details",
            },
            description: {
               id: "app.reports.inventory.stockMovementDetails.description",
               defaultMessage:
                  "Report for stock transaction of a product in detail for selected location and timeframe segregated under Stock In, Out, Transfer, Validation, and Sales.",
            },
         },
         stockRequisitionHistory: {
            title: {
               id: "app.reports.inventory.stockRequisitionHistory.title",
               defaultMessage: "Stock Requisition History",
            },
            description: {
               id: "app.reports.inventory.stockRequisitionHistory.description",
               defaultMessage: "Stock Requisition History",
            },
         },
         unfullfilledStockReq: {
            title: {
               id: "app.reports.inventory.unfullfilledStockReq.title",
               defaultMessage: "Unfulfilled Stock Requests",
            },
            description: {
               id: "app.reports.inventory.unfullfilledStockReq.description",
               defaultMessage: "Unfulfilled Stock Requests",
            },
         },
         unfullfilledStockTransfer: {
            title: {
               id: "app.reports.inventory.unfullfilledStockTransfer.title",
               defaultMessage: "Unfulfilled Stock Transfers",
            },
            desc: {
               id: "app.reports.inventory.unfullfilledStockTransfer.desc",
               defaultMessage: "List of unfulfilled Stock Transfers",
            },
         },
         productAgeingReport: {
            title: {
               id: "app.reports.inventory.productAgeingReport.title",
               defaultMessage: "Product Ageing Report",
            },
            description: {
               id: "app.reports.inventory.productAgeingReport.description",
               defaultMessage: "Product Ageing Report",
            },
         },
         profitMargin: {
            title: {
               id: "app.reports.inventory.profitMargin.title",
               defaultMessage: "Profit Margin",
            },
            description: {
               id: "app.reports.inventory.profitMargin.description",
               defaultMessage:
                  "Details of all the products available for the product in order for a set time range, stores, category and brand are mentioned here",
            },
            marginRange: {
               id: "app.reports.inventory.profitMargin.marginRange",
               defaultMessage: "Margin Range(%)",
            }
         },
         vendorTaxInput: {
            title: {
               id: "app.reports.inventory.vendorTaxInput.title",
               defaultMessage: "Vendor Tax Input",
            },
            description: {
               id: "app.reports.inventory.vendorTaxInput.description",
               defaultMessage:
                  "Details of all the Reports available for the product in order for a set time range, stores, category and brand are mentioned here",
            },
         },
         lowStockProducts: {
            lowStockAlertQuantity: {
               id: "app.reports.inventory.lowStockProducts.lowStockAlertQuantity",
               defaultMessage: "Low Stock Alert Level",
            },
            safeStockLevel: {
               id: "app.reports.inventory.lowStockProducts.safeStockLevel",
               defaultMessage: "Safe Stock Level",
            },
            createStockRequest: {
               id: "app.reports.inventory.lowStockProducts.createStockRequest",
               defaultMessage: "Create Stock Request",
            },
            title: {
               id: "app.reports.inventory.lowStockProducts.title",
               defaultMessage: "Low Stock Products",
            },
            description: {
               id: "app.reports.inventory.lowStockProducts.description",
               defaultMessage: "List of low stock products in stores",
            },
            orderQuantityLevel: {
               id: "app.reports.inventory.lowStockProducts.orderQuantityLevel",
               defaultMessage: "Order Quantity Level",
            },
            createStockRequestDesc: {
               id: "app.reports.inventory.lowStockProducts.createStockRequestDesc",
               defaultMessage: "Create Stock Request",
            },
            previewTitle: {
               id: "app.reports.inventory.lowStockProducts.previewTitle",
               defaultMessage: "Preview Stock Request Products",
            },
            previewDescription: {
               id: "app.reports.inventory.lowStockProducts.previewDescription",
               defaultMessage: "Preview Stock Request Products",
            },
         },
         vendorPurchaseSummary: {
            title: {
               id: "app.reports.inventory.vendorPurchaseSummary.title",
               defaultMessage: "Vendor Purchase Summary",
            },
            description: {
               id: "app.reports.inventory.vendorPurchaseSummary.description",
               defaultMessage: "Detail report of Vendor Purchase Summary. ",
            },
         },
         vendorProductPurchaseSummary: {
            title: {
               id: "app.reports.inventory.vendorProductPurchaseSummary.title",
               defaultMessage: "Vendor Product Purchase Summary",
            },
            description: {
               id: "app.reports.inventory.vendorProductPurchaseSummary.description",
               defaultMessage: "Detail report of Vendor Product Purchase Summary.",
            },
         },
         stockLedgerSummary: {
            title: {
               id: "app.reports.inventory.stockLedgerSummary.title",
               defaultMessage: "Stock Ledger Summary",
            },
            description: {
               id: "app.reports.inventory.stockLedgerSummary.description",
               defaultMessage: "Detail report of stock ledger summary.",
            },
            inventorySourceID: {
               id: "app.reports.inventory.stockLedgerSummary.inventorySourceID",
               defaultMessage: "Inventory Source ID",
            },
            inventorySourceType: {
               id: "app.reports.inventory.stockLedgerSummary.inventorySourceType",
               defaultMessage: "Inventory Source Type",
            },
            transactionPartyID: {
               id: "app.reports.inventory.stockLedgerSummary.transactionPartyID",
               defaultMessage: "Transaction Party ID",
            },
            transactionPartyName: {
               id: "app.reports.inventory.stockLedgerSummary.transactionPartyName",
               defaultMessage: "Transaction Party Name",
            },
            transactionType: {
               id: "app.reports.inventory.stockLedgerSummary.transactionType",
               defaultMessage: "Transaction Type",
            },
            transactionPartyType: {
               id: "app.reports.inventory.stockLedgerSummary.transactionPartyType",
               defaultMessage: "Transaction Party Type",
            },
            list: {
               id: "app.reports.inventory.stockLedgerSummary.list",
               defaultMessage: "Transaction Party Type",
            },
            transactionSubType: {
               id: "app.reports.inventory.stockLedgerSummary.transactionSubType",
               defaultMessage: "Transaction Sub Type",
            },
            transactionSubID: {
               id: "app.reports.inventory.stockLedgerSummary.transactionSubID",
               defaultMessage: "Transaction Sub ID",
            },
            transRef1: {
               id: "app.reports.inventory.stockLedgerSummary.transRef1",
               defaultMessage: "Trans Ref1",
            },
            transRef2: {
               id: "app.reports.inventory.stockLedgerSummary.transRef2",
               defaultMessage: "Trans Ref2",
            },
            auditID: {
               id: "app.reports.inventory.stockLedgerSummary.auditID",
               defaultMessage: "Audit ID",
            },
            approvedTotalTransactionValue: {
               id: "app.reports.inventory.stockLedgerSummary.approvedTotalTransactionValue",
               defaultMessage: "Total Approved Value",
            },
            totalTransactionValue: {
               id: "app.reports.inventory.stockLedgerSummary.totalTransactionValue",
               defaultMessage: "Total Transaction Value",
            },
            transactionUser: {
               id: "app.reports.inventory.stockLedgerSummary.transactionUser",
               defaultMessage: "Transaction User",
            },
            approvalUser: {
               id: "app.reports.inventory.stockLedgerSummary.approvalUser",
               defaultMessage: "Approval User",
            },
            approvalTime: {
               id: "app.reports.inventory.stockLedgerSummary.approvalTime",
               defaultMessage: "Approval Time",
            },
            inventorySyncStatus: {
               id: "app.reports.inventory.stockLedgerSummary.inventorySyncStatus",
               defaultMessage: "Inventory Sync Status",
            },
            inventorySyncTime: {
               id: "app.reports.inventory.stockLedgerSummary.inventorySyncTime",
               defaultMessage: "Inventory Sync Time",
            },
            logTimeLocal: {
               id: "app.reports.inventory.stockLedgerSummary.logTimeLocal",
               defaultMessage: "Log Time",
            },
            logDate: {
               id: "app.reports.inventory.stockLedgerSummary.logDate",
               defaultMessage: "Log Date",
            },
         },
         stockLedgerDetail: {
            title: {
               id: "app.reports.inventory.stockLedgerDetail.title",
               defaultMessage: "Stock Ledger Detail",
            },
            description: {
               id: "app.reports.inventory.stockLedgerDetail.description",
               defaultMessage: "Detail report of stock ledger Details. ",
            },
            ledgerID: {
               id: "app.reports.inventory.stockLedgerDetail.ledgerID",
               defaultMessage: "Ledger ID",
            },
            ledgerDetails: {
               id: "app.reports.inventory.stockLedgerDetail.ledgerDetails",
               defaultMessage: "Stock Ledger Details",
            },
            ledgersList: {
               id: "app.reports.inventory.stockLedgerDetail.ledgersList",
               defaultMessage: "Stock Ledgers List",
            },
         },
         stockMomentDetailsViaLedger:{
            title: {
               id: "app.reports.inventory.stockMomentDetailsViaLedger.title",
               defaultMessage: "Stock Movement Details Via Ledger",
            },
            description: {
               id: "app.reports.inventory.stockMomentDetailsViaLedger.description",
               defaultMessage: "Stock Movement Details Via Ledger in detail",
            },
            approvedTotalItemValue: {
               id: "app.reports.inventory.stockMomentDetailsViaLedger.approvedTotalItemValue",
               defaultMessage: "Approved Total Item Value",
            },
            approvalStatus: {
               id: "app.reports.inventory.stockMomentDetailsViaLedger.approvalStatus",
               defaultMessage: "Approval Status",
            },
            inventorySyncTime: {
               id: "app.reports.inventory.stockMomentDetailsViaLedger.inventorySyncTime",
               defaultMessage: "Inventory Sync Time",
            },
            transactionItemRemarks: {
               id: "app.reports.inventory.stockMomentDetailsViaLedger.transactionItemRemarks",
               defaultMessage: "Transaction Item Remarks",
            },
            approvalRemarks: {
               id: "app.reports.inventory.stockMomentDetailsViaLedger.approvalRemarks",
               defaultMessage: "Approval Remarks",
            },
            
         },
         stockReqDetail: {
            title: {
               id: "app.reports.inventory.stockReqDetail.title",
               defaultMessage: "Stock Requisiton Detail",
            },
            description: {
               id: "app.reports.inventory.stockReqDetail.description",
               defaultMessage: "Stock Requisition information including details and summary .",
            },
         },
         auditSummary: {
            title: {
               id: "app.reports.inventory.auditSummary.title",
               defaultMessage: "Stock Audit Summary",
            },
            description: {
               id: "app.reports.inventory.auditSummary.description",
               defaultMessage: "List of all stock audits.",
            },
         },
         auditDetail: {
            title: {
               id: "app.reports.inventory.auditDetail.title",
               defaultMessage: "Stock Audit Detail",
            },
            description: {
               id: "app.reports.inventory.auditDetail.description",
               defaultMessage: "Detailed information of Stock Audit.",
            },
         },
         stockTransactionDetail: {
            title: {
               id: "app.reports.inventory.stockTransactionDetail.title",
               defaultMessage: "Stock Transaction Detail",
            },
            description: {
               id: "app.reports.inventory.stockTransactionDetail.description",
               defaultMessage: "Stock Transaction information including details and summary.",
            },
         },
         ewayBill: {
            title: {
               id: "app.reports.inventory.ewayBill.title",
               defaultMessage: "E-Way Bill",
            },
            description: {
               id: "app.reports.inventory.ewayBill.description",
               defaultMessage: "Detail report for e-way bills.",
            },
            ewayBillNo: {
               id: "app.reports.inventory.ewayBill.ewayBillNo",
               defaultMessage: "EWay Bill Number",
            },
         },
      },
      sales: {
         dailySales: {
            title: {
               id: "app.reports.sales.dailySales.title",
               defaultMessage: "Daily Sales",
            },
            description: {
               id: "app.reports.sales.dailySales.description",
               defaultMessage: "Daywise cumulative revenue for selected stores and timeframe. ",
            },
         },
         listOfOrders: {
            title: {
               id: "app.reports.sales.listOfOrders.title",
               defaultMessage: "Store Wise Sales",
            },
            description: {
               id: "app.reports.sales.listOfOrders.description",
               defaultMessage: "Storewise cumulative revenue for selected timeframe.",
            },
            PINumber: {
               id: "app.reports.sales.listOfOrders.PINumber",
               defaultMessage: "PI Number",
            },
         },
         storeWiseSales: {
            title: {
               id: "app.reports.sales.storeWiseSales.title",
               defaultMessage: "Store Wise Sales",
            },
            description: {
               id: "app.reports.sales.storeWiseSales.description",
               defaultMessage: "Storewise cumulative revenue for selected timeframe.",
            },
         },
         entityWiseSales: {
            title: {
               id: "app.reports.sales.entityWiseSales.title",
               defaultMessage: "Entity Wise Sales",
            },
            description: {
               id: "app.reports.sales.entityWiseSales.description",
               defaultMessage: "Entity wise  sales cumulative revenue for selected timeframe.",
            },
         },
         locationWiseSales: {
            title: {
               id: "app.reports.sales.locationWiseSales.title",
               defaultMessage: "Location Wise Sales",
            },
            description: {
               id: "app.reports.sales.locationWiseSales.description",
               defaultMessage: "Location wise sales cumulative revenue for selected timeframe.",
            },
         },
         storeWiseProductSales: {
            title: {
               id: "app.reports.sales.storeWiseProductSales.title",
               defaultMessage: "Store Wise Product Sales",
            },
            description: {
               id: "app.reports.sales.storeWiseProductSales.description",
               defaultMessage:
                  "It displays all the revenue generated for a selected timeframe of a product in a particular store",
            },
            avgCostSale: {
               id: "app.reports.sales.storeWiseProductSales.avgCostSale",
               defaultMessage: "Cost of goods sold",
            },
            avgSellingPrice: {
               id: "app.reports.sales.storeWiseProductSales.avgSellingPrice",
               defaultMessage: "Avg. Selling Price",
            },
         },
         regionWiseSales: {
            title: {
               id: "app.reports.sales.regionWiseSales.title",
               defaultMessage: "Region Wise Sales",
            },
            description: {
               id: "app.reports.sales.regionWiseSales.description",
               defaultMessage: "Regionwise cumulative revenue for selected timeframe.",
            },
         },
         productWiseSales: {
            title: {
               id: "app.reports.sales.productWiseSales.title",
               defaultMessage: "Product Wise Sales",
            },
            description: {
               id: "app.reports.sales.productWiseSales.description",
               defaultMessage:
                  "Category wise cumulative revenue for selected stores and timeframe.",
            },
         },

         categoryWiseSales: {
            title: {
               id: "app.reports.sales.categoryWiseSales.title",
               defaultMessage: "Category Wise Sales",
            },
            description: {
               id: "app.reports.sales.categoryWiseSales.description",
               defaultMessage: "Details report of all the category items list . ",
            },
         },

         subCategoryWiseSales: {
            title: {
               id: "app.reports.sales.subCategoryWiseSales.title",
               defaultMessage: "Sub Category Wise Sales",
            },
            description: {
               id: "app.reports.sales.subCategoryWiseSales.description",
               defaultMessage: "Details report of all the sub category items list . ",
            },
         },

         brandWiseSales: {
            title: {
               id: "app.reports.sales.brandWiseSales.title",
               defaultMessage: "Brand Wise Sales",
            },
            description: {
               id: "app.reports.sales.brandWiseSales.description",
               defaultMessage: "Brandwise cumulative revenue for selected stores and timeframe.",
            },
         },

         batchWiseSales: {
            title: {
               id: "app.reports.sales.batchWiseSales.title",
               defaultMessage: "Batch Wise Sales",
            },
            description: {
               id: "app.reports.sales.batchWiseSales.description",
               defaultMessage: "Batch wise cumulative sales for selected stores and timeframe.",
            },
         },

         incomeHeadWiseSales: {
            title: {
               id: "app.reports.sales.incomeHeadWiseSales.title",
               defaultMessage: "Income Head Wise Sales",
            },
            description: {
               id: "app.reports.sales.incomeHeadWiseSales.description",
               defaultMessage:
                  "It displays all the revenue generated for a selected store and the timeframe for a particular income head wise sales",
            },
            incomeHeadName: {
               id: "app.reports.sales.incomeHeadWiseSales.incomeHeadName",
               defaultMessage: "Income Head Name",
            },
         },

         deviceWiseSales: {
            title: {
               id: "app.reports.sales.deviceWiseSales.title",
               defaultMessage: "Device Wise Sales",
            },
            description: {
               id: "app.reports.sales.deviceWiseSales.description",
               defaultMessage:
                  "POS devicewise cumulative revenue for selected stores and timeframe.",
            },
         },

         customerWiseSales: {
            title: {
               id: "app.reports.sales.customerWiseSales.title",
               defaultMessage: "Customer Wise Sales",
            },
            description: {
               id: "app.reports.sales.customerWiseSales.description",
               defaultMessage: "Customerwise cumulative revenue for selected stores and timeframe.",
            },
         },

         employeeWiseSales: {
            title: {
               id: "app.reports.sales.employeeWiseSales.title",
               defaultMessage: "Employee Wise Sales",
            },
            description: {
               id: "app.reports.sales.employeeWiseSales.description",
               defaultMessage:
                  "Employee Wise cumulative revenue for selected stores and timeframe.",
            },
         },

         employeeWiseProductSales: {
            title: {
               id: "app.reports.sales.employeeWiseProductSales.title",
               defaultMessage: "Employee Wise Product Sales",
            },
            description: {
               id: "app.reports.sales.employeeWiseProductSales.description",
               defaultMessage:
                  "Detail reports for Employee Wise Product Sales for a selected timeframe and store",
            },
            apiErr: {
               id: "app.reports.sales.employeeWiseProductSales.apiErr",
               defaultMessage: "Unable to get the data, Please contact support team.",
            },
         },

         storeHourlySales: {
            title: {
               id: "app.reports.sales.storeHourlySales.title",
               defaultMessage: "Store Hour Wise Sales",
            },
            description: {
               id: "app.reports.sales.storeHourlySales.description",
               defaultMessage: "Detail report of all hourly sales at the store.",
            },
         },

         dailyPaymentBreakUp: {
            title: {
               id: "app.reports.sales.dailyPaymentBreakUp.title",
               defaultMessage: "Daily Payment Breakup",
            },
            description: {
               id: "app.reports.sales.dailyPaymentBreakUp.description",
               defaultMessage:
                  "Daywise breakup of payments received in the form of cash, card, wallets, credit sales etc. for selected stores and timeframe.",
            },
         },

         orderWisePaymentBreakup: {
            title: {
               id: "app.reports.sales.orderWisePaymentBreakup.title",
               defaultMessage: "Order Wise Payment Breakup",
            },
            description: {
               id: "app.reports.sales.orderWisePaymentBreakup.description",
               defaultMessage:
                  " Orderwise breakup of payments received in the form of cash, card, wallets, credit sales etc. for selected stores and timeframe.",
            },
         },

         orderWiseTaxBreakup: {
            title: {
               id: "app.reports.sales.orderWiseTaxBreakup.title",
               defaultMessage: "Order Wise Tax Breakup",
            },
            description: {
               id: "app.reports.sales.orderWiseTaxBreakup.description",
               defaultMessage:
                  "Orderwise breakup of taxes collected for selected stores and timeframe.",
            },
         },

         productWiseTaxBreakup: {
            title: {
               id: "app.reports.sales.productWiseTaxBreakup.title",
               defaultMessage: "Product Wise Tax Breakup",
            },
            description: {
               id: "app.reports.sales.productWiseTaxBreakup.description",
               defaultMessage:
                  "Productwise breakup of taxes collected for selected stores and timeframe.",
            },
         },

         hsnTaxBreakup: {
            title: {
               id: "app.reports.sales.hsnTaxBreakup.title",
               defaultMessage: "HSN/SAC Wise Tax Breakup Report",
            },
            description: {
               id: "app.reports.sales.hsnTaxBreakup.description",
               defaultMessage: "Detail breakdown of all Product’s HSN/SAC code.",
            },
            HSNOrSACCode: {
               id: "app.reports.sales.hsnTaxBreakup.HSNOrSACCode",
               defaultMessage: "HSN / SAC Code",
            },
         },

         orderPaymentHistory: {
            title: {
               id: "app.reports.sales.orderPaymentHistory.title",
               defaultMessage: "Order Payment History Report",
            },
            description: {
               id: "app.reports.sales.orderPaymentHistory.description",
               defaultMessage: "Details history of all order payment with its breakup.",
            },
         },

         salesTransactionTracker: {
            title: {
               id: "app.reports.sales.salesTransactionTracker.title",
               defaultMessage: "Sale Transaction Tracker",
            },
            description: {
               id: "app.reports.sales.salesTransactionTracker.description",
               defaultMessage:
                  "An overview of a specific sale with detail breakdown by date range and store can be found here",
            },
         },

         productTransactionTracker: {
            title: {
               id: "app.reports.sales.productTransactionTracker.title",
               defaultMessage: "Product Transaction Tracker",
            },
            description: {
               id: "app.reports.sales.productTransactionTracker.description",
               defaultMessage:
                  "Product sales tracker with detail breakdown of a particular sale based on date range & store name.",
            },
         },

         salesTraget: {
            title: {
               id: "app.reports.sales.salesTraget.title",
               defaultMessage: "Sales Target Configuration",
            },
            description: {
               id: "app.reports.sales.salesTraget.description",
               defaultMessage: "Description of all sales target configuration",
            },
         },
         bundleSales: {
            title: {
               id: "app.reports.sales.bundleSales.title",
               defaultMessage: "Bundle Wise Sales",
            },
            description: {
               id: "app.reports.sales.bundleSales.description",
               defaultMessage: "Bundlewise cumulative revenue for selected stores and timeframe.",
            },
         },
         netProfit: {
            title: {
               id: "app.reports.sales.netProfit.title",
               defaultMessage: "Net Profit",
            },
            description: {
               id: "app.reports.sales.netProfit.description",
               defaultMessage: "A Detail report for net profit.",
            },
            salesTaxes: {
               id: "app.reports.sales.netProfit.salesTaxes",
               defaultMessage: "Sales Taxes",
            },
            salesCharges: {
               id: "app.reports.sales.netProfit.salesCharges",
               defaultMessage: "Sales Charges",
            },
            purchases: {
               id: "app.reports.sales.netProfit.purchases",
               defaultMessage: "Purchases",
            },
            purchaseTaxes: {
               id: "app.reports.sales.netProfit.purchaseTaxes",
               defaultMessage: "Purchases Taxes",
            },
            purchaseCharges: {
               id: "app.reports.sales.netProfit.purchaseCharges",
               defaultMessage: "Purchases Charges",
            },
            netProfit: {
               id: "app.reports.sales.netProfit.netProfit",
               defaultMessage: "Net Profit",
            },
            salesDiscounts:{
               id: "app.reports.sales.netProfit.salesDiscounts",
               defaultMessage: "Sales Discounts",
            },
            purchaseDiscounts:{
               id: "app.reports.sales.netProfit.purchaseDiscounts",
               defaultMessage: "Purchase Discounts",
            },

            refundSales:{
               id: "app.reports.sales.netProfit.refundSales",
               defaultMessage: "Refund Sales",
            },
            refundTaxes:{
               id: "app.reports.sales.netProfit.refundTaxes",
               defaultMessage: "Refund Taxes",
            },
            refundCharges:{
               id: "app.reports.sales.netProfit.refundCharges",
               defaultMessage: "Refund Charges",
            },
            refundDiscounts:{
               id: "app.reports.sales.netProfit.refundDiscounts",
               defaultMessage: "Refund Discount",
            },
            netTaxes:{
               id: "app.reports.sales.netProfit.netTaxes",
               defaultMessage: "Net Taxes",
            },

            expenses: {
               id: "app.reports.sales.netProfit.expenses",
               defaultMessage: "Expenses",
            }
         }
      },
      promotions: {
         productDiscountReport: {
            title: {
               id: "app.reports.promotions.productDiscountReport.title",
               defaultMessage: "Product Discount Report",
            },
            desc: {
               id: "app.reports.promotions.productDiscountReport.desc",
               defaultMessage: "Description",
            },
         },
         discountExpenses: {
            title: {
               id: "app.reports.promotions.discountExpenses.title",
               defaultMessage: "Discount Expenses",
            },
            desc: {
               id: "app.reports.promotions.discountExpenses.desc",
               defaultMessage: "Discount Expenses Report",
            },
            startDate: {
               id: "app.reports.promotions.discountExpenses.startDate",
               defaultMessage: "Start Date",
            },
            endDate: {
               id: "app.reports.promotions.discountExpenses.endDate",
               defaultMessage: "End Date",
            },
            discountExpense: {
               id: "app.reports.promotions.discountExpenses.discountExpense",
               defaultMessage: "Discount Expense",
            },
         },
         discountedProds: {
            title: {
               id: "app.reports.promotions.discountedProds.title",
               defaultMessage: "Discounted Products",
            },
            desc: {
               id: "app.reports.promotions.discountedProds.desc",
               defaultMessage: "Discounted Products Report",
            },
         },
         orderDiscountReport: {
            title: {
               id: "app.reports.promotions.orderDiscountReport.title",
               defaultMessage: "Order Discount Report",
            },
            desc: {
               id: "app.reports.promotions.orderDiscountReport.desc",
               defaultMessage: "List of all discounts order wise.",
            },
         },
         couponRedemption: {
            title: {
               id: "app.reports.promotions.couponRedemption.title",
               defaultMessage: "Coupons Redemption",
            },
            desc: {
               id: "app.reports.promotions.couponRedemption.desc",
               defaultMessage: "Detailed Report of Coupon Redemption",
            },
         },
         giftVoucherTracker: {
            title: {
               id: "app.reports.promotions.giftVoucherTracker.title",
               defaultMessage: "Gift Voucher Tracker",
            },
            desc: {
               id: "app.reports.promotions.giftVoucherTracker.desc",
               defaultMessage: "Detailed Report of Gift Vouchers",
            },
         },
      },
      orders: {
         listOfOrders: {
            desc: {
               id: "app.reports.orders.listOfOrders.desc",
               defaultMessage: "List of all invoices generated for selected stores and timeframe. ",
            },
            gstOrderNumber: {
               id: "app.reports.orders.listOfOrders.gstOrderNumber",
               defaultMessage: "GST Order Number",
            },
            orderMode: {
               id: "app.reports.orders.listOfOrders.orderMode",
               defaultMessage: "Order Mode",
            },
            taxesPL: {
               id: "app.reports.orders.listOfOrders.taxesPL",
               defaultMessage: "Taxes (Product Level)",
            },
            taxesOl: {
               id: "app.reports.orders.listOfOrders.taxesOl",
               defaultMessage: "Taxes (Order Level)",
            },
            paidAmount: {
               id: "app.reports.orders.listOfOrders.paidAmount",
               defaultMessage: "Paid Amount",
            },
            unpaidAmount: {
               id: "app.reports.orders.listOfOrders.unpaidAmount",
               defaultMessage: "Unpaid Amount",
            },
            voucherAmount: {
               id: "app.reports.orders.listOfOrders.voucherAmount",
               defaultMessage: "Voucher Amount",
            },
            customerEmail: {
               id: "app.reports.orders.listOfOrders.customerEmail",
               defaultMessage: "Customer Email",
            },
            customerPAN: {
               id: "app.reports.orders.listOfOrders.customerPAN",
               defaultMessage: "Customer PAN",
            },
            loyaltyPointsEarned: {
               id: "app.reports.orders.listOfOrders.loyaltyPointsEarned",
               defaultMessage: "Loyalty Points Earned",
            },
            loyaltyPointsRedeemed: {
               id: "app.reports.orders.listOfOrders.loyaltyPointsRedeemed",
               defaultMessage: "Loyalty Points Redeemed",
            },
            loyaltyPointsValue: {
               id: "app.reports.orders.listOfOrders.loyaltyPointsValue",
               defaultMessage: "Loyalty Points Value",
            },
            receiptPrintCount: {
               id: "app.reports.orders.listOfOrders.receiptPrintCount",
               defaultMessage: "Receipt Print Count",
            },
            quickBill: {
               id: "app.reports.orders.listOfOrders.quickBill",
               defaultMessage: "Quick Bill",
            },
            viewInvoice: {
               id: "app.reports.orders.listOfOrders.viewInvoice",
               defaultMessage: "View Invoice",
            },
            duplicateInvoice: {
               id: "app.reports.orders.listOfOrders.duplicateInvoice",
               defaultMessage: "Duplicate Invoice",
            },
         },
         orderDetails: {
            desc: {
               id: "app.reports.orders.orderDetails.desc",
               defaultMessage:
                  "Order information including the status of the payment, inventory, and delivery.",
            },
            basicInfodesc: {
               id: "app.reports.orders.orderDetails.basicInfodesc",
               defaultMessage:
                  "Enter Order ID or Invoice Number and Select Store to generate Order Details.",
            },
            generate: {
               id: "app.reports.orders.orderDetails.generate",
               defaultMessage: "Generate",
            },
            dateOrTime: {
               id: "app.reports.orders.orderDetails.dateOrTime",
               defaultMessage: "Date | Time",
            },
            orderStatus: {
               id: "app.reports.orders.orderDetails.orderStatus",
               defaultMessage: "Order Status",
            },
            deliveryStatus: {
               id: "app.reports.orders.orderDetails.deliveryStatus",
               defaultMessage: "Delivery Status",
            },
            KOTNumber: {
               id: "app.reports.orders.orderDetails.KOTNumber",
               defaultMessage: "KOT Number",
            },
            voidDevice: {
               id: "app.reports.orders.orderDetails.voidDevice",
               defaultMessage: "Void Device",
            },
             printTimeLocal: {
               id: "app.reports.orders.orderDetails.printTimeLocal",
               defaultMessage: "Print Time Local",
            },
            voidTimeLocal: {
               id: "app.reports.orders.orderDetails.voidTimeLocal",
               defaultMessage: "Void Time Local",
            },
            taxNet: {
               id: "app.reports.orders.orderDetails.taxNet",
               defaultMessage: "Tax Net",
            },
            taxValues: {
               id: "app.reports.orders.orderDetails.taxValues",
               defaultMessage: "Tax Values",
            },
            chargeNet: {
               id: "app.reports.orders.orderDetails.chargeNet",
               defaultMessage: "Charge Net",
            },
            chargeValues: {
               id: "app.reports.orders.orderDetails.chargeValues",
               defaultMessage: "Charge Values",
            },
            modifierValue: {
               id: "app.reports.orders.orderDetails.modifierValue",
               defaultMessage: "Modifier Value",
            },
            paymentSubID: {
               id: "app.reports.orders.orderDetails.paymentSubID",
               defaultMessage: "Payment Sub ID",
            },
            desc: {
               id: "app.reports.orders.orderDetails.desc",
               defaultMessage:
                  "Order information including the status of the payment, inventory, and delivery.",
            },
            refundOrderDetail :{
               id: "app.reports.orders.orderDetails.refundOrderDetail",
               defaultMessage: "Refund Order Detail",
            },
            refundID:{
               id: "app.reports.orders.orderDetails.refundID",
               defaultMessage: "Refund ID",
            },
            refundDate:{
               id: "app.reports.orders.orderDetails.refundDate",
               defaultMessage: "Refund Date",
            },
            refundAmount:{
               id: "app.reports.orders.orderDetails.refundAmount",
               defaultMessage: "Refund Amount",
            }
         },
         dailyPaymentCount: {
            desc: {
               id: "app.reports.orders.dailyPaymentCount.desc",
               defaultMessage:
                  "Day wise total number of invoices generated for selected stores and timeframe.",
            },
            totalOrderCount: {
               id: "app.reports.orders.dailyPaymentCount.totalOrderCount",
               defaultMessage: "Total Order Count",
            },
            regularOrders: {
               id: "app.reports.orders.dailyPaymentCount.regularOrders",
               defaultMessage: "Regular Orders",
            },
            noChargeOrders: {
               id: "app.reports.orders.dailyPaymentCount.noChargeOrders",
               defaultMessage: "No Charge Orders",
            },
            noChargeVoidCount: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoidCount",
               defaultMessage: "No Charge Void Count",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
            discountRemarks: {
               id: "app.reports.orders.dailyPaymentCount.discountRemarks",
               defaultMessage: "Discount Remarks",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
            noChargeVoid: {
               id: "app.reports.orders.dailyPaymentCount.noChargeVoid",
               defaultMessage: "No Charge Void",
            },
         },
         productInOrder: {
            desc: {
               id: "app.reports.orders.productInOrder.desc",
               defaultMessage:
                  "Details of all the products available for the product in order for a set time range, stores, category and brand are mentioned here ",
            },
            quantityOrdered: {
               id: "app.reports.orders.productInOrder.quantityOrdered",
               defaultMessage: "Quantity Ordered",
            },
            billingUser: {
               id: "app.reports.orders.productInOrder.billingUser",
               defaultMessage: "Billing User",
            },
            paymentMode: {
               id: "app.reports.orders.productInOrder.paymentMode",
               defaultMessage: "Payment Mode",
            },
            SNumber: {
               id: "app.reports.orders.productInOrder.SNumber",
               defaultMessage: "SNumber",
            },
            listOfVoidOrdersDesc: {
               id: "app.reports.orders.productInOrder.listOfVoidOrdersDesc",
               defaultMessage:
                  "It displays all the revenue generated for a selected store and timeframe in a particular day ",
            },
            orderComboReportDesc: {
               id: "app.reports.orders.productInOrder.orderComboReportDesc",
               defaultMessage: "Detail report of all the order combos available",
            },
            itemQuantity: {
               id: "app.reports.orders.productInOrder.itemQuantity",
               defaultMessage: "Item Quantity ",
            },
            refundedOrderID: {
               id: "app.reports.orders.productInOrder.refundedOrderID",
               defaultMessage: "Refunded Order ID",
            },
            kotVoidDetails: {
               id: "app.reports.orders.productInOrder.kotVoidDetails",
               defaultMessage: "Kot Void Details",
            },
            kotDetails: {
               id: "app.reports.orders.productInOrder.kotDetails",
               defaultMessage: "Kot Details",
            },
            kotDesc: {
               id: "app.reports.orders.productInOrder.kotDesc",
               defaultMessage: "Detail report of Kot Void Order List. ",
            },
            deviceSerialNo: {
               id: "app.reports.orders.productInOrder.deviceSerialNo",
               defaultMessage: "Device Serial No",
            },
            productLogs: {
               id: "app.reports.orders.productInOrder.productLogs",
               defaultMessage: "Product Logs",
            },
            productLogsDesc: {
               id: "app.reports.orders.productInOrder.productLogsDesc",
               defaultMessage: "Details reports available for the all Product Logs",
            },
         },
      },
      onlineOrder: {
         listOfOnlineOrders: {
            listOfOnlineOrders: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.listOfOnlineOrders",
               defaultMessage: "List of Online Orders",
            },
            listOfOnlineOrdersDesc: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.listOfOnlineOrdersDesc",
               defaultMessage:
                  "Details of Online Orders wise stock level segregated as Available, Restricted, Damaged, and Expired for selected location. ",
            },
            proformaInvoiceID: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.proformaInvoiceID",
               defaultMessage: "Proforma Invoice ID",
            },
            externalOrderID: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.externalOrderID",
               defaultMessage: "External Order ID",
            },
            aggregatorOrderID: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.aggregatorOrderID",
               defaultMessage: "Aggregator Order ID",
            },
            creationTime: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.creationTime",
               defaultMessage: "Creation time",
            },
            chargesProductLevel: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.chargesProductLevel",
               defaultMessage: "(+) Charges Product Level",
            },
            platform: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.platform",
               defaultMessage: "Platform",
            },
            subID: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.subID",
               defaultMessage: "Sub ID",
            },
            productCount: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.productCount",
               defaultMessage: "Product Count",
            },
            logTimeLocal: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.logTimeLocal",
               defaultMessage: "Log Time Local",
            },
            proformaInvoiceList: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.proformaInvoiceList",
               defaultMessage: "Proforma Invoice List",
            },
            onlineOrderDetails: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.onlineOrderDetails",
               defaultMessage: "Online Order Details",
            },
            onlineOrderDetailsDesc: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.onlineOrderDetailsDesc",
               defaultMessage: "Detail breakdown of a particular Online Order.",
            },
            basicInfoDesc: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.basicInfoDesc",
               defaultMessage:
                  "Enter Purchase ID or Invoice Number to generate Purchase Order Details.",
            },
            performaInvoiceID: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.performaInvoiceID",
               defaultMessage: "Proforma Invoice ID",
            },
            deliveryTracker: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.deliveryTracker",
               defaultMessage: " Delivery Tracker",
            },
            pISubID: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.pISubID",
               defaultMessage: "PI Sub ID",
            },
            deliveryUser: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.deliveryUser",
               defaultMessage: "Delivery User",
            },
            deliveryDueDate: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.deliveryDueDate",
               defaultMessage: "Delivery due date",
            },
            productInOnlineOrders: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.productInOnlineOrders",
               defaultMessage: "Product in Online Orders",
            },
            productInOnlineOrdersDesc: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.productInOnlineOrdersDesc",
               defaultMessage:
                  "Details of all Products available for Online Orders for a set time range, stores, category & brand.",
            },
            eanCodes: {
               id: "app.reports.onlineOrder.listOfOnlineOrders.eanCodes",
               defaultMessage: "EAN Codes"
            }
         },
      },
      parking:{
         vehicleTracker:{
            title: {
               id: "app.reports.parking.vehicleTracker.title",
               defaultMessage: "Vehicle Tracker",
            },
            desc: {
               id: "app.reports.parking.vehicleTracker.desc",
               defaultMessage: "Description of vehicle tracker is to be mentioned here!",
            },
            viewSlots:{
               id: "app.reports.parking.vehicleTracker.viewSlots",
               defaultMessage: "View Slots",
            },
            priceType:{
               id: "app.reports.parking.vehicleTracker.priceType",
               defaultMessage: "Price Type",
            },
            slots:{
               id: "app.reports.parking.vehicleTracker.slots",
               defaultMessage: "Slots",
            }
         }
      },
      customers:{
         loyalityPoints:{
            title:{
               id: "app.reports.customers.loyalityPoints.title",
               title: "Loyality Points"
            },
            desc:{
               id: "app.reports.customers.loyalityPoints.desc",
               title: "Desc Text for Loyality Points!"
            },
         }
      },
      store:{
         loyalityPoints:{
            title:{
               id: "app.reports.store.loyalityPoints.title",
               title: "Loyality Points"
            },
            desc:{
               id: "app.reports.store.loyalityPoints.desc",
               title: "Desc Text for Loyality Points!"
            },
         }
      },
      yearlyReport:{
         title: {
            id: "app.reports.yearlyReport.title",
            defaultMessage: "Title for financial Report",
         },
         desc: {
            id: "app.reports.yearlyReport.desc",
            defaultMessage: "Description of financial Report!",
         },
         reportName: {
            id: "app.reports.yearlyReport.reportName",
            defaultMessage: "Report Name",
         },
         downloadFile: {
            id: "app.reports.yearlyReport.downloadFile",
            defaultMessage: "Download File",
         },
         completionTime: {
            id: "app.reports.yearlyReport.completionTime",
            defaultMessage: "Completion Time",
         },
         expiry: {
            id: "app.reports.yearlyReport.expiry",
            defaultMessage: "Expiry",
         }
         
      },
      systemChangeLogs: {
         title: {
            id: "app.reports.systemChangeLogs.title",
            defaultMessage: "System Change Logs",
         },
         desc: {
            id: "app.reports.systemChangeLogs.desc",
            defaultMessage: "All system change logs report!",
         },
      },
      thirdpartylogs: {
         title: {
            id: "app.reports.thirdpartylogs.title",
            defaultMessage: "Third Party Api Logs",
         },
         desc: {
            id: "app.reports.thirdpartylogs.desc",
            defaultMessage: "Detail report of all third party api logs",
         },
      },
      challan: {
         challanID:{
            id: "app.reports.challan.challanID",
            defaultMessage: 'Fine/Challan ID', 
         },
         challanSubID:{
            id: "app.reports.challan.challanSubID",
            defaultMessage: 'Fine/Challan Sub ID', 
         },
         challanValue:{
            id: "app.reports.challan.challanValue",
            defaultMessage: 'Fine/Challan Value', 
         },
         chargeCount:{
            id: "app.reports.challan.chargeCount",
            defaultMessage: 'Charge Count',
         },
         totalChargeValue:{
            id: "app.reports.challan.totalChargeValue",
            defaultMessage: 'Total Charge Value',
         },
         list: {
            title: {
               id: "app.reports.challan.list.title",
               defaultMessage: 'All Fine/Challans',
            },
            desc: {
               id: "app.reports.challan.list.desc",
               defaultMessage: "List of all Fine/Challans for a selected locations and particular timeframe",
            },
            locationID: {
               id: "app.reports.challan.list.locationID",
               defaultMessage: 'Location ID',
            },
            locationName: {
               id: "app.reports.challan.list.locationName",
               defaultMessage: 'Location Name',
            }
         },
         challanDetails: {
            title: {
               id: "app.reports.challan.challanDetails.title",
               defaultMessage: 'Fine/Challan',
            },
            desc: {
               id: "app.reports.challan.challanDetails.desc",
               defaultMessage: "Fine/Challan ID information including the status of the payment, location and challan details",
            },
         },
         cumulative: {
            title: {
               id: "app.reports.challan.cumulative.title",
               defaultMessage: 'Cumulative Collection',
            },
            desc: {
               id: "app.reports.challan.cumulative.desc",
               defaultMessage: "List of all Cumulative Collection for a selected locations and pirticular timeframe",
            },
         },
         apilogs: {
            title: {
               id: "app.reports.challan.apilogs.title",
               defaultMessage: 'Muncipal API Logs',
            },
            desc: {
               id: "app.reports.challan.apilogs.desc",
               defaultMessage: "Details of Municipal API Logs",
            },
         }
      }
   },

   'breadCrumb/reports/thirdpartyapilogs': {
      "id": "breadCrumb/reports/thirdpartyapilogs",
      "defaultMessage": "Third Party API Logs"
   },
     "breadCrumb/developer/webhooks": {
      "id": "breadCrumb/developer/webhooks",
      "defaultMessage": "Developer"
   },
   "breadCrumb/developer/webhooks/1": {
      "id": "breadCrumb/developer/webhooks/1",
      "defaultMessage": "Webhooks"
   },
   "breadCrumb/developer/apitracker": {
      "id": "breadCrumb/developer/apitracker",
      "defaultMessage": "API Tracker Report"
   },
   "breadCrumb/developer/apitracker/apicalllog": {
      "id": "breadCrumb/developer/apitracker/apicalllog",
      "defaultMessage": "API Call Log"
   },
   "breadCrumb/reports/fiscalReport": {
      "id": "breadCrumb/reports/fiscalReport",
      "defaultMessage": "Financial Year"
   },
   "breadCrumb/reports/loyalty/customer": {
      "id": "breadCrumb/reports/loyalty/customer",
      "defaultMessage": "Loyalty"
   },
   "breadCrumb/reports/loyalty/customer/1": {
      "id": "breadCrumb/reports/loyalty/customer/1",
      "defaultMessage": "Customer Loyalty"
   },
   "breadCrumb/reports/loyalty/store": {
      "id": "breadCrumb/reports/loyalty/store",
      "defaultMessage": "Store Loyalty"
   },
   'breadCrumb/inventory/vendor/viewBillDetails/:id': {
      "id": 'breadCrumb/inventory/vendor/viewBillDetails/:id',
      "defaultMessage": "Vendor Bill Details"
   },
   "breadCrumb/reports/UnfullfilledStockTransfers": {
      "id": "breadCrumb/reports/UnfullfilledStockTransfers",
      "defaultMessage": "Unfullfilled Stock Transfer"
   },
   "breadCrumb/reports/fiscalReport": {
      "id": "breadCrumb/reports/fiscalReport",
      "defaultMessage": "Financial Year"
   },

   "breadCrumb/integrations/mloyal/apiLogs": {
      "id": "breadCrumb/integrations/mloyal/apiLogs",
      "defaultMessage": "Api Logs"

   },
   "breadCrumb/integrations/onlineOrder": {
      "id": "breadCrumb/integrations/onlineOrder",
      "defaultMessage": "Online Order"

   },
   "breadCrumb/reports/vehicleTracker": {
      "id": "breadCrumb/reports/vehicleTracker",
      "defaultMessage": "Parking"
   },
   "breadCrumb/reports/vehicleTracker/1": {
      "id": "breadCrumb/reports/vehicleTracker/1",
      "defaultMessage": "Vehicle Tracker"
   },
   "breadCrumb/reports/fiscalReport": {
      "id": "breadCrumb/reports/fiscalReport",
      "defaultMessage": "Financial Year"
   },
   "breadCrumb/integrations/dashboard": { "id": "breadCrumb/integrations/dashboard", "defaultMessage": "Integrations" },
   "breadCrumb/integrations/dashboard/1": { "id": "breadCrumb/integrations/dashboard/1", "defaultMessage": "Integration Dashboard" },

   "breadCrumb/home": { "id": "breadCrumb/home", "defaultMessage": "Home" },
   "breadCrumb/catalogDashboard": {
      "id": "breadCrumb/catalogDashboard",
      "defaultMessage": "Catalog",
      "lastCount": 1
   },
   "breadCrumb/catalogDashboard/1": {
      "id": "breadCrumb/catalogDashboard/1",
      "defaultMessage": "Catalog Dashboard"
   },
   "breadCrumb/master/items": { "id": "breadCrumb/master/items", "defaultMessage": "Products" },
   "breadCrumb/master/items/printbarcode-bulk": {
      "id": "breadCrumb/master/items/printbarcode-bulk",
      "defaultMessage": "Bulk - Print Product Barcode"
   },
   "breadCrumb/master/items/printbarcode/cases": {
      "id": "breadCrumb/master/items/printbarcode/cases",
      "defaultMessage": "Select Label Size",
      "lastCount": 1
   },
   "breadCrumb/master/items/printbarcode": {
      "id": "breadCrumb/master/items/printbarcode",
      "defaultMessage": "Print Product Barcode"
   },
   "breadCrumb/master/items/printbarcode/cases/1": {
      "id": "breadCrumb/master/items/printbarcode/cases/1",
      "defaultMessage": "Select Label Size"
   },
   "breadCrumb/master/items/createProduct": {
      "id": "breadCrumb/master/items/createProduct",
      "defaultMessage": "Create Product"
   },
   "breadCrumb/master/items/editProduct/:id": {
      "id": "breadCrumb/master/items/editProduct/:id",
      "defaultMessage": "Edit Product"
   },
   "breadCrumb/master/bulkcreateproducts": {
      "id": "breadCrumb/master/bulkcreateproducts",
      "defaultMessage": "Create Products"
   },
   "breadCrumb/master/bulkcreateproducts/preview": {
      "id": "breadCrumb/master/bulkcreateproducts/preview",
      "defaultMessage": "Create Products"
   },
   "breadCrumb/master/bulkeditproducts": {
      "id": "breadCrumb/master/bulkeditproducts",
      "defaultMessage": "Edit Products"
   },
   "breadCrumb/master/bulkeditproducts/preview": {
      "id": "breadCrumb/master/bulkeditproducts/preview",
      "defaultMessage": "Edit Products"
   },
   "breadCrumb/master/combos": { "id": "breadCrumb/master/combos", "defaultMessage": "Combos" },
   "breadCrumb/master/combos/createcombo": {
      "id": "breadCrumb/master/combos/createcombo",
      "defaultMessage": "Create Combo"
   },
   "breadCrumb/master/combos/editcombo/:id": {
      "id": "breadCrumb/master/combos/editcombo/:id",
      "defaultMessage": "Edit Combo"
   },
   "breadCrumb/master/productstoremap": {
      "id": "breadCrumb/master/productstoremap",
      "defaultMessage": "Assign products to store"
   },
   "breadCrumb/master/productstoremap/assign": {
      "id": "breadCrumb/master/productstoremap/assign",
      "defaultMessage": "Assign new products"
   },
   "breadCrumb/master/productstoremap/preview": {
      "id": "breadCrumb/master/productstoremap/preview",
      "defaultMessage": "Assign new products"
   },
   "breadCrumb/master/productstoremap/assignbulk": {
      "id": "breadCrumb/master/productstoremap/assignbulk",
      "defaultMessage": "Bulk Operation"
   },
   "breadCrumb/master/productstoremap/bulkpreview": {
      "id": "breadCrumb/master/productstoremap/bulkpreview",
      "defaultMessage": "Bulk Operation"
   },
   "breadCrumb/master/counterProductMap": {
      "id": "breadCrumb/master/counterProductMap",
      "defaultMessage": "Assign Products To Counter"
   },
   "breadCrumb/master/counterstoremap/assign": {
      "id": "breadCrumb/master/counterstoremap/assign",
      "defaultMessage": "Assign new Product"
   },
   "breadCrumb/master/counterstoremap/preview": {
      "id": "breadCrumb/master/counterstoremap/preview",
      "defaultMessage": "Assigned Product Preview"
   },
   "breadCrumb/master/Counterstoremap/assignbulk": {
      "id": "breadCrumb/master/Counterstoremap/assignbulk",
      "defaultMessage": "Bulk Operation"
   },
   "breadCrumb/master/counterstoremap/bulkpreview": {
      "id": "breadCrumb/master/counterstoremap/bulkpreview",
      "defaultMessage": "Bulk Operation"
   },
   "breadCrumb/master/categories": {
      "id": "breadCrumb/master/categories",
      "defaultMessage": "Category"
   },
   "breadCrumb/master/createcategory": {
      "id": "breadCrumb/master/createcategory",
      "defaultMessage": "Create Category"
   },
   "breadCrumb/master/editcategory/:id": {
      "id": "breadCrumb/master/editcategory/:id",
      "defaultMessage": "Edit Category"
   },
   "breadCrumb/master/bulkcreatecategory": {
      "id": "breadCrumb/master/bulkcreatecategory",
      "defaultMessage": "Create Categories"
   },
   "breadCrumb/master/bulkeditcategory": {
      "id": "breadCrumb/master/bulkeditcategory",
      "defaultMessage": "Edit Categories"
   },
   "breadCrumb/master/bulkcreatecategory/preview": {
      "id": "breadCrumb/master/bulkcreatecategory/preview",
      "defaultMessage": "Create Categories"
   },
   "breadCrumb/master/bulkeditcategory/preview": {
      "id": "breadCrumb/master/bulkeditcategory/preview",
      "defaultMessage": "Edit Categories"
   },
   "breadCrumb/master/subcategories": {
      "id": "breadCrumb/master/subcategories",
      "defaultMessage": "Sub Category"
   },
   "breadCrumb/master/createsubcategory": {
      "id": "breadCrumb/master/createsubcategory",
      "defaultMessage": "Create Sub Category"
   },
   "breadCrumb/master/editsubcategory/:id": {
      "id": "breadCrumb/master/editsubcategory/:id",
      "defaultMessage": "Edit Sub Category"
   },
   "breadCrumb/master/bulkcreatesubcategory": {
      "id": "breadCrumb/master/bulkcreatesubcategory",
      "defaultMessage": "Create Sub Categories"
   },
   "breadCrumb/master/bulkeditsubcategory": {
      "id": "breadCrumb/master/bulkeditsubcategory",
      "defaultMessage": "Edit Sub Categories"
   },
   "breadCrumb/master/bulkcreatesubcategory/preview": {
      "id": "breadCrumb/master/bulkcreatesubcategory/preview",
      "defaultMessage": "Create Sub Categories"
   },
   "breadCrumb/master/bulkeditsubcategory/preview": {
      "id": "breadCrumb/master/bulkeditsubcategory/preview",
      "defaultMessage": "Edit Sub Categories"
   },
   "breadCrumb/master/brands": { "id": "breadCrumb/master/brands", "defaultMessage": "Brand" },
   "breadCrumb/master/createbrand": {
      "id": "breadCrumb/master/createbrand",
      "defaultMessage": "Create Brand"
   },
   "breadCrumb/master/editbrand/:id": {
      "id": "breadCrumb/master/editbrand/:id",
      "defaultMessage": "Edit Brand"
   },
   "breadCrumb/master/bulkcreatebrand": {
      "id": "breadCrumb/master/bulkcreatebrand",
      "defaultMessage": "Create Brands"
   },
   "breadCrumb/master/bulkeditbrand": {
      "id": "breadCrumb/master/bulkeditbrand",
      "defaultMessage": "Edit Brands"
   },
   "breadCrumb/master/bulkcreatebrand/preview": {
      "id": "breadCrumb/master/bulkcreatebrand/preview",
      "defaultMessage": "Create Brands"
   },
   "breadCrumb/master/bulkeditbrand/preview": {
      "id": "breadCrumb/master/bulkeditbrand/preview",
      "defaultMessage": "Edit Brands"
   },
   "breadCrumb/master/manufacturers": {
      "id": "breadCrumb/master/manufacturers",
      "defaultMessage": "Manufacturer"
   },
   "breadCrumb/master/createmanufacturer": {
      "id": "breadCrumb/master/createmanufacturer",
      "defaultMessage": "Create Manufacturer"
   },
   "breadCrumb/master/editmanufacturer": {
      "id": "breadCrumb/master/editmanufacturer",
      "defaultMessage": "Edit Manufacturer"
   },
   "breadCrumb/master/bulkmanagemanufacturer": {
      "id": "breadCrumb/master/bulkmanagemanufacturer",
      "defaultMessage": "Create Manufacturer"
   },
   "breadCrumb/master/bulkEditmanagemanufacturer": {
      "id": "breadCrumb/master/bulkEditmanagemanufacturer",
      "defaultMessage": "Edit Manufacturer"
   },
   "breadCrumb/master/bulkeditcreatemanufacturer": {
      "id": "breadCrumb/master/bulkeditcreatemanufacturer",
      "defaultMessage": ""
   },
   "breadCrumb/master/incomehead": {
      "id": "breadCrumb/master/incomehead",
      "defaultMessage": "Income Head"
   },
   "breadCrumb/master/createincomehead": {
      "id": "breadCrumb/master/createincomehead",
      "defaultMessage": "Create Income Head"
   },
   "breadCrumb/master/editincomehead/:id": {
      "id": "breadCrumb/master/editincomehead/:id",
      "defaultMessage": "Edit Income Head"
   },
   "breadCrumb/master/editassignincomehead": {
      "id": "breadCrumb/master/editassignincomehead",
      "defaultMessage": "Assign Income Head"
   },
   "breadCrumb/master/services": {
      "id": "breadCrumb/master/services",
      "defaultMessage": "Services"
   },
   "breadCrumb/master/createservice": {
      "id": "breadCrumb/master/createservice",
      "defaultMessage": "Create Service"
   },
   "breadCrumb/master/services/editservice/:id": {
      "id": "breadCrumb/master/services/editservice/:id",
      "defaultMessage": "Edit Service"
   },
   "breadCrumb/master/bulkcreateservice": {
      "id": "breadCrumb/master/bulkcreateservice",
      "defaultMessage": "Create Service"
   },
   "breadCrumb/master/bulkeditservice": {
      "id": "breadCrumb/master/bulkeditservice",
      "defaultMessage": "Edit Service"
   },
   "breadCrumb/master/bulkcreateservice/preview": {
      "id": "breadCrumb/master/bulkcreateservice/preview",
      "defaultMessage": "Create Service Preview"
   },
   "breadCrumb/master/bulkeditservice/preview": {
      "id": "breadCrumb/master/bulkeditservice/preview",
      "defaultMessage": "Edit Service Preview"
   },
   "breadCrumb/master/servicegroups": {
      "id": "breadCrumb/master/servicegroups",
      "defaultMessage": "Service Group"
   },
   "breadCrumb/master/createservicegroup": {
      "id": "breadCrumb/master/createservicegroup",
      "defaultMessage": "Create Service Group"
   },
   "breadCrumb/master/editservicegroup/:id": {
      "id": "breadCrumb/master/editservicegroup/:id",
      "defaultMessage": "Edit Service Group"
   },
   "breadCrumb/master/createbulkservicegroup": {
      "id": "breadCrumb/master/createbulkservicegroup",
      "defaultMessage": "Create Bulk Service Groups"
   },
   "breadCrumb/master/bulkcreateservicegroup/preview": {
      "id": "breadCrumb/master/bulkcreateservicegroup/preview",
      "defaultMessage": "Create Bulk Service Groups Preview"
   },
   "breadCrumb/master/editbulkservicegroup": {
      "id": "breadCrumb/master/editbulkservicegroup",
      "defaultMessage": "Edit Bulk Service Groups"
   },
   "breadCrumb/master/editbulkservicegroup/preview": {
      "id": "breadCrumb/master/editbulkservicegroup/preview",
      "defaultMessage": "Edit Bulk Service Groups Preview"
   },
   "breadCrumb/master/modifiers": {
      "id": "breadCrumb/master/modifiers",
      "defaultMessage": "Modifiers"
   },
   "breadCrumb/master/createmodifiers": {
      "id": "breadCrumb/master/createmodifiers",
      "defaultMessage": "Create Modifier"
   },
   "breadCrumb/master/updatemodifiers/:id": {
      "id": "breadCrumb/master/updatemodifiers/:id",
      "defaultMessage": "Edit Modifier"
   },
   "breadCrumb/master/modifiersGroup": {
      "id": "breadCrumb/master/modifiersGroup",
      "defaultMessage": "Modifier Groups"
   },
   "breadCrumb/master/createModifiersGroup": {
      "id": "breadCrumb/master/createModifiersGroup",
      "defaultMessage": "Create Modifier Group"
   },
   "breadCrumb/master/assignModifiersGroup": {
      "id": "breadCrumb/master/assignModifiersGroup",
      "defaultMessage": "Assign Modifiers"
   },
   "breadCrumb/master/:id/editModifiersGroup": {
      "id": "breadCrumb/master/:id/editModifiersGroup",
      "defaultMessage": "Edit Modifier Group"
   },
   "breadCrumb/master/taxes": { "id": "breadCrumb/master/taxes", "defaultMessage": "Taxes" },
   "breadCrumb/master/taxes/createTax1": {
      "id": "breadCrumb/master/taxes/createTax1",
      "defaultMessage": "Create Tax"
   },
   "breadCrumb/master/taxes/createTax2": {
      "id": "breadCrumb/master/taxes/createTax2",
      "defaultMessage": "Create Tax"
   },
   "breadCrumb/master/taxes/editTax1/:id": {
      "id": "breadCrumb/master/taxes/editTax1/:id",
      "defaultMessage": "Edit Tax"
   },
   "breadCrumb/master/charges": { "id": "breadCrumb/master/charges", "defaultMessage": "Charges" },
   "breadCrumb/master/charges/create": {
      "id": "breadCrumb/master/charges/create",
      "defaultMessage": "Create Charge"
   },
   "breadCrumb/master/charges/editcharges/:id": {
      "id": "breadCrumb/master/charges/editcharges/:id",
      "defaultMessage": "Edit Charges"
   },
   "breadCrumb/master/promotions": {
      "id": "breadCrumb/master/promotions",
      "defaultMessage": "Promotions"
   },
   "breadCrumb/master/promotions/create": {
      "id": "breadCrumb/master/promotions/create",
      "defaultMessage": "Create Promotion"
   },
   "breadCrumb/master/editpromotions/:id": {
      "id": "breadCrumb/master/editpromotions/:id",
      "defaultMessage": "Edit Promotion"
   },
   "breadCrumb/master/EditCreatePromotionsPreview": {
      "id": "breadCrumb/master/EditCreatePromotionsPreview",
      "defaultMessage": "Edit Promotion Preview"
   },
   "breadCrumb/master/CreatePromotionsPreview": {
      "id": "breadCrumb/master/CreatePromotionsPreview",
      "defaultMessage": "Create Promotion Preview"
   },
   "breadCrumb/master/vouchers": {
      "id": "breadCrumb/master/vouchers",
      "defaultMessage": "Vouchers"
   },
   "breadCrumb/master/bulkcreatevouchers": {
      "id": "breadCrumb/master/bulkcreatevouchers",
      "defaultMessage": "Create Vouchers"
   },
   "breadCrumb/master/bulkcreatevouchers/preview": {
      "id": "breadCrumb/master/bulkcreatevouchers/preview",
      "defaultMessage": "Preview - Create Vouchers"
   },
   "breadCrumb/master/measurementunits": {
      "id": "breadCrumb/master/measurementunits",
      "defaultMessage": "Measurement Unit"
   },
   "breadCrumb/master/createunits": {
      "id": "breadCrumb/master/createunits",
      "defaultMessage": "Create Measurement Unit"
   },
   "breadCrumb/master/editmeasurementunit/:id": {
      "id": "breadCrumb/master/editmeasurementunit/:id",
      "defaultMessage": "Edit Measurement Unit"
   },
   "breadCrumb/master/productgroupsview": {
      "id": "breadCrumb/master/productgroupsview",
      "defaultMessage": "Products Groups"
   },
   "breadCrumb/master/productgroupsview/printbarcode": {
      "id": "breadCrumb/master/productgroupsview/printbarcode",
      "defaultMessage": "Print Product Group Barcode"
   },
   "breadCrumb/master/productgroupsview/printbarcode/cases": {
      "id": "breadCrumb/master/productgroupsview/printbarcode/cases",
      "defaultMessage": "Select Label Size"
   },
   "breadCrumb/master/productgroupsview/printbarcode/bulk": {
      "id": "breadCrumb/master/productgroupsview/printbarcode/bulk",
      "defaultMessage": "Print Product Group Barcode"
   },
   "breadCrumb/master/productgroupsview/printbarcode/cases/bulk": {
      "id": "breadCrumb/master/productgroupsview/printbarcode/cases/bulk",
      "defaultMessage": "Select Label Size"
   },
   "breadCrumb/master/productgroupsview/assignbulk": {
      "id": "breadCrumb/master/productgroupsview/assignbulk",
      "defaultMessage": "Bulk - Product Group"
   },
   "breadCrumb/master/productgroupsview/bulkpreview": {
      "id": "breadCrumb/master/productgroupsview/bulkpreview",
      "defaultMessage": "Bulk Preview - Product Group"
   },
   "breadCrumb/master/productgroupcreate1": {
      "id": "breadCrumb/master/productgroupcreate1",
      "defaultMessage": "Create Product Group"
   },
   "breadCrumb/master/productgroupcreate2": {
      "id": "breadCrumb/master/productgroupcreate2",
      "defaultMessage": "Create Product Group"
   },
   "breadCrumb/master/productgroupedit/:id": {
      "id": "breadCrumb/master/productgroupedit/:id",
      "defaultMessage": "Edit Product Group"
   },
   "breadCrumb/master/createproductgroup-bulk": {
      "id": "breadCrumb/master/createproductgroup-bulk",
      "defaultMessage": "Create Bulk Product Group"
   },
   "breadCrumb/master/createproductgroup/preview-bulk": {
      "id": "breadCrumb/master/createproductgroup/preview-bulk",
      "defaultMessage": "Create Bulk Product Group Preview"
   },
   "breadCrumb/master/memberships": {
      "id": "breadCrumb/master/memberships",
      "defaultMessage": "Memberships"
   },
   "breadCrumb/master/createmembership": {
      "id": "breadCrumb/master/createmembership",
      "defaultMessage": "Create Membership"
   },
   "breadCrumb/master/addproducts": {
      "id": "breadCrumb/master/addproducts",
      "defaultMessage": "Add Products"
   },
   "breadCrumb/master/bulkaddmembership": {
      "id": "breadCrumb/master/bulkaddmembership",
      "defaultMessage": "Add Bulk Products"
   },
   "breadCrumb/master/bulkeditmembership/:id": {
      "id": "breadCrumb/master/bulkeditmembership/:id",
      "defaultMessage": "Add Edit Products"
   },
   "breadCrumb/master/reviewmembership": {
      "id": "breadCrumb/master/reviewmembership",
      "defaultMessage": "Review"
   },
   "breadCrumb/master/editmemberships/:id": {
      "id": "breadCrumb/master/editmemberships/:id",
      "defaultMessage": "Edit Membership"
   },
   "breadCrumb/reservations": { "id": "breadCrumb/reservations", "defaultMessage": "Reservation" },
   "breadCrumb/reservation/create": {
      "id": "breadCrumb/reservation/create",
      "defaultMessage": "Create Reservation"
   },
   "breadCrumb/reservation/:id/edit": {
      "id": "breadCrumb/reservation/:id/edit",
      "defaultMessage": "Edit Reservation"
   },
   "breadCrumb/reservation/slots": {
      "id": "breadCrumb/reservation/slots",
      "defaultMessage": "Reservation Slots"
   },
   "breadCrumb/reservation/bulkupdateslots": {
      "id": "breadCrumb/reservation/bulkupdateslots",
      "defaultMessage": "Bulk Edit Slots"
   },
   "breadCrumb/reservation/bulkupdateslotsPreview": {
      "id": "breadCrumb/reservation/bulkupdateslotsPreview",
      "defaultMessage": "Bulk Edit Slots Preview"
   },
   "breadCrumb/master/productbundle": {
      "id": "breadCrumb/master/productbundle",
      "defaultMessage": "Product Bundle"
   },
   "breadCrumb/master/productbundle/createbundle": {
      "id": "breadCrumb/master/productbundle/createbundle",
      "defaultMessage": "Create Product Bundle"
   },
   "breadCrumb/master/productbundle/updatebundle": {
      "id": "breadCrumb/master/productbundle/updatebundle",
      "defaultMessage": "Update Product Bundle"
   },
   "breadCrumb/master/giftvouchers": {
      "id": "breadCrumb/master/giftvouchers",
      "defaultMessage": "Gift Vouchers"
   },
   "breadCrumb/master/creategiftvoucher": {
      "id": "breadCrumb/master/creategiftvoucher",
      "defaultMessage": "Create Gift Voucher"
   },
   "breadCrumb/master/editgiftvoucher/:id": {
      "id": "breadCrumb/master/editgiftvoucher/:id",
      "defaultMessage": "Update Gift Voucher"
   },
   "breadCrumb/master/priceList": {
      "id": "breadCrumb/master/priceList",
      "defaultMessage": "Price List"
   },
   "breadCrumb/master/createpriceList": {
      "id": "breadCrumb/master/createpriceList",
      "defaultMessage": "Create Price List"
   },
   "breadCrumb/master/updatepriceList/:id": {
      "id": "breadCrumb/master/updatepriceList/:id",
      "defaultMessage": "Update Price List"
   },
   "breadCrumb/inventoryDashboard": {
      "id": "breadCrumb/inventoryDashboard",
      "defaultMessage": "Inventory",
      "lastCount": 1
   },
   "breadCrumb/inventoryDashboard/1": {
      "id": "breadCrumb/inventoryDashboard/1",
      "defaultMessage": "Inventory Dashboard"
   },
   "breadCrumb/inventory/newallitems": {
      "id": "breadCrumb/inventory/newallitems",
      "defaultMessage": "Products List"
   },
   "breadCrumb/inventory/stockin": {
      "id": "breadCrumb/inventory/stockin",
      "defaultMessage": "Stock In"
   },
   "breadCrumb/inventory/stockpreview": {
      "id": "breadCrumb/inventory/stockpreview",
      "defaultMessage": "Stock In Preview",
      "lastCount": 1
   },
   "breadCrumb/inventory/stockinpreview": {
      "id": "breadCrumb/inventory/stockinpreview",
      "defaultMessage": "Stock In Preview"
   },
   "breadCrumb/inventory/newcart": {
      "id": "breadCrumb/inventory/newcart",
      "defaultMessage": "Cart"
   },
   "breadCrumb/inventory/stockout": {
      "id": "breadCrumb/inventory/stockout",
      "defaultMessage": "Stock Out"
   },
   "breadCrumb/inventory/stockoutpreview": {
      "id": "breadCrumb/inventory/stockoutpreview",
      "defaultMessage": "Stock Out Preview"
   },
   "breadCrumb/inventory/newallitems2": {
      "id": "breadCrumb/inventory/newallitems2",
      "defaultMessage": "Products List"
   },
   "breadCrumb/inventory/newcart2": {
      "id": "breadCrumb/inventory/newcart2",
      "defaultMessage": "Cart"
   },
   "breadCrumb/inventory/stockrequisition": {
      "id": "breadCrumb/inventory/stockrequisition",
      "defaultMessage": "Stock Requisition"
   },
   "breadCrumb/inventory/stockrequisitionpreview": {
      "id": "breadCrumb/inventory/stockrequisitionpreview",
      "defaultMessage": "Stock Requisition Preview"
   },
   "breadCrumb/inventory/newallitems1": {
      "id": "breadCrumb/inventory/newallitems1",
      "defaultMessage": "Products List"
   },
   "breadCrumb/inventory/stockin/cart": {
      "id": "breadCrumb/inventory/stockin/cart",
      "defaultMessage": "Cart - Preview"
   },
   "breadCrumb/inventory/stockout/cart": {
      "id": "breadCrumb/inventory/stockout/cart",
      "defaultMessage": "Cart - Preview"
   },
   "breadCrumb/inventory/stockrequisition/cart": {
      "id": "breadCrumb/inventory/stockrequisition/cart",
      "defaultMessage": "Cart - Preview"
   },
   "breadCrumb/inventory/stocktransfer/cart": {
      "id": "breadCrumb/inventory/stocktransfer/cart",
      "defaultMessage": "Cart - Preview"
   },
   "breadCrumb/inventory/stockaudit/cart": {
      "id": "breadCrumb/inventory/stockaudit/cart",
      "defaultMessage": "Cart - Preview"
   },
   "breadCrumb/inventory/newcart1": {
      "id": "breadCrumb/inventory/newcart1",
      "defaultMessage": "Cart"
   },
   "breadCrumb/inventory/stocktransfer": {
      "id": "breadCrumb/inventory/stocktransfer",
      "defaultMessage": "Stock Transfer"
   },
   "breadCrumb/inventory/stocktransferpreview": {
      "id": "breadCrumb/inventory/stocktransferpreview",
      "defaultMessage": "Stock Transfer Preview"
   },
   "breadCrumb/inventory/newallitems3": {
      "id": "breadCrumb/inventory/newallitems3",
      "defaultMessage": "Products List"
   },
   "breadCrumb/inventory/newcart3": {
      "id": "breadCrumb/inventory/newcart3",
      "defaultMessage": "Cart"
   },
   "breadCrumb/inventory/stockvalidation": {
      "id": "breadCrumb/inventory/stockvalidation",
      "defaultMessage": "Stock Validation"
   },
   "breadCrumb/inventory/stockpreview/1": {
      "id": "breadCrumb/inventory/stockpreview/1",
      "defaultMessage": "Stock Validation Preview"
   },
   "breadCrumb/inventory/reasonfordiscrepancy": {
      "id": "breadCrumb/inventory/reasonfordiscrepancy",
      "defaultMessage": "Discrepancy"
   },
   "breadCrumb/inventory/stockvalidationpreview": {
      "id": "breadCrumb/inventory/stockvalidationpreview",
      "defaultMessage": "Stock Validation Preview"
   },
   "breadCrumb/inventory/newcart4": {
      "id": "breadCrumb/inventory/newcart4",
      "defaultMessage": "Cart"
   },
   "breadCrumb/inventory/newallitems4": {
      "id": "breadCrumb/inventory/newallitems4",
      "defaultMessage": "Products List"
   },
   "breadCrumb/inventory/batches": {
      "id": "breadCrumb/inventory/batches",
      "defaultMessage": "Batches"
   },
   "breadCrumb/inventory/newcart6": {
      "id": "breadCrumb/inventory/newcart6",
      "defaultMessage": "Cart"
   },
   "breadCrumb/inventory/stockApproval": {
      "id": "breadCrumb/inventory/stockApproval",
      "defaultMessage": "Stock Approval"
   },
   "breadCrumb/inventory/stockApproval/:id": {
      "id": "breadCrumb/inventory/stockApproval/:id",
      "defaultMessage": "Transaction Details"
   },
   "breadCrumb/inventory/stockApprovalPreview": {
      "id": "breadCrumb/inventory/stockApprovalPreview",
      "defaultMessage": "Transaction Details"
   },
   "breadCrumb/inventory/vendors": {
      "id": "breadCrumb/inventory/vendors",
      "defaultMessage": "Purchase",
      "lastCount": 1
   },
   "breadCrumb/inventory/vendors/1": {
      "id": "breadCrumb/inventory/vendors/1",
      "defaultMessage": "Vendors"
   },
   "breadCrumb/inventory/vendors/vendorsource": {
      "id": "breadCrumb/inventory/vendors/vendorsource",
      "defaultMessage": "Vendor Source Mapping"
   },
   "breadCrumb/inventory/vendors/vendorproducts": {
      "id": "breadCrumb/inventory/vendors/vendorproducts",
      "defaultMessage": "Vendor Product Mapping"
   },
   "breadCrumb/inventory/vendors/createvendor": {
      "id": "breadCrumb/inventory/vendors/createvendor",
      "defaultMessage": "Create Vendors"
   },
   "breadCrumb/inventory/vendors/editvendor": {
      "id": "breadCrumb/inventory/vendors/editvendor",
      "defaultMessage": "Edit Vendors"
   },
   "breadCrumb/inventory/purchaseorder2": {
      "id": "breadCrumb/inventory/purchaseorder2",
      "defaultMessage": "Purchase Orders"
   },
   "breadCrumb/inventory/purchaseorder2/create": {
      "id": "breadCrumb/inventory/purchaseorder2/create",
      "defaultMessage": "Create Purchase Order"
   },
   "breadCrumb/inventory/purchaseorder2/edit/:id": {
      "id": "breadCrumb/inventory/purchaseorder2/edit/:id",
      "defaultMessage": "Edit Purchase Order"
   },
   "breadCrumb/inventory/purchaseorder2/addproducts": {
      "id": "breadCrumb/inventory/purchaseorder2/addproducts",
      "defaultMessage": "Add Product to Purchase Order"
   },
   "breadCrumb/inventory/purchaseorder2/preview": {
      "id": "breadCrumb/inventory/purchaseorder2/preview",
      "defaultMessage": "Preview"
   },
   "breadCrumb/inventory/newcartforPO": {
      "id": "breadCrumb/inventory/newcartforPO",
      "defaultMessage": "Cart"
   },
   "breadCrumb/inventory/newallitemsPO": {
      "id": "breadCrumb/inventory/newallitemsPO",
      "defaultMessage": "Products List"
   },
   "breadCrumb/vendor/vendorbill": {
      "id": "breadCrumb/vendor/vendorbill",
      "defaultMessage": "Vendor Bill"
   },
   "breadCrumb/inventory/vendor/createbill": {
      "id": "breadCrumb/inventory/vendor/createbill",
      "defaultMessage": "Create Vendor Bill"
   },
   "breadCrumb/inventory/vendor/addproducts": {
      "id": "breadCrumb/inventory/vendor/addproducts",
      "defaultMessage": "Add Products"
   },
   "breadCrumb/inventory/vendor/previewbill": {
      "id": "breadCrumb/inventory/vendor/previewbill",
      "defaultMessage": "Preview Bill"
   },
   "breadCrumb/inventory/vendor/unpaidbills": {
      "id": "breadCrumb/inventory/vendor/unpaidbills",
      "defaultMessage": "Unpaid Bills",
      "lastCount": 1
   },
   "breadCrumb/inventory/vendor/unpaidbills/1": {
      "id": "breadCrumb/inventory/vendor/unpaidbills/1",
      "defaultMessage": "Unpaid Bills"
   },
   "breadCrumb/vendor/vendorinvoice": {
      "id": "breadCrumb/vendor/vendorinvoice",
      "defaultMessage": "Vendor Invoices"
   },
   "breadCrumb/inventory/vendors/invoiceSettlement": {
      "id": "breadCrumb/inventory/vendors/invoiceSettlement",
      "defaultMessage": "Invoice Settlement"
   },
   "breadCrumb/customerDashboard": {
      "id": "breadCrumb/customerDashboard",
      "defaultMessage": "Customer",
      "lastCount": 1
   },
   "breadCrumb/customerDashboard/1": {
      "id": "breadCrumb/customerDashboard/1",
      "defaultMessage": "Customers Dashboard"
   },
   "breadCrumb/reports/customers": {
      "id": "breadCrumb/reports/customers",
      "defaultMessage": "List Of Customers"
   },
   "breadCrumb/master/bulkcreatecustomers": {
      "id": "breadCrumb/master/bulkcreatecustomers",
      "defaultMessage": "Create Customer"
   },
   "breadCrumb/master/bulkcreatecustomers/preview": {
      "id": "breadCrumb/master/bulkcreatecustomers/preview",
      "defaultMessage": "Create Customer"
   },
   "breadCrumb/master/bulkeditcustomers": {
      "id": "breadCrumb/master/bulkeditcustomers",
      "defaultMessage": "Bulk Edit Customer"
   },
   "breadCrumb/master/bulkeditcustomers/preview": {
      "id": "breadCrumb/master/bulkeditcustomers/preview",
      "defaultMessage": "Edit Products"
   },
   "breadCrumb/reports/inactiveCustomerReport": {
      "id": "breadCrumb/reports/inactiveCustomerReport",
      "defaultMessage": "Inactive Customers"
   },
   "breadCrumb/customer/loyaltySettings": {
      "id": "breadCrumb/customer/loyaltySettings",
      "defaultMessage": "Loyalty Settings"
   },
   "breadCrumb/reports/customerCredit": {
      "id": "breadCrumb/reports/customerCredit",
      "defaultMessage": "Customer Credit Sale"
   },
   "breadCrumb/reports/creditInvoiceMgmt": {
      "id": "breadCrumb/reports/creditInvoiceMgmt",
      "defaultMessage": "Credit Settlement"
   },
   "breadCrumb/reports/creditNoteHistory": {
      "id": "breadCrumb/reports/creditNoteHistory",
      "defaultMessage": "Credit Note History"
   },
   "breadCrumb/reports/messageHistory": {
      "id": "breadCrumb/reports/messageHistory",
      "defaultMessage": "Message History"
   },
   "breadCrumb/loyalty/smsCredits": {
      "id": "breadCrumb/loyalty/smsCredits",
      "defaultMessage": "SMS Credit"
   },
   "breadCrumb/loyalty/whatsappCredits": {
      "id": "breadCrumb/loyalty/whatsappCredits",
      "defaultMessage": "WhatsApp Credit"
   },
   "breadCrumb/reports/customersSalesReport": {
      "id": "breadCrumb/reports/customersSalesReport",
      "defaultMessage": "Customers Sales Report"
   },
   "breadCrumb/master/customersalesbulkcreate": {
      "id": "breadCrumb/master/customersalesbulkcreate",
      "defaultMessage": "Create Customer"
   },
   "breadCrumb/master/customersalesbulkcreate/preview": {
      "id": "breadCrumb/master/customersalesbulkcreate/preview",
      "defaultMessage": "Bulk Edit Customer"
   },
   "breadCrumb/master/customersalesbulkedit": {
      "id": "breadCrumb/master/customersalesbulkedit",
      "defaultMessage": "Bulk Edit Customer"
   },
   "breadCrumb/master/customersalesbulkedit/preview": {
      "id": "breadCrumb/master/customersalesbulkedit/preview",
      "defaultMessage": "Edit Products"
   },
   "breadCrumb/marketing": { "id": "breadCrumb/marketing", "defaultMessage": "Marketing" },
   "breadCrumb/reports/DSR": {
      "id": "breadCrumb/reports/DSR",
      "defaultMessage": "Reports",
      "lastCount": 2
   },
   "breadCrumb/reports/orders": {
      "id": "breadCrumb/reports/orders",
      "defaultMessage": "Orders",
      "lastCount": 1
   },
   "breadCrumb/reports/orders/1": {
      "id": "breadCrumb/reports/orders/1",
      "defaultMessage": "List Of Orders"
   },
   "breadCrumb/reports/orderReport": {
      "id": "breadCrumb/reports/orderReport",
      "defaultMessage": "Order Details"
   },
   "breadCrumb/reports/order/:id": {
      "id": "breadCrumb/reports/order/:id",
      "defaultMessage": "Order Details"
   },
   "breadCrumb/reports/dailyOrderCount": {
      "id": "breadCrumb/reports/dailyOrderCount",
      "defaultMessage": "Daily Order Count"
   },
   "breadCrumb/reports/productsInOrder": {
      "id": "breadCrumb/reports/productsInOrder",
      "defaultMessage": "Product In Orders"
   },
   "breadCrumb/reports/voidOrders": {
      "id": "breadCrumb/reports/voidOrders",
      "defaultMessage": "List Of Void Orders"
   },
   "breadCrumb/reports/OrderComboReport": {
      "id": "breadCrumb/reports/OrderComboReport",
      "defaultMessage": "Order Combo Report"
   },
   "breadCrumb/reports/kotVoidDetails": {
      "id": "breadCrumb/reports/kotVoidDetails",
      "defaultMessage": "Kot Void Details"
   },
   "breadCrumb/reports/ordersSummarized": {
      "id": "breadCrumb/reports/ordersSummarized",
      "defaultMessage": "Orders Summarized"
   },
   "breadCrumb/master/ProductDiscountReport": {
      "id": "breadCrumb/master/ProductDiscountReport",
      "defaultMessage": "Promotions",
      "lastCount": 1
   },
   "breadCrumb/master/ProductDiscountReport/1": {
      "id": "breadCrumb/master/ProductDiscountReport/1",
      "defaultMessage": "Product Discount Report"
   },
   "breadCrumb/reports/discountExpenses": {
      "id": "breadCrumb/reports/discountExpenses",
      "defaultMessage": "Discount Expenses"
   },
   "breadCrumb/reports/discountedProducts": {
      "id": "breadCrumb/reports/discountedProducts",
      "defaultMessage": "Discounted Products"
   },
   "breadCrumb/reports/orderDiscountReport": {
      "id": "breadCrumb/reports/orderDiscountReport",
      "defaultMessage": "Order Discount Report"
   },
   "breadCrumb/reports/couponRedemtion": {
      "id": "breadCrumb/reports/couponRedemtion",
      "defaultMessage": "Coupon Redemption"
   },
   "breadCrumb/reports/giftvouchertracker": {
      "id": "breadCrumb/reports/giftvouchertracker",
      "defaultMessage": "Gift Voucher Tracker"
   },
   "breadCrumb/reports/po": {
      "id": "breadCrumb/reports/po",
      "defaultMessage": "Purchase Orders Reports",
      "lastCount": 1
   },
   "breadCrumb/reports/po/1": {
      "id": "breadCrumb/reports/po/1",
      "defaultMessage": "List of Purchase Orders"
   },
   "breadCrumb/reports/prodPurchaseOrderInfo": {
      "id": "breadCrumb/reports/prodPurchaseOrderInfo",
      "defaultMessage": "Product In Purchase Orders"
   },
   "breadCrumb/reports/prodPurchaseOrderDetailReport": {
      "id": "breadCrumb/reports/prodPurchaseOrderDetailReport",
      "defaultMessage": "Purchase Order Details"
   },
   "breadCrumb/reports/prodPurchaseOrderInfo/:id": {
      "id": "breadCrumb/reports/prodPurchaseOrderInfo/:id",
      "defaultMessage": "Purchase Order Details"
   },
   "breadCrumb/reports/purchaseReturns": {
      "id": "breadCrumb/reports/purchaseReturns",
      "defaultMessage": "Purchase Return Reports",
      "lastCount": 1
   },
   "breadCrumb/reports/purchaseReturns/1": {
      "id": "breadCrumb/reports/purchaseReturns/1",
      "defaultMessage": "List of Purchase Returns"
   },
   "breadCrumb/reports/prodPurchaseReturnInfo": {
      "id": "breadCrumb/reports/prodPurchaseReturnInfo",
      "defaultMessage": "Product In Purchase Return"
   },
   "breadCrumb/reports/prodPurchaseReturnDetailReport": {
      "id": "breadCrumb/reports/prodPurchaseReturnDetailReport",
      "defaultMessage": "Purchase Return Details"
   },
   "breadCrumb/reports/prodPurchaseReturnDetailReport/:id": {
      "id": "breadCrumb/reports/prodPurchaseReturnDetailReport/:id",
      "defaultMessage": "Purchase Return Details"
   },
   "breadCrumb/reports/pi": {
      "id": "breadCrumb/reports/pi",
      "defaultMessage": "Proforma Invoices",
      "lastCount": 1
   },
   "breadCrumb/reports/pi/1": { "id": "breadCrumb/reports/pi/1", "defaultMessage": "List of PI" },
   "breadCrumb/reports/piDetail": {
      "id": "breadCrumb/reports/piDetail",
      "defaultMessage": "PI Detail"
   },
   "breadCrumb/reports/productsInPI": {
      "id": "breadCrumb/reports/productsInPI",
      "defaultMessage": "Product in PI"
   },
   "breadCrumb/reports/listofexpenseorders": {
      "id": "breadCrumb/reports/listofexpenseorders",
      "defaultMessage": "Expenses",
      "lastCount": 1
   },
   "breadCrumb/reports/listofexpenseorders/1": {
      "id": "breadCrumb/reports/listofexpenseorders/1",
      "defaultMessage": "List of Expenses"
   },
   "breadCrumb/reports/DSR/1": { "id": "breadCrumb/reports/DSR/1", "defaultMessage": "Sales" },
   "breadCrumb/reports/DSR/2": {
      "id": "breadCrumb/reports/DSR/2",
      "defaultMessage": "Daily Sales"
   },
   "breadCrumb/reports/salesordersdetails": {
      "id": "breadCrumb/reports/salesordersdetails",
      "defaultMessage": "List Of Orders"
   },
   "breadCrumb/reports/dailySalesOrderDetails": {
      "id": "breadCrumb/reports/dailySalesOrderDetails",
      "defaultMessage": "Order Details"
   },
   "breadCrumb/reports/orderPaymentBreakupDetails": {
      "id": "breadCrumb/reports/orderPaymentBreakupDetails",
      "defaultMessage": "Order Details"
   },
   "breadCrumb/reports/orderTaxBreakupDetails": {
      "id": "breadCrumb/reports/orderTaxBreakupDetails",
      "defaultMessage": "Order Details"
   },
   "breadCrumb/reports/prodStockMovementDetails": {
      "id": "breadCrumb/reports/prodStockMovementDetails",
      "defaultMessage": "Order Details"
   },
   "breadCrumb/reports/membershipPurchaseHistoryDetails": {
      "id": "breadCrumb/reports/membershipPurchaseHistoryDetails",
      "defaultMessage": "Order Details"
   },
   "breadCrumb/reports/membershipTrackerDetails": {
      "id": "breadCrumb/reports/membershipTrackerDetails",
      "defaultMessage": "Order Details"
   },
   "breadCrumb/reports/storeWiseSales": {
      "id": "breadCrumb/reports/storeWiseSales",
      "defaultMessage": "Store Wise Sales"
   },
   "breadCrumb/reports/entityWiseSales": {
      "id": "breadCrumb/reports/entityWiseSales",
      "defaultMessage": "Entity Wise Sales"
   },
   "breadCrumb/reports/locationWiseSales": {
      "id": "breadCrumb/reports/locationWiseSales",
      "defaultMessage": "Location Wise Sales"
   },
   "breadCrumb/reports/storeProductSales": {
      "id": "breadCrumb/reports/storeProductSales",
      "defaultMessage": "Store Wise Product Sales"
   },
   "breadCrumb/reports/regionSales": {
      "id": "breadCrumb/reports/regionSales",
      "defaultMessage": "Region Wise Sales"
   },
   "breadCrumb/reports/TopProductsWiseSales": {
      "id": "breadCrumb/reports/TopProductsWiseSales",
      "defaultMessage": "Product Wise Sales"
   },
   "breadCrumb/reports/categorySales": {
      "id": "breadCrumb/reports/categorySales",
      "defaultMessage": "Category Wise Sales"
   },
   "breadCrumb/reports/SubCategoryWiseSalesReport": {
      "id": "breadCrumb/reports/SubCategoryWiseSalesReport",
      "defaultMessage": "Sub Category Wise Sales"
   },
   "breadCrumb/reports/brandSales": {
      "id": "breadCrumb/reports/brandSales",
      "defaultMessage": "Brand Wise Sales"
   },
   "breadCrumb/reports/BatchWiseSalesReport": {
      "id": "breadCrumb/reports/BatchWiseSalesReport",
      "defaultMessage": "Batch Wise Sales"
   },
   "breadCrumb/reports/incomeHeadSales": {
      "id": "breadCrumb/reports/incomeHeadSales",
      "defaultMessage": "Income Head Wise Sales"
   },
   "breadCrumb/reports/deviceSales": {
      "id": "breadCrumb/reports/deviceSales",
      "defaultMessage": "Device Wise Sales"
   },
   "breadCrumb/reports/customerSales": {
      "id": "breadCrumb/reports/customerSales",
      "defaultMessage": "Customer Wise Sales"
   },
   "breadCrumb/reports/employeeSalesSummary": {
      "id": "breadCrumb/reports/employeeSalesSummary",
      "defaultMessage": "Employee Wise Sales"
   },
   "breadCrumb/reports/salesordersdetails2/:id": {
      "id": "breadCrumb/reports/salesordersdetails2/:id",
      "defaultMessage": "List Of Orders"
   },
   "breadCrumb/reports/employeewiseProductSalesSummary": {
      "id": "breadCrumb/reports/employeewiseProductSalesSummary",
      "defaultMessage": "Employee Wise Product Sales"
   },
   "breadCrumb/reports/StoreHourWiseSales": {
      "id": "breadCrumb/reports/StoreHourWiseSales",
      "defaultMessage": "Store Hourly Sales"
   },
   "breadCrumb/reports/DPB": {
      "id": "breadCrumb/reports/DPB",
      "defaultMessage": "Daily Payment Breakup"
   },
   "breadCrumb/reports/salesordersdetails1": {
      "id": "breadCrumb/reports/salesordersdetails1",
      "defaultMessage": "List Of Orders"
   },
   "breadCrumb/reports/orderPaymentBreakup": {
      "id": "breadCrumb/reports/orderPaymentBreakup",
      "defaultMessage": "Order wise Payment Breakup"
   },
   "breadCrumb/reports/orderTaxBreakup": {
      "id": "breadCrumb/reports/orderTaxBreakup",
      "defaultMessage": "Order Wise Tax Breakup"
   },
   "breadCrumb/reports/productTaxBreakup": {
      "id": "breadCrumb/reports/productTaxBreakup",
      "defaultMessage": "Product-wise Tax Breakup"
   },
   "breadCrumb/reports/HsnSacWiseTaxBreakupReport": {
      "id": "breadCrumb/reports/HsnSacWiseTaxBreakupReport",
      "defaultMessage": "HSN/SAC Wise Tax Breakup "
   },
   "breadCrumb/reports/OrderPaymentHistoryReport": {
      "id": "breadCrumb/reports/OrderPaymentHistoryReport",
      "defaultMessage": "Order Payment History"
   },
   "breadCrumb/reports/OrderPaymentHistoryReport/:id": {
      "id": "breadCrumb/reports/OrderPaymentHistoryReport/:id",
      "defaultMessage": "Order Payment History Details"
   },
   "breadCrumb/reports/SalesTransactionDetail": {
      "id": "breadCrumb/reports/SalesTransactionDetail",
      "defaultMessage": "Sales Transaction Tracker"
   },
   "breadCrumb/reports/ProductTransactionTracker": {
      "id": "breadCrumb/reports/ProductTransactionTracker",
      "defaultMessage": "Product Transaction Tracker"
   },
   "breadCrumb/reports/bundleSales": {
      "id": "breadCrumb/reports/bundleSales",
      "defaultMessage": "Bundle Wise Sales"
   },
   "breadCrumb/reports/counterWiseSales": {
      "id": "breadCrumb/reports/counterWiseSales",
      "defaultMessage": "Counter Wise Sales"
   },
   "breadCrumb/reports/salesTargetConfig": {
      "id": "breadCrumb/reports/salesTargetConfig",
      "defaultMessage": "Sales Target Configuration Report"
   },
   "breadCrumb/reports/salesTargetView/:ID": {
      "id": "breadCrumb/reports/salesTargetView/:ID",
      "defaultMessage": "Configuration Details"
   },
   "breadCrumb/reports/netProfit": {
      "id": "breadCrumb/reports/netProfit",
      "defaultMessage": "Net Profit"
   },
   "breadCrumb/reports/ListOfOnlineOrders": {
      "id": "breadCrumb/reports/ListOfOnlineOrders",
      "defaultMessage": "Online Order",
      "lastCount": 1
   },
   "breadCrumb/reports/ListOfOnlineOrders/1": {
      "id": "breadCrumb/reports/ListOfOnlineOrders/1",
      "defaultMessage": "List Of Online Orders"
   },
   "breadCrumb/reports/ListOfOnlineOrdersReport": {
      "id": "breadCrumb/reports/ListOfOnlineOrdersReport",
      "defaultMessage": "Online Order Detail"
   },
   "breadCrumb/reports/ListOfOnlineOrders/:id": {
      "id": "breadCrumb/reports/ListOfOnlineOrders/:id",
      "defaultMessage": "Online Order Detail"
   },
   "breadCrumb/reports/productInOnlineOrders": {
      "id": "breadCrumb/reports/productInOnlineOrders",
      "defaultMessage": "Product in Online orders"
   },
   "breadCrumb/reports/stockLevel": {
      "id": "breadCrumb/reports/stockLevel",
      "defaultMessage": "Inventory",
      "lastCount": 1
   },
   "breadCrumb/reports/stockLevel/1": {
      "id": "breadCrumb/reports/stockLevel/1",
      "defaultMessage": "Stock Level"
   },
   "breadCrumb/reports/storeWiseStockLevel": {
      "id": "breadCrumb/reports/storeWiseStockLevel",
      "defaultMessage": "Store Wise Stock Level"
   },
   "breadCrumb/reports/productGroup": {
      "id": "breadCrumb/reports/productGroup",
      "defaultMessage": "Product Group Stock Level"
   },
   "breadCrumb/reports/stockTransactionHistory": {
      "id": "breadCrumb/reports/stockTransactionHistory",
      "defaultMessage": "Stock Operations"
   },
   "breadCrumb/reports/stockTransactionDetail/:id": {
      "id": "breadCrumb/reports/stockTransactionDetail/:id",
      "defaultMessage": "Stock Transaction Detail"
   },
   "breadCrumb/reports/stockOperationDetail": {
      "id": "breadCrumb/reports/stockOperationDetail",
      "defaultMessage": "Stock Operations Detail"
   },
   "breadCrumb/reports/stockMovement": {
      "id": "breadCrumb/reports/stockMovement",
      "defaultMessage": "Stock Movement"
   },
   "breadCrumb/reports/prodStockMovement": {
      "id": "breadCrumb/reports/prodStockMovement",
      "defaultMessage": "Stock Movement Detail"
   },
   "breadCrumb/reports/stockRequisitionHistory": {
      "id": "breadCrumb/reports/stockRequisitionHistory",
      "defaultMessage": "Stock Requisition"
   },
   "breadCrumb/reports/stockRequisitionHistory/:id": {
      "id": "breadCrumb/reports/stockRequisitionHistory/:id",
      "defaultMessage": "Stock Requisition Detail"
   },
   "breadCrumb/reports/UnfullfilledStockRequests": {
      "id": "breadCrumb/reports/UnfullfilledStockRequests",
      "defaultMessage": "Unfulfilled Stock Requests"
   },
   "breadCrumb/reports/ProductAgeingReport": {
      "id": "breadCrumb/reports/ProductAgeingReport",
      "defaultMessage": "Product Ageing Report"
   },
   "breadCrumb/reports/stockCostMargin": {
      "id": "breadCrumb/reports/stockCostMargin",
      "defaultMessage": "Profit Margin"
   },
   "breadCrumb/reports/vendorTaxInput": {
      "id": "breadCrumb/reports/vendorTaxInput",
      "defaultMessage": "Vendor Tax Input"
   },
   "breadCrumb/reports/lowStockProducts": {
      "id": "breadCrumb/reports/lowStockProducts",
      "defaultMessage": "Low Stock Products"
   },
   "breadCrumb/reports/lowStockProducts/createStockReport": {
      "id": "breadCrumb/reports/lowStockProducts/createStockReport",
      "defaultMessage": "Create Stock Request"
   },
   "breadCrumb/reports/lowStockProducts/previewStockReport": {
      "id": "breadCrumb/reports/lowStockProducts/previewStockReport",
      "defaultMessage": "Preview Stock Request"
   },
   "breadCrumb/reports/stockAuditSummary": {
      "id": "breadCrumb/reports/stockAuditSummary",
      "defaultMessage": "Stock Audit Summary"
   },
   "breadCrumb/reports/stockAuditDetail/:id": {
      "id": "breadCrumb/reports/stockAuditDetail/:id",
      "defaultMessage": "Stock Audit Detail"
   },
   "breadCrumb/reports/stockAuditAnalysis": {
      "id": "breadCrumb/reports/stockAuditAnalysis",
      "defaultMessage": "Stock Audit Analysis"
   },
   "breadCrumb/reports/vendorPurchaseSummary": {
      "id": "breadCrumb/reports/vendorPurchaseSummary",
      "defaultMessage": "Vendor Purchase Summary"
   },
   "breadCrumb/reports/vendorProductPurchaseSummary": {
      "id": "breadCrumb/reports/vendorProductPurchaseSummary",
      "defaultMessage": "Vendor Product Purchase Summary"
   },
   "breadCrumb/reports/stockLedgerSummary": {
      "id": "breadCrumb/reports/stockLedgerSummary",
      "defaultMessage": "Stock Ledger Summary"
   },
   "breadCrumb/reports/stockLedgerDetails": {
      "id": "breadCrumb/reports/stockLedgerDetails",
      "defaultMessage": "Stock Ledger Details"
   },
   "breadCrumb/reports/stockMovementViaLedger": {
      "id": "breadCrumb/reports/stockMovementViaLedger",
      "defaultMessage": "Stock Movement Via Ledger"
   },
   "breadCrumb/reports/ewaybill": {
      "id": "breadCrumb/reports/ewaybill",
      "defaultMessage": "E-Way Bill Report"
   },
   "breadCrumb/reports/modifierReport": {
      "id": "breadCrumb/reports/modifierReport",
      "defaultMessage": "Modifier",
      "lastCount": 1
   },
   "breadCrumb/reports/modifierReport/1": {
      "id": "breadCrumb/reports/modifierReport/1",
      "defaultMessage": "Modifiers in order"
   },
   "breadCrumb/reports/attendanceReport": {
      "id": "breadCrumb/reports/attendanceReport",
      "defaultMessage": "Users",
      "lastCount": 1
   },
   "breadCrumb/reports/attendanceReport/1": {
      "id": "breadCrumb/reports/attendanceReport/1",
      "defaultMessage": "Attendance Report"
   },
   "breadCrumb/reports/infoDaysReport": {
      "id": "breadCrumb/reports/infoDaysReport",
      "defaultMessage": "Days Info Report"
   },
   "breadCrumb/reports/BatchInfoReport": {
      "id": "breadCrumb/reports/BatchInfoReport",
      "defaultMessage": "Batch Info Report"
   },
   "breadCrumb/reports/membershipPurchaseHistory": {
      "id": "breadCrumb/reports/membershipPurchaseHistory",
      "defaultMessage": "Membership",
      "lastCount": 1
   },
   "breadCrumb/reports/membershipPurchaseHistory/1": {
      "id": "breadCrumb/reports/membershipPurchaseHistory/1",
      "defaultMessage": "Membership Purchase History"
   },
   "breadCrumb/reports/membershipPurchaseHistory/:id": {
      "id": "breadCrumb/reports/membershipPurchaseHistory/:id",
      "defaultMessage": "Membership Purchase History"
   },
   "breadCrumb/reports/membershipTracker": {
      "id": "breadCrumb/reports/membershipTracker",
      "defaultMessage": "Membership Tracker"
   },
   "breadCrumb/reports/cashManageMentHistory": {
      "id": "breadCrumb/reports/cashManageMentHistory",
      "defaultMessage": "Cash Management",
      "lastCount": 1
   },
   "breadCrumb/reports/cashManageMentHistory/1": {
      "id": "breadCrumb/reports/cashManageMentHistory/1",
      "defaultMessage": "Cash Management History"
   },
   "breadCrumb/reports/reservationTracker": {
      "id": "breadCrumb/reports/reservationTracker",
      "defaultMessage": "Reservation",
      "lastCount": 1
   },
   "breadCrumb/reports/reservationTracker/1": {
      "id": "breadCrumb/reports/reservationTracker/1",
      "defaultMessage": "Reservation Tracker"
   },
   "breadCrumb/reports/reservation/slotsTracker": {
      "id": "breadCrumb/reports/reservation/slotsTracker",
      "defaultMessage": "Reservation Slots Tracker"
   },
   "breadCrumb/reports/currencyValidation": {
      "id": "breadCrumb/reports/currencyValidation",
      "defaultMessage": "Currency Conversion",
      "lastCount": 1
   },
   "breadCrumb/reports/currencyValidation/1": {
      "id": "breadCrumb/reports/currencyValidation/1",
      "defaultMessage": "Currency Validation"
   },
   "breadCrumb/reports/tenderWiseCollection": {
      "id": "breadCrumb/reports/tenderWiseCollection",
      "defaultMessage": "Tender Wise Collection"
   },
   "breadCrumb/reports/productLogs": {
      "id": "breadCrumb/reports/productLogs",
      "defaultMessage": "Logs",
      "lastCount": 1
   },
   "breadCrumb/reports/productLogs/1": {
      "id": "breadCrumb/reports/productLogs/1",
      "defaultMessage": "Product Logs"
   },
   "breadCrumb/reports/deviceSettlementLog": {
      "id": "breadCrumb/reports/deviceSettlementLog",
      "defaultMessage": "Device Settlement Logs"
   },
   "breadCrumb/reports/appointments": {
      "id": "breadCrumb/reports/appointments",
      "defaultMessage": "Service",
      "lastCount": 1
   },
   "breadCrumb/reports/appointments/1": {
      "id": "breadCrumb/reports/appointments/1",
      "defaultMessage": "Appointments"
   },
   "breadCrumb/reports/salesStaff": {
      "id": "breadCrumb/reports/salesStaff",
      "defaultMessage": "Staff Sales"
   },
   "breadCrumb/reports/serviceByStaff": {
      "id": "breadCrumb/reports/serviceByStaff",
      "defaultMessage": "Service By Staff"
   },
   "breadCrumb/reports/materializedview": {
      "id": "breadCrumb/reports/materializedview",
      "defaultMessage": "IRD Reports",
      "lastCount": 1
   },
   "breadCrumb/reports/materializedview/1": {
      "id": "breadCrumb/reports/materializedview/1",
      "defaultMessage": "Materialized View"
   },
   "breadCrumb/reports/salesbook": {
      "id": "breadCrumb/reports/salesbook",
      "defaultMessage": "Sales Book"
   },
   "breadCrumb/reports/salesledger": {
      "id": "breadCrumb/reports/salesledger",
      "defaultMessage": "Sales Ledger"
   },
   "breadCrumb/reports/salesreturnsledger": {
      "id": "breadCrumb/reports/salesreturnsledger",
      "defaultMessage": "Sales Return Ledger"
   },
   "breadCrumb/reports/purchaseledger": {
      "id": "breadCrumb/reports/purchaseledger",
      "defaultMessage": "Purchase Ledger"
   },
   "breadCrumb/reports/purchasereturnledger": {
      "id": "breadCrumb/reports/purchasereturnledger",
      "defaultMessage": "Purchase Return Ledger"
   },
   "breadCrumb/reports/activitylogs": {
      "id": "breadCrumb/reports/activitylogs",
      "defaultMessage": "Activity Logs"
   },
   "breadCrumb/reports/backupdata": {
      "id": "breadCrumb/reports/backupdata",
      "defaultMessage": "Backup Data"
   },
   "breadCrumb/setup/chainprofile2": {
      "id": "breadCrumb/setup/chainprofile2",
      "defaultMessage": "Settings",
      "lastCount": 1
   },
   "breadCrumb/setup/chainprofile2/1": {
      "id": "breadCrumb/setup/chainprofile2/1",
      "defaultMessage": "Chain Profile"
   },
   "breadCrumb/setup/storeslist": {
      "id": "breadCrumb/setup/storeslist",
      "defaultMessage": "Stores"
   },
   "breadCrumb/setup/storeslist/store/create": {
      "id": "breadCrumb/setup/storeslist/store/create",
      "defaultMessage": "Create Store"
   },
   "breadCrumb/setup/storeslist/store/:id": {
      "id": "breadCrumb/setup/storeslist/store/:id",
      "defaultMessage": "Edit Store"
   },
   "breadCrumb/setup/counterList": {
      "id": "breadCrumb/setup/counterList",
      "defaultMessage": "Store Counter"
   },
   "breadCrumb/setup/counterList/counter/create": {
      "id": "breadCrumb/setup/counterList/counter/create",
      "defaultMessage": "Create Counter"
   },
   "breadCrumb/setup/counterList/counter/:id": {
      "id": "breadCrumb/setup/counterList/counter/:id",
      "defaultMessage": "Edit Counter"
   },
   "breadCrumb/setup/warehouse": {
      "id": "breadCrumb/setup/warehouse",
      "defaultMessage": "Warehouses"
   },
   "breadCrumb/setup/warehouse/createwarehouse": {
      "id": "breadCrumb/setup/warehouse/createwarehouse",
      "defaultMessage": "Create Warehouse"
   },
   "breadCrumb/setup/warehouse/editwarehouse/:id": {
      "id": "breadCrumb/setup/warehouse/editwarehouse/:id",
      "defaultMessage": "Edit Warehouse"
   },
   "breadCrumb/setup/warehouse/viewwarehouse/:id": {
      "id": "breadCrumb/setup/warehouse/viewwarehouse/:id",
      "defaultMessage": "View Warehouse"
   },
   "breadCrumb/setup/regions": { "id": "breadCrumb/setup/regions", "defaultMessage": "Regions" },
   "breadCrumb/setup/regionStores/:id": {
      "id": "breadCrumb/setup/regionStores/:id",
      "defaultMessage": "View Store"
   },
   "breadCrumb/setup/regions/create": {
      "id": "breadCrumb/setup/regions/create",
      "defaultMessage": "Create Region"
   },
   "breadCrumb/setup/regions/edit/:id": {
      "id": "breadCrumb/setup/regions/edit/:id",
      "defaultMessage": "Edit"
   },
   "breadCrumb/setup/attributes": {
      "id": "breadCrumb/setup/attributes",
      "defaultMessage": "Attributes"
   },
   "breadCrumb/setup/attributes/createattribute": {
      "id": "breadCrumb/setup/attributes/createattribute",
      "defaultMessage": "Create Attribute"
   },
   "breadCrumb/setup/attributes/editattribute/:id": {
      "id": "breadCrumb/setup/attributes/editattribute/:id",
      "defaultMessage": "Edit Attribute"
   },
   "breadCrumb/setup/systemattributes": {
      "id": "breadCrumb/setup/systemattributes",
      "defaultMessage": "System Attributes"
   },
   "breadCrumb/setup/systemattributes/edit/:id": {
      "id": "breadCrumb/setup/systemattributes/edit/:id",
      "defaultMessage": "Edit System Attribute"
   },
   "breadCrumb/setup/remarks": { "id": "breadCrumb/setup/remarks", "defaultMessage": "Remarks" },
   "breadCrumb/setup/editremark/:id": {
      "id": "breadCrumb/setup/editremark/:id",
      "defaultMessage": "Edit Remark"
   },
   "breadCrumb/setup/createremark": {
      "id": "breadCrumb/setup/createremark",
      "defaultMessage": "Create Remark"
   },
   "breadCrumb/master/floors": { "id": "breadCrumb/master/floors", "defaultMessage": "Floors" },
   "breadCrumb/master/createfloor": {
      "id": "breadCrumb/master/createfloor",
      "defaultMessage": "Create Floor"
   },
   "breadCrumb/master/editfloor/:id": {
      "id": "breadCrumb/master/editfloor/:id",
      "defaultMessage": "Edit Floor"
   },
   "breadCrumb/setup/rooms": { "id": "breadCrumb/setup/rooms", "defaultMessage": "Rooms" },
   "breadCrumb/setup/createroom": {
      "id": "breadCrumb/setup/createroom",
      "defaultMessage": "Create Rooms"
   },
   "breadCrumb/setup/editroom/:id": {
      "id": "breadCrumb/setup/editroom/:id",
      "defaultMessage": "Edit Rooms"
   },
   "breadCrumb/master/tables": { "id": "breadCrumb/master/tables", "defaultMessage": "Tables" },
   "breadCrumb/master/createtable": {
      "id": "breadCrumb/master/createtable",
      "defaultMessage": "Create Tables"
   },
   "breadCrumb/master/edittable/:id": {
      "id": "breadCrumb/master/edittable/:id",
      "defaultMessage": "Edit Tables"
   },
   "breadCrumb/setup/customizedreceiptprint": {
      "id": "breadCrumb/setup/customizedreceiptprint",
      "defaultMessage": "Customize Receipt Print"
   },
   "breadCrumb/setup/appsettings": {
      "id": "breadCrumb/setup/appsettings",
      "defaultMessage": "Application Device Settings"
   },
   "breadCrumb/setup/device/:id": {
      "id": "breadCrumb/setup/device/:id",
      "defaultMessage": "Device"
   },
   "breadCrumb/setup/deviceSyncLogs": {
      "id": "breadCrumb/setup/deviceSyncLogs",
      "defaultMessage": "Device Sync Logs"
   },
   "breadCrumb/licenses/softwarelicenses": {
      "id": "breadCrumb/licenses/softwarelicenses",
      "defaultMessage": "Software License"
   },
   "breadCrumb/licenses/softwareLicensesPayment": {
      "id": "breadCrumb/licenses/softwareLicensesPayment",
      "defaultMessage": "Payment Software License"
   },
   "breadCrumb/licenses/confirm": {
      "id": "breadCrumb/licenses/confirm",
      "defaultMessage": "License Renewal Confirmation"
   },
   "breadCrumb/setup/staff": {
      "id": "breadCrumb/setup/staff",
      "defaultMessage": "Staff Management"
   },
   "breadCrumb/setup/staff/store/:id": {
      "id": "breadCrumb/setup/staff/store/:id",
      "defaultMessage": "View Store"
   },
   "breadCrumb/setup/staff/createuser": {
      "id": "breadCrumb/setup/staff/createuser",
      "defaultMessage": "Create Staff"
   },
   "breadCrumb/setup/staff/edituser/:id": {
      "id": "breadCrumb/setup/staff/edituser/:id",
      "defaultMessage": "Edit Staff"
   },
   "breadCrumb/setup/currencyConversions": {
      "id": "breadCrumb/setup/currencyConversions",
      "defaultMessage": "Currency Conversions"
   },
   "breadCrumb/setup/updateCurrencyConversion/:id": {
      "id": "breadCrumb/setup/updateCurrencyConversion/:id",
      "defaultMessage": "Update Currency Conversion"
   },
   "breadCrumb/setup/currencyConversion-bulk": {
      "id": "breadCrumb/setup/currencyConversion-bulk",
      "defaultMessage": "Bulk - Update Currency Conversion"
   },
   "breadCrumb/setup/currencyConversion-bulk/preview": {
      "id": "breadCrumb/setup/currencyConversion-bulk/preview",
      "defaultMessage": "Bulk - Update Currency Conversion Preview"
   },
   "breadCrumb/setup/currencyConversion-bulk-create/preview": {
      "id": "breadCrumb/setup/currencyConversion-bulk-create/preview",
      "defaultMessage": "Bulk - Create Currency Conversion Preview"
   },
   "breadCrumb/setup/createCurrencyConversion": {
      "id": "breadCrumb/setup/createCurrencyConversion",
      "defaultMessage": "Create Currency Conversion"
   },
   "breadCrumb/setup/currencyConversion-create-bulk": {
      "id": "breadCrumb/setup/currencyConversion-create-bulk",
      "defaultMessage": "Bulk - Create Currency Conversion"
   },
   "breadCrumb/setup/globalCurrencies": {
      "id": "breadCrumb/setup/globalCurrencies",
      "defaultMessage": "Global Currencies"
   },
   "breadCrumb/setup/salesAssessmentPeriods": {
      "id": "breadCrumb/setup/salesAssessmentPeriods",
      "defaultMessage": "Sales Assessment Periods"
   },
   "breadCrumb/setup/createsalesAssessmentPeriods": {
      "id": "breadCrumb/setup/createsalesAssessmentPeriods",
      "defaultMessage": "Create Sales Assessment Period"
   },
   "breadCrumb/setup/updatesalesAssessmentPeriods": {
      "id": "breadCrumb/setup/updatesalesAssessmentPeriods",
      "defaultMessage": "Update Sales Assessment Period"
   },
   "breadCrumb/setup/salesTarget": {
      "id": "breadCrumb/setup/salesTarget",
      "defaultMessage": "Sales Target"
   },
   "breadCrumb/setup/salesTargetView/:ID": {
      "id": "breadCrumb/setup/salesTargetView/:ID",
      "defaultMessage": "Configuration Details"
   },
   "breadCrumb/setup/createSalesTarget": {
      "id": "breadCrumb/setup/createSalesTarget",
      "defaultMessage": "Create Target"
   },
   "breadCrumb/setup/updateSalesTarget/:ID": {
      "id": "breadCrumb/setup/updateSalesTarget/:ID",
      "defaultMessage": "Update Target"
   },
   "breadCrumb/setup/channels": { "id": "breadCrumb/setup/channels", "defaultMessage": "Channel" },
   "breadCrumb/setup/channels/create": {
      "id": "breadCrumb/setup/channels/create",
      "defaultMessage": "Create Channel"
   },
   "breadCrumb/setup/channels/edit/:id": {
      "id": "breadCrumb/setup/channels/edit/:id",
      "defaultMessage": "Update Channel"
   },
   "breadCrumb/support": { "id": "breadCrumb/support", "defaultMessage": "Support" },
   "breadCrumb/settings": { "id": "breadCrumb/settings", "defaultMessage": "Settings" },
   "breadCrumb/urbanpiper/catalogue": {
      "id": "breadCrumb/urbanpiper/catalogue",
      "defaultMessage": "Urbanpiper",
      "lastCount": 1
   },
   "breadCrumb/urbanpiper/catalogue/1": {
      "id": "breadCrumb/urbanpiper/catalogue/1",
      "defaultMessage": "Catalog"
   },
   "breadCrumb/urbanpiper/catalog/assignbulk": {
      "id": "breadCrumb/urbanpiper/catalog/assignbulk",
      "defaultMessage": "Bulk Operation"
   },
   "breadCrumb/urbanpiper/catalog/bulkpreview": {
      "id": "breadCrumb/urbanpiper/catalog/bulkpreview",
      "defaultMessage": "Bulk preview - Bulk Operation"
   },
   "breadCrumb/urbanpiper/catalogue/upeditsingleproduct/:id": {
      "id": "breadCrumb/urbanpiper/catalogue/upeditsingleproduct/:id",
      "defaultMessage": "Edit Urbanpiper Product"
   },
   "breadCrumb/urbanpiper/catalogue/upaddremoveprods": {
      "id": "breadCrumb/urbanpiper/catalogue/upaddremoveprods",
      "defaultMessage": "Add or Remove Urbanpiper Products"
   },
   "breadCrumb/urbanpiper/catalogue/upaddremovepreview": {
      "id": "breadCrumb/urbanpiper/catalogue/upaddremovepreview",
      "defaultMessage": "Preview - Selected products to sell on urbanpiper"
   },
   "breadCrumb/urbanpiper/settings": {
      "id": "breadCrumb/urbanpiper/settings",
      "defaultMessage": "Settings"
   },
   "breadCrumb/urbanpiper/urbanpiperApiLogs": {
      "id": "breadCrumb/urbanpiper/urbanpiperApiLogs",
      "defaultMessage": "Urbanpiper Api Logs"
   },
   "breadCrumb/estoreDashboard": {
      "id": "breadCrumb/estoreDashboard",
      "defaultMessage": "eStore",
      "lastCount": 1
   },
   "breadCrumb/estoreDashboard/1": {
      "id": "breadCrumb/estoreDashboard/1",
      "defaultMessage": "eStore Dashboard"
   },
   "breadCrumb/estore/catalog": { "id": "breadCrumb/estore/catalog", "defaultMessage": "Catalog" },
   "breadCrumb/estore/catalog/addremoveprods": {
      "id": "breadCrumb/estore/catalog/addremoveprods",
      "defaultMessage": "Add or remove products"
   },
   "breadCrumb/estore/catalog/addremovepreview": {
      "id": "breadCrumb/estore/catalog/addremovepreview",
      "defaultMessage": "Preview - Selected products to sell online"
   },
   "breadCrumb/estore/catalog/editsingleproduct/:id": {
      "id": "breadCrumb/estore/catalog/editsingleproduct/:id",
      "defaultMessage": "Edit Store Product"
   },
   "breadCrumb/estore/catalog/assignbulk": {
      "id": "breadCrumb/estore/catalog/assignbulk",
      "defaultMessage": "Bulk Operation"
   },
   "breadCrumb/estore/catalog/bulkpreview": {
      "id": "breadCrumb/estore/catalog/bulkpreview",
      "defaultMessage": "Bulk preview - Bulk Operation"
   },
   "breadCrumb/estore/banners": {
      "id": "breadCrumb/estore/banners",
      "defaultMessage": "Promo Ads"
   },
   "breadCrumb/estore/createbanner": {
      "id": "breadCrumb/estore/createbanner",
      "defaultMessage": "Create Banner"
   },
   "breadCrumb/estore/editbanner/:id": {
      "id": "breadCrumb/estore/editbanner/:id",
      "defaultMessage": "Edit Banner"
   },
   "breadCrumb/estore/legalpolicies": {
      "id": "breadCrumb/estore/legalpolicies",
      "defaultMessage": "Legal Policies"
   },
   "breadCrumb/estore/settings": {
      "id": "breadCrumb/estore/settings",
      "defaultMessage": "Settings"
   },
   "breadCrumb/estore/credits": { "id": "breadCrumb/estore/credits", "defaultMessage": "Credit" },
   "breadCrumb/reports/customerLedger": {
      "id": "breadCrumb/reports/customerLedger",
      "defaultMessage": "Khata",
      "lastCount": 1
   },
   "breadCrumb/reports/customersBalance": {
      "id": "breadCrumb/reports/customersBalance",
      "defaultMessage": "Customer Balance"
   },
   "breadCrumb/reports/customerLedger/1": {
      "id": "breadCrumb/reports/customerLedger/1",
      "defaultMessage": "Customer Ledger"
   },
   "breadCrumb/appointments": {
      "id": "breadCrumb/appointments",
      "defaultMessage": "Appointments",
      "lastCount": 1
   },
   "breadCrumb/appointments/1": {
      "id": "breadCrumb/appointments/1",
      "defaultMessage": "Appointments"
   },
   "breadCrumb/reports/muncipal/apilogs": {
      "id": "breadCrumb/reports/muncipal/apilogs",
      "defaultMessage": "Api Logs",
      "lastCount": 1
   },
   "breadCrumb/reports/allchallans": {
      "id": "breadCrumb/reports/allchallans",
      "defaultMessage": "Fine/Challan Reports",
      "lastCount": 1
   },
   "breadCrumb/reports/allchallans/1": {
      "id": "breadCrumb/reports/allchallans/1",
      "defaultMessage": "All Fine/Challans"
   },
   "breadCrumb/reports/challanWiseSalesReport": {
      "id": "breadCrumb/reports/challanWiseSalesReport",
      "defaultMessage": "Cumulative Collection"
   },
   "breadCrumb/reports/allchallans/:id": {
      "id": "breadCrumb/reports/allchallans/:id",
      "defaultMessage": "Details"
   },
   "breadCrumb/integrations/unicommerce/catalog": {
      "id": "breadCrumb/integrations/unicommerce/catalog",
      "defaultMessage": "Integrations",
      "lastCount": 2
   },
   "breadCrumb/integrations/unicommerce/catalog/1": {
      "id": "breadCrumb/integrations/unicommerce/catalog/1",
      "defaultMessage": "Unicommerce"
   },
   "breadCrumb/integrations/unicommerce/catalog/2": {
      "id": "breadCrumb/integrations/unicommerce/catalog/2",
      "defaultMessage": "Catalog"
   },
   "breadCrumb/integrations/unicommerce/settings": {
      "id": "breadCrumb/integrations/unicommerce/settings",
      "defaultMessage": "Settings"
   },
   "breadCrumb/integrations/unicommerce/UnicommerceApiLogs": {
      "id": "breadCrumb/integrations/unicommerce/UnicommerceApiLogs",
      "defaultMessage": "API Logs"
   },
   "breadCrumb/integrations/unicommerce/UnicommerceSaleOrderList": {
      "id": "breadCrumb/integrations/unicommerce/UnicommerceSaleOrderList",
      "defaultMessage": "Sales Orders"
   },
   "breadCrumb/integrations/easyecom/catalog": {
      "id": "breadCrumb/integrations/easyecom/catalog",
      "defaultMessage": "Easy Ecom",
      "lastCount": 1
   },
   "breadCrumb/integrations/easyecom/catalog/1": {
      "id": "breadCrumb/integrations/easyecom/catalog/1",
      "defaultMessage": "Catalog"
   },
   "breadCrumb/integrations/easyecom/settings": {
      "id": "breadCrumb/integrations/easyecom/settings",
      "defaultMessage": "Settings"
   },
   "breadCrumb/integrations/easyecom/ordertracker": {
      "id": "breadCrumb/integrations/easyecom/ordertracker",
      "defaultMessage": "Order Tracker"
   },
   "breadCrumb/integrations/easyecom/globallogs": {
      "id": "breadCrumb/integrations/easyecom/globallogs",
      "defaultMessage": "Global Logs"
   },
   "breadCrumb/integrations/easyrewards/apilogs": {
      "id": "breadCrumb/integrations/easyrewards/apilogs",
      "defaultMessage": "Easy Rewards",
      "lastCount": 1
   },
   "breadCrumb/integrations/easyrewards/apilogs/1": {
      "id": "breadCrumb/integrations/easyrewards/apilogs/1",
      "defaultMessage": "Api Logs"
   },
   "breadCrumb/integrations/easyrewards/customerLedger": {
      "id": "breadCrumb/integrations/easyrewards/customerLedger",
      "defaultMessage": "Customer Ledger"
   },
   "breadCrumb/integrations/easyrewards/wizard": {
      "id": "breadCrumb/integrations/easyrewards/wizard",
      "defaultMessage": "WorkFlow Wizard"
   },
   "breadCrumb/integrations/mloyal/ledger": {
      "id": "breadCrumb/integrations/mloyal/ledger",
      "defaultMessage": "Mloyal",
      "lastCount": 1
   },
   "breadCrumb/integrations/mloyal/ledger/1": {
      "id": "breadCrumb/integrations/mloyal/ledger/1",
      "defaultMessage": "Mloyal ledger"
   },
   "breadCrumb/integrations/navision" : {
      "id": "breadCrumb/integrations/navision",
      "defaultMessage": "Navision"
   },
   "breadCrumb/billing": { "id": "breadCrumb/billing", "defaultMessage": "Billing" },
   "breadCrumb/billing/settings": {
      "id": "breadCrumb/billing/settings",
      "defaultMessage": "Billing Settings"
   },
   "breadCrumb/billing/settings/deviceprintermapping": {
      "id": "breadCrumb/billing/settings/deviceprintermapping",
      "defaultMessage": "Device/Printer Mapping"
   },
   "breadCrumb/billing/onlineOrders": {
      "id": "breadCrumb/billing/onlineOrders",
      "defaultMessage": "List of Online Orders"
   },
   "breadCrumb/billing/payment": {
      "id": "breadCrumb/billing/payment",
      "defaultMessage": "Billing Payment"
   },
   "breadCrumb/billing/payment/success": {
      "id": "breadCrumb/billing/payment/success",
      "defaultMessage": "Billing Successful Payment"
   },
   "breadCrumb/billing/refundOrder": {
      "id": "breadCrumb/billing/refundOrder",
      "defaultMessage": "Billing Refund Order"
   },
   "breadCrumb/billing/refundOrderDetail": {
      "id": "breadCrumb/billing/refundOrderDetail",
      "defaultMessage": "Billing Refund Order Detail"
   },
   "breadCrumb/billing/refundOrderSummary": {
      "id": "breadCrumb/billing/refundOrderSummary",
      "defaultMessage": "Billing Refund Order Summary"
   },
   "breadCrumb/billing/refundOrder/success": {
      "id": "breadCrumb/billing/refundOrder/success",
      "defaultMessage": "Billing Refund Order Successful"
   },
   "breadCrumb/billing/PIList": {
      "id": "breadCrumb/billing/PIList",
      "defaultMessage": "List of Proforma Invoices"
   },
   "breadCrumb/billing/PIDetails": {
      "id": "breadCrumb/billing/PIDetails",
      "defaultMessage": "Proforma Invoice Detail"
   },
   "breadCrumb/kds": { "id": "breadCrumb/kds", "defaultMessage": "KDS" },
   "breadCrumb/kds/orders/:id": {
      "id": "breadCrumb/kds/orders/:id",
      "defaultMessage": "List Of Orders"
   },
   "breadCrumb/reports/stockMovementDetailViaLedger": {
      "id": "breadCrumb/reports/stockMovementDetailViaLedger",
      "defaultMessage": "Stock Movement Detail Via Ledger"
   },
   "breadCrumb/expense/expenseordersList": {
      "id": "breadCrumb/expense/expenseordersList",
      "defaultMessage": "Expense",
      "lastCount": 1
   },
   "breadCrumb/expense/expenseordersList/1": {
      "id": "breadCrumb/expense/expenseordersList/1",
      "defaultMessage": "Expense Management"
   },
   "breadCrumb/expense/createExpense" : {
      "id": "breadCrumb/expense/createExpense",
      "defaultMessage": "Create Expense"
   },
   "breadCrumb/expense/updateExpense/:id" : {
      "id": "breadCrumb/expense/updateExpense/:id",
      "defaultMessage": "Update Expense"
   },
   "breadCrumb/expense/expenseDetails": {
      "id": "breadCrumb/expense/expenseDetails",
      "defaultMessage": "Expense Detail"
   },
   "breadCrumb/expense/expenseorderList/:id": {
      "id": "breadCrumb/expense/expenseorderList/:id",
      "defaultMessage": "Expense Detail"
   },
   "breadCrumb/expense/productInExpense": {
      "id": "breadCrumb/expense/productInExpense",
      "defaultMessage": "Product In Expenses"
   },
   "breadCrumb/expense/expenseCategory": {
      "id": "breadCrumb/expense/expenseCategory",
      "defaultMessage": "Manage Category"
   },
   "breadCrumb/reports/systemchangelogs": {
      "id": "breadCrumb/reports/systemchangelogs",
      "defaultMessage": "System Change Logs",
   },
   "breadCrumb/setup/ListOfPrinters": {
      "id": "breadCrumb/setup/ListOfPrinters",
      "defaultMessage": "List Of Printers"
   },
   "breadCrumb/setup/ListOfPrinters/:id": {
      "id": "breadCrumb/setup/ListOfPrinters/:id",
      "defaultMessage": "Printer Product Mapping"
   },

   "breadCrumb/reservation/filtergroup": {
      "id": "breadCrumb/reservation/filtergroup",
      "defaultMessage": "Filter Group"
   },
   "breadCrumb/reservation/createfiltergroup": {
      "id": "breadCrumb/reservation/createfiltergroup",
      "defaultMessage": "Create Filter Group"
   },
   "breadCrumb/reservation/updatefiltergroup/:id": {
      "id": "breadCrumb/reservation/updatefiltergroup/:id",
      "defaultMessage": "Update Filter Group"
   },
   "breadCrumb/reservation/filter": {
      "id": "breadCrumb/reservation/filter",
      "defaultMessage": "Reservation Filters"
   },
   "breadCrumb/reservation/createfilter": {
      "id": "breadCrumb/reservation/createfilter",
      "defaultMessage": "Create Reservation Filters"
   },
   "breadCrumb/reservation/updatefilter/:id": {
      "id": "breadCrumb/reservation/updatefilter/:id",
      "defaultMessage": "Update Reservation Filter"
   },
   "breadCrumb/setup/stocktransferrule": {
      "id": "breadCrumb/setup/stocktransferrule",
      "defaultMessage": "Stock Transfer Rule"
   },
   "breadCrumb/setup/stocktransferrule/create": {
      "id": "breadCrumb/setup/stocktransferrule/create",
      "defaultMessage": "Create Stock Transfer Rule"
   },
   "breadCrumb/setup/stocktransferrule/edit/:id": {
      "id": "breadCrumb/setup/stocktransferrule/edit/:id",
      "defaultMessage": "Update Stock Transfer Rule"
   },
  
   
   breadCrumbNav: {
      home: {
         id: "app.breadCrumbNav.home",
         defaultMessage: "Home",
      },
      catalogDashboard: {
         id: "app.breadCrumbNav.catalogDashboard",
         defaultMessage: "Catalog Dashboard",
      },
      counterProductMap: {
         id: "app.breadCrumbNav.counterProductMap",
         defaultMessage: "Assign Products To Counter",
      },
      assign: {
         id: "app.breadCrumbNav.assign",
         defaultMessage: "Assign new products",
      },
      preview: {
         id: "app.breadCrumbNav.preview",
         defaultMessage: "Edit Products",
      },
      assignbulk: {
         id: "app.breadCrumbNav.assignbulk",
         defaultMessage: "Bulk Operation",
      },
      bulkpreview: {
         id: "app.breadCrumbNav.bulkpreview",
         defaultMessage: "Bulk Operation",
      },
      items: {
         id: "app.breadCrumbNav.items",
         defaultMessage: "Products",
      },
      createProduct: {
         id: "app.breadCrumbNav.createProduct",
         defaultMessage: "Create Product",
      },
      editProduct: {
         id: "app.breadCrumbNav.editProduct",
         defaultMessage: "Edit Product",
      },
      bulkcreateproducts: {
         id: "app.breadCrumbNav.bulkcreateproducts",
         defaultMessage: "Create Products",
      },
      bulkeditproducts: {
         id: "app.breadCrumbNav.bulkeditproducts",
         defaultMessage: "Edit Products",
      },
      combos: {
         id: "app.breadCrumbNav.combos",
         defaultMessage: "Combos",
      },
      createcombo: {
         id: "app.breadCrumbNav.createcombo",
         defaultMessage: "Create Combo",
      },
      editcombo: {
         id: "app.breadCrumbNav.editcombo",
         defaultMessage: "Edit Combo",
      },
      productstoremap: {
         id: "app.breadCrumbNav.productstoremap",
         defaultMessage: "Assign products to store",
      },
      categories: {
         id: "app.breadCrumbNav.categories",
         defaultMessage: "Category",
      },
      createcategory: {
         id: "app.breadCrumbNav.createcategory",
         defaultMessage: "Create Category",
      },
      editcategory: {
         id: "app.breadCrumbNav.editcategory",
         defaultMessage: "Edit Category",
      },
      bulkcreatecategory: {
         id: "app.breadCrumbNav.bulkcreatecategory",
         defaultMessage: "Create Categories",
      },
      bulkeditcategory: {
         id: "app.breadCrumbNav.bulkeditcategory",
         defaultMessage: "Edit Categories",
      },
      subcategories: {
         id: "app.breadCrumbNav.subcategories",
         defaultMessage: "Sub Category",
      },
      createsubcategory: {
         id: "app.breadCrumbNav.createsubcategory",
         defaultMessage: "Create Sub Category",
      },
      editsubcategory: {
         id: "app.breadCrumbNav.editsubcategory",
         defaultMessage: "Edit Sub Category",
      },
      bulkcreatesubcategory: {
         id: "app.breadCrumbNav.bulkcreatesubcategory",
         defaultMessage: "Create Sub Categories",
      },
      bulkeditsubcategory: {
         id: "app.breadCrumbNav.bulkeditsubcategory",
         defaultMessage: "Edit Sub Categories",
      },
      brands: {
         id: "app.breadCrumbNav.brands",
         defaultMessage: "Brand",
      },
      createbrand: {
         id: "app.breadCrumbNav.createbrand",
         defaultMessage: "Create Brand",
      },
      editbrand: {
         id: "app.breadCrumbNav.editbrand",
         defaultMessage: "Edit Brand",
      },
      bulkcreatebrand: {
         id: "app.breadCrumbNav.bulkcreatebrand",
         defaultMessage: "Create Brands",
      },
      bulkeditbrand: {
         id: "app.breadCrumbNav.bulkeditbrand",
         defaultMessage: "Edit Brands",
      },
      manufacturers: {
         id: "app.breadCrumbNav.manufacturers",
         defaultMessage: "Manufacturer",
      },
      createmanufacturer: {
         id: "app.breadCrumbNav.createmanufacturer",
         defaultMessage: "Create Manufacturer",
      },
      editmanufacturer: {
         id: "app.breadCrumbNav.editmanufacturer",
         defaultMessage: "Edit Manufacturer",
      },
      bulkmanagemanufacturer: {
         id: "app.breadCrumbNav.bulkmanagemanufacturer",
         defaultMessage: "Create Manufacturer",
      },
      bulkEditmanagemanufacturer: {
         id: "app.breadCrumbNav.bulkEditmanagemanufacturer",
         defaultMessage: "Edit Manufacturer",
      },
      bulkeditcreatemanufacturer: {
         id: "app.breadCrumbNav.bulkeditcreatemanufacturer",
         defaultMessage: "",
      },
      incomehead: {
         id: "app.breadCrumbNav.incomehead",
         defaultMessage: "Income Head",
      },
      createincomehead: {
         id: "app.breadCrumbNav.createincomehead",
         defaultMessage: "Create Income Head",
      },
      editincomehead: {
         id: "app.breadCrumbNav.editincomehead",
         defaultMessage: "Edit Income Head",
      },
      editassignincomehead: {
         id: "app.breadCrumbNav.editassignincomehead",
         defaultMessage: "Assign Income Head",
      },
      services: {
         id: "app.breadCrumbNav.services",
         defaultMessage: "Services",
      },
      createservice: {
         id: "app.breadCrumbNav.createservice",
         defaultMessage: "Create Service",
      },
      editservice: {
         id: "app.breadCrumbNav.editservice",
         defaultMessage: "Edit Service",
      },
      servicegroups: {
         id: "app.breadCrumbNav.servicegroups",
         defaultMessage: "Service Group",
      },
      createservicegroup: {
         id: "app.breadCrumbNav.createservicegroup",
         defaultMessage: "Create Service Group",
      },
      editservicegroup: {
         id: "app.breadCrumbNav.editservicegroup",
         defaultMessage: "Edit Service Group",
      },
      createbulkservicegroup: {
         id: "app.breadCrumbNav.createbulkservicegroup",
         defaultMessage: "Create Bulk Service Groups",
      },
      editbulkservicegroup: {
         id: "app.breadCrumbNav.editbulkservicegroup",
         defaultMessage: "Edit Bulk Service Groups",
      },
      modifiers: {
         id: "app.breadCrumbNav.modifiers",
         defaultMessage: "Modifiers",
      },
      createmodifiers: {
         id: "app.breadCrumbNav.createmodifiers",
         defaultMessage: "Create Modifier",
      },
      updatemodifiers: {
         id: "app.breadCrumbNav.updatemodifiers",
         defaultMessage: "Edit Modifier",
      },
      modifiersGroup: {
         id: "app.breadCrumbNav.modifiersGroup",
         defaultMessage: "Modifier Groups",
      },
      createModifiersGroup: {
         id: "app.breadCrumbNav.createModifiersGroup",
         defaultMessage: "Create Modifier Group",
      },
      assignModifiersGroup: {
         id: "app.breadCrumbNav.assignModifiersGroup",
         defaultMessage: "Assign Modifiers",
      },
      editModifiersGroup: {
         id: "app.breadCrumbNav.editModifiersGroup",
         defaultMessage: "Edit Modifier Group",
      },
      taxes: {
         id: "app.breadCrumbNav.taxes",
         defaultMessage: "Taxes",
      },
      createTax1: {
         id: "app.breadCrumbNav.createTax1",
         defaultMessage: "Create Tax",
      },
      createTax2: {
         id: "app.breadCrumbNav.createTax2",
         defaultMessage: "Create Tax",
      },
      editTax1: {
         id: "app.breadCrumbNav.editTax1",
         defaultMessage: "Edit Tax",
      },
      charges: {
         id: "app.breadCrumbNav.charges",
         defaultMessage: "Charges",
      },
      create: {
         id: "app.breadCrumbNav.create",
         defaultMessage: "Create Region",
      },
      editcharges: {
         id: "app.breadCrumbNav.editcharges",
         defaultMessage: "Edit Charges",
      },
      promotions: {
         id: "app.breadCrumbNav.promotions",
         defaultMessage: "Promotions",
      },
      editpromotions: {
         id: "app.breadCrumbNav.editpromotions",
         defaultMessage: "Edit Promotion",
      },
      EditCreatePromotionsPreview: {
         id: "app.breadCrumbNav.EditCreatePromotionsPreview",
         defaultMessage: "Edit Promotion Preview",
      },
      CreatePromotionsPreview: {
         id: "app.breadCrumbNav.CreatePromotionsPreview",
         defaultMessage: "Create Promotion Preview",
      },
      vouchers: {
         id: "app.breadCrumbNav.vouchers",
         defaultMessage: "Vouchers",
      },
      bulkcreatevouchers: {
         id: "app.breadCrumbNav.bulkcreatevouchers",
         defaultMessage: "Create Vouchers",
      },
      measurementunits: {
         id: "app.breadCrumbNav.measurementunits",
         defaultMessage: "Measurement Unit",
      },
      createunits: {
         id: "app.breadCrumbNav.createunits",
         defaultMessage: "Create Measurement Unit",
      },
      editmeasurementunit: {
         id: "app.breadCrumbNav.editmeasurementunit",
         defaultMessage: "Edit Measurement Unit",
      },
      productgroupsview: {
         id: "app.breadCrumbNav.productgroupsview",
         defaultMessage: "Products Groups",
      },
      productgroupcreate1: {
         id: "app.breadCrumbNav.productgroupcreate1",
         defaultMessage: "Create Product Group",
      },
      productgroupcreate2: {
         id: "app.breadCrumbNav.productgroupcreate2",
         defaultMessage: "Create Product Group",
      },
      productgroupedit: {
         id: "app.breadCrumbNav.productgroupedit",
         defaultMessage: "Edit Product Group",
      },
      memberships: {
         id: "app.breadCrumbNav.memberships",
         defaultMessage: "Memberships",
      },
      createmembership: {
         id: "app.breadCrumbNav.createmembership",
         defaultMessage: "Create Membership",
      },
      addproducts: {
         id: "app.breadCrumbNav.addproducts",
         defaultMessage: "Add Products",
      },
      bulkaddmembership: {
         id: "app.breadCrumbNav.bulkaddmembership",
         defaultMessage: "Add Bulk Products",
      },
      bulkeditmembership: {
         id: "app.breadCrumbNav.bulkeditmembership",
         defaultMessage: "Add Edit Products",
      },
      reviewmembership: {
         id: "app.breadCrumbNav.reviewmembership",
         defaultMessage: "Review",
      },
      editmemberships: {
         id: "app.breadCrumbNav.editmemberships",
         defaultMessage: "Edit Membership",
      },
      reservations: {
         id: "app.breadCrumbNav.reservations",
         defaultMessage: "Reservation",
      },
      edit: {
         id: "app.breadCrumbNav.edit",
         defaultMessage: "Edit System Attribute",
      },
      slots: {
         id: "app.breadCrumbNav.slots",
         defaultMessage: "Reservation Slots",
      },
      inventoryDashboard: {
         id: "app.breadCrumbNav.inventoryDashboard",
         defaultMessage: "Inventory Dashboard",
      },
      newallitems: {
         id: "app.breadCrumbNav.newallitems",
         defaultMessage: "Products List",
      },
      stockin: {
         id: "app.breadCrumbNav.stockin",
         defaultMessage: "Stock In",
      },
      stockpreview: {
         id: "app.breadCrumbNav.stockpreview",
         defaultMessage: "Stock Validation Preview",
      },
      stockinpreview: {
         id: "app.breadCrumbNav.stockinpreview",
         defaultMessage: "Stock In Preview",
      },
      newcart: {
         id: "app.breadCrumbNav.newcart",
         defaultMessage: "Cart",
      },
      stockout: {
         id: "app.breadCrumbNav.stockout",
         defaultMessage: "Stock Out",
      },
      stockoutpreview: {
         id: "app.breadCrumbNav.stockoutpreview",
         defaultMessage: "Stock Out Preview",
      },
      newallitems2: {
         id: "app.breadCrumbNav.newallitems2",
         defaultMessage: "Products List",
      },
      newcart2: {
         id: "app.breadCrumbNav.newcart2",
         defaultMessage: "Cart",
      },
      stockrequisition: {
         id: "app.breadCrumbNav.stockrequisition",
         defaultMessage: "Stock Requisition",
      },
      stockrequisitionpreview: {
         id: "app.breadCrumbNav.stockrequisitionpreview",
         defaultMessage: "Stock Requisition Preview",
      },
      newallitems1: {
         id: "app.breadCrumbNav.newallitems1",
         defaultMessage: "Products List",
      },
      newcart1: {
         id: "app.breadCrumbNav.newcart1",
         defaultMessage: "Cart",
      },
      stocktransfer: {
         id: "app.breadCrumbNav.stocktransfer",
         defaultMessage: "Stock Transfer",
      },
      stocktransferpreview: {
         id: "app.breadCrumbNav.stocktransferpreview",
         defaultMessage: "Stock Transfer Preview",
      },
      newallitems3: {
         id: "app.breadCrumbNav.newallitems3",
         defaultMessage: "Products List",
      },
      newcart3: {
         id: "app.breadCrumbNav.newcart3",
         defaultMessage: "Cart",
      },
      stockvalidation: {
         id: "app.breadCrumbNav.stockvalidation",
         defaultMessage: "Stock Validation",
      },
      reasonfordiscrepancy: {
         id: "app.breadCrumbNav.reasonfordiscrepancy",
         defaultMessage: "Discrepancy",
      },
      stockvalidationpreview: {
         id: "app.breadCrumbNav.stockvalidationpreview",
         defaultMessage: "Stock Validation Preview",
      },
      newcart4: {
         id: "app.breadCrumbNav.newcart4",
         defaultMessage: "Cart",
      },
      newallitems4: {
         id: "app.breadCrumbNav.newallitems4",
         defaultMessage: "Products List",
      },
      batches: {
         id: "app.breadCrumbNav.batches",
         defaultMessage: "Batches",
      },
      newcart6: {
         id: "app.breadCrumbNav.newcart6",
         defaultMessage: "Cart",
      },
      vendors: {
         id: "app.breadCrumbNav.vendors",
         defaultMessage: "Vendors",
      },
      vendorsource: {
         id: "app.breadCrumbNav.vendorsource",
         defaultMessage: "Vendor Source Mapping",
      },
      vendorproducts: {
         id: "app.breadCrumbNav.vendorproducts",
         defaultMessage: "Vendor Product Mapping",
      },
      createvendor: {
         id: "app.breadCrumbNav.createvendor",
         defaultMessage: "Create Vendors",
      },
      editvendor: {
         id: "app.breadCrumbNav.editvendor",
         defaultMessage: "Edit Vendors",
      },
      purchaseorder: {
         id: "app.breadCrumbNav.purchaseorder",
         defaultMessage: "Purchase Orders",
      },
      CreatePo: {
         id: "app.breadCrumbNav.CreatePo",
         defaultMessage: "Create Purchase Order",
      },
      editpo: {
         id: "app.breadCrumbNav.editpo",
         defaultMessage: "Edit Purchase Order",
      },
      previeworder: {
         id: "app.breadCrumbNav.previeworder",
         defaultMessage: "Preview Purchase Order",
      },
      po: {
         id: "app.breadCrumbNav.po",
         defaultMessage: "List of Purchase Orders",
      },
      vendorinvoice: {
         id: "app.breadCrumbNav.vendorinvoice",
         defaultMessage: "Vendor Invoices",
      },
      invoiceSettlement: {
         id: "app.breadCrumbNav.invoiceSettlement",
         defaultMessage: "Invoice Settlement",
      },
      customerDashboard: {
         id: "app.breadCrumbNav.customerDashboard",
         defaultMessage: "Customers Dashboard",
      },
      customers: {
         id: "app.breadCrumbNav.customers",
         defaultMessage: "List Of Customers",
      },
      bulkcreatecustomers: {
         id: "app.breadCrumbNav.bulkcreatecustomers",
         defaultMessage: "Create Customer",
      },
      bulkeditcustomers: {
         id: "app.breadCrumbNav.bulkeditcustomers",
         defaultMessage: "Bulk Edit Customer",
      },
      inactiveCustomerReport: {
         id: "app.breadCrumbNav.inactiveCustomerReport",
         defaultMessage: "Inactive Customers",
      },
      loyaltySettings: {
         id: "app.breadCrumbNav.loyaltySettings",
         defaultMessage: "Loyalty Settings",
      },
      customerCredit: {
         id: "app.breadCrumbNav.customerCredit",
         defaultMessage: "Customer Credit Sale",
      },
      creditInvoiceMgmt: {
         id: "app.breadCrumbNav.creditInvoiceMgmt",
         defaultMessage: "Credit Settlement",
      },
      creditNoteHistory: {
         id: "app.breadCrumbNav.creditNoteHistory",
         defaultMessage: "Credit Note History",
      },
      messageHistory: {
         id: "app.breadCrumbNav.messageHistory",
         defaultMessage: "Message History",
      },
      smsCredits: {
         id: "app.breadCrumbNav.smsCredits",
         defaultMessage: "SMS Credit",
      },
      customersSalesReport: {
         id: "app.breadCrumbNav.customersSalesReport",
         defaultMessage: "Customers Sales Report",
      },
      customersalesbulkcreate: {
         id: "app.breadCrumbNav.customersalesbulkcreate",
         defaultMessage: "Create Customer",
      },
      customersalesbulkedit: {
         id: "app.breadCrumbNav.customersalesbulkedit",
         defaultMessage: "Bulk Edit Customer",
      },
      marketing: {
         id: "app.breadCrumbNav.marketing",
         defaultMessage: "Marketing",
      },
      DSR: {
         id: "app.breadCrumbNav.DSR",
         defaultMessage: "Daily Sales",
      },
      "#": {
         id: "app.breadCrumbNav.#",
         defaultMessage: "Cash Management",
      },
      orders: {
         id: "app.breadCrumbNav.orders",
         defaultMessage: "List Of Orders",
      },
      orderReport: {
         id: "app.breadCrumbNav.orderReport",
         defaultMessage: "Order Details",
      },
      order: {
         id: "app.breadCrumbNav.order",
         defaultMessage: "Order Details",
      },
      dailyOrderCount: {
         id: "app.breadCrumbNav.dailyOrderCount",
         defaultMessage: "Daily Order Count",
      },
      productsInOrder: {
         id: "app.breadCrumbNav.productsInOrder",
         defaultMessage: "Product In Orders",
      },
      voidOrders: {
         id: "app.breadCrumbNav.voidOrders",
         defaultMessage: "List Of Void Orders",
      },
      OrderComboReport: {
         id: "app.breadCrumbNav.OrderComboReport",
         defaultMessage: "Order Combo Report",
      },
      ProductDiscountReport: {
         id: "app.breadCrumbNav.ProductDiscountReport",
         defaultMessage: "Product Discount Report",
      },
      discountExpenses: {
         id: "app.breadCrumbNav.discountExpenses",
         defaultMessage: "Discount Expenses",
      },
      discountedProducts: {
         id: "app.breadCrumbNav.discountedProducts",
         defaultMessage: "Discounted Products",
      },
      orderDiscountReport: {
         id: "app.breadCrumbNav.orderDiscountReport",
         defaultMessage: "Order Discount Report",
      },
      prodPurchaseOrderInfo: {
         id: "app.breadCrumbNav.prodPurchaseOrderInfo",
         defaultMessage: "Purchase Order Details",
      },
      prodPurchaseOrderDetailReport: {
         id: "app.breadCrumbNav.prodPurchaseOrderDetailReport",
         defaultMessage: "Purchase Order Details",
      },
      pi: {
         id: "app.breadCrumbNav.pi",
         defaultMessage: "List of PI",
      },
      piDetail: {
         id: "app.breadCrumbNav.piDetail",
         defaultMessage: "PI Detail",
      },
      productsInPI: {
         id: "app.breadCrumbNav.productsInPI",
         defaultMessage: "Product in PI",
      },
      listofexpenseorders: {
         id: "app.breadCrumbNav.listofexpenseorders",
         defaultMessage: "List of Expenses",
      },
      expenseorderdetailReport: {
         id: "app.breadCrumbNav.expenseorderdetailReport",
         defaultMessage: "Expense Detail",
      },
      expenseorderdetail: {
         id: "app.breadCrumbNav.expenseorderdetail",
         defaultMessage: "Expense Detail",
      },
      prodexpenseorderinfo: {
         id: "app.breadCrumbNav.prodexpenseorderinfo",
         defaultMessage: "Product In Expenses",
      },
      expensecategory: {
         id: "app.breadCrumbNav.expensecategory",
         defaultMessage: "Manage Category",
      },
      storeWiseSales: {
         id: "app.breadCrumbNav.storeWiseSales",
         defaultMessage: "Store Wise Sales",
      },
      storeProductSales: {
         id: "app.breadCrumbNav.storeProductSales",
         defaultMessage: "Store Wise Product Sales",
      },
      regionSales: {
         id: "app.breadCrumbNav.regionSales",
         defaultMessage: "Region Wise Sales",
      },
      TopProductsWiseSales: {
         id: "app.breadCrumbNav.TopProductsWiseSales",
         defaultMessage: "Product Wise Sales",
      },
      categorySales: {
         id: "app.breadCrumbNav.categorySales",
         defaultMessage: "Category Wise Sales",
      },
      SubCategoryWiseSalesReport: {
         id: "app.breadCrumbNav.SubCategoryWiseSalesReport",
         defaultMessage: "Sub Category Wise Sales",
      },
      brandSales: {
         id: "app.breadCrumbNav.brandSales",
         defaultMessage: "Brand Wise Sales",
      },
      BatchWiseSalesReport: {
         id: "app.breadCrumbNav.BatchWiseSalesReport",
         defaultMessage: "Batch Wise Sales",
      },
      incomeHeadSales: {
         id: "app.breadCrumbNav.incomeHeadSales",
         defaultMessage: "Income Head Wise Sales",
      },
      deviceSales: {
         id: "app.breadCrumbNav.deviceSales",
         defaultMessage: "Device Wise Sales",
      },
      customerSales: {
         id: "app.breadCrumbNav.customerSales",
         defaultMessage: "Customer Wise Sales",
      },
      employeeSalesSummary: {
         id: "app.breadCrumbNav.employeeSalesSummary",
         defaultMessage: "Employee Wise Sales",
      },
      StoreHourWiseSales: {
         id: "app.breadCrumbNav.StoreHourWiseSales",
         defaultMessage: "Store Hourly Sales",
      },
      DPB: {
         id: "app.breadCrumbNav.DPB",
         defaultMessage: "Daily Payment Breakup",
      },
      orderPaymentBreakup: {
         id: "app.breadCrumbNav.orderPaymentBreakup",
         defaultMessage: "Order wise Payment Breakup",
      },
      orderTaxBreakup: {
         id: "app.breadCrumbNav.orderTaxBreakup",
         defaultMessage: "Order Wise Tax Breakup",
      },
      productTaxBreakup: {
         id: "app.breadCrumbNav.productTaxBreakup",
         defaultMessage: "Product-wise Tax Breakup",
      },
      HsnSacWiseTaxBreakupReport: {
         id: "app.breadCrumbNav.HsnSacWiseTaxBreakupReport",
         defaultMessage: "HSN/SAC Wise Tax Breakup ",
      },
      OrderPaymentHistoryReport: {
         id: "app.breadCrumbNav.OrderPaymentHistoryReport",
         defaultMessage: "Order Payment History",
      },
      SalesTransactionDetail: {
         id: "app.breadCrumbNav.SalesTransactionDetail",
         defaultMessage: "Sales Transaction Tracker",
      },
      ProductTransactionTracker: {
         id: "app.breadCrumbNav.ProductTransactionTracker",
         defaultMessage: "Product Transaction Tracker",
      },
      stockLevel: {
         id: "app.breadCrumbNav.stockLevel",
         defaultMessage: "Stock Level",
      },
      storeWiseStockLevel: {
         id: "app.breadCrumbNav.storeWiseStockLevel",
         defaultMessage: "Store Wise Stock Level",
      },
      productGroup: {
         id: "app.breadCrumbNav.productGroup",
         defaultMessage: "Product Group Stock Level",
      },
      stockTransactionHistory: {
         id: "app.breadCrumbNav.stockTransactionHistory",
         defaultMessage: "Stock Operations",
      },
      stockRequisitionHistory: {
         id: "app.breadCrumbNav.stockRequisitionHistory",
         defaultMessage: "Stock Requisition",
      },
      stockMovement: {
         id: "app.breadCrumbNav.stockMovement",
         defaultMessage: "Stock Movement",
      },
      prodStockMovement: {
         id: "app.breadCrumbNav.prodStockMovement",
         defaultMessage: "Stock Movement Detail",
      },
      StockRequestAnalysis: {
         id: "app.breadCrumbNav.StockRequestAnalysis",
         defaultMessage: "Stock Request Bulk",
      },
      UnfullfilledStockRequests: {
         id: "app.breadCrumbNav.UnfullfilledStockRequests",
         defaultMessage: "Unfullfilled Stock Requests",
      },
      ProductAgeingReport: {
         id: "app.breadCrumbNav.ProductAgeingReport",
         defaultMessage: "Product Ageing Report",
      },
      stockCostMargin: {
         id: "app.breadCrumbNav.stockCostMargin",
         defaultMessage: "Profit Margin",
      },
      vendorTaxInput: {
         id: "app.breadCrumbNav.vendorTaxInput",
         defaultMessage: "Vendor Tax Input",
      },
      lowStockProducts: {
         id: "app.breadCrumbNav.lowStockProducts",
         defaultMessage: "Low Stock Products",
      },
      createStockReport: {
         id: "app.breadCrumbNav.createStockReport",
         defaultMessage: "Create Stock Request",
      },
      previewStockReport: {
         id: "app.breadCrumbNav.previewStockReport",
         defaultMessage: "Preview Stock Request",
      },
      stockAuditSummary: {
         id: "app.breadCrumbNav.stockAuditSummary",
         defaultMessage: "Stock Audit Summary",
      },
      stockAuditDetail: {
         id: "app.breadCrumbNav.stockAuditDetail",
         defaultMessage: "Stock Audit Detail",
      },
      stockAuditAnalysis: {
         id: "app.breadCrumbNav.stockAuditAnalysis",
         defaultMessage: "Stock Audit Analysis",
      },
      modifierReport: {
         id: "app.breadCrumbNav.modifierReport",
         defaultMessage: "Modifiers in order",
      },
      attendanceReport: {
         id: "app.breadCrumbNav.attendanceReport",
         defaultMessage: "Attendance Report",
      },
      infoDaysReport: {
         id: "app.breadCrumbNav.infoDaysReport",
         defaultMessage: "Days Info Report",
      },
      BatchInfoReport: {
         id: "app.breadCrumbNav.BatchInfoReport",
         defaultMessage: "Batch Info Report",
      },
      membershipPurchaseHistory: {
         id: "app.breadCrumbNav.membershipPurchaseHistory",
         defaultMessage: "Membership Purchase History",
      },
      membershipTracker: {
         id: "app.breadCrumbNav.membershipTracker",
         defaultMessage: "Membership Tracker",
      },
      cashManageMentHistory: {
         id: "app.breadCrumbNav.cashManageMentHistory",
         defaultMessage: "Cash Management History",
      },
      chainprofile2: {
         id: "app.breadCrumbNav.chainprofile2",
         defaultMessage: "Chain Profile",
      },
      storeslist: {
         id: "app.breadCrumbNav.storeslist",
         defaultMessage: "Stores",
      },
      store: {
         id: "app.breadCrumbNav.store",
         defaultMessage: "View Store",
      },
      warehouse: {
         id: "app.breadCrumbNav.warehouse",
         defaultMessage: "Warehouses",
      },
      createwarehouse: {
         id: "app.breadCrumbNav.createwarehouse",
         defaultMessage: "Create Warehouse",
      },
      editwarehouse: {
         id: "app.breadCrumbNav.editwarehouse",
         defaultMessage: "Edit Warehouse",
      },
      viewwarehouse: {
         id: "app.breadCrumbNav.viewwarehouse",
         defaultMessage: "View Warehouse",
      },
      counterList: {
         id: "app.breadCrumbNav.counterList",
         defaultMessage: "Counter List",
      },
      counter: {
         id: "app.breadCrumbNav.counter",
         defaultMessage: "Edit Counter",
      },
      regions: {
         id: "app.breadCrumbNav.regions",
         defaultMessage: "Regions",
      },
      regionStores: {
         id: "app.breadCrumbNav.regionStores",
         defaultMessage: "View Store",
      },
      attributes: {
         id: "app.breadCrumbNav.attributes",
         defaultMessage: "Attributes",
      },
      createattribute: {
         id: "app.breadCrumbNav.createattribute",
         defaultMessage: "Create Attribute",
      },
      editattribute: {
         id: "app.breadCrumbNav.editattribute",
         defaultMessage: "Edit Attribute",
      },
      systemattributes: {
         id: "app.breadCrumbNav.systemattributes",
         defaultMessage: "System Attributes",
      },
      remarks: {
         id: "app.breadCrumbNav.remarks",
         defaultMessage: "Remarks",
      },
      editremark: {
         id: "app.breadCrumbNav.editremark",
         defaultMessage: "Edit Remark",
      },
      createremark: {
         id: "app.breadCrumbNav.createremark",
         defaultMessage: "Create Remark",
      },
      floors: {
         id: "app.breadCrumbNav.floors",
         defaultMessage: "Floors",
      },
      createfloor: {
         id: "app.breadCrumbNav.createfloor",
         defaultMessage: "Create Floor",
      },
      editfloor: {
         id: "app.breadCrumbNav.editfloor",
         defaultMessage: "Edit Floor",
      },
      rooms: {
         id: "app.breadCrumbNav.rooms",
         defaultMessage: "Rooms",
      },
      createroom: {
         id: "app.breadCrumbNav.createroom",
         defaultMessage: "Create Rooms",
      },
      editroom: {
         id: "app.breadCrumbNav.editroom",
         defaultMessage: "Edit Rooms",
      },
      tables: {
         id: "app.breadCrumbNav.tables",
         defaultMessage: "Tables",
      },
      createtable: {
         id: "app.breadCrumbNav.createtable",
         defaultMessage: "Create Tables",
      },
      edittable: {
         id: "app.breadCrumbNav.edittable",
         defaultMessage: "Edit Tables",
      },
      customizedreceiptprint: {
         id: "app.breadCrumbNav.customizedreceiptprint",
         defaultMessage: "Customize Receipt Print",
      },
      appsettings: {
         id: "app.breadCrumbNav.appsettings",
         defaultMessage: "Application Device Settings",
      },
      device: {
         id: "app.breadCrumbNav.device",
         defaultMessage: "Device",
      },
      deviceSyncLogs: {
         id: "app.breadCrumbNav.deviceSyncLogs",
         defaultMessage: "Device Sync Logs",
      },
      softwarelicenses: {
         id: "app.breadCrumbNav.softwarelicenses",
         defaultMessage: "Software License",
      },
      softwareLicensesPayment: {
         id: "app.breadCrumbNav.softwareLicensesPayment",
         defaultMessage: "Payment Software License",
      },
      confirm: {
         id: "app.breadCrumbNav.confirm",
         defaultMessage: "License Renewal Confirmation",
      },
      staff: {
         id: "app.breadCrumbNav.staff",
         defaultMessage: "Staff Management",
      },
      createuser: {
         id: "app.breadCrumbNav.createuser",
         defaultMessage: "Create Staff",
      },
      edituser: {
         id: "app.breadCrumbNav.edituser",
         defaultMessage: "Edit Staff",
      },
      support: {
         id: "app.breadCrumbNav.support",
         defaultMessage: "Support",
      },
      settings: {
         id: "app.breadCrumbNav.settings",
         defaultMessage: "Settings",
      },
      appointments: {
         id: "app.breadCrumbNav.appointments",
         defaultMessage: "Appointments",
      },
      allchallans: {
         id: "app.breadCrumbNav.allchallans",
         defaultMessage: "Details",
      },
      challanWiseSalesReport: {
         id: "app.breadCrumbNav.challanWiseSalesReport",
         defaultMessage: "Cumulative Collection",
      },
      dontForgotToAddMeBuddy: {
         id: "app.breadCrumbNav.dontForgotToAddMeBuddy",
         defaultMessage: "Missing Descriptor",
      },
      whatsappCredits: {
         id: "app.breadCrumbNav.whatsappCredits",
         defaultMessage: "WhatsApp Credit",
      },
      "createproductgroup-bulk": {
         id: "app.breadCrumbNav.createproductgroup-bulk",
         defaultMessage: "Create Product Group Bulk",
      },
      "preview-bulk": {
         id: "app.breadCrumbNav.preview-bulk",
         defaultMessage: "Create Product Group Bulk Preview",
      },
      printbarcode: {
         id: "app.breadCrumbNav.printbarcode",
         defaultMessage: "Print Barcode",
      },
   },
   purchaseModule: {
      vendors: {
         landingPage: {
            vendor: {
               id: "app.purchaseModule.vendors.landingPage.vendor",
               defaultMessage: "Vendors",
            },
            vendorDesc: {
               id: "app.purchaseModule.vendors.landingPage.vendorDesc",
               defaultMessage: "Descriptive Text",
            },
            createVendor: {
               id: "app.purchaseModule.vendors.landingPage.createVendor",
               defaultMessage: "Create Vendor",
            },
            assignSource: {
               id: "app.purchaseModule.vendors.landingPage.assignSource",
               defaultMessage: "Assign Source",
            },
            assignproducts: {
               id: "app.purchaseModule.vendors.landingPage.assignproducts",
               defaultMessage: "Assign Products",
            },
            sureToDelete: {
               id: "app.purchaseModule.vendors.landingPage.sureToDelete",
               defaultMessage: "Are you sure you want to delete this vendor?",
            },
         },
         createVendor: {
            createVendor: {
               id: "app.purchaseModule.vendors.createVendor.createVendor",
               defaultMessage: "Create Vendor",
            },
            createVendorDesc: {
               id: "app.purchaseModule.vendors.createVendor.createVendorDesc",
               defaultMessage: "Descriptive Text",
            },
            vendorName: {
               id: "app.purchaseModule.vendors.createVendor.vendorName",
               defaultMessage: "Vendor Name",
            },
            vendorCompany: {
               id: "app.purchaseModule.vendors.createVendor.vendorCompany",
               defaultMessage: "Vendor Company",
            },
            vendorShortCode: {
               id: "app.purchaseModule.vendors.createVendor.vendorShortCode",
               defaultMessage: "Vendor Short Code",
            },
            vendorMargin: {
               id: "app.purchaseModule.vendors.createVendor.vendorMargin",
               defaultMessage: "Vendor Margin(%)",
            },
            typeCharToGetCityOpt: {
               id: "app.purchaseModule.vendors.createVendor.typeCharToGetCityOpt",
               defaultMessage: "Just type character to get the city options",
            },
            vendorNameisMandatory: {
               id: "app.purchaseModule.vendors.createVendor.vendorNameisMandatory",
               defaultMessage: "Vendor Name is Mandatory",
            },
            phoneNumberisMandatory: {
               id: "app.purchaseModule.vendors.createVendor.phoneNumberisMandatory",
               defaultMessage: "Phone number is mandatory",
            },
            pinCodeInvalid: {
               id: "app.purchaseModule.vendors.createVendor.pinCodeInvalid",
               defaultMessage: "Please enter valid pincode",
            },
            vendorMarginErr: {
               id: "app.purchaseModule.vendors.createVendor.vendorMarginErr",
               defaultMessage: "Please enter non-negative number",
            },
         },
         editVendor: {
            editVendor: {
               id: "app.purchaseModule.vendors.editVendor.editVendor",
               defaultMessage: "Edit Vendor",
            },
            editVendorDesc: {
               id: "app.purchaseModule.vendors.editVendor.editVendorDesc",
               defaultMessage: "Descriptive Text",
            },
         },
         assignSource: {
            assignSourceDesc: {
               id: "app.purchaseModule.vendors.assignSource.assignSourceDesc",
               defaultMessage: "Descriptive Text",
            },
         },
         assignProducts: {
            assignProductsDesc: {
               id: "app.purchaseModule.vendors.assignProducts.assignProductsDesc",
               defaultMessage: "Descriptive Text",
            },
            marginRule: {
               id: "app.purchaseModule.vendors.assignProducts.marginRule",
               defaultMessage: "Margin Rule",
            },
            marginValue: {
               id: "app.purchaseModule.vendors.assignProducts.marginValue",
               defaultMessage: "Margin Value (%)",
            },
            defaultPrice: {
               id: "app.purchaseModule.vendors.assignProducts.defaultPrice",
               defaultMessage: "Default Price",
            },
         },
      },
      po: {
         purchseOrderTitle: {
            id: "app.purchaseModule.po.purchseOrderTitle",
            defaultMessage: "Purchase Order",
         },
         purchseOrderDesc: {
            id: "app.purchaseModule.po.purchseOrderDesc",
            defaultMessage: "Purchase Order Description",
         },
         createPoUsingRequisition: {
            id: "app.purchaseModule.po.createPoUsingRequisition",
            defaultMessage: "Create PO Using Requisition",
         },
         createPoUsingStockRequisitionID: {
            id: "app.purchaseModule.po.createPoUsingRequisitionID",
            defaultMessage: "Create PO Using Stock Requisition ID",
         },
         createPO: {
            id: "app.purchaseModule.po.createPO",
            defaultMessage: "Create Purchase Order",
         },
         returnPO: {
            id: "app.purchaseModule.po.returnPO",
            defaultMessage: "Return Purchase Order",
         },
         sureReturnPO: {
            id: "app.purchaseModule.po.sureReturnPO",
            defaultMessage: "Are you sure you want to Return this purchase Order?",
         },
         removeAllTaxes: {
            id: "app.purchaseModule.po.removeAllTaxes",
            defaultMessage: "Remove All Taxes",
         },
         pleaseSelectDestination: {
            id: "app.purchaseModule.po.pleaseSelectDestination",
            defaultMessage: "Please select a destination!",
         },
         pleaseSelectVendor: {
            id: "app.purchaseModule.po.pleaseSelectVendor",
            defaultMessage: "Please select a vendor!",
         },
         invalidVendorState: {
            id: "app.purchaseModule.po.invalidVendorState",
            defaultMessage: "Invalid state found for vendor!",
         },
         notesForPo: {
            id: "app.purchaseModule.po.notesForPo",
            defaultMessage: "Notes for purchase Order.",
         },
         tncForPo: {
            id: "app.purchaseModule.po.tncForPo",
            defaultMessage: "Terms and Conditions for Purchase Order",
         },
         sureChangeDesinationTitle: {
            id: "app.purchaseModule.po.sureChangeDesinationTitle",
            defaultMessage: "Are you sure you want to change destination",
         },
         sureChangeDesinationDesc: {
            id: "app.purchaseModule.po.sureChangeDesinationDesc",
            defaultMessage:
               "This will unselect all your selected products on product selection screen",
         },
         setItem: {
            id: "app.purchaseModule.po.setItem",
            defaultMessage: "Set Item",
         },
         totalItems: {
            id: "app.purchaseModule.po.totalItems",
            defaultMessage: "Total Items :",
         },
         polist: {
            purchaseOrderID: {
               id: "app.purchaseModule.po.polist.purchaseOrderID",
               defaultMessage: "Purchase Order ID",
            },
            destinationName: {
               id: "app.purchaseModule.po.polist.destinationName",
               defaultMessage: "Destination Name",
            },
            destinationType: {
               id: "app.purchaseModule.po.polist.destinationType",
               defaultMessage: "Destination Type",
            },
            invoiceDate: {
               id: "app.purchaseModule.po.polist.invoiceDate",
               defaultMessage: "Invoice Date",
            },
            invoiceTime: {
               id: "app.purchaseModule.po.polist.invoiceTime",
               defaultMessage: "Invoice Time",
            },
            fullfillmentStatus: {
               id: "app.purchaseModule.po.polist.fullfillmentStatus",
               defaultMessage: "Fullfillment Status",
            },
         },
         createpo: {
            createPoTitle: {
               id: "app.purchaseModule.po.createpo.createPoTitle",
               defaultMessage: "Create Purchase Order",
            },
            createPoTitleDesc: {
               id: "app.purchaseModule.po.createpo.createPoTitleDesc",
               defaultMessage: "Description of the create purchase is to mentioned here",
            },
            addProductTitle: {
               id: "app.purchaseModule.po.createpo.addProductTitle",
               defaultMessage: "Create Purchase Order",
            },
            addProductTitleDesc: {
               id: "app.purchaseModule.po.createpo.addProductTitleDesc",
               defaultMessage: "Select desired products & proceed to create purchase order",
            },
            previewPoTitle: {
               id: "app.purchaseModule.po.createpo.previewPoTitle",
               defaultMessage: "Preview Purchase Order",
            },
            previewPoTitleDesc: {
               id: "app.purchaseModule.po.createpo.previewPoTitleDesc",
               defaultMessage: "Description of the preview Po is to mentioned herec.",
            },
         },
         editpo: {
            editPoTitle: {
               id: "app.purchaseModule.po.editpo.editPoTitle",
               defaultMessage: "Edit Purchase Order",
            },
            editPoTitleDesc: {
               id: "app.purchaseModule.po.editpo.editPoTitleDesc",
               defaultMessage: "Description of the edit purchase is to mentioned here",
            },
            addProductTitle: {
               id: "app.purchaseModule.po.editpo.addProductTitle",
               defaultMessage: "Edit Purchase Order",
            },
            addProductTitleDesc: {
               id: "app.purchaseModule.po.editpo.addProductTitleDesc",
               defaultMessage: "Select desired products & proceed to edit purchase order",
            },
            previewPoTitle: {
               id: "app.purchaseModule.po.editpo.previewPoTitle",
               defaultMessage: "Preview Purchase Order",
            },
            previewPoTitleDesc: {
               id: "app.purchaseModule.po.editpo.previewPoTitleDesc",
               defaultMessage: "Description of the preview Po is to mentioned here.",
            },
         },
      },
      vb: {
         vendorBillTitle: {
            id: "app.purchaseModule.vb.vendorBillTitle",
            defaultMessage: "Vendor Bill",
         },
         vendorBillDesc: {
            id: "app.purchaseModule.vb.vendorBillDesc",
            defaultMessage: "Vendor Bill description will be updated later.",
         },
         creteVendorBill: {
            id: "app.purchaseModule.vb.creteVendorBill",
            defaultMessage: "Create Vendor Bill",
         },
         createNewBill: {
            id: "app.purchaseModule.vb.createNewBill",
            defaultMessage: "Create New Bill",
         },
         stockInGrn: {
            id: "app.purchaseModule.vb.stockInGrn",
            defaultMessage: "Stock In (GRN)",
         },
         vendorBillID: {
            id: "app.purchaseModule.vb.vendorBillID",
            defaultMessage: "Vendor Bill ID",
         },
         plsEnterValidTransctionID: {
            id: "app.purchaseModule.vb.plsEnterValidTransctionID",
            defaultMessage: "Please enter a valid Stock In transaction ID!",
         },
         createBill: {
            title: {
               id: "app.purchaseModule.vb.createBill.title",
               defaultMessage: "Create Vendor Bill",
            },
            desc: {
               id: "app.purchaseModule.vb.createBill.desc",
               defaultMessage: "Description of the Vendor Bill is to mentioned here.",
            },
            notesForCreateBill: {
               id: "app.purchaseModule.vb.createBill.notesForCreateBill",
               defaultMessage: " Notes for vendor Bill.",
            },
            termsAndConditionsForCreateBill: {
               id: "app.purchaseModule.vb.createBill.termsAndConditionsForCreateBill",
               defaultMessage: "Terms and condition for vendor Bill.",
            },
            pleaseSelectDestination: {
               id: "app.purchaseModule.vb.createBill.pleaseSelectDestination",
               defaultMessage: "Please select a destination!",
            },
            pleaseSelectVendor: {
               id: "app.purchaseModule.vb.createBill.pleaseSelectVendor",
               defaultMessage: "Please select a vendor!",
            },
            addProductsTitle: {
               id: "app.purchaseModule.vb.createBill.addProductsTitle",
               defaultMessage: "Vendor Bill",
            },
            addProductsDesc: {
               id: "app.purchaseModule.vb.createBill.addProductsDesc",
               defaultMessage: "Select desired products & proceed to create vendor Bill.",
            },
            previewTitle: {
               id: "app.purchaseModule.vb.createBill.previewTitle",
               defaultMessage: "Preview Vendor Bill",
            },
            previewDesc: {
               id: "app.purchaseModule.vb.createBill.previewDesc",
               defaultMessage: "Description of the vendor Bill is to mentioned here.",
            },
            priceIncludingTaxes: {
               id: "app.purchaseModule.vb.createBill.priceIncludingTaxes",
               defaultMessage: "Price Including Taxes",
            },
         },
      },
      ub: {
         title: {
            id: "app.purchaseModule.ub.title",
            defaultMessage: "Unpaid Vendor Bill",
         },
         desc: {
            id: "app.purchaseModule.ub.desc",
            defaultMessage: "Unpaid Vendor Bill description will be updated later.",
         },
         selectMethodAndPay: {
            id: "app.purchaseModule.ub.selectMethodAndPay",
            defaultMessage: "Select Payment Method and Save Details.",
         },
         billDate: {
            id: "app.purchaseModule.ub.billDate",
            defaultMessage: "Bill Date",
         },
         billTime: {
            id: "app.purchaseModule.ub.billTime",
            defaultMessage: "Bill Time",
         }
      },
      vi: {
         vendorInvoiceTitle: {
            id: "app.purchaseModule.vi.vendorInvoiceTitle",
            defaultMessage: "List of all invoices",
         },
         vendorInvoiceDesc: {
            id: "app.purchaseModule.vi.vendorInvoiceDesc",
            defaultMessage: "List of all invoices",
         },
         invoicePaymentList: {
            id: "app.purchaseModule.vi.invoicePaymentList",
            defaultMessage: "Invoice Payment List",
         },
         invoicePaymentDetails: {
            id: "app.purchaseModule.vi.invoicePaymentDetails",
            defaultMessage: "Invoice Payment Details",
         },
         createVendorInvoice: {
            id: "app.purchaseModule.vi.createVendorInvoice",
            defaultMessage: "Create Vendor Invoices",
         },
         editVendorInvoice: {
            id: "app.purchaseModule.vi.editVendorInvoice",
            defaultMessage: "Edit Vendor Invoices",
         },
         pleaseFillamount: {
            id: "app.purchaseModule.vi.pleaseFillamount",
            defaultMessage: "Please fill Amount",
         },
         amountLessThan9Digit: {
            id: "app.purchaseModule.vi.amountLessThan9Digit",
            defaultMessage: "Amount should conatin less than 9 digits",
         },
         pleaseSelectDate: {
            id: "app.purchaseModule.vi.pleaseSelectDate",
            defaultMessage: "Please select Date!",
         },
         invoiceUpdated: {
            id: "app.purchaseModule.vi.invoiceUpdated",
            defaultMessage: "Invoice Updated Successfully",
         },
         errorUpdating: {
            id: "app.purchaseModule.vi.errorUpdating",
            defaultMessage: "Error While Editing Invoice",
         },
         vendorInvoiceTotalKeyword: {
            id: "app.purchaseModule.vi.vendorInvoiceTotalKeyword",
            defaultMessage: "Vendor Invoices(s)",
         },
         vendorInvoiceMakePaymentErrToast: {
            id: "app.purchaseModule.vi.vendorInvoiceMakePaymentErrToast",
            defaultMessage: "Payment amount cannot be greater than amount due",
         },

         vendorInvoicesColumns: {
            pOID: {
               id: "app.purchaseModule.vi.vendorInvoicesColumns.pOID",
               defaultMessage: "PO ID",
            },
            invoiceCreationDate: {
               id: "app.purchaseModule.vi.vendorInvoicesColumns.invoiceCreationDate",
               defaultMessage: "Invoice Creation Date",
            },
            dueDate: {
               id: "app.purchaseModule.vi.vendorInvoicesColumns.dueDate",
               defaultMessage: "Due Date",
            },
            createdby: {
               id: "app.purchaseModule.vi.vendorInvoicesColumns.createdby",
               defaultMessage: "Created by",
            },
         },

         vendorInvoice: {
            id: "app.purchaseModule.vi.vendorInvoice",
            defaultMessage: "Home",
         },
         vendorBillDetail:{
            title: {
               id: "app.purchaseModule.vi.vendorBillDetail.title",
               defaultMessage: "Vendor Bill Details",
            },
            desc: {
               id: "app.purchaseModule.vi.vendorBillDetail.desc",
               defaultMessage: "Get the summary and products details of Vendor Bill",
            },
            basicInfoDesc: {
               id: "app.purchaseModule.vi.vendorBillDetail.basicInfoDesc",
               defaultMessage: "Enter Vendor Bill ID to generate Vendor Bill Details.",
            },
            vendorBillSummary:{
               id: "app.purchaseModule.vi.vendorBillDetail.vendorBillSummary",
               defaultMessage: "Vendor Bill Summary",
            },
            vendorBillDetails:{
               id: "app.purchaseModule.vi.vendorBillDetail.vendorBillDetails",
               defaultMessage: "Vendor Bill Details",
            }
            

         }
      },
      is: {
         invoiceSettlementTitle: {
            id: "app.purchaseModule.is.invoiceSettlementTitle",
            defaultMessage: "Vendor Invoice Credit Settlement",
         },
         invoiceSettlementDesc: {
            id: "app.purchaseModule.is.invoiceSettlementDesc",
            defaultMessage:
               "Detail report for the vendor invoice credit settlement according to vendor and invoice status.",
         },
         paymentDetails: {
            id: "app.purchaseModule.is.paymentDetails",
            defaultMessage: "Payment Details",
         },
         viewPaymentDetails: {
            id: "app.purchaseModule.is.viewPaymentDetails",
            defaultMessage: "View Payment Details",
         },
         invoicePaymentDetails: {
            id: "app.purchaseModule.is.invoicePaymentDetails",
            defaultMessage: "Invoice Payment Details",
         },
      },
   },
   billing: {
      common: {
         deletePI: {
            id: "app.billing.common.deletePI",
            defaultMessage: "Are you sure you want to delete the Proforma Invoice?",
         },
         changeBack: {
            id: "app.billing.common.changeBack",
            defaultMessage: "Change Back:",
         },
         noLocation: {
            id: "app.billing.common.noLocation",
            defaultMessage: "No Location Found.",
         },
         stockLevelQuantity: {
            id: "app.billing.common.stockLevelQuantity",
            defaultMessage: "The Quantity you selected is greater than the available stock level.",
         },
         noProd: {
            id: "app.billing.common.noProd",
            defaultMessage: "No Product Found.",
         },
         quantityCheck: {
            id: "app.billing.common.quantityCheck",
            defaultMessage: "Quantity cannot be negative or zero.",
         },
         emptyCart: {
            id: "app.billing.common.emptyCart",
            defaultMessage: "Please add some items to the cart.",
         },
         addCust: {
            id: "app.billing.common.addCust",
            defaultMessage: "Please add a customer.",
         },
         prodMaxLimit: {
            id: "app.billing.common.prodMaxLimit",
            defaultMessage: "Number of items exceed the maximum limit.",
         },
         enableOnlineOrder: {
            id: "app.billing.common.enableOnlineOrder",
            defaultMessage: "Please enable online ordering for the store.",
         },
         somethingWentWrong: {
            id: "app.billing.common.somethingWentWrong",
            defaultMessage: "Something went wrong.",
         },
         totalPayableAmount: {
            id: "app.billing.common.totalPayableAmount",
            defaultMessage: "Total Payable Amount",
         },
         paymentCollected: {
            id: "app.billing.common.paymentCollected",
            defaultMessage: "Payment Collected",
         },
         creditNoteToUse: {
            id: "app.billing.common.creditNoteToUse",
            defaultMessage: "Please select a credit note to use.",
         },
         creditAmountErr: {
            id: "app.billing.common.creditAmountErr",
            defaultMessage: "Credit Note amount cannot be greater than the payable amount.",
         },
         OTPVerfied: {
            id: "app.billing.common.OTPVerfied",
            defaultMessage: "OTP Verified.",
         },
         voucherCodeErr: {
            id: "app.billing.common.voucherCodeErr",
            defaultMessage: "Voucher Code cannot be empty.",
         },
         collectedAmountErr: {
            id: "app.billing.common.collectedAmountErr",
            defaultMessage: "Collected amount cannot be zero.",
         },
         collectedAmountDueErr: {
            id: "app.billing.common.collectedAmountDueErr",
            defaultMessage: "Collected amount cannot be greater than the due amount.",
         },
         invalidVoucher: {
            id: "app.billing.common.invalidVoucher",
            defaultMessage: "Invalid Voucher Code.",
         },
         dueAmountErr: {
            id: "app.billing.common.dueAmountErr",
            defaultMessage: "Due amount is already 0.",
         },
         noMobileNumber: {
            id: "app.billing.common.noMobileNumber",
            defaultMessage: "No mobile number found.",
         },
         custNotFound: {
            id: "app.billing.common.custNotFound",
            defaultMessage: "Customer not found.",
         },
         noChargeStaff: {
            id: "app.billing.common.noChargeStaff",
            defaultMessage: "No Charge disabled for staff.",
         },
         noChargeErr: {
            id: "app.billing.common.noChargeErr",
            defaultMessage: "No Charge is not allowed on partial payment.",
         },
         reqFields: {
            id: "app.billing.common.reqFields",
            defaultMessage: "Please fill the required fields.",
         },
         amountCollectedZero: {
            id: "app.billing.common.amountCollectedZero",
            defaultMessage: "Amount collected cannot be 0.",
         },
         cannotRemovePayment: {
            id: "app.billing.common.cannotRemovePayment",
            defaultMessage: "Cannot remove already paid payment modes.",
         },
         billingUserErr: {
            id: "app.billing.common.billingUserErr",
            defaultMessage: "Please select the billing user.",
         },
         irnEwaybill : {
            id: "app.billing.common.irnEwaybill",
            defaultMessage: "Please generate E-Way Bill.",
         },
         dueAmountZero: {
            id: "app.billing.common.dueAmountZero",
            defaultMessage: "Due amount must be zero.",
         },
         rejectConfirmation: {
            id: "app.billing.common.rejectConfirmation",
            defaultMessage: "Are you sure you want to ",
         },
         rejectConfirmation1: {
            id: "app.billing.common.rejectConfirmation1",
            defaultMessage: " this order?",
         },
         updateStatus: {
            id: "app.billing.common.updateStatus",
            defaultMessage: "Are you sure you want to change the status to - ",
         },
         reject: {
            id: "app.billing.common.reject",
            defaultMessage: "Are you sure you want to reject this order ?",
         },
         prevOrders: {
            id: "app.billing.common.prevOrders",
            defaultMessage: "Previous Orders",
         },
         orderNumber: {
            id: "app.billing.common.orderNumber",
            defaultMessage: "Order Number:",
         },
         integerationChannel: {
            id: "app.billing.common.integerationChannel",
            defaultMessage: "Integeration Channel",
         },
         customerDetails: {
            id: "app.billing.common.customerDetails",
            defaultMessage: "Customer Details",
         },
         cartDetails: {
            id: "app.billing.common.cartDetails",
            defaultMessage: "Cart Details",
         },
         items: {
            id: "app.billing.common.items",
            defaultMessage: "Items",
         },
         cancelOrder: {
            id: "app.billing.common.cancelOrder",
            defaultMessage: "Cancel Order",
         },
      },
      storeSelection: {
         title: {
            id: "app.billing.storeSelection.title",
            defaultMessage: "Select Store",
         },
         confirmation: {
            id: "app.billing.storeSelection.confirmation",
            defaultMessage: "Are you sure you want to select this store?",
         },
      },
      catalogue: {
         confirmationModal: {
            hold: {
               id: "app.billing.catalogue.confirmationModal.hold",
               defaultMessage: "Are you sure you want to hold this order ?",
            },
            clear: {
               id: "app.billing.catalogue.confirmationModal.clear",
               defaultMessage: "Are you sure you want to clear cart ?",
            },
            remark: {
               id: "app.billing.catalogue.confirmationModal.remark",
               defaultMessage: "Add Remarks",
            },
         },
         quantityModal: {
            title: {
               id: "app.billing.catalogue.quantityModal.title",
               defaultMessage: "Enter Quantity",
            },
            prodName: {
               id: "app.billing.catalogue.quantityModal.prodName",
               defaultMessage: "Product Name:",
            },
            price: {
               id: "app.billing.catalogue.quantityModal.price",
               defaultMessage: "Price:",
            },
         },
         common: {
            customItem: {
               id: "app.billing.catalogue.common.customItem",
               defaultMessage: "Add Custom Item",
            },
         },
         filterView: {
            title: {
               id: "app.billing.catalogue.filterView.title",
               defaultMessage: "Filter By",
            },
            allProds: {
               id: "app.billing.catalogue.filterView.allProds",
               defaultMessage: "All Products",
            },
            allServices: {
               id: "app.billing.catalogue.filterView.allServices",
               defaultMessage: "All Services",
            },
            allCombos: {
               id: "app.billing.catalogue.filterView.allCombos",
               defaultMessage: "All Combos",
            },
            allMemberships: {
               id: "app.billing.catalogue.filterView.allMemberships",
               defaultMessage: "All Memberships",
            },
         },
         sortView: {
            title: {
               id: "app.billing.catalogue.sortView.title",
               defaultMessage: "Sort Item By",
            },
            creationDate: {
               id: "app.billing.catalogue.sortView.creationDate",
               defaultMessage: "Creation Date",
            },
            nameAsc: {
               id: "app.billing.catalogue.sortView.nameAsc",
               defaultMessage: "Name (A to Z)",
            },
            nameDsc: {
               id: "app.billing.catalogue.sortView.nameDsc",
               defaultMessage: "Name (Z to A)",
            },
            priceL2H: {
               id: "app.billing.catalogue.sortView.priceL2H",
               defaultMessage: "Price (Low to High)",
            },
            priceH2L: {
               id: "app.billing.catalogue.sortView.priceH2L",
               defaultMessage: "Price (High to Low)",
            },
         },
         categoryView: {
            title: {
               id: "app.billing.catalogue.categoryView.title",
               defaultMessage: "All Categories",
            },
            notFound: {
               id: "app.billing.catalogue.categoryView.notFound",
               defaultMessage: "No Sub-Category Found",
            },
         },
         comboView: {
            title: {
               id: "app.billing.catalogue.comboView.title",
               defaultMessage: "Select Products",
            },
            productSets: {
               id: "app.billing.catalogue.comboView.productSets",
               defaultMessage: "Product Sets",
            },
            select: {
               id: "app.billing.catalogue.comboView.select",
               defaultMessage: "Select",
            },
            notFound: {
               id: "app.billing.catalogue.comboView.notFound",
               defaultMessage: "No Products Found",
            },
            modifiers: {
               id: "app.billing.catalogue.comboView.modifiers",
               defaultMessage: "List of Modifiers",
            },
            noModifierFound: {
               id: "app.billing.catalogue.comboView.noModifierFound",
               defaultMessage: "No Modifier Found",
            },
         },
         customItem: {
            title: {
               id: "app.billing.catalogue.customItem.title",
               defaultMessage: "Add Custom Item",
            },
            basicDetails: {
               id: "app.billing.catalogue.customItem.basicDetails",
               defaultMessage: "Basic Details",
            },
            taxesNcharges: {
               id: "app.billing.catalogue.customItem.taxesNcharges",
               defaultMessage: "Taxes & Charges",
            },
            name: {
               id: "app.billing.catalogue.customItem.name",
               defaultMessage: "Name",
            },
            unit: {
               id: "app.billing.catalogue.customItem.unit",
               defaultMessage: "Unit",
            },
            price: {
               id: "app.billing.catalogue.customItem.price",
               defaultMessage: "Price/Unit",
            },
            addToCart: {
               id: "app.billing.catalogue.customItem.addToCart",
               defaultMessage: "Add To Cart",
            },
         },
         openBill: {
            title: {
               id: "app.billing.catalogue.openBill.title",
               defaultMessage: "New Fixed Price Sale",
            },
            newCartValue: {
               id: "app.billing.catalogue.openBill.newCartValue",
               defaultMessage: "New Cart Value",
            },
         },
         productGroup: {
            title: {
               id: "app.billing.catalogue.productGroup.title",
               defaultMessage: "Select Product Group",
            },
            IMEI: {
               id: "app.billing.catalogue.productGroup.IMEI",
               defaultMessage: "IMEI:",
            },
            barcode: {
               id: "app.billing.catalogue.productGroup.barcode",
               defaultMessage: "Barcode:",
            },
            MRP: {
               id: "app.billing.catalogue.productGroup.MRP",
               defaultMessage: "M.R.P.:",
            },
            serialNumber: {
               id: "app.billing.catalogue.productGroup.serialNumber",
               defaultMessage: "Serial Number:",
            },
            expiryDate: {
               id: "app.billing.catalogue.productGroup.expiryDate",
               defaultMessage: "Expiry Date:",
            },
         },
         addDiscount: {
            addNew: {
               id: "app.billing.catalogue.addDiscount.addNew",
               defaultMessage: "Add New",
            },
            useCoupon: {
               id: "app.billing.catalogue.addDiscount.useCoupon",
               defaultMessage: "Use Coupon",
            },
            remove: {
               id: "app.billing.catalogue.addDiscount.remove",
               defaultMessage: "Remove",
            },
            addNewDiscount: {
               id: "app.billing.catalogue.addDiscount.addNewDiscount",
               defaultMessage: "Add New Discount",
            },
            level: {
               id: "app.billing.catalogue.addDiscount.level",
               defaultMessage: "Level",
            },
            products: {
               id: "app.billing.catalogue.addDiscount.products",
               defaultMessage: "Products",
            },
            order: {
               id: "app.billing.catalogue.addDiscount.order",
               defaultMessage: "Order",
            },
            amount: {
               id: "app.billing.catalogue.addDiscount.amount",
               defaultMessage: "Amount",
            },
            percentage: {
               id: "app.billing.catalogue.addDiscount.percentage",
               defaultMessage: "Percentage",
            },
            value1: {
               id: "app.billing.catalogue.addDiscount.value1",
               defaultMessage: "Value",
            },
            value2: {
               id: "app.billing.catalogue.addDiscount.value2",
               defaultMessage: "Value (%)",
            },
            discountOnMRP: {
               id: "app.billing.catalogue.addDiscount.discountOnMRP",
               defaultMessage: "Discount on MRP",
            },
            ID: {
               id: "app.billing.catalogue.addDiscount.ID",
               defaultMessage: "ID",
            },
            appliedOn: {
               id: "app.billing.catalogue.addDiscount.appliedOn",
               defaultMessage: "Applied On",
            },
         },
         repeatConfirmation: {
            title: {
               id: "app.billing.catalogue.repeatConfirmation.title",
               defaultMessage: "Repeat last added customization?",
            },
            addNew: {
               id: "app.billing.catalogue.repeatConfirmation.addNew",
               defaultMessage: "Add New",
            },
            repeatLast: {
               id: "app.billing.catalogue.repeatConfirmation.repeatLast",
               defaultMessage: "Repeat Last",
            },
         },
         createPI: {
            title: {
               id: "app.billing.catalogue.createPI.title",
               defaultMessage: "New Proforma Invoice",
            },
            addNote: {
               id: "app.billing.catalogue.createPI.addNote",
               defaultMessage: "Add Note",
            },
            detail: {
               id: "app.billing.catalogue.createPI.detail",
               defaultMessage: "Proforma Invoice Details",
            },
            subID: {
               id: "app.billing.catalogue.createPI.subID",
               defaultMessage: "Sub ID",
            },
            basePrice: {
               id: "app.billing.catalogue.createPI.basePrice",
               defaultMessage: "Base Price",
            },
            netPrice: {
               id: "app.billing.catalogue.createPI.netPrice",
               defaultMessage: "Net Price",
            },
            discountsName: {
               id: "app.billing.catalogue.createPI.discountsName",
               defaultMessage: "Discounts Name",
            },
            discountsValue: {
               id: "app.billing.catalogue.createPI.discountsValue",
               defaultMessage: "Discounts Value",
            },
            discountsNet: {
               id: "app.billing.catalogue.createPI.discountsNet",
               defaultMessage: "Discounts Net",
            },
            phone: {
               id: "app.billing.catalogue.createPI.phone",
               defaultMessage: "Phone No.",
            },
            PIID: {
               id: "app.billing.catalogue.createPI.PIID",
               defaultMessage: "Proforma Invoice ID",
            },
         }
      },
      cart: {
         onlineOrders: {
            id: "app.billing.cart.onlineOrders",
            defaultMessage: "Online Orders",
         },
         ordersOnHold: {
            id: "app.billing.cart.ordersOnHold",
            defaultMessage: "Orders on Hold",
         },
         title: {
            id: "app.billing.cart.title",
            defaultMessage: "Cart",
         },
         addCust: {
            id: "app.billing.cart.addCust",
            defaultMessage: "Add Customer",
         },
         price: {
            id: "app.billing.cart.price",
            defaultMessage: "Price/Unit:",
         },
         orderDetails: {
            id: "app.billing.cart.orderDetails",
            defaultMessage: "Order Details",
         },
         addDiscount: {
            id: "app.billing.cart.addDiscount",
            defaultMessage: "Add Discount",
         },
         addCharges: {
            id: "app.billing.cart.addCharges",
            defaultMessage: "Add Charges",
         },
         checkout: {
            id: "app.billing.cart.checkout",
            defaultMessage: "Checkout",
         },
         customers: {
            title: {
               id: "app.billing.cart.customers.title",
               defaultMessage: "Customers",
            },
            addNew: {
               id: "app.billing.cart.customers.addNew",
               defaultMessage: "Add New",
            },
            new: {
               id: "app.billing.cart.customers.new",
               defaultMessage: "New",
            },
            creditBalance: {
               id: "app.billing.cart.customers.creditBalance",
               defaultMessage: "Credit Balance",
            },
            orderHistory: {
               id: "app.billing.cart.customers.orderHistory",
               defaultMessage: "Order History",
            },
            createNew: {
               id: "app.billing.cart.customers.createNew",
               defaultMessage: "Create New Customer",
            },
            customerDetails: {
               id: "app.billing.cart.customers.customerDetails",
               defaultMessage: "Customer Details",
            },
            individual: {
               id: "app.billing.cart.customers.individual",
               defaultMessage: "Individual",
            },
            company: {
               id: "app.billing.cart.customers.company",
               defaultMessage: "Company",
            },
            gender: {
               id: "app.billing.cart.customers.gender",
               defaultMessage: "Gender",
            },
            male: {
               id: "app.billing.cart.customers.male",
               defaultMessage: "Male",
            },
            female: {
               id: "app.billing.cart.customers.female",
               defaultMessage: "Female",
            },
            gst: {
               id: "app.billing.cart.customers.gst",
               defaultMessage: "GST Number",
            },
            companyName: {
               id: "app.billing.cart.customers.companyName",
               defaultMessage: "Company Name",
            },
            fullName: {
               id: "app.billing.cart.customers.fullName",
               defaultMessage: "Full Name",
            },
            phoneNumber: {
               id: "app.billing.cart.customers.phoneNumber",
               defaultMessage: "Phone Number",
            },
            email: {
               id: "app.billing.cart.customers.email",
               defaultMessage: "Email",
            },
            companyPanNumber: {
               id: "app.billing.cart.customers.companyPanNumber",
               defaultMessage: "Company PAN Number",
            },
            contactPerson: {
               id: "app.billing.cart.customers.contactPerson",
               defaultMessage: "Contact Person",
            },
            panNumber: {
               id: "app.billing.cart.customers.panNumber",
               defaultMessage: "PAN Number",
            },
            adhaarNumber: {
               id: "app.billing.cart.customers.adhaarNumber",
               defaultMessage: "Adhaar Number",
            },
            contactPersonPhone: {
               id: "app.billing.cart.customers.contactPersonPhone",
               defaultMessage: "Contact Person's Phone",
            },
            billingAddress: {
               id: "app.billing.cart.customers.billingAddress",
               defaultMessage: "Add Billing Address",
            },
            shippingAddress: {
               id: "app.billing.cart.customers.shippingAddress",
               defaultMessage: "Add Shipping Address",
            },
            address1: {
               id: "app.billing.cart.customers.address1",
               defaultMessage: "Address 1",
            },
            address2: {
               id: "app.billing.cart.customers.address2",
               defaultMessage: "Address 2",
            },
            city: {
               id: "app.billing.cart.customers.city",
               defaultMessage: "City",
            },
            zipCode: {
               id: "app.billing.cart.customers.zipCode",
               defaultMessage: "Zip/Postal Code",
            },
            state: {
               id: "app.billing.cart.customers.state",
               defaultMessage: "State",
            },
            country: {
               id: "app.billing.cart.customers.country",
               defaultMessage: "Country",
            },
            otherDetails: {
               id: "app.billing.cart.customers.otherDetails",
               defaultMessage: "Other Details",
            },
            dob: {
               id: "app.billing.cart.customers.dob",
               defaultMessage: "Date of Birth",
            },
            anniversary: {
               id: "app.billing.cart.customers.anniversary",
               defaultMessage: "Anniversary",
            },
            notes: {
               id: "app.billing.cart.customers.notes",
               defaultMessage: "Notes",
            },
         },
         holdOrder: {
            title: {
               id: "app.billing.cart.holdOrder.title",
               defaultMessage: "Hold Orders",
            },
            items: {
               id: "app.billing.cart.holdOrder.items",
               defaultMessage: "Items",
            },
            moveToCart: {
               id: "app.billing.cart.holdOrder.moveToCart",
               defaultMessage: "Move to Cart",
            },
            printKOT: {
               id: "app.billing.cart.holdOrder.printKOT",
               defaultMessage: "Print KOT",
            },
            quantity: {
               id: "app.billing.cart.holdOrder.quantity",
               defaultMessage: "Quantity",
            },
            price: {
               id: "app.billing.cart.holdOrder.price",
               defaultMessage: "Price",
            },
            info: {
               id: "app.billing.cart.holdOrder.info",
               defaultMessage: "Please select an order.",
            },
         },
         action: {
            title: {
               id: "app.billing.cart.action.title",
               defaultMessage: "Action",
            },
            holdOrder: {
               id: "app.billing.cart.action.holdOrder",
               defaultMessage: "Hold Order",
            },
            holdOrderDesc: {
               id: "app.billing.cart.action.holdOrderDesc",
               defaultMessage: "Hold multiple items & bill them later.",
            },
            fixedPrice: {
               id: "app.billing.cart.action.fixedPrice",
               defaultMessage: "Fixed Price Sale",
            },
            fixedPriceDesc: {
               id: "app.billing.cart.action.fixedPriceDesc",
               defaultMessage:
                  "This allows the cashier to generate a bill of specific amount only.",
            },
            proformaInvoice: {
               id: "app.billing.cart.action.proformaInvoice",
               defaultMessage: "Proforma Invoice",
            },
            proformaInvoiceDesc: {
               id: "app.billing.cart.action.proformaInvoiceDesc",
               defaultMessage: "Create new Proforma Invoice.",
            },
            voidOrder: {
               id: "app.billing.cart.action.voidOrder",
               defaultMessage: "Void Order",
            },
            voidOrderDesc: {
               id: "app.billing.cart.action.voidOrderDesc",
               defaultMessage: "This allows the cashier to refund orders.",
            },
         },
      },
      payment: {
         title: {
            id: "app.billing.payment.title",
            defaultMessage: "Billing Summary",
         },
         orderSummary: {
            id: "app.billing.payment.orderSummary",
            defaultMessage: "Order Summary",
         },
         itemDetails: {
            id: "app.billing.payment.itemDetails",
            defaultMessage: "Item Details",
         },
         quantity: {
            id: "app.billing.payment.quantity",
            defaultMessage: "Quantity",
         },
         price: {
            id: "app.billing.payment.price",
            defaultMessage: "Price",
         },
         subtotal: {
            id: "app.billing.payment.subtotal",
            defaultMessage: "Subtotal",
         },
         rounding: {
            id: "app.billing.payment.rounding",
            defaultMessage: "Rounding",
         },
         payableAmount: {
            id: "app.billing.payment.payableAmount",
            defaultMessage: "Payable Amount",
         },
         availableBalance: {
            id: "app.billing.payment.availableBalance",
            defaultMessage: "Available Balance",
         },
         creditNoteSelectMsg: {
            id: "app.billing.payment.creditNoteSelectMsg",
            defaultMessage: "Please Select Credit Note",
         },
         issuedOn: {
            id: "app.billing.payment.issuedOn",
            defaultMessage: "Issued On:",
         },
         getOTP: {
            id: "app.billing.payment.getOTP",
            defaultMessage: "Get OTP",
         },
         enterOTP: {
            id: "app.billing.payment.enterOTP",
            defaultMessage: "Enter OTP",
         },
         otpDesc: {
            id: "app.billing.payment.otpDesc",
            defaultMessage: "A One Time Password has been sent to the mobile number",
         },
         resendOTP: {
            id: "app.billing.payment.resendOTP",
            defaultMessage: "Resend OTP",
         },
         verify: {
            id: "app.billing.payment.verify",
            defaultMessage: "Verify",
         },
         payViaVoucher: {
            id: "app.billing.payment.payViaVoucher",
            defaultMessage: "Pay Via Voucher",
         },
         voucherCode: {
            id: "app.billing.payment.voucherCode",
            defaultMessage: "Voucher Code",
         },
         enterAmount: {
            id: "app.billing.payment.enterAmount",
            defaultMessage: "Enter Amount",
         },
         dueAmount: {
            id: "app.billing.payment.dueAmount",
            defaultMessage: "Due Amount",
         },
         validate: {
            id: "app.billing.payment.validate",
            defaultMessage: "Validate & Accept",
         },
         totalPayable: {
            id: "app.billing.payment.totalPayable",
            defaultMessage: "Total Payable Amount",
         },
         remainingAmount: {
            id: "app.billing.payment.remainingAmount",
            defaultMessage: "Remaining Amount",
         },
         paymentMode: {
            id: "app.billing.payment.paymentMode",
            defaultMessage: "Select Payment Mode",
         },
         payVia: {
            id: "app.billing.payment.payVia",
            defaultMessage: "Pay Via",
         },
         fullPayment: {
            id: "app.billing.payment.fullPayment",
            defaultMessage: "Full Payment",
         },
         partialPayment: {
            id: "app.billing.payment.partialPayment",
            defaultMessage: "Partial Payment",
         },
         enterDetails: {
            id: "app.billing.payment.enterDetails",
            defaultMessage: "Enter Details",
         },
         transactionNumber: {
            id: "app.billing.payment.transactionNumber",
            defaultMessage: "Transaction Number",
         },
         cardType: {
            id: "app.billing.payment.cardType",
            defaultMessage: "Card Type",
         },
         lastDigits: {
            id: "app.billing.payment.lastDigits",
            defaultMessage: "Last 4 Digits",
         },
         upiNote: {
            id: "app.billing.payment.upiNote",
            defaultMessage: "Note: Scan the QR code to complete the payment",
         },
         generateQR: {
            id: "app.billing.payment.generateQR",
            defaultMessage: "Generate QR",
         },
         collect: {
            id: "app.billing.payment.collect",
            defaultMessage: "Collect",
         },
         phoneNumber: {
            id: "app.billing.payment.phoneNumber",
            defaultMessage: "Enter Phone Number",
         },
         email: {
            id: "app.billing.payment.email",
            defaultMessage: "Enter Email",
         },
         sendInvoice: {
            id: "app.billing.payment.sendInvoice",
            defaultMessage: "Send Invoice",
         },
         printA4: {
            id: "app.billing.payment.printA4",
            defaultMessage: "Print A4",
         },
         print: {
            id: "app.billing.payment.print",
            defaultMessage: "Print",
         },
         viewDetails: {
            id: "app.billing.payment.viewDetails",
            defaultMessage: "View Details",
         },
         success: {
            id: "app.billing.payment.success",
            defaultMessage: "Payment Successful",
         },
         newSale: {
            id: "app.billing.payment.newSale",
            defaultMessage: "New Sale",
         },
         orderType: {
            id: "app.billing.payment.orderType",
            defaultMessage: "Order Type",
         },
         remarks: {
            id: "app.billing.payment.remarks",
            defaultMessage: "Remarks",
         },
         generateEwayBill : {
            id: "app.billing.payment.generateEwayBill",
            defaultMessage: "Generate Eway-Bill",
         },
         updateEwayBill : {
            id: "app.billing.payment.updateEwayBill",
            defaultMessage: "Update Eway-Bill",
         }
      },
      refund: {
         allOrders: {
            id: "app.billing.refund.allOrders",
            defaultMessage: "All Orders",
         },
         date: {
            id: "app.billing.refund.date",
            defaultMessage: "Date",
         },
         time: {
            id: "app.billing.refund.time",
            defaultMessage: "Time",
         },
         refund: {
            id: "app.billing.refund.refund",
            defaultMessage: "Refund",
         },
         refundQty: {
            id: "app.billing.refund.refundQty",
            defaultMessage: "Refund Quantity",
         },
         totalAmount: {
            id: "app.billing.refund.totalAmount",
            defaultMessage: "Total Amount",
         },
         totalAmount: {
            id: "app.billing.refund.totalAmount",
            defaultMessage: "Total Amount",
         },
         totalItems: {
            id: "app.billing.refund.totalItems",
            defaultMessage: "Total Items",
         },
         custPhoneNumber: {
            id: "app.billing.refund.custPhoneNumber",
            defaultMessage: "Customer's Phone Number",
         },
         orderDetails: {
            id: "app.billing.refund.orderDetails",
            defaultMessage: "Order Details",
         },
         toRefund: {
            id: "app.billing.refund.toRefund",
            defaultMessage: "To Refund",
         },
         refundMode: {
            id: "app.billing.refund.refundMode",
            defaultMessage: "Select Refund Mode",
         },
         fullRefund: {
            id: "app.billing.refund.fullRefund",
            defaultMessage: "Full Refund",
         },
         partialRefund: {
            id: "app.billing.refund.partialRefund",
            defaultMessage: "Partial Refund",
         },
         confirmPayment: {
            id: "app.billing.refund.confirmPayment",
            defaultMessage: "Confirm Refund",
         },
         success: {
            id: "app.billing.refund.success",
            defaultMessage: "Order Successfully Refunded",
         },
         done: {
            id: "app.billing.refund.done",
            defaultMessage: "Done",
         },
      },
      ewayBill : {
         addressSameAsCustomer : {
            id: "app.billing.ewayBill.addressSameAsCustomer",
            defaultMessage: "Address same of customer address",
         },
         addressSameAsStore : {
            id: "app.billing.ewayBill.addressSameAsStore",
            defaultMessage: "Address same of store address",
         },
         shippingAddress1 : {
            id: "app.billing.ewayBill.shippingAddress1",
            defaultMessage: "Shipping Address 1",
         },
         dispatchAddress1 : {
            id: "app.billing.ewayBill.dispatchAddress1",
            defaultMessage: "Dispatch Address 1",
         },
         shippingAddress2 : {
            id: "app.billing.ewayBill.shippingAddress2",
            defaultMessage: "Shipping Address 2",
         },
         dispatchAddress2 : {
            id: "app.billing.ewayBill.dispatchAddress2",
            defaultMessage: "Dispatch Address 2",
         },
         shippingLocation : {
            id: "app.billing.ewayBill.shippingLocation",
            defaultMessage: "Shipping Location",
         },
         shippingPinCode : {
            id: "app.billing.ewayBill.shippingPinCode",
            defaultMessage: "Shipping Pincode",
         },
         dispatchPinCode : {
            id: "app.billing.ewayBill.dispatchPinCode",
            defaultMessage: "Dispatch Pincode",
         },
         shippingState : {
            id: "app.billing.ewayBill.shippingState",
            defaultMessage: "Shipping State",
         },
         dispatchState : {
            id: "app.billing.ewayBill.dispatchState",
            defaultMessage: "Dispatch State",
         },
         selectCustomer : {
            id: "app.billing.ewayBill.selectCustomer",
            defaultMessage: "Please add the customer to generate e-way bill.",
         },
         ewabillErrOnIndividual : {
            id: "app.billing.ewayBill.ewabillErrOnIndividual",
            defaultMessage: "An e-way bill could not be generated for a customer of type Individual.",
         },
         continue : {
            id: "app.billing.ewayBill.continue",
            defaultMessage: "Continue",
         },
         customerInfo : {
            id: "app.billing.ewayBill.customerInfo",
            defaultMessage: "Customer Information",
         },
         ewayBillInfo : {
            id: "app.billing.ewayBill.ewayBillInfo",
            defaultMessage: "E-Way Bill Details",
         },
         shippingInfo : {
            id: "app.billing.ewayBill.shippingInfo",
            defaultMessage: "Shipping Details",
         },
         dispatchInfo : {
            id: "app.billing.ewayBill.dispatchInfo",
            defaultMessage: "Dispatch Details",
         },
         noCustAdd : {
            id: "app.billing.ewayBill.noCustAdd",
            defaultMessage: "No customer address available. Please enter manually.",
         },
         noStoreAdd : {
            id: "app.billing.ewayBill.noStoreAdd",
            defaultMessage: "No store address available. Please enter manually.",
         },
         shippAdd1Req : {
            id: "app.billing.ewayBill.shippAdd1Req",
            defaultMessage: "Shipping Address 1 is required.",
         },
         shipCity : {
            id: "app.billing.ewayBill.shipCity",
            defaultMessage: "Please select city.",
         },
         shipPinCodeReq : {
            id: "app.billing.ewayBill.shipPinCodeReq",
            defaultMessage: "Shipping Pincode is required.",
         },
         dispatchNameReq : {
            id: "app.billing.ewayBill.dispatchNameReq",
            defaultMessage: "Dispatch Name is required.",
         },
         generationRestriction : {
            id: "app.billing.ewayBill.generationRestriction",
            defaultMessage: "E-way bill generation is restricted for intra-state transactions; ensure origin and destination states differ",
         },
         dispacthName : {
            id: "app.billing.ewayBill.dispacthName",
            defaultMessage: "Dispatch Name",
         },
         transportID : {
            id: "app.billing.ewayBill.transportID",
            defaultMessage: "Transporter ID",
         },
         transID :{
            id: "app.billing.ewayBill.transID",
            defaultMessage: "Transporter ID is required.",
         },
         transMode : {
            id: "app.billing.ewayBill.transMode",
            defaultMessage: "Transport Mode is required.",
         },
         transName : {
            id: "app.billing.ewayBill.transName",
            defaultMessage: "Transporter Name is required.",
         }
      }
   },
   estore: {
      estoreDashboard: {
         estoreDashboard: {
            id: "app.estore.estoreDashboard.estoreDashboard",
            defaultMessage: "eStore Dashboard",
         },
         estoreDashboardDesc: {
            id: "app.estore.estoreDashboard.estoreDashboardDesc",
            defaultMessage: "Here's the overview of your eStore",
         },
         eStoreInsights: {
            id: "app.estore.estoreDashboard.eStoreInsights",
            defaultMessage: "eStore Insights",
         },
         placed: {
            id: "app.estore.estoreDashboard.placed",
            defaultMessage: "Placed",
         },
         accepted: {
            id: "app.estore.estoreDashboard.accepted",
            defaultMessage: "Accepted",
         },
         invoiced: {
            id: "app.estore.estoreDashboard.invoiced",
            defaultMessage: "Invoiced",
         },
         rejected: {
            id: "app.estore.estoreDashboard.rejected",
            defaultMessage: "Rejected",
         },
         paymentInsights: {
            id: "app.estore.estoreDashboard.paymentInsights",
            defaultMessage: "Payment Insights",
         },
         topItemsSales: {
            id: "app.estore.estoreDashboard.topItemsSales",
            defaultMessage: "Top Items Sales",
         },
         itemName: {
            id: "app.estore.estoreDashboard.itemName",
            defaultMessage: "Item Name",
         },
         itemCount: {
            id: "app.estore.estoreDashboard.itemCount",
            defaultMessage: "Item Count",
         },
         categorySales: {
            id: "app.estore.estoreDashboard.categorySales",
            defaultMessage: "Category Sales",
         },
      },

      promoAds: {
         promoAds: {
            id: "app.estore.promoAds.promoAds",
            defaultMessage: "Promo Ads",
         },
         promoAdsDesc: {
            id: "app.estore.promoAds.promoAdsDesc",
            defaultMessage: "List of all banners.",
         },
         bannerName: {
            id: "app.estore.promoAds.bannerName",
            defaultMessage: "Banner Name",
         },
         activeDate: {
            id: "app.estore.promoAds.activeDate",
            defaultMessage: "Active Date",
         },
         subType: {
            id: "app.estore.promoAds.subType",
            defaultMessage: "Sub Type",
         },
         viewBanner: {
            id: "app.estore.promoAds.viewBanner",
            defaultMessage: "View Banner",
         },
         viewStores: {
            id: "app.estore.promoAds.viewStores",
            defaultMessage: "View Store",
         },
         createBanner: {
            id: "app.estore.promoAds.createBanner",
            defaultMessage: "Create Banner",
         },
         createBannerDesc: {
            id: "app.estore.promoAds.createBannerDesc",
            defaultMessage: "Add banner in your eStore home page.",
         },
         uploadBannerImage: {
            id: "app.estore.promoAds.uploadBannerImage",
            defaultMessage: "Upload Banner Image",
         },
         uploadBannerImageDesc: {
            id: "app.estore.promoAds.uploadBannerImageDesc",
            defaultMessage: "Banner image ratio should be 8:3 automatically",
         },
         removeImage: {
            id: "app.estore.promoAds.removeImage",
            defaultMessage: "Remove Image",
         },
         cropImage: {
            id: "app.estore.promoAds.cropImage",
            defaultMessage: "Crop Image",
         },
         bannerPreview: {
            id: "app.estore.promoAds.bannerPreview",
            defaultMessage: "Banner Preview",
         },
         bannerInformation: {
            id: "app.estore.promoAds.bannerInformation",
            defaultMessage: "Banner Information",
         },
         bannerType: {
            id: "app.estore.promoAds.bannerType",
            defaultMessage: "Banner Type",
         },
         bannerSubType: {
            id: "app.estore.promoAds.bannerSubType",
            defaultMessage: "Banner Sub Type",
         },
         bannerSubTypeValues: {
            id: "app.estore.promoAds.bannerSubTypeValues",
            defaultMessage: "Sub type value(s)",
         },
         banner: {
            id: "app.estore.promoAds.banner",
            defaultMessage: "Banner ",
         },
         bannerDeleteMsg: {
            id: "app.estore.promoAds.bannerDeleteMsg",
            defaultMessage: "Are you sure you want to delete banner",
         },
         URL: {
            id: "app.estore.promoAds.URL",
            defaultMessage: "URL",
         },
         bannerListEmpty: {
            id: "app.estore.promoAds.bannerListEmpty",
            defaultMessage: "Banners list empty",
         },
         bannersTotalKeyWord: {
            id: "app.estore.promoAds.bannersTotalKeyWord",
            defaultMessage: "Banner(s)",
         },
         uploadImage: {
            id: "app.estore.promoAds.uploadImage",
            defaultMessage: "Please upload image.",
         },
         editBanner: {
            id: "app.estore.promoAds.editBanner",
            defaultMessage: "Edit Banner.",
         },
         cropImgaeNotifier: {
            id: "app.estore.promoAds.cropImgaeNotifier",
            defaultMessage: "Please crop image",
         },
         bannerNameIsRequired: {
            id: "app.estore.promoAds.bannerNameIsRequired",
            defaultMessage: "Banner name is required!.",
         },
         storeSelectionIsMandatory: {
            id: "app.estore.promoAds.storeSelectionIsMandatory",
            defaultMessage: "Store selection is mandatory!.",
         },
         storeSelectionIsMandatory: {
            id: "app.estore.promoAds.storeSelectionIsMandatory",
            defaultMessage: "Store selection is mandatory!.",
         },
         urlIsRequired: {
            id: "app.estore.promoAds.urlIsRequired",
            defaultMessage: "Url is required!.",
         },
         editBannerDesc: {
            id: "app.estore.promoAds.editBannerDesc",
            defaultMessage: "Edit banner in your eStore home page.",
         },
         editBannerImageDesc: {
            id: "app.estore.promoAds.editBannerImageDesc",
            defaultMessage:
               "This is the current banner image, click 'Edit' to upload a new banner image.",
         },
         legalPolicies: {
            id: "app.estore.promoAds.legalPolicies",
            defaultMessage: "Legal Policies",
         },
         legalPoliciesDesc: {
            id: "app.estore.promoAds.legalPoliciesDesc",
            defaultMessage: "Create your own legal policy pages or add from templates. ",
         },
         aboutUs: {
            id: "app.estore.promoAds.aboutUs",
            defaultMessage: "About Us",
         },
         termsAndConditions: {
            id: "app.estore.promoAds.termsAndConditions",
            defaultMessage: "Terms and conditions",
         },
         privacyPolicy: {
            id: "app.estore.promoAds.privacyPolicy",
            defaultMessage: "Privacy Policy",
         },
         refundPolicy: {
            id: "app.estore.promoAds.refundPolicy",
            defaultMessage: "Refund Policy",
         },
         returnPolicy: {
            id: "app.estore.promoAds.returnPolicy",
            defaultMessage: "Return Policy",
         },
         shippingPolicy: {
            id: "app.estore.promoAds.shippingPolicy",
            defaultMessage: "Shipping Policy",
         },
      },
      settings: {
         settingsDesc: {
            id: "app.estore.settings.settingsDesc",
            defaultMessage: "Update eStore settings.",
         },
         eStoreBasicSettings: {
            id: "app.estore.settings.eStoreBasicSettings",
            defaultMessage: "eStore Basic Settings",
         },
         enableeStore: {
            id: "app.estore.settings.enableeStore",
            defaultMessage: "Enable eStore",
         },
         enableeStoreDesc: {
            id: "app.estore.settings.enableeStoreDesc",
            defaultMessage: "Accept online orders by enabling eStore for the chain",
         },
         brandImageDesc: {
            id: "app.estore.settings.brandImageDesc",
            defaultMessage: "This image will be displayed in the QB eStore page.",
         },
         showProductImageDesc: {
            id: "app.estore.settings.showProductImageDesc",
            defaultMessage: "Show product image in estore catalog",
         },
         expiryDuration: {
            id: "app.estore.settings.expiryDuration",
            defaultMessage: "Duration to expire orders automatically (Hours)",
         },
         onlinePaymentSettings: {
            id: "app.estore.settings.onlinePaymentSettings",
            defaultMessage: "Online Payment Settings",
         },
         enableOnlinePayments: {
            id: "app.estore.settings.enableOnlinePayments",
            defaultMessage: "Do you want to enable online payments?",
         },
         merchantID: {
            id: "app.estore.settings.merchantID",
            defaultMessage: "Merchant ID",
         },
         clientID: {
            id: "app.estore.settings.clientID",
            defaultMessage: "Client ID",
         },
         clientSecretKey: {
            id: "app.estore.settings.clientSecretKey",
            defaultMessage: "Client Secret Key",
         },
         storeSettings: {
            id: "app.estore.settings.storeSettings",
            defaultMessage: "Store Settings",
         },
         uploadStoreLogo: {
            id: "app.estore.settings.uploadStoreLogo",
            defaultMessage: "Upload store logo",
         },
         uploadStoreLogoDesc: {
            id: "app.estore.settings.uploadStoreLogoDesc",
            defaultMessage: "This logo will be displayed in the QB eStore page.",
         },
         eStoreQRcode: {
            id: "app.estore.settings.eStoreQRcode",
            defaultMessage: "eStore QR code",
         },
         allowOnlineOrdering: {
            id: "app.estore.settings.allowOnlineOrdering",
            defaultMessage: "Allow online ordering for store.",
         },
         eStoreURL: {
            id: "app.estore.settings.eStoreURL",
            defaultMessage: "eStore URL",
         },
         addCopyrightNotice: {
            id: "app.estore.settings.addCopyrightNotice",
            defaultMessage: "Add Copyright Notice",
         },
         addFacebookLink: {
            id: "app.estore.settings.addFacebookLink",
            defaultMessage: "Add Facebook Link",
         },
         addInstagramLink: {
            id: "app.estore.settings.addInstagramLink",
            defaultMessage: "Add Instagram Link",
         },
         addTwitterLink: {
            id: "app.estore.settings.addTwitterLink",
            defaultMessage: "Add Twitter Link",
         },
         addYoutubeLink: {
            id: "app.estore.settings.addYoutubeLink",
            defaultMessage: "Add Youtube Link",
         },
         pickUpFromStore: {
            id: "app.estore.settings.pickUpFromStore",
            defaultMessage: "Allow pick up from store.",
         },
         allowCashOnDelivery: {
            id: "app.estore.settings.allowCashOnDelivery",
            defaultMessage: "Allow cash on delivery.",
         },
         minimumOrderValue: {
            id: "app.estore.settings.minimumOrderValue",
            defaultMessage: "Minimum Order Value",
         },
         minimumDeliveryTime: {
            id: "app.estore.settings.minimumDeliveryTime",
            defaultMessage: "Minimum Delivery Time (in minutes)",
         },
         storeOpeningTime: {
            id: "app.estore.settings.storeOpeningTime",
            defaultMessage: "Store Opening Time.",
         },
         storeClosingTime: {
            id: "app.estore.settings.storeClosingTime",
            defaultMessage: "Store Closing Time",
         },
         latitude: {
            id: "app.estore.settings.latitude",
            defaultMessage: "Latitude",
         },
         longitude: {
            id: "app.estore.settings.longitude",
            defaultMessage: "Longitude",
         },
         eStoreCredits: {
            id: "app.estore.settings.eStoreCredits",
            defaultMessage: "eStore Credits",
         },
         remainingEStoreCredits: {
            id: "app.estore.settings.remainingEStoreCredits",
            defaultMessage: "Remaining eStore Credits",
         },
         creditsPurchased: {
            id: "app.estore.settings.creditsPurchased",
            defaultMessage: "Total eStore Credits Purchased",
         },
         creditsConsumed: {
            id: "app.estore.settings.creditsConsumed",
            defaultMessage: "Total eStore Credits Consumed",
         },
         starter: {
            id: "app.estore.settings.starter",
            defaultMessage: "Starter",
         },
         professional: {
            id: "app.estore.settings.professional",
            defaultMessage: "Professional",
         },
         enterprise: {
            id: "app.estore.settings.enterprise",
            defaultMessage: "Enterprise",
         },
         starterDesc: {
            id: "app.estore.settings.starterDesc",
            defaultMessage: "To start your business",
         },
         professionalDesc: {
            id: "app.estore.settings.professionalDesc",
            defaultMessage: "To grow your business",
         },
         enterpriseDesc: {
            id: "app.estore.settings.settingsDesc",
            defaultMessage: "To scale your business",
         },
         choosePlan: {
            id: "app.estore.settings.choosePlan",
            defaultMessage: "Choose Plan",
         },
      },
      catalog: {
         catalog: {
            id: "app.estore.catalog.catalog",
            defaultMessage: "Catalog",
         },
         catalogDesc: {
            id: "app.estore.catalog.catalogDesc",
            defaultMessage: "List of all products in a store.",
         },
         addOrRemoveProducts: {
            id: "app.estore.catalog.addOrRemoveProducts",
            defaultMessage: "Add or remove products",
         },
         sellOnline: {
            id: "app.estore.catalog.sellOnline",
            defaultMessage: "Sell Online",
         },
         onlinePrice: {
            id: "app.estore.catalog.onlinePrice",
            defaultMessage: "Online Price",
         },
         recommended: {
            id: "app.estore.catalog.recommended",
            defaultMessage: "Recommended",
         },
         previewSelectedproducts: {
            id: "app.estore.catalog.previewSelectedproducts",
            defaultMessage: "Preview - Selected products to sell online",
         },
         sellOnline: {
            id: "app.estore.catalog.sellOnline",
            defaultMessage: "Sell Online?",
         },
         selectUpToFiveStore: {
            id: "app.estore.catalog.selectUpToFiveStore",
            defaultMessage: "Please Select Upto 5 Stores",
         },
         previewBulkOperation: {
            id: "app.estore.catalog.previewBulkOperation",
            defaultMessage: "Preview - Bulk Operation",
         },
         recentOrders: {
            id: "app.estore.catalog.recentOrders",
            defaultMessage: "Recent Orders",
         },
         viewReport: {
            id: "app.estore.catalog.viewReport",
            defaultMessage: "View Report",
         },
         topCategorySales: {
            id: "app.estore.catalog.topCategorySales",
            defaultMessage: "Top Category Sales",
         },
         createFromTemplate: {
            id: "app.estore.catalog.createFromTemplate",
            defaultMessage: "Create from Template",
         },
         noRazorpayAccount: {
            id: "app.estore.catalog.noRazorpayAccount",
            defaultMessage: "No razorpay account?",
         },
         noRazorpayAccountDesc: {
            id: "app.estore.catalog.noRazorpayAccountDesc",
            defaultMessage:
               "You need to register with razorpay to start accepting online payments. Let us know your contact details on which we shall contact you to help you setting up your account.",
         },
         enterEmail: {
            id: "app.estore.catalog.enterEmail",
            defaultMessage:"Please enter email",
         },
         enterValidEmail: {
            id: "app.estore.catalog.enterValidEmail",
            defaultMessage:"Please enter valid email",
         },
         enterValidPhoneNumber: {
            id: "app.estore.catalog.enterValidPhoneNumber",
            defaultMessage:"Please enter a valid phone number",
         },
         storeSettingSuccess: {
            id: "app.estore.catalog.storeSettingSuccess",
            defaultMessage: "Store settings successfully updated",
         },
         chainSettingSuccess: {
            id: "app.estore.catalog.chainSettingSuccess",
            defaultMessage: "Chain settings successfully updated",
         },
         paymentSuccess: {
            id: "app.estore.catalog.paymentSuccess",
            defaultMessage: "Payment settings successfully updated",
         },
         addressVal: {
            id: "app.estore.catalog.addressVal",
            defaultMessage: "Please enter address",
         },
         cityNameVal: {
            id: "app.estore.catalog.cityNameVal",
            defaultMessage: "Please enter city name.",
         },
         selectCity: {
            id: "app.estore.catalog.selectCity",
            defaultMessage: "Please Select City",
         },
         stateNameVal: {
            id: "app.estore.catalog.stateNameVal",
            defaultMessage: "Please enter state name.",
         },
         pinCodeVal: {
            id: "app.estore.catalog.pinCodeVal",
            defaultMessage: "Please enter pincode.",
         },
         countryVal: {
            id: "app.estore.catalog.countryVal",
            defaultMessage: "Please enter country name.",
         },
         postCode: {
            id: "app.estore.catalog.postCode",
            defaultMessage: "Post Code",
         },
         storeHandle: {
            id: "app.estore.catalog.storeHandle",
            defaultMessage: "Store Handle",
         },
         selectPaymentGateway: {
            id: "app.estore.catalog.selectPaymentGateway",
            defaultMessage: "Select payment gateway",
         },
         errorContactSupport: {
            id: "app.estore.catalog.errorContactSupport",
            defaultMessage: "Something went wrong. Please contact support team.",
         },
         productMustVal: {
            id: "app.estore.catalog.productMustVal",
            defaultMessage: "Atlease select one product.",
         },
         setOnlinePrice: {
            id: "app.estore.catalog.setOnlinePrice",
            defaultMessage: "Set Online Price",
         },
         moreThanFiveStore: {
            id: "app.estore.catalog.moreThanFiveStore",
            defaultMessage: "More than 5 stores selected",
         },
      },
      catalogEdit:{
         catalogEditTitle: {
            id: "app.estore.catalogEdit.catalogEditTitle",
            defaultMessage: "Edit Store Product",
         },
         catalogEditDesc: {
            id: "app.estore.catalogEdit.catalogEditDesc",
            defaultMessage: "Update product information.",
         },
         catalogEditCardTile: {
            id: "app.estore.catalogEdit.catalogEditCardTile",
            defaultMessage: "Basic Product Information",
         },
      },
      addOrRemoveProduct:{
         addOrRemoveProductTitle: {
            id: "app.estore.addOrRemoveProduct.addOrRemoveProductTitle",
            defaultMessage: "Add or remove products",
         },
         addOrRemoveProductTitle2: {
            id: "app.estore.addOrRemoveProduct.addOrRemoveProductTitle2",
            defaultMessage: "Preview - Selected products to sell online",
         },
        
      }
   },
   khata: {
      customerBalance: {
         customerBalance: {
            id: "app.khata.customerBalance.customerBalance",
            defaultMessage: "Customer Balance",
         },
         customerBalanceDesc: {
            id: "app.khata.customerBalance.customerBalanceDesc",
            defaultMessage: "Descriptive text for customer balance report. ",
         },
         balanceDue: {
            id: "app.khata.customerBalance.balanceDue",
            defaultMessage: "Balance Due",
         },
      },
      customerLedger: {
         customerLedger: {
            id: "app.khata.customerLedger.customerLedger",
            defaultMessage: "Customer Ledger",
         },
         customerLedgerDesc: {
            id: "app.khata.customerLedger.customerLedgerDesc",
            defaultMessage: "Descriptive text for customer ledger report.",
         },
         transactionAmount: {
            id: "app.khata.customerLedger.transactionAmount",
            defaultMessage: "Transaction Amount",
         },
         totalCustomerBalanceBreakUp: {
            id: "app.khata.customerLedger.totalCustomerBalanceBreakUp",
            defaultMessage: "Total customer balance breakup",
         },
      },

   },
   filtersBar: {
      statusAndType: {
         id: "app.filtersBar.statusAndType",
         defaultMessage: "Type & Status",
      },
      orderStatus: {
         id: "app.filtersBar.orderStatus",
         defaultMessage: "Order Status",
      },
      all: {
         id: "app.filtersBar.all",
         defaultMessage: "All",
      },
      allLocations: {
         id: "app.filtersBar.allLocations",
         defaultMessage: "All Locations",
      },
      selectDate: {
         id: "app.filtersBar.selectDate",
         defaultMessage: "Select Date",
      },
      selectDiscount: {
         id: "app.filtersBar.selectDiscount",
         defaultMessage: "Select Discount",
      },
      searchProduct: {
         id: "app.filtersBar.searchProduct",
         defaultMessage: "Search Product",
      },
      timeRange: {
         id: "app.filtersBar.timeRange",
         defaultMessage: "Time Range",
      },
      staff: {
         id: "app.filtersBar.staff",
         defaultMessage: "Staff",
      },
      selectDevice: {
         id: "app.filtersBar.selectDevice",
         defaultMessage: "Select Device"
      },
      selectVehicle: {
         id: "app.filtersBar.selectVehicle",
         defaultMessage: "Select Vehicle"
      },
      selectRegion: {
         id: "app.filtersBar.selectRegion",
         defaultMessage: "Select Region"
      },
      selectSource: {
         id: "app.filtersBar.selectSource",
         defaultMessage: "Select Source"
      },
      transactionType: {
         id: "app.filtersBar.transactionType",
         defaultMessage: "Transaction Type"
      },
      redeemed: {
         id: "app.filtersBar.redeemed",
         defaultMessage: "REDEEMED"
      },
      earned: {
         id: "app.filtersBar.earned",
         defaultMessage: "EARNED"
      },
      couponRedeemed: {
         id: "app.filtersBar.couponRedeemed",
         defaultMessage: "COUPON_REDEEMED"
      },
      couponReversed: {
         id: "app.filtersBar.couponReversed",
         defaultMessage: "COUPON_REVERSED"
      },
      selectOneStoreErr: {
         id: "app.filtersBar.selectOneStoreErr",
         defaultMessage: "Please select at least one store/region"
      },
      regionStores: {
         id: "app.filtersBar.regionStores",
         defaultMessage: " Regions & Stores"
      },
      regions: {
         id: "app.filtersBar.regions",
         defaultMessage: " Regions"
      },
      stores: {
         id: "app.filtersBar.stores",
         defaultMessage: "Stores"
      },
      selectLocation: {
         id: "app.filtersBar.selectLocation",
         defaultMessage: "Select Location"
      },
      expiryStatus: {
         id: "app.filtersBar.expiryStatus",
         defaultMessage: "Expiry Status"
      },
      goingToExpire: {
         id: "app.filtersBar.goingToExpire",
         defaultMessage: "Going to Expire"
      },
      noOfDays: {
         id: "app.filtersBar.noOfDays",
         defaultMessage: "Number of Days"
      },
      vendor: {
         id: "app.filtersBar.vendor",
         defaultMessage: "Vendor"
      },
      invoiceStatus: {
         id: "app.filtersBar.invoiceStatus",
         defaultMessage: "Invoice Status"
      },
      paid: {
         id: "app.filtersBar.paid",
         defaultMessage: "Paid"
      },
      partiallyPaid: {
         id: "app.filtersBar.partiallyPaid",
         defaultMessage: "Partially Paid"
      },
      unpaid: {
         id: "app.filtersBar.unpaid",
         defaultMessage: "Unpaid"
      },
      selectReservation: {
         id: "app.filtersBar.selectReservation",
         defaultMessage: "Select Reservation"
      },
      selectCounter: {
         id: "app.filtersBar.selectCounter",
         defaultMessage: "Select Counter"
      },
      selectSalesPeriod: {
         id: "app.filtersBar.selectSalesPeriod",
         defaultMessage: "Select Sales Period"
      },
      selectProduct: {
         id: "app.filtersBar.selectProduct",
         defaultMessage: "Select Product"
      },
      partiallyCancelled: {
         id: "app.filtersBar.partiallyCancelled",
         defaultMessage: "Partially Cancelled"
      },
      cancelled: {
         id: "app.filtersBar.cancelled",
         defaultMessage: "Cancelled"
      },
      delivered: {
         id: "app.filtersBar.delivered",
         defaultMessage: "Delivered"
      },
      unDelivered: {
         id: "app.filtersBar.unDelivered",
         defaultMessage: "Un-delivered"
      },
      placed: {
         id: "app.filtersBar.placed",
         defaultMessage: "Placed"
      },
      invoiced: {
         id: "app.filtersBar.invoiced",
         defaultMessage: "Invoiced"
      },
      accepted: {
         id: "app.filtersBar.accepted",
         defaultMessage: "Accepted"
      },
      dispatched: {
         id: "app.filtersBar.dispatched",
         defaultMessage: "Dispatched"
      },
      rejected: {
         id: "app.filtersBar.rejected",
         defaultMessage: "Rejected"
      },
      acknowledged: {
         id: "app.filtersBar.acknowledged",
         defaultMessage: "Acknowledged"
      },
      foodReady: {
         id: "app.filtersBar.foodReady",
         defaultMessage: "Food Ready"
      },
      completed: {
         id: "app.filtersBar.completed",
         defaultMessage: "Completed"
      },
      modified: {
         id: "app.filtersBar.modified",
         defaultMessage: "Modified"
      },
      assigned: {
         id: "app.filtersBar.assigned",
         defaultMessage: "Assigned"
      },
      inventoryStatus: {
         id: "app.filtersBar.inventoryStatus",
         defaultMessage: "Inventory Status"
      },
      synced: {
         id: "app.filtersBar.synced",
         defaultMessage: "Synced"
      },
      unSynced: {
         id: "app.filtersBar.unSynced",
         defaultMessage: "Un-synced"
      },
      allOrders: {
         id: "app.filtersBar.allOrders",
         defaultMessage: "All Orders (Except No Charge)"
      },
      discountedOrders: {
         id: "app.filtersBar.discountedOrders",
         defaultMessage: "Discounted Orders"
      },
      regular: {
         id: "app.filtersBar.regular",
         defaultMessage: "Regular"
      },
      fullyVoid: {
         id: "app.filtersBar.fullyVoid",
         defaultMessage: "Fully Void"
      },
      partiallyVoid: {
         id: "app.filtersBar.partiallyVoid",
         defaultMessage: "Partially Void"
      },
      noCharge: {
         id: "app.filtersBar.noCharge",
         defaultMessage: "No Charge"
      },
      orderMode: {
         id: "app.filtersBar.orderMode",
         defaultMessage: "Order Mode"
      },
      dineIn: {
         id: "app.filtersBar.dineIn",
         defaultMessage: "Dine In"
      },
      homeDelivery: {
         id: "app.filtersBar.homeDelivery",
         defaultMessage: "Home Delivery"
      },
      takeaway: {
         id: "app.filtersBar.takeaway",
         defaultMessage: "Takeaway"
      },
      thirdParty: {
         id: "app.filtersBar.thirdParty",
         defaultMessage: "Third Party"
      },
      reportType: {
         id: "app.filtersBar.reportType",
         defaultMessage: "Report Type"
      },
      cumulative: {
         id: "app.filtersBar.cumulative",
         defaultMessage: "Cumulative"
      },
      dayWise: {
         id: "app.filtersBar.dayWise",
         defaultMessage: "Day Wise"
      },
      acceptCreditNoteOTP: {
         id: "app.filtersBar.acceptCreditNoteOTP",
         defaultMessage: "Accept Credit Note OTP"
      },
      anniversary: {
         id: "app.filtersBar.anniversary",
         defaultMessage: "Anniversary"
      },
      birthday: {
         id: "app.filtersBar.birthday",
         defaultMessage: "Birthday"
      },
      campaign: {
         id: "app.filtersBar.campaign",
         defaultMessage: "Campaign"
      },
      miscellanenous: {
         id: "app.filtersBar.miscellanenous",
         defaultMessage: "Miscellanenous"
      },
      pointsEarned: {
         id: "app.filtersBar.pointsEarned",
         defaultMessage: "Points Earned"
      },
      pointsExpiry: {
         id: "app.filtersBar.pointsExpiry",
         defaultMessage: "Points Expiry"
      },
      pointsRedeemed: {
         id: "app.filtersBar.pointsRedeemed",
         defaultMessage: "Points Redeemed"
      },
      receipt: {
         id: "app.filtersBar.receipt",
         defaultMessage: "Receipt"
      },
      redeemPointsOTP: {
         id: "app.filtersBar.redeemPointsOTP",
         defaultMessage: "Redeem Points OTP"
      },
      pending: {
         id: "app.filtersBar.pending",
         defaultMessage: "Pending"
      },
      fulfiled: {
         id: "app.filtersBar.fulfiled",
         defaultMessage: "Fulfiled"
      },
      cards: {
         id: "app.filtersBar.cards",
         defaultMessage: "Cards"
      },
      wallets: {
         id: "app.filtersBar.wallets",
         defaultMessage: "Wallets"
      },
      visaCard: {
         id: "app.filtersBar.visaCard",
         defaultMessage: "Visa Card"
      },
      american: {
         id: "app.filtersBar.american",
         defaultMessage: "American Express Card"
      },
      rupay: {
         id: "app.filtersBar.rupay",
         defaultMessage: "Rupay Card"
      },
      diners: {
         id: "app.filtersBar.diners",
         defaultMessage: "Diners Card"
      },
      paymentWallet: {
         id: "app.filtersBar.paymentWallet",
         defaultMessage: "Paytm Wallet"
      },
      mobikwikWallet: {
         id: "app.filtersBar.mobikwikWallet",
         defaultMessage: "Mobikwik Wallet"
      },
      freecharge: {
         id: "app.filtersBar.freecharge",
         defaultMessage: "Freecharge Wallet"
      },
      oxigen: {
         id: "app.filtersBar.oxigen",
         defaultMessage: "Oxigen Wallet"
      },
      phonePeWallet: {
         id: "app.filtersBar.phonePeWallet",
         defaultMessage: "PhonePe Wallet"
      },
      gPayWallet: {
         id: "app.filtersBar.gPayWallet",
         defaultMessage: "GPay Wallet"
      },
      bharathPeWallet: {
         id: "app.filtersBar.bharathPeWallet",
         defaultMessage: "BharatPe Wallet"
      },
      amazonPayWallet: {
         id: "app.filtersBar.amazonPayWallet",
         defaultMessage: "Amazon Pay Wallet"
      },
      loyalty: {
         id: "app.filtersBar.loyalty",
         defaultMessage: "Loyalty"
      },
      creditNote: {
         id: "app.filtersBar.creditNote",
         defaultMessage: "Credit Note"
      },
      bookingStatus: {
         id: "app.filtersBar.bookingStatus",
         defaultMessage: "Booking Status"
      },
      reserved: {
         id: "app.filtersBar.reserved",
         defaultMessage: "Reserved"
      },
      visited: {
         id: "app.filtersBar.visited",
         defaultMessage: "Visited"
      },
      channelName: {
         id: "app.filtersBar.channelName",
         defaultMessage: "Channel Name"
      },
      group: {
         id: "app.filtersBar.group",
         defaultMessage: "Group"
      },
      cats: {
         id: "app.filtersBar.cats",
         defaultMessage: "Cats"
      },
      subCats: {
         id: "app.filtersBar.subCats",
         defaultMessage: "Sub-cats"
      },
      batch: {
         id: "app.filtersBar.batch",
         defaultMessage: "Batch"
      },
      manufacturer: {
         id: "app.filtersBar.manufacturer",
         defaultMessage: "Manufacturer"
      },
      batches: {
         id: "app.filtersBar.batches",
         defaultMessage: "Batches"
      },
      sales: {
         id: "app.filtersBar.sales",
         defaultMessage: "Sales"
      },
      return: {
         id: "app.filtersBar.return",
         defaultMessage: "Return"
      },
      stockSold: {
         id: "app.filtersBar.stockSold",
         defaultMessage: "Stock Sold"
      },
      modifer: {
         id: "app.filtersBar.modifer",
         defaultMessage: "Modifer"
      },
      product: {
         id: "app.filtersBar.product",
         defaultMessage: "Product"
      },
      hindeNonsaleableItems: {
         id: "app.filtersBar.hindeNonsaleableItems",
         defaultMessage: "Hide non-saleable items?"
      },
      productType: {
         id: "app.filtersBar.productType",
         defaultMessage: "Product Type"
      },
      operation: {
         id: "app.filtersBar.operation",
         defaultMessage: "Operation"
      },
      stockWastage: {
         id: "app.filtersBar.stockWastage",
         defaultMessage: "Stock Wastage"
      },
      specific: {
         id: "app.filtersBar.specific",
         defaultMessage: "Specific"
      },
      remarks: {
         id: "app.filtersBar.remarks",
         defaultMessage: "Remarks"
      },
      operationID: {
         id: "app.filtersBar.operationID",
         defaultMessage: "Operation ID"
      },
      device: {
         id: "app.filtersBar.device",
         defaultMessage: "Device"
      },
      syncType: {
         id: "app.filtersBar.syncType",
         defaultMessage: "Sync Type"
      },
      customerPhone: {
         id: "app.filtersBar.customerPhone",
         defaultMessage: "Customer Phone"
      },
      showOnlyDiscountedInvoice: {
         id: "app.filtersBar.showOnlyDiscountedInvoice",
         defaultMessage: "Show only Discounted Invoice"
      },
      selectLogsType: {
         id: "app.filtersBar.selectLogsType",
         defaultMessage: "Select Entity Type"
      },
   },
   navbar: {
      loggedInAs: {
         id: "app.navbar.loggedInAs",
         defaultMessage: "Logged in as",
      },
      signOut: {
         id: "app.navbar.signOut",
         defaultMessage: "Sign Out"
      },
      chainId: {
         id: "app.navbar.chainId",
         defaultMessage: "Chain ID"
      },
      languageSettings: {
         id: "app.navbar.languageSettings",
         defaultMessage: "Language Settings"
      },
      changePassword: {
         id: "app.navbar.changePassword",
         defaultMessage: "Change Password"
      },
      switchTo: {
         id: "app.navbar.switchTo",
         defaultMessage: "Switch to"
      },
      oldVersion: {
         id: "app.navbar.oldVersion",
         defaultMessage: "Old Version"
      },
      search: {
         id: "app.navbar.search",
         defaultMessage: "Search"
      },
      popularSearch: {
         id: "app.navbar.popularSearch",
         defaultMessage: "Popular Searches"
      },
      lookingForToday: {
         id: "app.navbar.lookingForToday",
         defaultMessage: "What are you looking for today?"
      },
      clear: {
         id: "app.navbar.clear",
         defaultMessage: "Clear"
      },
      setDefaultLanguage: {
         id: "app.navbar.setDefaultLanguage",
         defaultMessage: "Set default language"
      },
   },
   home: {
      welcome: {
         id: "app.home.welcome",
         defaultMessage: "Welcome"
      },
      buisnessStatistic: {
         id: "app.home.buisnessStatistic",
         defaultMessage: "Business statistics for"
      },
      topSellingProducts: {
         id: "app.home.topSellingProducts",
         defaultMessage: "Top Selling Products"
      },
      salesTrend: {
         id: "app.home.salesTrend",
         defaultMessage: "Sales Trend"
      },
      storeSales: {
         id: "app.home.storeSales",
         defaultMessage: "Store Sales"
      },
      grossSales: {
         id: "app.home.grossSales",
         defaultMessage: "Gross Sales"
      },
      totalCollection: {
         id: "app.home.totalCollection",
         defaultMessage: "Total Collection"
      },
      eStoreInsights: {
         id: "app.home.eStoreInsights",
         defaultMessage: "eStore Insights"
      },
      paymentBreakup: {
         id: "app.home.paymentBreakup",
         defaultMessage: "Payment Breakup"
      },
      taxCollected: {
         id: "app.home.taxCollected",
         defaultMessage: "Tax Collected",
      },
      reports: {
         id: "app.home.reports",
         defaultMessage: "Reports"
      },
      urbanpiper: {
         id: "app.home.urbanpiper",
         defaultMessage: "Urbanpiper"
      },
      eStore: {
         id: "app.home.eStore",
         defaultMessage: "eStore"
      },
      khata: {
         id: "app.home.khata",
         defaultMessage: "Khata"
      },
      integrations: {
         id: "app.home.integrations",
         defaultMessage: "Integrations"
      },
      KDS: {
         id: "app.home.KDS",
         defaultMessage: "KDS"
      }
   },
   newKeys: {
      catalogDashBoard: {
         brandWiseItemDistribution: {
            id: "app.newKeys.catalogDashBoard.brandWiseItemDistribution",
            defaultMessage: "Brand wise item distribution"
         }
      },
      products:{
         productsCreateNotifier: {
            id: "app.newKeys.products.productsCreateNotifier",
            defaultMessage: "Product successfully created."
         },
         productsUpdatedNotifier: {
            id: "app.newKeys.products.productsUpdatedNotifier",
            defaultMessage: "Product successfully Updated."
         },
         productsCreateNotifierErr: {
            id: "app.newKeys.products.productsCreateNotifierErr",
            defaultMessage: "Error creating product."
         },
         productsUpdatedNotifierErr: {
            id: "app.newKeys.products.productsUpdatedNotifierErr",
            defaultMessage: "Unable to update product."
         },
         productsUpdatedNotifierErr2: {
            id: "app.newKeys.products.productsUpdatedNotifierErr2",
            defaultMessage: "Error updating product."
         },
      },
      subCategory: {
         subCategoryID: {
            id: "app.newKeys.subCategory.subCategoryID",
            defaultMessage: "Sub Category ID"
         },
         subcategoryS: {
            id: "app.newKeys.subCategory.subcategoryS",
            defaultMessage: "sub category(s)"
         },
         duplicateSubCat: {
            id: "app.newKeys.subCategory.duplicateSubCat",
            defaultMessage: "Duplicate Sub-Cateogries"
         },
         duplicateSubCatDesc: {
            id: "app.newKeys.subCategory.duplicateSubCatDesc",
            defaultMessage: "The listed subcategories  are duplicate in the uploaded subcategories "
         },
      },
      brand: {
         duplicateBrands: {
            id: "app.newKeys.brand.duplicateBrands",
            defaultMessage: "Duplicate Brands"
         },
         duplicateBrandsDesc: {
            id: "app.newKeys.brand.duplicateBrandsDesc",
            defaultMessage: "The listed brands  are duplicate in the uploaded brands"
         },
         brandTotalKeyWord: {
            id: "app.newKeys.brand.brandTotalKeyWord",
            defaultMessage: "Brand(s)"
         },
      },
      category: {
         bulkCreateCategories: {
            id: "app.newKeys.category.bulkCreateCategories",
            defaultMessage: "Bulk - Create Categories"
         },
         bulkEditCategories: {
            id: "app.newKeys.category.bulkEditCategories",
            defaultMessage: "Bulk - Edit Categories"
         },
         multipleCategories: {
            id: "app.newKeys.category.multipleCategories",
            defaultMessage: "multiple categories"
         },
         categoryS: {
            id: "app.newKeys.category.categoryS",
            defaultMessage: "category(s)"
         },
         duplicateCateogry: {
            id: "app.newKeys.category.duplicateCateogry",
            defaultMessage: "Duplicate Cateogry"
         },
         duplicateCateogryDesc: {
            id: "app.newKeys.category.duplicateCateogryDesc",
            defaultMessage: "The listed category  are duplicate in the uploaded cateogries"
         },
         categoryID: {
            id: "app.newKeys.category.categoryID",
            defaultMessage: "Category ID"
         },
         previewCreateCategories: {
            id: "app.newKeys.category.previewCreateCategories",
            defaultMessage: "Preview - Create Categories"
         },
         previewEditCategories: {
            id: "app.newKeys.category.previewEditCategories",
            defaultMessage: "Preview - Edit Categories"
         }
      },
      common: {
         isBulkOps: {
            id: "app.newKeys.common.isBulkOps",
            defaultMessage: "Use bulk operation"
         },
         QuantitySoldInCombo: {
            id: "app.newKeys.common.QuantitySoldInCombo",
            defaultMessage: "Quantity Sold in Combo"
         },
         unisex:{
            id: "app.newKeys.common.unisex",
            defaultMessage: "Unisex" 
         },
         edit:{
            id: "app.newKeys.common.edit",
            defaultMessage: "edit " 
         },
         multipleBrands:{
            id: "app.newKeys.common.multipleBrands",
            defaultMessage: "multiple brands" 
         },
         multipleSubCategories:{
            id: "app.newKeys.common.multipleSubCategories",
            defaultMessage: "multiple subcategories" 
         },
         achieved: {
            id: "app.newKeys.common.achieved",
            defaultMessage: "Achieved"
         },
         notAchieved:{
            id: "app.newKeys.common.notAchieved",
            defaultMessage: "Not Achieved"
         },
         chain:{
            id: "app.newKeys.common.chain",
            defaultMessage: "Chain"
         },
         inActive:{
            id: "app.newKeys.common.inActive",
            defaultMessage: "Inactive"
         },
         noProduct:{
            id: "app.newKeys.common.noProduct",
            defaultMessage: "No Product (s)"
         },
         uncontrolledTooltipText:{
            id: "app.newKeys.common.uncontrolledTooltipText",
            defaultMessage: "Select multiple products and click on delete icon to delete multiple products at once."
         }
      },
      modifiers: {
         productsCanNotBeRepeated: {
            id: "app.newKeys.modifiers.productsCanNotBeRepeated",
            defaultMessage: "Products cannot be repeated in added recipes!"
         },
         productsFillQtyErr: {
            id: "app.newKeys.modifiers.productsFillQtyErr",
            defaultMessage: "Products fill Quanity in Attach recipe products!"
         },
         regionsAvaiable: {
            id: "app.newKeys.modifiers.regionsAvaiable",
            defaultMessage: " Region(s) available"
         },
         selectDateForSelectingTime: {
            id: "app.newKeys.modifiers.selectDateForSelectingTime",
            defaultMessage: "Please Select Date for selecting time"
         }
      },
      membership: {
         plsFillName: {
            id: "app.newKeys.membership.plsFillName",
            defaultMessage: "Please fill name"
         },
         plsFillValidity: {
            id: "app.newKeys.membership.plsFillValidity",
            defaultMessage: "Please fill validity"
         },
         fillDiscountAmount: {
            id: "app.newKeys.membership.fillDiscountAmount",
            defaultMessage: "Please fill discount amount"
         },
         fillPrice: {
            id: "app.newKeys.membership.fillPrice",
            defaultMessage: "Please fill Price"
         },
         fillQty: {
            id: "app.newKeys.membershipfillQty",
            defaultMessage: "Please fill quantity"
         },
         validityGreater0: {
            id: "app.newKeys.membership.validityGreater0",
            defaultMessage: "Validity should be greater than 0"
         },
         discountShouldGreater0: {
            id: "app.newKeys.membership.discountShouldGreater0",
            defaultMessage: "Discount should be greater than or equal to 0"
         },
         priceShouldGreter0: {
            id: "app.newKeys.membership.priceShouldGreter0",
            defaultMessage: "Price should be greater than 0!"
         },
         quantityGreater0: {
            id: "app.newKeys.membership.quantityGreater0",
            defaultMessage: "Quantity should be greater than 0!"
         },
         discountPercentErr: {
            id: "app.newKeys.membership.discountPercentErr",
            defaultMessage: "Percent discount can't be more than 100!"
         },
         selectOneProd: {
            id: "app.newKeys.membership.selectOneProd",
            defaultMessage: "Please Select Product"
         },
         membershipId: {
            id: "app.newKeys.membership.membershipId",
            defaultMessage: "Membership ID"
         },
         membershipName: {
            id: "app.newKeys.membership.membershipName",
            defaultMessage: "Membership Name"
         },
         purchaseOnOrder: {
            id: "app.newKeys.membership.purchaseOnOrder",
            defaultMessage: "Purchased On Order"
         },
         purchaseDate: {
            id: "app.newKeys.membership.purchaseDate",
            defaultMessage: "Purchase Date"
         },
         grossValue: {
            id: "app.newKeys.membership.grossValue",
            defaultMessage: "Gross Value"
         },
         finalDiscountValue: {
            id: "app.newKeys.membership.finalDiscountValue",
            defaultMessage: "Final Discount Value"
         },
         membershipCreated : {
            id: "app.newKeys.membership.membershipCreated",
            defaultMessage: "Membership Created Successfully."
         },
         membershipEdited : {
            id: "app.newKeys.membership.membershipEdited",
            defaultMessage: "Membership Edited Successfully."
         },
         errorCreating : {
            id: "app.newKeys.membership.errorCreating",
            defaultMessage: "Error while Creating Membership."
         },
         errorUpdate : {
            id: "app.newKeys.membership.errorUpdate",
            defaultMessage: "Error while Editing Membership."
         },
         deleteMembership : {
            id: "app.newKeys.membership.deleteMembership",
            defaultMessage: "Membership Deleted Successfully."
         },
         errorOnDelete : {
            id: "app.newKeys.membership.errorOnDelete",
            defaultMessage: "Error while Deleting Membership."
         },
         preview : {
            id: "app.newKeys.membership.preview",
            defaultMessage: "Membership Preview"
         },
         membershipRepresentation:{
            id: "app.newKeys.membership.membershipRepresentation",
            defaultMessage: "Membership Representation"
         },
         membershipRepresentationDesc:{
            id: "app.newKeys.membership.membershipRepresentationDesc",
            defaultMessage: "Upload a membership picture and/or pick a color to uniquely identify the membership."
         },
         membershipConfiguration:{
            id: "app.newKeys.membership.membershipConfiguration",
            defaultMessage: "Membership Configuration"
         },
         maximumDiscountAmount:{
            id: "app.newKeys.membership.maximumDiscountAmount",
            defaultMessage: "Maximum Discount Amount"
         },
         maximumLifetimeDiscount:{
            id: "app.newKeys.membership.maximumLifetimeDiscount",
            defaultMessage: "Maximum Lifetime Discount" 
         }, 
         finalAmount: {
            id: "app.newKeys.membership.finalAmount",
            defaultMessage: "Final Amount"
         },
         usedOnOrder: {
            id: "app.newKeys.membership.usedOnOrder",
            defaultMessage: "Used on Order"
         },
         membershipPurchaseDetails: {
            id: "app.newKeys.membership.membershipPurchaseDetails",
            defaultMessage: "Membership purchase Details"
         },
         purchaseId: {
            id: "app.newKeys.membership.purchaseId",
            defaultMessage: "Purchase ID"
         },
         membershipTracker: {
            id: "app.newKeys.membership.membershipTracker",
            defaultMessage: "Membership Tracker"
         }
      },
      measurementUnits: {
         deleteUnit: {
            id: "app.newKeys.measurementUnits.deleteUnit",
            defaultMessage: "Are you sure you want to delete Unit"
         },
         unitReq : {
            id: "app.newKeys.measurementUnits.unitReq",
            defaultMessage: "Unit name is required."
         },
         conversionFactorReq : {
            id: "app.newKeys.measurementUnits.conversionFactorReq",
            defaultMessage: "Conversion factor is required."
         },
         createdSuccess : {
            id: "app.newKeys.measurementUnits.createdSuccess",
            defaultMessage: "Measurement unit successfully created."
         },
         createdError : {
            id: "app.newKeys.measurementUnits.createdError",
            defaultMessage: "Error creating measurement unit."
         },
         updateSuccess : {
            id: "app.newKeys.measurementUnits.updateSuccess",
            defaultMessage: "Measurement unit successfully updated."
         },
         updateError : {
            id: "app.newKeys.measurementUnits.updateError",
            defaultMessage: "Error updating measurement unit."
         }
      },
      voucher: {
         absolute: {
            id: "app.newKeys.voucher.absolute",
            defaultMessage: "ABSOLUTE"
         },
         percentage: {
            id: "app.newKeys.voucher.percentage",
            defaultMessage: "PERCENTAGE"
         },
         createdSuccess : {
            id: "app.newKeys.voucher.createdSuccess",
            defaultMessage: "Voucher Successfully Created."
         },
         createdError : {
            id: "app.newKeys.voucher.createdError",
            defaultMessage: "Error Creating Voucher."
         },
         updateSuccess : {
            id: "app.newKeys.voucher.updateSuccess",
            defaultMessage: "Voucher Successfully Updated."
         },
         updateError : {
            id: "app.newKeys.voucher.updateError",
            defaultMessage: "Error Updating Voucher."
         },
         alreadyPresent : {
            id: "app.newKeys.voucher.alreadyPresent",
            defaultMessage: "Voucher Code Already Present."
         },
         added : {
            id: "app.newKeys.voucher.added",
            defaultMessage: "Vouchers successfully added"
         },
         addErr : {
            id: "app.newKeys.voucher.addErr",
            defaultMessage: "Error adding vouchers."
         },
         deleteSuccess : {
            id: "app.newKeys.voucher.deleteSuccess",
            defaultMessage: "Voucher Deleted Successfully."
         },
         deleteErr : {
            id: "app.newKeys.voucher.deleteErr",
            defaultMessage: "Error Deleting Voucher."
         }
      },
      giftVouchers: {
         totalKeyword : {
            id: "app.newKeys.giftVouchers.totalKeyword",
            defaultMessage : "gift voucher(s)"
         },
         generateVoucherCode: {
            id: "app.newKeys.giftVouchers.generateVoucherCode",
            defaultMessage: "Generate Voucher Code(s)"
         },
         pleaseSelectSource: {
            id: "app.newKeys.giftVouchers.pleaseSelectSource",
            defaultMessage: "Please Select Source"
         },
         listOfGiftVouchers: {
            id: "app.newKeys.giftVouchers.listOfGiftVouchers",
            defaultMessage: "List of all Gift Vouchers"
         }
      },
      createGiftVoucher: {
         sellingPriceRequired: {
            id: "app.newKeys.createGiftVoucher.sellingPriceRequired",
            defaultMessage: "Selling price is required to map the product to a particular store."
         },
         uploadVoucherPicture: {
            id: "app.newKeys.createGiftVoucher.uploadVoucherPicture",
            defaultMessage: "Upload a voucher picture and/or pick a voucher color to uniquely identify the product."
         },
         uniqueProductPicture: {
            id: "app.newKeys.createGiftVoucher.uniqueProductPicture",
            defaultMessage: "This allows you to upload a unique product picture. (Max-size: 10 Mb)"
         },
         basicInfoAboutProduct: {
            id: "app.newKeys.createGiftVoucher.basicInfoAboutProduct",
            defaultMessage: "Provide some basic information about the product."
         },
         specialAttributesDesc: {
            id: "app.newKeys.createGiftVoucher.specialAttributesDesc",
            defaultMessage: "Create custom identifiers like size, color, barcode etc. to differentiate one product SKU from another."
         },
         voucherConfiguration: {
            id: "app.newKeys.createGiftVoucher.voucherConfiguration",
            defaultMessage: "Voucher Configuration"
         },
         setVoucherConfig: {
            id: "app.newKeys.createGiftVoucher.setVoucherConfig",
            defaultMessage: "Set voucher configuration."
         },
         minimumCartValue: {
            id: "app.newKeys.createGiftVoucher.minimumCartValue",
            defaultMessage: "Minimum Cart Value"
         },
         maximumVoucherDiscount: {
            id: "app.newKeys.createGiftVoucher.maximumVoucherDiscount",
            defaultMessage: "Maximum Voucher Discount"
         },
         validityDays: {
            id: "app.newKeys.createGiftVoucher.validityDays",
            defaultMessage: "Validity Days"
         },
         allowVariablePricing: {
            id: "app.newKeys.createGiftVoucher.allowVariablePricing",
            defaultMessage: "Allow variable pricing ?"
         },
         manageInventoryDesc: {
            id: "app.newKeys.createGiftVoucher.manageInventoryDesc",
            defaultMessage: "Define the basic information about this product's inventory management. Visit the inventory module for extensive functionality."
         }
      },
      updateGiftVoucher: {
         decrease: {
            id: "app.newKeys.updateGiftVoucher.decrease",
            defaultMessage: "DECREASE"
         },
         increase: {
            id: "app.newKeys.updateGiftVoucher.increase",
            defaultMessage: "INCREASE"
         },
         noEffect: {
            id: "app.newKeys.updateGiftVoucher.noEffect",
            defaultMessage: "NO EFFECT"
         }
      },
      channels: {
         deleteChannel: {
            id: "app.newKeys.channels.deleteChannel",
            defaultMessage: "Delete Channel"
         },
         online: {
            id: "app.newKeys.channels.online",
            defaultMessage: "ONLINE",
         },
         offline: {
            id: "app.newKeys.channels.offline",
            defaultMessage: "OFFLINE"
         }
      },
      listOfCurrencyConversion: {
         deleteConversion: {
            id: "app.newKeys.listOfCurrencyConversion.deleteConversion",
            defaultMessage: "Delete Currency Conversion"
         }
      },
      slPayment: {
         slPaymentDesc: {
            id: "app.newKeys.slPayment.slPaymentDesc",
            defaultMessage: "Make a payment by entering the appropriate details."
         },
         addDiscount: {
            id: "app.newKeys.slPayment.addDiscount",
            defaultMessage: "Add discount"
         }
      },
      manageInventoryDesc: {
         id: "app.newKeys.createGiftVoucher.manageInventoryDesc",
         defaultMessage: "Define the basic information about this product's inventory management. Visit the inventory module for extensive functionality."
      },
    updateGiftVoucher: {
      decrease: {
         id: "app.newKeys.updateGiftVoucher.decrease",
         defaultMessage: "DECREASE"
      },
      increase: {
         id: "app.newKeys.updateGiftVoucher.increase",
         defaultMessage: "INCREASE"
      },
      noEffect: {
         id: "app.newKeys.updateGiftVoucher.noEffect",
         defaultMessage: "NO EFFECT"
      }
    },
    appsettings: {
      doYouWantToShowProducts: {
         id: "app.newKeys.appsettings.doYouWantToShowProducts",
         defaultMessage: "Do You Want to Show Products ?"
      },
    },
    staff:{
      viewStoresDescription: {
         id: "app.newKeys.staff.viewStoresDescription",
         defaultMessage: "List Of All Respected Stores",
         description: "",
      },
    },
    systemAttributes: {
      editSystemAttribute :{
         id: "app.newKeys.systemAttributes.editSystemAttribute",
         defaultMessage: "Edit System Attribute",
      },
      editSystemAttributeDesc :{
         id: "app.newKeys.systemAttributes.editSystemAttributeDesc",
         defaultMessage: "Update system attribute by providing below information",
      },
      editSystemAttributeTitle :{
         id: "app.newKeys.systemAttributes.editSystemAttributeTitle",
         defaultMessage: "System Attribute Information",
      },
      shouldTheAttributeBeActive:{
         id: "app.newKeys.systemAttributes.shouldTheAttributeBeActive",
         defaultMessage: "Should the attribute be active?",
      },
      shouldTheAttributeBeActiveDesc:{
         id: "app.newKeys.systemAttributes.shouldTheAttributeBeActiveDesc",
         defaultMessage: "Enabling this will make the attribute actively applicable.",
      },
      printThisAttributeOnBill:{
         id: "app.newKeys.systemAttributes.printThisAttributeOnBill",
         defaultMessage: "Print this attribute on bill?",
      },
      printThisAttributeOnBillDesc:{
         id: "app.newKeys.systemAttributes.printThisAttributeOnBillDesc",
         defaultMessage: "Enabling this would print the attribute name while billing.",
      },
      shouldTheAttributeBeMandatory:{
         id: "app.newKeys.systemAttributes.shouldTheAttributeBeMandatory",
         defaultMessage: "Should the attribute be mandatory?",
      },
      shouldTheAttributeBeMandatoryDesc:{
         id: "app.newKeys.systemAttributes.shouldTheAttributeBeMandatoryDesc",
         defaultMessage: "Enabling this will make the attribute mandatorily applicable",
      },
      shouldTheAttributeBeUnique:{
         id: "app.newKeys.systemAttributes.shouldTheAttributeBeUnique",
         defaultMessage: "Should the attribute be unique?",
      },
      shouldTheAttributeBeUniqueDesc:{
         id: "app.newKeys.systemAttributes.shouldTheAttributeBeUniqueDesc",
         defaultMessage: "Enabling this will make the attribute uniquely applicable.",
      },
    },
    chainProfile:{
      Upgrade:{
         id: "app.newKeys.chainProfile.Upgrade",
         defaultMessage: "Upgrade",
      }
    },  
    inventory : {
      selectTransactionID : {
         id : "app.newKeys.inventory.selectTransactionID",
         defaultMessage : "Please select a Transaction ID."
      },
      step2AddStock : {
         id : "app.newKeys.inventory.step2AddStock",
         defaultMessage : "Step : 2 Add Stock"
      },
      selectTansactionIdPlaceHolder : {
         id : "app.newKeys.inventory.selectTansactionIdPlaceHolder",
         defaultMessage : "Select Transaction ID"
      },
      selectVendorBillIDPlaceHolder : {
         id : "app.newKeys.inventory.selectVendorBillIDPlaceHolder",
         defaultMessage : "Select Vendor Bill ID"
      },
      uploadExcel :{
         id : "app.newKeys.inventory.uploadExcel",
         defaultMessage : "Please Upload Excel file!"
      },
      transferID : {
         id : "app.newKeys.inventory.transferID",
         defaultMessage : "Stock In (Transfer)"
      },
      unAssignedProducts: {
         id: "app.newKeys.inventory.unAssignedProducts",
         defaultMessage: "Unassigned Products"
      },
      wouldYouLikeToContinueWithOperation : {
         id: "app.newKeys.inventory.wouldYouLikeToContinueWithOperation",
         defaultMessage: "*The listed products are not available in the selected destination. Would you like to proceed with the transaction?",
      },
      selectFulfillmentCenter: {
         id: "app.newKeys.inventory.selectFulfillmentCenter",
         defaultMessage: "Please select the fulfillment center.",
      },
      noProductsMappedError: {
         id: "app.newKeys.inventory.noProductsMappedError",
         defaultMessage: "No products are currently found in the selected destination. Please upload a valid file with products that are mapped accordingly.",
      },
      transporterGSTN : {
         id: "app.newKeys.inventory.transporterGSTN",
         defaultMessage: "Transporter GST Number is required.",
      },
      distance : {
         id: "app.newKeys.inventory.distance",
         defaultMessage: "Distance is required.",
      },
      distanceLabel : {
         id: "app.newKeys.inventory.distanceLabel",
         defaultMessage: "Distance (KM)",
      },
      transporterGSTNLength : {
         id: "app.newKeys.inventory.transporterGSTNLength",
         defaultMessage: "Transporter GST Number length must be 15 digit.",
      },
      transportDateErr : {
         id: "app.newKeys.inventory.transportDateErr",
         defaultMessage: "Transporter Document Date must be greater than or equal to current date.",
      },
      importExportError : {
         id: "app.newKeys.inventory.importExportError",
         defaultMessage : "Import and export options will be applicable only if the countries are different"
      },
      vehicleNumberValidation : {
         id: "app.newKeys.inventory.vehicleNumberValidation",
         defaultMessage : "Velicle Number is required."
      },
      transporterDocNoValidation : {
         id: "app.newKeys.inventory.transporterDocNoValidation",
         defaultMessage : "Transporter Document Number is required."
      },
      subSupplyDescErr : {
         id: "app.newKeys.inventory.subSupplyDescErr",
         defaultMessage : "SubSupply Description is required."
      },
      subSupplyDescLengthErr : {
         id: "app.newKeys.inventory.subSupplyDescLengthErr",
         defaultMessage : "SubSupply Description length upto 20 characters."
      },
      SubSupplyFirst : {
         id: "app.newKeys.inventory.SubSupplyFirst",
         defaultMessage : "Please select SubSupply Type first."
      },
      supplyFirst : {
         id: "app.newKeys.inventory.supplyFirst",
         defaultMessage : "Please select Supply Type first."
      },
      noProdFoundForStockOps : {
         id: "app.newKeys.inventory.noProdFoundForStockOps",
         defaultMessage : "No product found for stock operation."
      },
      stockInPoBulk : {
         id: "app.newKeys.inventory.stockInPoBulk",
         defaultMessage : "Stock In (Purchase Order)"
      },
      stockInPoTransactionIdNotFound : {
         id: "app.newKeys.inventory.stockInPoTransactionIdNotFound",
         defaultMessage : "File does not match the template downloaded. Please check."
      },
      selectBatch : {
         id: "app.newKeys.inventory.selectBatch",
         defaultMessage : "Select The Batch"
      },
      batchNotFound : {
         id: "app.newKeys.inventory.batchNotFound",
         defaultMessage : "Batch Not Found"
      },
      entityName : {
         id: "app.newKeys.inventory.entityName",
         defaultMessage : "Entity Name"
      },
      entityID : {
         id: "app.newKeys.inventory.entityID",
         defaultMessage : "Entity ID"
      },
      entityType : {
         id: "app.newKeys.inventory.entityType",
         defaultMessage : "Entity Type"
      },
      purchaseReturnInfo: {
         id: "app.newKeys.inventory.purchaseReturnInfo",
         defaultMessage: "Stock Out / Purchase Return Information",
      },
      poNoProdFound : {
         id: "app.newKeys.inventory.poNoProdFound",
         defaultMessage: "No product found for stock operation.",
      },
      selectPO : {
         id: "app.newKeys.inventory.selectPO",
         defaultMessage: "Please Select Purchase Order ID",
      },
      wouldLikeToProceedOps :  {
         id: "app.newKeys.inventory.wouldLikeToProceedOps",
         defaultMessage: "*The products you have selected are not available in the selected source. Would you like to continue with the transaction?",
      }
   },
    channels: {
      deleteChannel: {
         id: "app.newKeys.channels.deleteChannel",
         defaultMessage: "Delete Channel"
      },
      online: {
         id: "app.newKeys.channels.online",
         defaultMessage: "ONLINE",
      },
      offline: {
         id: "app.newKeys.channels.offline",
         defaultMessage: "OFFLINE"
      }
    },
    listOfCurrencyConversion: {
      deleteConversion:{
         id: "app.newKeys.listOfCurrencyConversion.deleteConversion",
         defaultMessage: "Delete Currency Conversion"
      }
    },
    bulkCurrencyConversion: {
      bulk: {
         id: "app.newKeys.bulkCurrencyConversion.bulk",
         defaultMessage: "Bulk"
      },
      downloadAndUpload:{
         id: "app.newKeys.bulkCurrencyConversion.downloadAndUpload",
         defaultMessage: "Click here to download excel template & upload it to "
      },
      currencyConversions: {
         id: "app.newKeys.bulkCurrencyConversion.currencyConversions",
         defaultMessage: "currency conversions",
      },
      repeatedCurrencyError: {
         id: "app.newKeys.bulkCurrencyConversion.repeatedCurrencyError",
         defaultMessage: "Base Currency Code And Target Currency Code should not be the same."
      },
      validExcelError: {
         id: "app.newKeys.bulkCurrencyConversion.validExcelError",
         defaultMessage: "Please upload the valid excel file..."
      },
      conversionFactorReq: {
         id: "app.newKeys.bulkCurrencyConversion.conversionFactorReq",
         defaultMessage: "Conversion factor is required....!"
      },
      updateTitle : {
         id: "app.newKeys.bulkCurrencyConversion.updateTitle",
         defaultMessage: "Bulk - Update Currency Conversion Preview"
      },
      createTitle : {
         id: "app.newKeys.bulkCurrencyConversion.createTitle",
         defaultMessage: "Bulk - Create Currency Conversion Preview"
      }
    },
    slPayment: {
      slPaymentDesc: {
         id: "app.newKeys.slPayment.slPaymentDesc",
         defaultMessage: "Make a payment by entering the appropriate details."
      },
      addDiscount: {
         id: "app.newKeys.slPayment.addDiscount",
         defaultMessage: "Add discount"
      }
    },
    orderSummarized: {
      orderSummarized: {
         id: "app.newKeys.orderSummarized.orderSummarized",
         defaultMessage: "Order Summarized"
      },
      orderSummarizedDesc: {
         id: "app.newKeys.orderSummarized.orderSummarizedDesc",
         defaultMessage: "Fill details for getting orders Summarized details"
      },
      pleaseEnterEmail: {
         id: "app.newKeys.orderSummarized.pleaseEnterEmail",
         defaultMessage: "Please Enter Email"
      }
    },
    users:{
      userID: {
         id: "app.newKeys.users.userID",
         defaultMessage: "User ID"
      },
      attendanceDate: {
         id: "app.newKeys.users.attendanceDate",
         defaultMessage: "Attendance Date"
      },
      inTime: {
         id: "app.newKeys.users.inTime",
         defaultMessage: "In Time"
      },
      outTime: {
         id: "app.newKeys.users.outTime",
         defaultMessage: "Out Time"
      },
      totalHours: {
         id: "app.newKeys.users.totalHours",
         defaultMessage: "Total Hours"
      },
      outstandingTime: {
         id: "app.newKeys.users.outstandingTime",
         defaultMessage: "Outstanding Time"
      },
      attendanceReportDesc: {
         id: "app.newKeys.users.attendanceReportDesc",
         defaultMessage: "Attendance Report description can be found here"
      },
      attendenceS:{
         id: "app.newKeys.users.attendenceS",
         defaultMessage: "Attendence(s)"
      },
      openingUser:{
         id: "app.newKeys.users.openingUser",
         defaultMessage: "Opening User"
      },
      openDate:{
         id: "app.newKeys.users.openDate",
         defaultMessage: "Open date"
      },
      openTime:{
         id: "app.newKeys.users.openTime",
         defaultMessage: "Open Time"
      },
      closeDate:{
         id: "app.newKeys.users.closeDate",
         defaultMessage: "Close Date"
      },
      closingUser:{
         id: "app.newKeys.users.closingUser",
         defaultMessage: "Closing User"
      },
      revertDate:{
         id: "app.newKeys.users.revertDate",
         defaultMessage: "Revert Date"
      },
      revertTime:{
         id: "app.newKeys.users.revertTime",
         defaultMessage: "Revert Time"
      },
      revertedBy:{
         id: "app.newKeys.users.revertedBy",
         defaultMessage: "Reverted By"
      },
      infoDaysReportDesc:{
         id: "app.newKeys.users.infoDaysReportDesc",
         defaultMessage: "Days Info Report description can be found here"
      },
      closeTime:{
         id: "app.newKeys.users.closeTime",
         defaultMessage: "Close Time"
      },
      closeTime:{
         id: "app.newKeys.users.closeTime",
         defaultMessage: "Close Time"
      },
      startTime:{
         id: "app.newKeys.users.startTime",
         defaultMessage: "Start Time"
      },
      batchOpenAmount:{
         id: "app.newKeys.users.batchOpenAmount",
         defaultMessage: "Batch Open Amount"
      },
      batchSales:{
         id: "app.newKeys.users.batchSales",
         defaultMessage: "Batch Sales"
      },
      batchVariance:{
         id: "app.newKeys.users.batchVariance",
         defaultMessage: "Batch Variance"
      },
      batchCloseTenderAmount:{
         id: "app.newKeys.users.batchCloseTenderAmount",
         defaultMessage: "Batch Close Amount"
      },
      varianceRemarks:{
         id: "app.newKeys.users.varianceRemarks",
         defaultMessage: "Variance Remarks"
      },
      batchInfoReportDesc:{
         id: "app.newKeys.users.batchInfoReportDesc",
         defaultMessage: "It displays a detailed report of all batch sales at a store"
      }
    },
    reservation:{
      reservationDate:{
         id: "app.newKeys.reservation.reservationDate",
         defaultMessage: "Reservation Date"
      },
      reservationTime:{
         id: "app.newKeys.reservation.reservationTime",
         defaultMessage: "Reservation Time"
      },
      slotDay:{
         id: "app.newKeys.reservation.slotDay",
         defaultMessage: "Slot Day"
      },
      noOfVisitors:{
         id: "app.newKeys.reservation.noOfVisitors",
         defaultMessage: "No of Visitors"
      },
      bookingAmount:{
         id: "app.newKeys.reservation.bookingAmount",
         defaultMessage: "Booking amount"
      },
      reservationTracker:{
         id: "app.newKeys.reservation.reservationTracker",
         defaultMessage: "Reservation Tracker"
      },
      reservationTrackerDesc:{
         id: "app.newKeys.reservation.reservationTrackerDesc",
         defaultMessage: "Details reports available for the all Reservation bookings"
      },
      totalSlots:{
         id: "app.newKeys.reservation.totalSlots",
         defaultMessage: "Total Slots"
      },
      totalBookings:{
         id: "app.newKeys.reservation.totalBookings",
         defaultMessage: "Total Bookings"
      },
      fullyOccupiedSlots:{
         id: "app.newKeys.reservation.fullyOccupiedSlots",
         defaultMessage: "Fully Occupied Slots"
      },
      reservationSlotsTracker:{
         id: "app.newKeys.reservation.reservationSlotsTracker",
         defaultMessage: "Reservation Slots Tracker"
      },
      reservationSlotsTrackerDesc:{
         id: "app.newKeys.reservation.reservationSlotsTrackerDesc",
         defaultMessage: "Details reports available for the Reservation Slots"
      },
    },
    room:{
      includeRoomInFloor:{
         id: "app.newKeys.room.includeRoomInFloor",
         defaultMessage: "Include Room in Floor"
      },
      viewTables:{
         id: "app.newKeys.room.viewTables",
         defaultMessage: "View Tables"
      },
      tablesIn:{
         id: "app.newKeys.room.tablesIn",
         defaultMessage: "Tables in"
      },
      deleteRoom:{
         id: "app.newKeys.room.deleteRoom",
         defaultMessage: "Are you sure you want to delete Room :"
      },
      sizeValidation:{
         id: "app.newKeys.room.sizeValidation",
         defaultMessage: "Size is required"
      },
      storeValidation:{
         id: "app.newKeys.room.storeValidation",
         defaultMessage: "Please select the store"
      },
      floorValidation:{
         id: "app.newKeys.room.floorValidation",
         defaultMessage: "Please select the Floor"
      },
      roomsInformation:{
         id: "app.newKeys.room.roomsInformation",
         defaultMessage: "Rooms Information"
      },
      roomName:{
         id: "app.newKeys.room.roomName",
         defaultMessage: "Room Name"
      },
      size:{
         id: "app.newKeys.room.size",
         defaultMessage: "Size (Sq.ft)"
      },
      includeRoomInTableAvailabilityCalculations:{
         id: "app.newKeys.room.includeRoomInTableAvailabilityCalculations",
         defaultMessage: "Include Room In Table Availability Calculations."
      }
      
    },
    filtersBar:{
      transctionType:{
         id: "app.newKeys.filtersBar.transctionType",
         defaultMessage: "Transction Type"
      },
      selectInventorySource:{
         id: "app.newKeys.filtersBar.selectInventorySource",
         defaultMessage: "Select Inventory Source"
      },
      itemGroup:{
         id: "app.newKeys.filtersBar.itemGroup",
         defaultMessage: "Item Group"
      },
      activityMode:{
         id: "app.newKeys.filtersBar.activityMode",
         defaultMessage: "Activity Mode"
      }
     },  
    listOfExpense: {
      listOfExpenseDesc: {
         id: "app.newKeys.listOfExpense.listOfExpenseDesc",
         defaultMessage: "List of all expenses generated for selected stores and timeframe."
      },
      expenseId: {
         id: "app.newKeys.listOfExpense.expenseId",
         defaultMessage: "Expense ID"
      }
    },
    expenseOrderDetail: {
      storeEmail: {
         id: "app.newKeys.expenseOrderDetail.storeEmail",
         defaultMessage: "Store Email"
      },
      expenseAmount: {
         id: "app.newKeys.expenseOrderDetail.expenseAmount",
         defaultMessage: "Expense Amount"
      },
      expenseItem: {
         id: "app.newKeys.expenseOrderDetail.expenseItem",
         defaultMessage: "Expense Item"
      },
      netBill: {
         id: "app.newKeys.expenseOrderDetail.netBill",
         defaultMessage: "Net Bill"
      },
      expenseOrderDetailDesc: {
         id: "app.newKeys.expenseOrderDetail.expenseOrderDetailDesc",
         defaultMessage: "Generate the details of Expense."
      },
      generateExpenseDetail: {
         id: "app.newKeys.expenseOrderDetail.generateExpenseDetail",
         defaultMessage: "Generate Expense Details"
      },
      expensePayment: {
         id: "app.newKeys.expenseOrderDetail.expensePayment",
         defaultMessage: "Expense Payment"
      },
      expenseSummary: {
         id: "app.newKeys.expenseOrderDetail.expenseSummary",
         defaultMessage: "Expense Summary"
      },
      expenseOrderList: {
         id: "app.newKeys.expenseOrderDetail.expenseOrderList",
         defaultMessage: "Expense Order List"
      },
      expenseId: {
         id: "app.newKeys.expenseOrderDetail.expenseId",
         defaultMessage: "Expense ID"
      }
    },
    prodExpenseOrderInfo: {
      prodInExpensesDesc: {
         id: "app.newKeys.prodExpenseOrderInfo.prodInExpensesDesc",
         defaultMessage: "List of all the Products of Expense Order."
      }
    },
    expenseCategory: {
      maxAmountAllowed: {
         id: "app.newKeys.expenseCategory.maxAmountAllowed",
         defaultMessage: "Max Amount Allowed"
      },
      deleteExpenseCategory: {
         id: "app.newKeys.expenseCategory.deleteExpenseCategory",
         defaultMessage: "You want to delete expense category"
      },
      expenseCategoryDesc: {
         id: "app.newKeys.expenseCategory.expenseCategoryDesc",
         defaultMessage: "Create Category by providing below information"
      },
      expenseCategoryDesc2: {
         id: "app.newKeys.expenseCategory.expenseCategoryDesc2",
         defaultMessage: "Edit Category by edit below information"
      },
      categoryDesc: {
         id: "app.newKeys.expenseCategory.categoryDesc",
         defaultMessage: "List of all available categories"
      },
      storesInCategory: {
         id: "app.newKeys.expenseCategory.storesInCategory",
         defaultMessage: "Stores in category"
      }
    },
    modifier: {
      basePrice: {
         id: "app.newKeys.modifier.basePrice",
         defaultMessage: "Base Price"
      },
      netSale: {
         id: "app.newKeys.modifier.netSale",
         defaultMessage: "Net Sale"
      }
    },
    cashManageMent : {
      salePunched : {
         id: "app.newKeys.cashManageMent.salePunched",
         defaultMessage: "Sale Punched"
      },
      cummulativeSales : {
         id: "app.newKeys.cashManageMent.cummulativeSales",
         defaultMessage: "Cummulative Sale"
      },
      cashCollected : {
         id: "app.newKeys.cashManageMent.cashCollected",
         defaultMessage: "Cash Entered"
      },
      cummulativeCashCollected : {
         id: "app.newKeys.cashManageMent.cummulativeCashCollected",
         defaultMessage: "Cummulative Cash Collected"
      },
      cashEnteredTime : {
         id: "app.newKeys.cashManageMent.cashEnteredTime",
         defaultMessage: "Cash Entered Time"
      },
      auditGC : {
         id: "app.newKeys.cashManageMent.auditGC",
         defaultMessage: "Audit GC"
      },
      cashShortage : {
         id: "app.newKeys.cashManageMent.cashShortage",
         defaultMessage: "Cash Shortage"
      },
      title : {
         id: "app.newKeys.cashManageMent.title",
         defaultMessage: "Cash Management History"
      },
      description : {
         id: "app.newKeys.cashManageMent.description",
         defaultMessage: "Report for Cash Management History and Details."
      }
    },
    currencyValidation : {
      title : {
         id: "app.newKeys.currencyValidation.title",
         defaultMessage: "Currency Validation"
      },
      description : {
         id: "app.newKeys.currencyValidation.description",
         defaultMessage: "Details reports available for the all Currency Validation"
      },
      validationDate : {
         id: "app.newKeys.currencyValidation.validationDate",
         defaultMessage: "Validation Date"
      },
      validationTime : {
         id: "app.newKeys.currencyValidation.validationTime",
         defaultMessage: "Validation Time"
      },
      openingBalance : {
         id: "app.newKeys.currencyValidation.openingBalance",
         defaultMessage: "Opening Balance"
      },
      totalSalesValue : {
         id: "app.newKeys.currencyValidation.totalSalesValue",
         defaultMessage: "Total Sale Value"
      },
      totalRefundsValue : {
         id: "app.newKeys.currencyValidation.totalRefundsValue",
         defaultMessage: "Total Refund Value"
      },
      closingBalanceSystem : {
         id: "app.newKeys.currencyValidation.closingBalanceSystem",
         defaultMessage: "System Closing Balance"
      },
      closingBalanceUser : {
         id: "app.newKeys.currencyValidation.closingBalanceUser",
         defaultMessage: "User Closing Balance"
      },
      difference : {
         id: "app.newKeys.currencyValidation.difference",
         defaultMessage: "Difference"
      },
      apiError : {
         id: "app.newKeys.currencyValidation.apiError",
         defaultMessage: "Unable to get the data, Please contact support team."
      }
    },
    tinderWiseCollection : {
      title : {
         id: "app.newKeys.tinderWiseCollection.title",
         defaultMessage: "Tender Wise Collection"
      },
      description : {
         id: "app.newKeys.tinderWiseCollection.description",
         defaultMessage: "Details of Tendor Wise Collection Report"
      },
      amountRecieved : {
         id: "app.newKeys.tinderWiseCollection.amountRecieved",
         defaultMessage: "Amount Recieved"
      },
      amountReturned : {
         id: "app.newKeys.tinderWiseCollection.amountReturned",
         defaultMessage: "Amount Returned"
      },
      netCollection : {
         id: "app.newKeys.tinderWiseCollection.netCollection",
         defaultMessage: "Net Collection"
      },
    },
    appointmentsReport : {
      appointmentID : {
         id: "app.newKeys.appointmentsReport.appointmentID",
         defaultMessage: "Appointment ID"
      },
      linkedOrderID : {
         id: "app.newKeys.appointmentsReport.linkedOrderID",
         defaultMessage: "Linked Order ID"
      },
      estimatedCost : {
         id: "app.newKeys.appointmentsReport.estimatedCost",
         defaultMessage: "Estimated Cost"
      },
      appointmentDuration : {
         id: "app.newKeys.appointmentsReport.appointmentDuration",
         defaultMessage: "Appointment Duration"
      },
      appointmentDurationUnit : {
         id: "app.newKeys.appointmentsReport.appointmentDurationUnit",
         defaultMessage: "Appointment Duration Unit"
      },
      serviceCount : {
         id: "app.newKeys.appointmentsReport.serviceCount",
         defaultMessage: "Service Count"
      },
      startTimeLocal : {
         id: "app.newKeys.appointmentsReport.startTimeLocal",
         defaultMessage: "Start Time"
      },
      endTimeLocal : {
         id: "app.newKeys.appointmentsReport.endTimeLocal",
         defaultMessage: "End Time"
      },
      createTime : {
         id: "app.newKeys.appointmentsReport.createTime",
         defaultMessage: "Creation Time"
      },
      createdBy : {
         id: "app.newKeys.appointmentsReport.createdBy",
         defaultMessage: "Created By"
      },
      creationMode : {
         id: "app.newKeys.appointmentsReport.creationMode",
         defaultMessage: "Creation Mode"
      },
      repeatCount : {
         id: "app.newKeys.appointmentsReport.repeatCount",
         defaultMessage: "Frequency(In days)"
      },
      repeatFrequency : {
         id: "app.newKeys.appointmentsReport.repeatFrequency",
         defaultMessage: "Repeat Frequency"
      },
      cancellationReason : {
         id: "app.newKeys.appointmentsReport.cancellationReason",
         defaultMessage: "Cancellation Reason"
      },
      title : {
         id: "app.newKeys.appointmentsReport.title",
         defaultMessage: "Appointments"
      },
      description : {
         id: "app.newKeys.appointmentsReport.description",
         defaultMessage: "List of Appointments."
      },
      CONFIRMED : {
         id: "app.newKeys.appointmentsReport.CONFIRMED",
         defaultMessage: "CONFIRMED"
      },
      CHECKIN : {
         id: "app.newKeys.appointmentsReport.CHECKIN",
         defaultMessage: "CHECK-IN"
      },
      NOSHOW : {
         id: "app.newKeys.appointmentsReport.NOSHOW",
         defaultMessage: "NO-SHOW"
      },
      COMPLETED : {
         id: "app.newKeys.appointmentsReport.COMPLETED",
         defaultMessage: "COMPLETED"
      },
      CANCELLED : {
         id: "app.newKeys.appointmentsReport.CANCELLED",
         defaultMessage: "CANCELLED"
      },
      CONFIRMED : {
         id: "app.newKeys.appointmentsReport.CONFIRMED",
         defaultMessage: "CONFIRMED"
      }, CONFIRMED : {
         id: "app.newKeys.appointmentsReport.CONFIRMED",
         defaultMessage: "CONFIRMED"
      }
    },
    staffSales: {
      serviceID : {
         id: "app.newKeys.staffSales.serviceID",
         defaultMessage: "Service ID"
      },
      staff : {
         id: "app.newKeys.staffSales.staff",
         defaultMessage: "Staff"
      },
      discounts : {
         id: "app.newKeys.staffSales.discounts",
         defaultMessage: "Discounts"
      },
      totalVoidAmount : {
         id: "app.newKeys.staffSales.totalVoidAmount",
         defaultMessage: "Total Void Amount"
      },
      totalTaxes : {
         id: "app.newKeys.staffSales.totalTaxes",
         defaultMessage: "Total Taxes"
      }, 
      title : {
         id : "app.newKeys.staffSales.title",
         defaultMessage : "Staff Sales"
      },
      description : {
         id : "app.newKeys.staffSales.description",
         defaultMessage : "List of Staff Sales."
      }
    },
    serviceByStaff : {
      title : {
         id : "app.newKeys.serviceByStaff.title",
         defaultMessage : "Service By Staff"
      },
      description : {
         id : "app.newKeys.serviceByStaff.description",
         defaultMessage : "List of Service By Staff. "
      }
    },
    stockMovementViaLedger : {
      inventorySourceName : {
         id: "app.newKeys.stockMovementViaLedger.inventorySourceName",
         defaultMessage: "Inventory SourceName"
      },
      stockrequest : {
         id: "app.newKeys.stockMovementViaLedger.stockrequest",
         defaultMessage: "Stock Request"
      },
      stockPurchase : {
         id: "app.newKeys.stockMovementViaLedger.stockPurchase",
         defaultMessage: "Stock Purchase"
      },
      stockTransferred : {
         id: "app.newKeys.stockMovementViaLedger.stockTransferred",
         defaultMessage: "Stock Transferred"
      },
      stockYieldWastage : {
         id: "app.newKeys.stockMovementViaLedger.stockYieldWastage",
         defaultMessage: " Stock Yield Wastage"
      },
      salesOrderStock : {
         id: "app.newKeys.stockMovementViaLedger.salesOrderStock",
         defaultMessage : "Sales Order Stock"
      },
      stockOnHold : {
         id: "app.newKeys.stockMovementViaLedger.stockOnHold",
         defaultMessage : "Stock On Hold"
      },
      invoicedStock : {
         id: "app.newKeys.stockMovementViaLedger.invoicedStock",
         defaultMessage  : "Invoiced Stock"
      },
      stockConsumption : {
         id: "app.newKeys.stockMovementViaLedger.stockConsumption",
         defaultMessage : "Stock Consumption"
      },
      netStockMovement : {
         id: "app.newKeys.stockMovementViaLedger.netStockMovement",
         defaultMessage : "Net Stock Movement"
      },
      avgStockLevel : {
         id: "app.newKeys.stockMovementViaLedger.avgStockLevel",
         defaultMessage : "Avg Stock Level"
      },
      itr : {
         id: "app.newKeys.stockMovementViaLedger.itr",
         defaultMessage : "ITR"
      },
      dsi : {
         id: "app.newKeys.stockMovementViaLedger.dsi",
         defaultMessage : "DSI"
      },
      stockPurchaseReturn : {
         id: "app.newKeys.stockMovementViaLedger.stockPurchaseReturn",
         defaultMessage : "Stock Returned"
      },
      salesReturnedStock : {
         id: "app.newKeys.stockMovementViaLedger.salesReturnedStock",
         defaultMessage : "Sales Returned Stock"
      },
      title : { 
         id: "app.newKeys.stockMovementViaLedger.title",
         defaultMessage : "Stock Movement Via Ledger"
      },
      description : {
         id: "app.newKeys.stockMovementViaLedger.description",
         defaultMessage : "Stock Movement Via Ledger in detail"
      },
      totalOrderWisePaymentBreakup:{
         id: "app.newKeys.stockMovementViaLedger.totalOrderWisePaymentBreakup",
         defaultMessage : "Total Order Wise Payment Breakup"
      }
    },
    newLandingPage: {
      messageSentSuccessfully: {
         id: "app.newKeys.newLandingPage.messageSentSuccessfully",
         defaultMessage: "Message Sent Successfully"
      },
      otpVerified: {
         id: "app.newKeys.newLandingPage.otpVerified",
         defaultMessage: "Otp verified successfully!"
      },
      passwordUpdated: {
         id: "app.newKeys.newLandingPage.passwordUpdated",
         defaultMessage: "Password Updated Successfully"
      },
      passwordError: {
         id: "app.newKeys.newLandingPage.passwordError",
         defaultMessage: "Atleast 8 characters with a mix of uppercase letter ,lowercase letter ,special character and number"
      },
      resetPassword: {
         id: "app.newKeys.newLandingPage.resetPassword",
         defaultMessage: "Reset your password"
      },
      welcomeBackText: {
         id: "app.newKeys.newLandingPage.welcomeBackText",
         defaultMessage: "Welcome Back!"
      },
      createYourAccount: {
         id: "app.newKeys.newLandingPage.createYourAccount",
         defaultMessage: "Let’s create your account!"
      },
      signupDetails: {
         id: "app.newKeys.newLandingPage.signupDetails",
         defaultMessage: "Just sign up with your details and we’ll customise"
      },
      adaptivePOS: {
         id: "app.newKeys.newLandingPage.adaptivePOS",
         defaultMessage: "with the most adaptive point-of-sale software."
      },
      yourDashboard: {
         id: "app.newKeys.newLandingPage.yourDashboard",
         defaultMessage: "your dashboard for you."
      },
      resetPasswordText: {
         id: "app.newKeys.newLandingPage.resetPasswordText",
         defaultMessage: "Reset Password"
      },
      usernameResetPassword: {
         id: "app.newKeys.newLandingPage.usernameResetPassword",
         defaultMessage: "Enter the username for which you would like to reset the password"
      },
      backToLogin: {
         id: "app.newKeys.newLandingPage.backToLogin",
         defaultMessage: "Back To Login"
      },
      otpSharedText: {
         id: "app.newKeys.newLandingPage.otpSharedText",
         defaultMessage: "An OTP has been shared with you on your registered email address. Please input it below."
      },
      enterCredText: {
         id: "app.newKeys.newLandingPage.enterCredText",
         defaultMessage: "Please enter your credentials to login"
      },
      newUser: {
         id: "app.newKeys.newLandingPage.newUser",
         defaultMessage: "New user? "
      },
      signup: {
         id: "app.newKeys.newLandingPage.signup",
         defaultMessage: "Sign Up"
      },
      nowText: {
         id: "app.newKeys.newLandingPage.nowText",
         defaultMessage: "now!"
      },
      forgotPassword: {
         id: "app.newKeys.newLandingPage.forgotPassword",
         defaultMessage: "Forgot Password"
      },
      loginText: {
         id: "app.newKeys.newLandingPage.loginText",
         defaultMessage: "Login"
      },
      otpSentNumberText: {
         id: "app.newKeys.newLandingPage.otpSentNumberText",
         defaultMessage: "OTP will be sent to this number"
      },
      resendOTPText: {
         id: "app.newKeys.newLandingPage.resendOTPText",
         defaultMessage: "Re-send OTP"
      },
      otpText: {
         id: "app.newKeys.newLandingPage.otpText",
         defaultMessage: "OTP"
      },
      sendOTPText: {
         id: "app.newKeys.newLandingPage.sendOTPText",
         defaultMessage: "Send OTP"
      },
      agreeingToQB: {
         id: "app.newKeys.newLandingPage.agreeingToQB",
         defaultMessage: "By signing up on QueueBuster, you are agreeing to our"
      },
      termsOfUse: {
         id: "app.newKeys.newLandingPage.termsOfUse",
         defaultMessage: "Terms of Use"
      },
      andText: {
         id: "app.newKeys.newLandingPage.andText",
         defaultMessage: "and"
      },
      privacyPolicyText: {
         id: "app.newKeys.newLandingPage.privacyPolicyText",
         defaultMessage: "Privacy Policy"
      },
      alreadyHaveAccount: {
         id: "app.newKeys.newLandingPage.alreadyHaveAccount",
         defaultMessage: "Already have an account?"
      },
      chooseNewPassword: {
         id: "app.newKeys.newLandingPage.chooseNewPassword",
         defaultMessage: "Choose New Password"
      },
      highlyRecommendText: {
         id: "app.newKeys.newLandingPage.highlyRecommendText",
         defaultMessage: "We highly recommend you create a unique password."
      },
      passwordUpdatedText: {
         id: "app.newKeys.newLandingPage.passwordUpdatedText",
         defaultMessage: "Password Updated!"
      },
      passwordUpdationSuccessfull: {
         id: "app.newKeys.newLandingPage.passwordUpdationSuccessfull",
         defaultMessage: "Your password has been changed successfully. Use your new password to login"
      },
      verificationText: {
         id: "app.newKeys.newLandingPage.verificationText",
         defaultMessage: "Verification"
      },
      weSentText: {
         id: "app.newKeys.newLandingPage.weSentText",
         defaultMessage: "We sent a text message to",
      },
      enterCodeToResetPwd: {
         id: "app.newKeys.newLandingPage.enterCodeToResetPwd",
         defaultMessage: "with your verfication code. Enter it below to reset your password."
      },
      verifyOTPText: {
         id: "app.newKeys.newLandingPage.verifyOTPText",
         defaultMessage: "Verify OTP"
      },
      registrationSuccessText: {
         id: "app.newKeys.newLandingPage.registrationSuccessText",
         defaultMessage: "Registeration Successful"
      },
      signupCompletedText: {
         id: "app.newKeys.newLandingPage.signupCompletedText",
         defaultMessage: "Your signup is now completed. Now start"
      },
      intrestedInText: {
         id: "app.newKeys.newLandingPage.intrestedInText",
         defaultMessage: "exploring what you might be interested in."
      },
      getStartedText: {
         id: "app.newKeys.newLandingPage.getStartedText",
         defaultMessage: "Get Started"
      },
      sorryText: {
         id: "app.newKeys.newLandingPage.sorryText",
         defaultMessage: "Sorry!"
      },
      availableOnDesktop: {
         id: "app.newKeys.newLandingPage.availableOnDesktop",
         defaultMessage: "We are currently available only on desktop version"
      },
      loginAndManage: {
         id: "app.newKeys.newLandingPage.loginAndManage",
         defaultMessage: "Login and manage your business"
      },
      loginAndManageFullText: {
         id: "app.newKeys.newLandingPage.loginAndManageFullText",
         defaultMessage: "Login and manage your business with the most adaptive point-of-sale software."
      },
      pwdNotMatch: {
         id: "app.newKeys.newLandingPage.pwdNotMatch",
         defaultMessage: "Password doest not match"
      }
    },
    newCartItem : {
      stockOutInformation : {
         id : "app.newKeys.newCartItem.stockOutInformation",
         defaultMessage : "Stock Out Information"
      },
      stockRequisitionInformation : {
         id : "app.newKeys.newCartItem.stockRequisitionInformation",
         defaultMessage : "Stock Requisition Information"
      },
      stockInInformation : {
         id : "app.newKeys.newCartItem.stockInInformation",
         defaultMessage : "Stock In Information"
      },
      stockTransferInformation : {
         id : "app.newKeys.newCartItem.stockTransferInformation",
         defaultMessage : "Stock Transfer Information"
      },
      stockValidationInformation : {
         id : "app.newKeys.newCartItem.stockValidationInformation",
         defaultMessage : "Stock Audit Information"
      },
      cartStockIn : {
         id : "app.newKeys.newCartItem.cartStockIn",
         defaultMessage : "Cart - Stock In"
      },
      cartStockOut : {
         id : "app.newKeys.newCartItem.cartStockOut",
         defaultMessage : "Cart - Stock Out"
      },
      cartStockRequisition : {
         id : "app.newKeys.newCartItem.cartStockRequisition",
         defaultMessage : "Cart - Stock Requisition"
      },
      cartStockTransfer : {
         id : "app.newKeys.newCartItem.cartStockTransfer",
         defaultMessage : "Cart - Stock Transfer"
      },
      cartStockValidation : {
         id : "app.newKeys.newCartItem.cartStockValidation",
         defaultMessage : "Cart - Stock Audit"
      },
      cartEditBatch : {
         id : "app.newKeys.newCartItem.cartEditBatch",
         defaultMessage : "Cart - Edit Batch"
      },
      inventoryStockIn : {
         id : "app.newKeys.newCartItem.inventoryStockIn",
         defaultMessage : "Inventory - Stock In"
      },
      inventoryStockOut : {
         id : "app.newKeys.newCartItem.inventoryStockOut",
         defaultMessage : "Inventory - Stock Out"
      },
      inventoryStockRequisition : {
         id : "app.newKeys.newCartItem.inventoryStockRequisition",
         defaultMessage : "Inventory - Stock Requisition"
      },
      inventoryStockTransfer : {
         id : "app.newKeys.newCartItem.inventoryStockTransfer",
         defaultMessage : "Inventory - Stock Transfer"
      },
      inventoryStockValidation : {
         id : "app.newKeys.newCartItem.inventoryStockValidation",
         defaultMessage : "Inventory - Stock Audit"
      },
      inventoryEditBatch : {
         id : "app.newKeys.newCartItem.inventoryEditBatch",
         defaultMessage : "Inventory - Edit Batch"
      },
    },
    stockExcelPreview : {
      descriptionStockIn : {
         id: "app.newKeys.stockExcelPreview.descriptionStockIn",
         defaultMessage : "Stock In data is ready, please review & proceed. "
      },
      descriptionStockout : {
         id: "app.newKeys.stockExcelPreview.descriptionStockout",
         defaultMessage : "Stock Out data is ready, please review & proceed. "
      },
      descriptionStockTranfer : {
         id: "app.newKeys.stockExcelPreview.descriptionStockTranfer",
         defaultMessage : "Stock transfer data is ready, please review & proceed. "
      },
      descriptionStockvalidation : {
         id: "app.newKeys.stockExcelPreview.descriptionStockvalidation",
         defaultMessage : "Stock Validation data is ready, please review & proceed. "
      },
      descriptionStockRequisition : {
         id: "app.newKeys.stockExcelPreview.descriptionStockRequisition",
         defaultMessage : "Stock Requisition data is ready, please review & proceed."
      },
      stockInP : {
         id: "app.newKeys.stockExcelPreview.stockInP",
         defaultMessage : "Stock In Preview"
      },
      stockOutP : {
         id: "app.newKeys.stockExcelPreview.stockOutP",
         defaultMessage : "Stock Out Preview"
      },
      stockRequisitionP  :{
         id: "app.newKeys.stockExcelPreview.stockRequisitionP",
         defaultMessage : "Stock Requisition Preview"
      },
      stockTransferP  :{
         id: "app.newKeys.stockExcelPreview.stockTransferP",
         defaultMessage : "Stock Transfer Preview"
      },
      stockValidationP  :{
         id: "app.newKeys.stockExcelPreview.stockValidationP",
         defaultMessage : "Stock Validation Preview"
      },
      descriptionStockBulkTransfer : {
         id: "app.newKeys.stockExcelPreview.descriptionStockBulkTransfer",
         defaultMessage : "Stock Transfer (Bulk) data is ready, please review & proceed."
      },
      StockBulkTransferBulkP : {
         id: "app.newKeys.stockExcelPreview.StockBulkTransferBulkP",
         defaultMessage : "Stock Transfer (Bulk) Preview"
      }
    },
    batchs : {
      noProds : {
         id : "app.newKeys.batchs.noProds",
         defaultMessage : "No products in the batch."
      }
    },
    excelOperationModal : {
      stockIn : {
         id: "app.newKeys.excelOperationModal.stockIn",
         defaultMessage : "Stock In (Requisition)"
      },
      stockOut : {
         id: "app.newKeys.excelOperationModal.stockOut",
         defaultMessage : "Stock Out (Requisition)"
      },
      stockTransfer : {
         id: "app.newKeys.excelOperationModal.stockTransfer",
         defaultMessage : "Stock Transfer (Requisition)"
      },
    },
    validations:{
      productNameValidation:{
         id: "app.newKeys.validations.productNameValidation",
         defaultMessage : "Product name is required"
      },
      commonValidation: {
         id: "app.newKeys.validations.commonValidation",
         defaultMessage : "This field is required"
      },
      productSetValidation:{
         id: "app.newKeys.validations.productSetValidation",
         defaultMessage : "Atleast add one product set"
      },
      productQuantityValidation:{
         id: "app.newKeys.validations.productQuantityValidation",
         defaultMessage : "Please select product(s) with quantity as a mandatory attribute."
      },
      quantityForSelectedProVal:{
         id: "app.newKeys.validations.quantityForSelectedProVal",
         defaultMessage : "Please enter quantity field for selected products at the Table"
      },
      noOfProductVal:{
         id: "app.newKeys.validations.noOfProductVal",
         defaultMessage : "Option to choose cannot be greater than number of products seleceted in as set."
      },
      catNameVal:{
         id: "app.newKeys.validations.catNameVal",
         defaultMessage : "Category name is required"
      },
      subCatNameVal:{
         id: "app.newKeys.validations.subCatNameVal",
         defaultMessage : "Sub category name is required"
      },
      selectCatVal:{
         id: "app.newKeys.validations.selectCatVal",
         defaultMessage : "Please select category."
      },
      brandNameVal:{
         id: "app.newKeys.validations.brandNameVal",
         defaultMessage : "Brand name is required"
      },
      manufacturerNameVal:{
         id: "app.newKeys.validations.manufacturerNameVal",
         defaultMessage : "Manufacturer name is required"
      },
      incomeHeadNameVal:{
         id: "app.newKeys.validations.incomeHeadNameVal",
         defaultMessage : "Income Head name is required"
      },
      modifierNameVal:{
         id: "app.newKeys.validations.modifierNameVal",
         defaultMessage : "Modifier Name is mandatory field!"
      },
      modifierProVal:{
         id: "app.newKeys.validations.modifierProVal",
         defaultMessage : "Products cannot be repeated in added product to modifier!"
      },
      showing:{
         id: "app.newKeys.validations.showing",
         defaultMessage : "Showing"
      },
      to:{
         id: "app.newKeys.validations.to",
         defaultMessage : "to"
      },
      of:{
         id: "app.newKeys.validations.of",
         defaultMessage : "of"
      },
      Results:{
         id: "app.newKeys.validations.Results",
         defaultMessage : "Results"
      },
      modifierGroupNameVal:{
         id: "app.newKeys.validations.modifierGroupNameVal",
         defaultMessage : "Group Name is required..!"
      },
      minSelectableVal:{
         id: "app.newKeys.validations.minSelectableVal",
         defaultMessage : "Min Selectable should be less than or equal to Max Selectable"
      },
      voucherCodeVal:{
         id: "app.newKeys.validations.voucherCodeVal",
         defaultMessage : "Voucher code is required"
      },
      voucherValueVal:{
         id: "app.newKeys.validations.voucherValueVal",
         defaultMessage : "Voucher Value is required"
      },
      voucherCountVal:{
         id: "app.newKeys.validations.voucherCountVal",
         defaultMessage : "Voucher Count is required"
      },
      exportVoucher:{
         id: "app.newKeys.validations.exportVoucher",
         defaultMessage : "Export Vouchers"
      },
      companyNameVal:{
         id: "app.newKeys.validations.companyNameVal",
         defaultMessage : "Company name is required!"
      },
      phoneNumberVal:{
         id: "app.newKeys.validations.phoneNumberVal",
         defaultMessage : "Please enter a valid mobile number"
      },
      countryCodeVal:{
         id: "app.newKeys.validations.countryCodeVal",
         defaultMessage : "Please select country code."
      },
      fassaiValidation:{
         id: "app.newKeys.validations.fassaiValidation",
         defaultMessage : "Please enter 14 digit fssai number"
      },
      complianceInformation:{
         id: "app.newKeys.validations.complianceInformation",
         defaultMessage : "Compliance Information"
      },
      emailValidation:{
         id: "app.newKeys.validations.emailValidation",
         defaultMessage : "Please enter valid email"
      },
      automaticPrint:{
         id: "app.newKeys.validations.automaticPrint",
         defaultMessage : "Automatic Print"
      },
      panNumber:{
         id: "app.newKeys.validations.panNumber",
         defaultMessage : "Pan Number"
      },
      taxInformation:{
         id: "app.newKeys.validations.taxInformation",
         defaultMessage : "Tax Information"
      },
      wareHouseValName:{
         id: "app.newKeys.validations.wareHouseValName",
         defaultMessage : "Please enter warehouse name"
      },
      storeSelectionVal:{
         id: "app.newKeys.validations.storeSelectionVal",
         defaultMessage : "Store selection is required"
      },
      showCompanyLogo:{
         id: "app.newKeys.validations.showCompanyLogo",
         defaultMessage : "Show Company Logo"
      },
      uploadLogo:{
         id: "app.newKeys.validations.uploadLogo",
         defaultMessage : "Upload Logo"
      },
      changeLogo:{
         id: "app.newKeys.validations.changeLogo",
         defaultMessage : "Change Logo"
      },
      firstNameVal:{
         id: "app.newKeys.validations.firstNameVal",
         defaultMessage : "First name is Required"
      },
      usernameVal:{
         id: "app.newKeys.validations.usernameVal",
         defaultMessage : "User name is Required"
      },
      passwordVal:{
         id: "app.newKeys.validations.passwordVal",
         defaultMessage : "Password is Required"
      },
      passwordMatch:{
         id: "app.newKeys.validations.passwordMatch",
         defaultMessage : "Passwords must match"
      },
      passwordlengthVal:{
         id: "app.newKeys.validations.passwordlengthVal",
         defaultMessage : "must be atleast 6 character long!"
      },
      emailValidation:{
         id: "app.newKeys.validations.emailValidation",
         defaultMessage : "Email is required"
      },
      counterType:{
         id: "app.newKeys.validations.counterType",
         defaultMessage : "Counter Type"
      },
      isAvailable:{
         id: "app.newKeys.validations.isAvailable",
         defaultMessage : "Is Available"
      },
      counterCode:{
         id: "app.newKeys.validations.counterCode",
         defaultMessage : "Counter Code"
      },
      deleteCounter:{
         id: "app.newKeys.validations.deleteCounter",
         defaultMessage : "Are you sure you want to delete counter :"
      },
      listOfCounter:{
         id: "app.newKeys.validations.listOfCounter",
         defaultMessage : "List of Counter"
      },
      createCounter:{
         id: "app.newKeys.validations.createCounter",
         defaultMessage : "Create Counter"
      },
      storeCounter:{
         id: "app.newKeys.validations.storeCounter",
         defaultMessage : "Store Counter"
      },
      gstValidation : {
         id: "app.newKeys.validations.gstValidation",
         defaultMessage : "GST Number is required."
      },
      gstValidation15 : {
         id: "app.newKeys.validations.gstValidation15",
         defaultMessage : "GST Number length must be 15 digit."
      },
      selectFloor : {
         id: "app.newKeys.validations.selectFloor",
         defaultMessage : "Please select floor Status"
      },
      unavailable : {
         id: "app.newKeys.validations.unavailable",
         defaultMessage : "Unavailable"
      },
      repair : {
         id: "app.newKeys.validations.repair",
         defaultMessage : "Repair"
      },
      createFloor : {
         id: "app.newKeys.validations.createFloor",
         defaultMessage : "Floor Successfully Created."
      },
      updateFloor : {
         id: "app.newKeys.validations.updateFloor",
         defaultMessage : "Floor Successfully Updated."
      },
      errorUpdateFloor : {
         id: "app.newKeys.validations.errorUpdateFloor",
         defaultMessage : "Error On Updating Floor."
      },
      roomUpdate : {
         id: "app.newKeys.validations.roomUpdate",
         defaultMessage : "Room Successfully Updated"
      },
      errorUpdateRoom : {
         id: "app.newKeys.validations.errorUpdateRoom",
         defaultMessage : "Error On Updating Room."
      },
      updateTable : {
         id: "app.newKeys.validations.updateTable",
         defaultMessage : "Table Successfully Updated"
      },
      createTable : {
         id: "app.newKeys.validations.createTable",
         defaultMessage : "Table created successfully"
      },
      errorCreateTable : {
         id: "app.newKeys.validations.errorCreateTable",
         defaultMessage : "Error Creating table."
      },
      createRoom : {
         id: "app.newKeys.validations.createRoom",
         defaultMessage : "Room Successfully Created."
      },
      errorCreateRoom : {
         id: "app.newKeys.validations.errorCreateRoom",
         defaultMessage : "Error Creating Room."
      },
      deleteFloor : {
         id: "app.newKeys.validations.deleteFloor",
         defaultMessage : "Floor Successfully Deleted"
      },
      deleteTable : {
         id: "app.newKeys.validations.deleteTable",
         defaultMessage : "Table Successfully Updated."
      },
      deleteRoom : {
         id: "app.newKeys.validations.deleteRoom",
         defaultMessage : "Room Deleted Successfully."
      },
      viewAddress : {
         id: "app.newKeys.validations.viewAddress",
         defaultMessage : "View Address"
      },
      addressType : {
         id: "app.newKeys.validations.addressType",
         defaultMessage : "Address Type"
      },
      roleSelection:{
         id:"app.newKeys.validations.roleSelection",
         defaultMessage:"Role selection is required"
      },
      storeMapping:{
         id:"app.newKeys.validations.storeMapping",
         defaultMessage:"Store mapping is required"
      },
      imageApp:{
         id:"app.newKeys.validations.imageApp",
         defaultMessage: "This allows to Show Product Images on App"
      },
      showProducts:{
         id:"app.newKeys.validations.showProducts",
         defaultMessage: "This allows to show Products on Homepage."
      },
      askQuantityOnProductClick:{
         id:"app.newKeys.validations.askQuantityOnProductClick",
         defaultMessage: "This allows to Ask Quantity On Product Click."
      },
      enableOneClickOrderingOnDevice:{
         id:"app.newKeys.validations.enableOneClickOrderingOnDevice",
         defaultMessage: "This allows one click Odering on device."
      },
      showProductOnHomepage:{
         id:"app.newKeys.validations.showProductOnHomepage",
         defaultMessage: "Show Product on Homepage"
      },
      createRegion:{
         id:"app.newKeys.validations.createRegion",
         defaultMessage: "Region successfully created."
      },
      errorCreateRegion:{
         id:"app.newKeys.validations.errorCreateRegion",
         defaultMessage: "Error creating region."
      },
      updateRegion:{
         id:"app.newKeys.validations.updateRegion",
         defaultMessage: "Region successfully updated."
      },
      errorUpdatingRegion:{
         id:"app.newKeys.validations.errorUpdatingRegion",
         defaultMessage: "Error updating region"
      },
      deleteRegion:{
         id:"app.newKeys.validations.deleteRegion",
         defaultMessage: "Region successfully deleted."
      },
      errorDeleteRegion:{
         id:"app.newKeys.validations.errorDeleteRegion",
         defaultMessage: "Error deleting region."
      },
      remarksCreated:{
         id:"app.newKeys.validations.remarksCreated",
         defaultMessage: "Remark Successfully Created"
      },
      errorCreatingRemarks:{
         id:"app.newKeys.validations.errorCreatingRemarks",
         defaultMessage: "Error Creating Remark."
      },
      updateRemarks:{
         id:"app.newKeys.validations.updateRemarks",
         defaultMessage: "Remark Successfully Updated."
      },
      errorUpdatingRemarks:{
         id:"app.newKeys.validations.errorUpdatingRemarks",
         defaultMessage: "Error Updating Remark"
      },
      chainInfo:{
         id:"app.newKeys.validations.chainInfo",
         defaultMessage: "Chain information successfully updated."
      },
      errorChainInfo:{
         id:"app.newKeys.validations.errorChainInfo",
         defaultMessage: "Error updating chain information."
      },
      counterCreate:{
         id:"app.newKeys.validations.counterCreate",
         defaultMessage: "Counter Successfully Created."
      },
      deleteCounterVal:{
         id:"app.newKeys.validations.deleteCounterVal",
         defaultMessage: "Counter Successfully deleted."
      },
      storeSuccessUpdate:{
         id:"app.newKeys.validations.storeSuccessUpdate",
         defaultMessage: "Store Successfully Updated"
      },
      errorUpdateStore:{
         id:"app.newKeys.validations.errorUpdateStore",
         defaultMessage: "Error Updating Store."
      },
      errorcreteStore:{
         id:"app.newKeys.validations.errorcreteStore",
         defaultMessage: "Error Crete Store"
      },
      printRecipt:{
         id:"app.newKeys.validations.printRecipt",
         defaultMessage: "Print receipt settings updated successfully ."
      },
      errorPrint:{
         id:"app.newKeys.validations.errorPrint",
         defaultMessage: "Error updating print receipt settings."
      },
      billingAdd:{
         id:"app.newKeys.validations.billingAddAdd",
         defaultMessage: "Billing address successfuly added."
      },
      errorBillingAdd:{
         id:"app.newKeys.validations.errorBillingAdd",
         defaultMessage: "Error updating billing address."
      },
      updateBillAdd:{
         id:"app.newKeys.validations.updateBillAdd",
         defaultMessage: "Billing address successfuly updated."
      },
      errorUpdateBill:{
         id:"app.newKeys.validations.errorUpdateBill",
         defaultMessage: "Show Product on Homepage"
      },
      appSetUpdate:{
         id:"app.newKeys.validations.appSetUpdate",
         defaultMessage: "Application Device settings updated successfully."
      },
      smsSettingUpdate:{
         id:"app.newKeys.validations.smsSettingUpdate",
         defaultMessage: "SMS settings updated successfully."
      },
      errorsmsSettingUpdate:{
         id:"app.newKeys.validations.errorsmsSettingUpdate",
         defaultMessage: "Error updating SMS settingse"
      },
      updateCust:{
         id:"app.newKeys.validations.updateCust",
         defaultMessage: "Customers Successfully Updated"
      },
      errorupdateCust:{
         id:"app.newKeys.validations.errorupdateCust",
         defaultMessage: "Error in updating customer."
      },

      loyalitySettingsUppdated:{
         id:"app.newKeys.validations.loyalitySettingsUppdated",
         defaultMessage: "Loyalty settings updated."
      },
      errorLoyalitySettingsUppdated:{
         id:"app.newKeys.validations.errorLoyalitySettingsUppdated",
         defaultMessage: "Error in updating loyalty settings."
      },
      errorupdateWareHouse:{
         id:"app.newKeys.validations.errorupdateWareHouse",
         defaultMessage: "Error updating Warehouse."
      },
      errorCreateWareHouse:{
         id:"app.newKeys.validations.errorCreateWareHouse",
         defaultMessage: "Error Creating Warehouse."
      },
      deleteWareHouse:{
         id:"app.newKeys.validations.deleteWareHouse",
         defaultMessage: "Warehouse Successfully Deleted."
      },
      errorDeleteWareHouse:{
         id:"app.newKeys.validations.errorDeleteWareHouse",
         defaultMessage: "Error Deleting Warehouse."
      },
      attrCreated:{
         id:"app.newKeys.validations.attrCreated",
         defaultMessage: "Attribute Successfully Created"
      },
      errorAttrCreated:{
         id:"app.newKeys.validations.errorAttrCreated",
         defaultMessage: "Error Creating Attribute."
      },
      attrUpdated:{
         id:"app.newKeys.validations.attrUpdated",
         defaultMessage: "Attribute Successfully Updated"
      },
      errorattrUpdated:{
         id:"app.newKeys.validations.errorattrUpdated",
         defaultMessage: "Error in updating Attribute."
      },
      deleteAttr:{
         id:"app.newKeys.validations.deleteAttr",
         defaultMessage: "Attribute Successfully deleted"
      },
      errordeleteAttr:{
         id:"app.newKeys.validations.errordeleteAttr",
         defaultMessage: "Error deleting Attribute."
      },
      subCatAdded:{
         id:"app.newKeys.validations.subCatAdded",
         defaultMessage: "Sub Category Successfully Added"
      },
      errorsubCatAdded:{
         id:"app.newKeys.validations.errorsubCatAdded",
         defaultMessage: "Error Adding Sub Category."
      },
      subCatUpdate:{
         id:"app.newKeys.validations.subCatUpdate",
         defaultMessage: "Sub Category Successfully Updated"
      },
      errorsubCatUpdate:{
         id:"app.newKeys.validations.errorsubCatUpdate",
         defaultMessage: "Error Updating Sub Category."
      },
      prodDelete:{
         id:"app.newKeys.validations.prodDelete",
         defaultMessage: "Products Successfully Deleted"
      },
      passUpdate:{
         id:"app.newKeys.validations.passUpdate",
         defaultMessage: "Password Succesfully updated!"
      },
      errorpassUpdate:{
         id:"app.newKeys.validations.errorpassUpdate",
         defaultMessage: "Error in updating password."
      },
      errorlicInfo:{
         id:"app.newKeys.validations.errorlicInfo",
         defaultMessage: "Error fetching liscense information."
      },
      licRenew:{
         id:"app.newKeys.validations.licRenew",
         defaultMessage: "Congratulations your licenses have been renewed."
      },
      errorLicRenew:{
         id:"app.newKeys.validations.errorLicRenew",
         defaultMessage: "Error renewing license."
      },
      custAdd:{
         id:"app.newKeys.validations.custAdd",
         defaultMessage: "Customers successfully added."
      },
      errorCustAdd:{
         id:"app.newKeys.validations.errorCustAdd",
         defaultMessage: "Error in creating customer."
      },
      dashboardPref:{
         id:"app.newKeys.validations.dashboardPref",
         defaultMessage: "Dashboard Preferrence Set as V2"
      },
      errordashboardPref:{
         id:"app.newKeys.validations.errordashboardPref",
         defaultMessage: "Error while setting preferred dashboard."
      },
      remarksInfo:{
         id:"app.newKeys.validations.remarksInfo",
         defaultMessage: "Edit Remarks Information"
      },
      selectCounter:{
         id:"app.newKeys.validations.selectCounter",
         defaultMessage: "Select Counter"
      }
    },
    kds : {
      noOrders : {
         id : "app.newKeys.kds.noOrders",
         defaultMessage : "No Orders Found."
      },
      foodReady : {
         id : "app.newKeys.kds.foodReady",
         defaultMessage : "Food Ready ?"
      },
    },
    qbStrap : {
      goToLicense : {
         id: "app.newKeys.qbStrap.goToLicense",
         defaultMessage : "Go to license"
      },
   },
    manageChannel: {
      channelNameReq: {
         id: "app.newKeys.manageChannel.channelNameReq",
         defaultMessage: "Channel Name is Required"
      },
      channelCodeReq: {
         id: "app.newKeys.manageChannel.channelCodeReq",
         defaultMessage: "Channel Code is Required"
      },
      online: {
         id: "app.newKeys.manageChannel.online",
         defaultMessage: "Online"
      },
      channel: {
         id: "app.newKeys.manageChannel.channel",
         defaultMessage: "Channel"
      },
    },
    viewSalesTarget: {
      entities: {
         id: "app.newKeys.viewSalesTarget.entities",
         defaultMessage: "Enitities"
      }
    },
    updateSalesTarget: {
      configutraionNameReq: {
         id: "app.newKeys.updateSalesTarget.configutraionNameReq",
         defaultMessage: "ConfigurationName Name is Required"
      },
      entityCountZeroError: {
         id: "app.newKeys.updateSalesTarget.entityCountZeroError",
         defaultMessage: "Entity Count Can't be zero please select any entity"
      },
      targetValuesError: {
         id: "app.newKeys.updateSalesTarget.targetValuesError",
         defaultMessage: "Please fill target values of selected entities"
      }
   },
   createSalesTarget: {
      update: {
         id: "app.newKeys.createSalesTarget.update",
         defaultMessage: "Update"
      },
      periodNameReq: {
         id: "app.newKeys.createSalesTarget.periodNameReq",
         defaultMessage: "Period Name is Required"
      }
   },
   createCurrencyConversion: {
      currencyCodeReqErr: {
         id: "app.newKeys.createCurrencyConversion.currencyCodeReqErr",
         defaultMessage: "Base Currency Code is required...!"
      },
      targetCurrencyCodeReqErr: {
         id: "app.newKeys.createCurrencyConversion.targetCurrencyCodeReqErr",
         defaultMessage: "Target Currency Code is required...!"
      },
   },
    appointments : {
      title : {
         id: "app.newKeys.appointments.title",
         defaultMessage : "Appointments"
      },
      description : {
         id: "app.newKeys.appointments.description",
         defaultMessage : "List of all appointments"
      },
      staffName : {
         id: "app.newKeys.appointments.staffName",
         defaultMessage : "Staff Name"
      },
      manageInventoryDescServices:{
         id: "app.newKeys.appointments.manageInventoryDescServices",
         defaultMessage : "Define the basic information about this services inventory management."
      }
    },
    staffManagement : {
      exportFile : {
         id: "app.newKeys.staffManagement.exportFile",
         defaultMessage : "Export Staff Management"
      },
      totalKeyword : {
         id: "app.newKeys.staffManagement.totalKeyword",
         defaultMessage : "staff(s)"
      }
    },
    stores : {
      exportFile : {
         id: "app.newKeys.stores.exportFile",
         defaultMessage : "Export Stores"
      }
    },
    softWareLicences : {
      exportFile : {
         id: "app.newKeys.softWareLicences.exportFile",
         defaultMessage : "Export Software License"
      }
   },
    regions : {
      totalKeyword : {
         id: "app.newKeys.regions.totalKeyword",
         defaultMessage : "region(s)"
      }
    },
    urbanPiper:{
      syncMenu : {
         id: "app.newKeys.urbanPiper.syncMenu",
         defaultMessage : "Sync Menu"
      },
      sellOnUrbanpiper : {
         id: "app.newKeys.urbanPiper.sellOnUrbanpiper",
         defaultMessage : "Sell On Urbanpiper"
      },
      urbanPiperPrice : {
         id: "app.newKeys.urbanPiper.urbanPiperPrice",
         defaultMessage : "Urban Piper Price"
      },
      publishMenuFor : {
         id: "app.newKeys.urbanPiper.publishMenuFor",
         defaultMessage : "Publish menu for ?"
      },
      singleStore : {
         id: "app.newKeys.urbanPiper.singleStore",
         defaultMessage : "Single Store"
      },
      multipleStores : {
         id: "app.newKeys.urbanPiper.multipleStores",
         defaultMessage : "Multiple Stores"
      },
      urbanpiperMenu : {
         id: "app.newKeys.urbanPiper.urbanpiperMenu",
         defaultMessage : "Urbanpiper Menu"
      },
      lastSyncedAt  : {
         id: "app.newKeys.urbanPiper.lastSyncedAt",
         defaultMessage : "Last synced at "
      },
      aggregatorMenu : {
         id: "app.newKeys.urbanPiper.aggregatorMenu",
         defaultMessage : "Aggregator Menu"
      },
      urbanpiperSettings  : {
         id: "app.newKeys.urbanPiper.urbanpiperSettings",
         defaultMessage : "Urbanpiper Settings"
      },
      chainLevelSettings : {
         id: "app.newKeys.urbanPiper.chainLevelSettings",
         defaultMessage : "Chain Level Settings"
      },
      enableOnlineOrdering  : {
         id: "app.newKeys.urbanPiper.enableOnlineOrdering",
         defaultMessage : "Enable Online Ordering (Enables UrbanPiper throughout the chain)"
      },
      urbanpiperUsername : {
         id: "app.newKeys.urbanPiper.urbanpiperUsername",
         defaultMessage : "Urbanpiper Username"
      },
      urbanpiperApiKey : {
         id: "app.newKeys.urbanPiper.urbanpiperApiKey",
         defaultMessage : "Urbanpiper ApiKey"
      },
      storeLevelSettings : {
         id: "app.newKeys.urbanPiper.storeLevelSettings",
         defaultMessage : "Store Level Settings"
      },
      chooseOperationalDaysAndHours : {
         id: "app.newKeys.urbanPiper.chooseOperationalDaysAndHours",
         defaultMessage : "Choose operational days and hours"
      },
      orderingEnable : {
         id: "app.newKeys.urbanPiper.orderingEnable",
         defaultMessage : "Ordering Enable"
      },
      minOrderVal : {
         id: "app.newKeys.urbanPiper.minOrderVal",
         defaultMessage : "Min. Order Value"
      },
      minDeliveryTime : {
         id: "app.newKeys.urbanPiper.minDeliveryTime",
         defaultMessage : "Min. Delivery Time (in Seconds)"
      },
      minPickupTime : {
         id: "app.newKeys.urbanPiper.minPickupTime",
         defaultMessage : "Min. Pickup Time (in Seconds)"
      },
      deliveryPlatforms : {
         id: "app.newKeys.urbanPiper.deliveryPlatforms",
         defaultMessage : "Delivery Platforms"
      },
      deliveryZipCodes : {
         id: "app.newKeys.urbanPiper.deliveryZipCodes",
         defaultMessage : "Delivery ZipCodes"
      },
      notificationEmails : {
         id: "app.newKeys.urbanPiper.notificationEmails",
         defaultMessage : "Notification Emails"
      },
      notificationPhones : {
         id: "app.newKeys.urbanPiper.notificationPhones",
         defaultMessage : "Notification Phones"
      },
      urbanPiperApiLogs : {
         id: "app.newKeys.urbanPiper.urbanPiperApiLogs",
         defaultMessage : "UrbanPiper Api Logs"
      },
      entityID : {
         id: "app.newKeys.urbanPiper.entityID",
         defaultMessage : "Entity ID"
      },
      entityType : {
         id: "app.newKeys.urbanPiper.entityType",
         defaultMessage : "Entity Type"
      },
      entityOperation : {
         id: "app.newKeys.urbanPiper.entityOperation",
         defaultMessage : "Entity Operation"
      },
      requestTime : {
         id: "app.newKeys.urbanPiper.requestTime",
         defaultMessage : "Request Time"
      },
      reference : {
         id: "app.newKeys.urbanPiper.reference",
         defaultMessage : "Reference"
      },
      responseStatus : {
         id: "app.newKeys.urbanPiper.responseStatus",
         defaultMessage : "Response Status"
      },
      responseTime : {
         id: "app.newKeys.urbanPiper.responseTime",
         defaultMessage : "Response Time"
      },
      responseMessage : {
         id: "app.newKeys.urbanPiper.responseMessage",
         defaultMessage : "Response Message"
      },
      payload : {
         id: "app.newKeys.urbanPiper.payload",
         defaultMessage : "Payload"
      },
      wrongMenuDetail : {
         id: "app.newKeys.urbanPiper.wrongMenuDetail",
         defaultMessage : "Wrong Menu Detail"
      },
      errorPublishingMenu : {
         id: "app.newKeys.urbanPiper.errorPublishingMenu",
         defaultMessage : "Error while Publishing menu"
      },
      menuPublishedSuccess : {
         id: "app.newKeys.urbanPiper.menuPublishedSuccess",
         defaultMessage : "Menu Published Successfully"
      },
      publish : {
         id: "app.newKeys.urbanPiper.publish",
         defaultMessage : "Publish"
      },
      checkStatus : {
         id: "app.newKeys.urbanPiper.checkStatus",
         defaultMessage : "Check Status"
      },
      import : {
         id: "app.newKeys.urbanPiper.import",
         defaultMessage : "Import"
      },
      go : {
         id: "app.newKeys.urbanPiper.go",
         defaultMessage : "GO"
      },
      hello : {
         id: "app.newKeys.urbanPiper.hello",
         defaultMessage : "hello"
      },
      addOrRemoveProd : {
         id: "app.newKeys.urbanPiper.addOrRemoveProd",
         defaultMessage : "Add or Remove Urbanpiper Products"
      },
      setUrbanpiperPrice : {
         id: "app.newKeys.urbanPiper.setUrbanpiperPrice",
         defaultMessage : "Set Urbanpiper Price"
      },
      deleteProduct : {
         id: "app.newKeys.urbanPiper.deleteProduct",
         defaultMessage : "Are you sure you want to delete this product ?"
      },
      days : {
         id: "app.newKeys.urbanPiper.days",
         defaultMessage : "Days"
      },
      from : {
         id: "app.newKeys.urbanPiper.from",
         defaultMessage : "From"
      },
      monday : {
         id: "app.newKeys.urbanPiper.monday",
         defaultMessage : "Monday"
      },
      tuesday : {
         id: "app.newKeys.urbanPiper.tuesday",
         defaultMessage : "Tuesday"
      },
      wednesday : {
         id: "app.newKeys.urbanPiper.wednesday",
         defaultMessage : "Wednesday"
      },
      thursday : {
         id: "app.newKeys.urbanPiper.thursday",
         defaultMessage : "Thursday"
      },
      friday : {
         id: "app.newKeys.urbanPiper.friday",
         defaultMessage : "Friday"
      },
      saturday : {
         id: "app.newKeys.urbanPiper.saturday",
         defaultMessage : "Saturday"
      },
      sunday : {
         id: "app.newKeys.urbanPiper.sunday",
         defaultMessage : "Sunday"
      },
      to : {
         id: "app.newKeys.urbanPiper.to",
         defaultMessage : "To"
      },
      requestPayload : {
         id: "app.newKeys.urbanPiper.requestPayload",
         defaultMessage : "Request Payload"
      },
      requestUrl : {
         id: "app.newKeys.urbanPiper.requestUrl",
         defaultMessage : "Request URL"
      },
      editUrbanpiperProduct : {
         id: "app.newKeys.urbanPiper.editUrbanpiperProduct",
         defaultMessage : "Edit Urbanpiper Product"
      },
      urbanpiperProductConfiguration : {
         id: "app.newKeys.urbanPiper.urbanpiperProductConfiguration",
         defaultMessage : "Urbanpiper product configuration"
      },
      urbanpiperPrice : {
         id: "app.newKeys.urbanPiper.urbanpiperPrice",
         defaultMessage : "Urbanpiper Price"
      },
      isRecommended : {
         id: "app.newKeys.urbanPiper.isRecommended",
         defaultMessage : "Is Recommended ?"
      },
      for:{
         id: "app.newKeys.urbanPiper.for",
         defaultMessage : "for"
      },
      downloadQrCode:{
         id: "app.newKeys.urbanPiper.downloadQrCode",
         defaultMessage : "Download QR Code"
      },
      information:{
         id:"app.newKeys.urbanPiper.information",
         defaultMessage:"INFORMATION"
      },
      other:{
         id:"app.newKeys.urbanPiper.other",
         defaultMessage:"Other"
      },
      chain:{
         id:"app.newKeys.urbanPiper.chain",
         defaultMessage:"Chain"
      },
      productSelectedToDelete:{
         id:"app.newKeys.urbanPiper.productSelectedToDelete",
         defaultMessage:"Products Selected to delete"
      },
      previewSelectedProd:{
         id:"app.newKeys.urbanPiper.previewSelectedProd",
         defaultMessage:"Preview - Selected products to sell on urbanpiper"
      },
      defaultMrp : {
         id: "app.newKeys.urbanPiper.defaultMrp",
         defaultMessage : "Default MRP"
      }
   },
   customer : {
     viewAddress : {
        id: "app.newKeys.customer.viewAddress",
        defaultMessage : "View Address"
     },
     addressType : {
        id: "app.newKeys.customer.addressType",
        defaultMessage : "Address Type"
     },
     otherStores : {
        id: "app.newKeys.customer.otherStores",
        defaultMessage : "Other Stores"
     }
    },
    productGroups : {
      deleteSuccess : {
         id: "app.newKeys.productGroups.deleteSuccess",
         defaultMessage : "Product group successfully deleted."
      },
      deleteError : {
         id: "app.newKeys.productGroups.deleteError",
         defaultMessage : "Error deleting product group."
      },
      deleteNonZero : {
         id: "app.newKeys.productGroups.deleteNonZero",
         defaultMessage : "Product group inventory is non-zero. Cannot delete!"
      },
      updateSuccess : {
         id: "app.newKeys.productGroups.updateSuccess",
         defaultMessage : "Product group successfully updated."
      },
      updateError : {
         id: "app.newKeys.productGroups.updateError",
         defaultMessage : "Error updating product group."
      },
      createSuccess : {
         id: "app.newKeys.productGroups.createSuccess",
         defaultMessage : "Product group successfully created."
      },
      createError : {
         id: "app.newKeys.productGroups.createError",
         defaultMessage : "Error creating product group."
      },
      createSuccessBulk : {
         id: "app.newKeys.productGroups.createSuccessBulk",
         defaultMessage : "Product Groups Sucessfully Created."
      },
      createErrorBulk : {
         id: "app.newKeys.productGroups.createErrorBulk",
         defaultMessage : "Error creating product groups."
      },
    },
    tax : {
      createSuccess : {
         id: "app.newKeys.tax.createSuccess",
         defaultMessage : "Tax Successfully Created."
      },
      createError : {
         id: "app.newKeys.tax.createError",
         defaultMessage : "Error Creating Tax."
      },
      updateSuccess : {
         id: "app.newKeys.tax.updateSuccess",
         defaultMessage : "Tax Successfully Updated."
      },
      updateError : {
         id: "app.newKeys.tax.updateError",
         defaultMessage : "Error Updating Tax."
      }
    },
    charge : {
      createSuccess : {
         id: "app.newKeys.charge.createSuccess",
         defaultMessage : "Charge Successfully Created."
      },
      createError : {
         id: "app.newKeys.charge.createError",
         defaultMessage : "Error Creating Charge."
      },
      updateSuccess : {
         id: "app.newKeys.charge.updateSuccess",
         defaultMessage : "Charge Successfully Updated."
      },
      updateError : {
         id: "app.newKeys.charge.updateError",
         defaultMessage : "Error Updating Charge."
      }
   },
    apiResponse:{
      updateServiceGroupSuccess:{
         id: "app.newKeys.apiResponse.updateServiceGroupSuccess",
         defaultMessage : "Service Group Successfully Updated."
      },
      errorUpdatingServiceGroup:{
         id: "app.newKeys.apiResponse.errorUpdatingServiceGroup",
         defaultMessage : "Error Updating Service Group."
      },
      createServiceGroupSuccess:{
         id: "app.newKeys.apiResponse.createServiceGroupSuccess",
         defaultMessage : "Service Group Successfully Created."
      },
      errorCreatingServiceGroup:{
         id: "app.newKeys.apiResponse.errorCreatingServiceGroup",
         defaultMessage : "Error Creating Service Group."
      },
      defaultServiceGroup:{
         id: "app.newKeys.apiResponse.defaultServiceGroup",
         defaultMessage : "Default Service Group (none)"
      },
      serviceSuccessfullyUpdated:{
         id: "app.newKeys.apiResponse.serviceSuccessfullyUpdated",
         defaultMessage : "Service Successfully Updated."
      },
      errorUpdatingService:{
         id: "app.newKeys.apiResponse.errorUpdatingService",
         defaultMessage : "Error Updating Service."
      },
      serviceSuccessfullyDeleted:{
         id: "app.newKeys.apiResponse.serviceSuccessfullyDeleted",
         defaultMessage : "Service Successfully Deleted."
      },
      errorDeletingService:{
         id: "app.newKeys.apiResponse.errorDeletingService",
         defaultMessage : "Error Deleting Service."
      },
      noExtraTime:{
         id: "app.newKeys.apiResponse.noExtraTime",
         defaultMessage : "No Extra Time"
      },
      processingTimeAfter:{
         id: "app.newKeys.apiResponse.processingTimeAfter",
         defaultMessage : "Processing Time After"
      },
      blockedTimeAfter:{
         id: "app.newKeys.apiResponse.blockedTimeAfter",
         defaultMessage : "Blocked Time After"
      },
      serviceSuccessfullyCreated:{
         id: "app.newKeys.apiResponse.serviceSuccessfullyCreated",
         defaultMessage : "Service Successfully Created."
      },
      errorCreatingService:{
         id: "app.newKeys.apiResponse.errorCreatingService",
         defaultMessage : "Error Creating Service."
      },
      incomeHeadSuccessfullyUpdated:{
         id: "app.newKeys.apiResponse.incomeHeadSuccessfullyUpdated",
         defaultMessage : "Income Head successfully updated."
      },
      errorUpdatingIncomeHead:{
         id: "app.newKeys.apiResponse.errorUpdatingIncomeHead",
         defaultMessage : "Error updating Income Head."
      },
      incomeHeadSuccessfullyCreated:{
         id: "app.newKeys.apiResponse.incomeHeadSuccessfullyCreated",
         defaultMessage : "Income Head successfully created."
      },
      errorCreatingIncomeHead:{
         id: "app.newKeys.apiResponse.errorCreatingIncomeHead",
         defaultMessage : "Error creating Income Head."
      },
      manufacturerSuccessfullyUpdated:{
         id: "app.newKeys.apiResponse.manufacturerSuccessfullyUpdated",
         defaultMessage : "Manufacturer Successfully Updated"
      },
      errorUpdatingManufactuer:{
         id: "app.newKeys.apiResponse.errorUpdatingManufactuer",
         defaultMessage : "Error Updating Manufactuer."
      },
      manufacturerSuccessfullyCreated:{
         id: "app.newKeys.apiResponse.manufacturerSuccessfullyCreated",
         defaultMessage : "Manufacturer Successfully Created."
      },
      errorCreatingManufactuer:{
         id: "app.newKeys.apiResponse.errorCreatingManufactuer",
         defaultMessage : "Error Creating Manufactuer."
      },
      manufacturerSuccessfullyDeleted:{
         id: "app.newKeys.apiResponse.manufacturerSuccessfullyDeleted",
         defaultMessage : "Manufacturer Successfully Deleted."
      },
      errorDeletingManufactuer:{
         id: "app.newKeys.apiResponse.errorDeletingManufactuer",
         defaultMessage : "Error Deleting Manufactuer."
      },
      brandSuccessfullyCreated:{
         id: "app.newKeys.apiResponse.brandSuccessfullyCreated",
         defaultMessage : "Brand Successfully Created."
      },
      errorCreatingBrand:{
         id: "app.newKeys.apiResponse.errorCreatingBrand",
         defaultMessage : "Error Creating Brand."
      },
      brandSuccessfullyUpdated:{
         id: "app.newKeys.apiResponse.brandSuccessfullyUpdated",
         defaultMessage : "Brand Successfully Updated."
      },
      errorUpdatingBrand:{
         id: "app.newKeys.apiResponse.errorUpdatingBrand",
         defaultMessage : "Error Updating Brand"
      },
      brandsSuccessfullyAdded:{
         id: "app.newKeys.apiResponse.brandsSuccessfullyAdded",
         defaultMessage : "Brands successfully added."
      },
      errorAddingBrands:{
         id: "app.newKeys.apiResponse.errorAddingBrands",
         defaultMessage : "Error adding brands."
      },
      categorySuccessfullyCreated:{
         id: "app.newKeys.apiResponse.categorySuccessfullyCreated",
         defaultMessage : "Category Successfully Created."
      },
      errorCreatingCategory:{
         id: "app.newKeys.apiResponse.errorCreatingCategory",
         defaultMessage : "Error Creating Category."
      },
      categorySuccessfullyUpdated:{
         id: "app.newKeys.apiResponse.categorySuccessfullyUpdated",
         defaultMessage : "Category Successfully Updated"
      },
      errorUpdatingCategory:{
         id: "app.newKeys.apiResponse.errorUpdatingCategory",
         defaultMessage : "Error Updating Category."
      },
      productsSuccessfullyAssigned:{
         id: "app.newKeys.apiResponse.productsSuccessfullyAssigned",
         defaultMessage : "Products successfully assigned"
      },
      productListIsRequired:{
         id: "app.newKeys.apiResponse.productListIsRequired",
         defaultMessage : "Product List is required!."
      },
      errorAssigningProducts:{
         id: "app.newKeys.apiResponse.errorAssigningProducts",
         defaultMessage : "Error assigning products."
      },
      comboSuccessfullyAdded:{
         id: "app.newKeys.apiResponse.comboSuccessfullyAdded",
         defaultMessage : "Combo Successfully Added"
      },
      errorAddingCombo:{
         id: "app.newKeys.apiResponse.errorAddingCombo",
         defaultMessage : "Error Adding Combo."
      },
      comboSuccessfullyUpdated:{
         id: "app.newKeys.apiResponse.comboSuccessfullyUpdated",
         defaultMessage : "Combo Successfully Updated"
      },
      unableToUpdateCombo:{
         id: "app.newKeys.apiResponse.unableToUpdateCombo",
         defaultMessage : "Unable to update Combo."
      },
      comboSuccessfullyDeleted:{
         id: "app.newKeys.apiResponse.comboSuccessfullyDeleted",
         defaultMessage : "Combo Successfully Deleted"
      },
      invalidProductData:{
         id: "app.newKeys.apiResponse.invalidProductData",
         defaultMessage : "Invalid product Data!"
      },
      pleaseEnterValidRecords:{
         id: "app.newKeys.apiResponse.pleaseEnterValidRecords",
         defaultMessage : "Please enter valid records"
      },
      chain:{
         id: "app.newKeys.apiResponse.chain",
         defaultMessage : "Chain"
      },
      subcategoriesSuccessfullyUpdated:{
         id: "app.newKeys.apiResponse.subcategoriesSuccessfullyUpdated",
         defaultMessage : "Subcategories Successfully Updated."
      },
      errorUpdatingSubCategories:{
         id: "app.newKeys.apiResponse.errorUpdatingSubCategories",
         defaultMessage : "Error updating sub-categories."
      },
      subCategoriesSuccessfullyAdded:{
         id: "app.newKeys.apiResponse.subCategoriesSuccessfullyAdded",
         defaultMessage : "Sub-categories successfully added"
      },
      errorAddingSubCategories:{
         id: "app.newKeys.apiResponse.errorAddingSubCategories",
         defaultMessage : "Error adding sub-categories."
      },
      catalogueSuccessfullyUpdated:{
         id: "app.newKeys.apiResponse.catalogueSuccessfullyUpdated",
         defaultMessage : "Catalogue successfully updated."
      },
      errorCreatingProduct:{
         id: "app.newKeys.apiResponse.errorCreatingProduct",
         defaultMessage : "Error creating product."
      },
      productsSuccessfullyDeleted:{
         id: "app.newKeys.apiResponse.productsSuccessfullyDeleted",
         defaultMessage : "Products successfully deleted"
      },
      errorDeletingProduct:{
         id: "app.newKeys.apiResponse.errorDeletingProduct",
         defaultMessage : "Error deleting product."
      },      
    },
    apiTracker:{
      apiTrackerTitle:{
         id: "app.newKeys.apiTracker.apiTrackerTitle",
         defaultMessage : "API Tracker Report"
      },
      apiTrackerId:{
         id: "app.newKeys.apiTracker.apiTrackerId",
         defaultMessage : "API Tracker ID"
      },
      packageId:{
         id: "app.newKeys.apiTracker.packageId",
         defaultMessage : "Package ID"
      },
      apiCallsConsumed:{
         id: "app.newKeys.apiTracker.apiCallsConsumed",
         defaultMessage : "API Calls Consumed"
      },
      monthlyAPICallLimit:{
         id: "app.newKeys.apiTracker.monthlyAPICallLimit",
         defaultMessage : "Monthly API Call Limit"
      },
      monthlyAPICost:{
         id: "app.newKeys.apiTracker.monthlyAPICost",
         defaultMessage : "Monthly API Cost"
      },
      overageCostPerCall:{
         id: "app.newKeys.apiTracker.overageCostPerCall",
         defaultMessage : "Average Cost Per Call"
      },
      monthlyAPICallThrottleLimit:{
         id: "app.newKeys.apiTracker.monthlyAPICallThrottleLimit",
         defaultMessage : "Monthly API Call ThrottleLimit"
      },
      paymentDueDate:{
         id: "app.newKeys.apiTracker.paymentDueDate",
         defaultMessage : "Payment Due Date"
      },
      status:{
         id: "app.newKeys.apiTracker.status",
         defaultMessage : "Status"
      },
      packageAmount:{
         id: "app.newKeys.apiTracker.packageAmount",
         defaultMessage : "Package Amount"
      },
      taxes:{
         id: "app.newKeys.apiTracker.taxes",
         defaultMessage : "Taxes"
      },
      invoiceAmount:{
         id: "app.newKeys.apiTracker.invoiceAmount",
         defaultMessage : "Invoice Amount"
      },
      invoiceStatus:{
         id: "app.newKeys.apiTracker.invoiceStatus",
         defaultMessage : "Invoice Status"
      },
      isActive:{
         id: "app.newKeys.apiTracker.isActive",
         defaultMessage : "Is Active"
      },
      startDateLocal:{
         id: "app.newKeys.apiTracker.startDateLocal",
         defaultMessage : "Start Date"
      },
      endDateLocal:{
         id: "app.newKeys.apiTracker.endDateLocal",
         defaultMessage : "End Date"
      },
      viewLog:{
         id: "app.newKeys.apiTracker.viewLog",
         defaultMessage : "View Log"
      },
      log:{
         id: "app.newKeys.apiTracker.log",
         defaultMessage : "Log"
      },
      apiTrackerTitle2:{
         id: "app.newKeys.apiTracker.apiTrackerTitle2",
         defaultMessage : "Api Call Log Data"
      },
      productsInSubCategory:{
         id: "app.newKeys.apiTracker.productsInSubCategory",
         defaultMessage : "Products in sub-category"
      },
      contentOfPayload:{
         id: "app.newKeys.apiTracker.contentOfPayload",
         defaultMessage : "Content of payload"
      },
      
      
   },
   expenseManagement : {
      expenseListTitle  : {
         id: "app.newKeys.expenseManagement.expenseListTitle",
         defaultMessage : "Expense Management"
      },
      createExpense  : {
         id: "app.newKeys.expenseManagement.createExpense",
         defaultMessage : "Create Expenses"
      },
      createExpenseDesc : {
         id: "app.newKeys.expenseManagement.createExpenseDesc",
         defaultMessage : "Create expenses by adding items."
      },
      addItem : {
         id: "app.newKeys.expenseManagement.addItem",
         defaultMessage : "Add items"
      },
      addItemImageDesc : {
         id: "app.newKeys.expenseManagement.addItemImageDesc",
         defaultMessage : "This image will be displayed in the expenses list"
      },
      addNewItem : {
         id: "app.newKeys.expenseManagement.addNewItem",
         defaultMessage : "Add New Item"
      },
      expenseAmountErr : {
         id: "app.newKeys.expenseManagement.expenseAmountErr",
         defaultMessage : "Expense Amount is required."
      },
      expenseNameErr : {
         id: "app.newKeys.expenseManagement.expenseNameErr",
         defaultMessage : "Expense Name is required."
      },
      expenseCreated : {
         id: "app.newKeys.expenseManagement.expenseCreated",
         defaultMessage : "Expense Order Created Successfully."
      },
      updateExpense  : {
         id: "app.newKeys.expenseManagement.updateExpense",
         defaultMessage : "Update Expenses"
      },
      updateExpenseDesc : {
         id: "app.newKeys.expenseManagement.updateExpenseDesc",
         defaultMessage : "Update expenses by updating or adding items."
      },
      expenseUpdated : {
         id: "app.newKeys.expenseManagement.expenseUpdated",
         defaultMessage : "Expense Order Updated Successfully."
      },
      deleteItem : {
         id: "app.newKeys.expenseManagement.deleteItem",
         defaultMessage : "Delete Item"
      },
      expenseID : {
         id: "app.newKeys.expenseManagement.expenseID",
         defaultMessage : "Expense OrderID"
      },
      deleteSuccess : {
         id: "app.newKeys.expenseManagement.deleteSuccess",
         defaultMessage : "Category Successfully Deleted"
      },
      updateSuccess : {
         id: "app.newKeys.expenseManagement.updateSuccess",
         defaultMessage : "Category Successfully Updated."
      },
      unableToUpdate : {
         id: "app.newKeys.expenseManagement.unableToUpdate",
         defaultMessage : "Unabel to update Category."
      },
      createSuccess : {
         id: "app.newKeys.expenseManagement.createSuccess",
         defaultMessage : "Category Successfully Created."
      },
      itemDeleted : {
         id: "app.newKeys.expenseManagement.itemDeleted",
         defaultMessage : "Expense Item Successfully Deleted."
      },
      expenseDeleted: {
         id: "app.newKeys.expenseManagement.expenseDeleted",
         defaultMessage : "This expense has either been removed or does not exist."
      }
   },
   webHook:{
      webHookTitle:{
         id: "app.newKeys.webHook.webHookTitle",
         defaultMessage : "Webhooks"
      },
      webHookDesc:{
         id: "app.newKeys.webHook.webHookDesc",
         defaultMessage : "Central Repository for all webhooks you created"
      },
      addWebhook:{
         id: "app.newKeys.webHook.addWebhook",
         defaultMessage : "Add Webhook"
      },
      WebhookDeleteMsg:{
         id: "app.newKeys.webHook.WebhookDeleteMsg",
         defaultMessage : "Are you sure you want to delete this WebHook?"
      },
      editWebhook:{
         id: "app.newKeys.webHook.editWebhook",
         defaultMessage :  "Edit Webhook"
      },
      createWebhook:{
         id: "app.newKeys.webHook.createWebhook",
         defaultMessage :  "Create Webhook"
      },
      WebhookDescEditAndCreate:{
         id: "app.newKeys.webHook.WebhookDescEditAndCreate",
         defaultMessage :  "Create Webhooks by filling the details"
      },
      url:{
         id: "app.newKeys.webHook.url",
         defaultMessage :  "URL"
      },
      urlIsRequired:{
         id: "app.newKeys.webHook.urlIsRequired",
         defaultMessage :  "URL is required"
      },
      events:{
         id: "app.newKeys.webHook.events",
         defaultMessage :  "Events"
      },
      headers:{
         id: "app.newKeys.webHook.headers",
         defaultMessage :  "Headers"
      },
      key:{
         id: "app.newKeys.webHook.key",
         defaultMessage :  "Key"
      },
      keyIsRequired:{
         id: "app.newKeys.webHook.keyIsRequired",
         defaultMessage :  "Key name is required"
      },
      value:{
         id: "app.newKeys.webHook.value",
         defaultMessage :  "Value"
      },
      valueIsRequired:{
         id: "app.newKeys.webHook.valueIsRequired",
         defaultMessage :  "Value is required"
      },
      createdAt:{
         id: "app.newKeys.webHook.createdAt",
         defaultMessage :  "CREATED AT"
      },
      toggle:{
         id: "app.newKeys.webHook.toggle",
         defaultMessage :  "Toggle"
      },
      "pleaseSelectEvent":{
         id: "app.newKeys.webHook.pleaseSelectEvent",
         defaultMessage :  "Please select Event."
      },
   },
   spinnner : {
      loaderText : {
         id: "app.newKeys.spinnner.loaderText",
         defaultMessage : "Please wait while its loading…"
      },
   },
   remarksModule:{
      totalKeyword:{
         id:"app.newKeys.remarksModule.totalKeyword",
         defaultMessage:"remarks(s)"
      }
   },
settings: {
   xiaomi: {
       id: "app.newKeys.settings.xiaomi",
       defaultMessage: "Xiaomi"
     },
     "mi": {
       "id": "app.newKeys.settings.mi",
       "defaultMessage": "MI"
     },
     "miStore": {
       "id": "app.newKeys.settings.miStore",
       "defaultMessage": "MI Store"
     },
     "storeAddressLine1": {
       "id": "app.newKeys.settings.storeAddressLine1",
       "defaultMessage": "Store Address line 1"
     },
     "storeAddressLine2": {
       "id": "app.newKeys.settings.storeAddressLine2",
       "defaultMessage": "Store Address line 2"
     },
     "storeCity": {
       "id": "app.newKeys.settings.storeCity",
       "defaultMessage": "Store City"
     },
     "storeState": {
       "id": "app.newKeys.settings.storeState",
       "defaultMessage": "Store State"
     },
     "pincode": {
       "id": "app.newKeys.settings.pincode",
       "defaultMessage": "Pincode : 201002"
     },
     "contactNumber": {
       "id": "app.newKeys.settings.contactNumber",
       "defaultMessage": "Contact Number : 7836978665"
     },
     "email": {
       "id": "app.newKeys.settings.email",
       "defaultMessage": "Email : emai@email.com"
     },
     "gstNumber": {
       "id": "app.newKeys.settings.gstNumber",
       "defaultMessage": "GST Number : 123409874563"
     },
     "taxInvoice": {
       "id": "app.newKeys.settings.taxInvoice",
       "defaultMessage": "TAX INVOICE"
     },
     "invoiceNo5": {
       "id": "app.newKeys.settings.invoiceNo5",
       "defaultMessage": "Invoice No-5"
     },
     "billNumber": {
       "id": "app.newKeys.settings.billNumber",
       "defaultMessage": "Bill Number : 12340988993"
     },
     "billTime": {
       "id": "app.newKeys.settings.billTime",
       "defaultMessage": "Bill Time : 23-02-2020 15:23:19"
     },
     "printTime": {
       "id": "app.newKeys.settings.printTime",
       "defaultMessage": "Print Time : 23-02-2020 15:23:19"
     },
     "billingUserId": {
       "id": "app.newKeys.settings.billingUserId",
       "defaultMessage": "Billing User(ID) : food_qb"
     },
     "customerName": {
       "id": "app.newKeys.settings.customerName",
       "defaultMessage": "Customer Name : Mr.JeffersonPhone : 9987675645Address : 109/c, Sector-22, Gurgoan"
     },
     "cashReceived": {
       "id": "app.newKeys.settings.cashReceived",
       "defaultMessage": "Cash Recieved"
     },
     "₹12546.0": {
       "id": "app.newKeys.settings.₹12546.0",
       "defaultMessage": "₹ 12546.0"
     },
     "item": {
       "id": "app.newKeys.settings.item",
       "defaultMessage": "Item"
     },
     "qty": {
       "id": "app.newKeys.settings.qty",
       "defaultMessage": "Qty"
     },
     "price": {
       "id": "app.newKeys.settings.price",
       "defaultMessage": "Price"
     },
     "redmeNotePro": {
       "id": "app.newKeys.settings.redmeNotePro",
       "defaultMessage": "Redme Note Pro       1"
     },
     "size32": {
       "id": "app.newKeys.settings.size32",
       "defaultMessage": "Size 32"
     },
     "hsnSacCode23146986": {
       "id": "app.newKeys.settings.hsnSacCode23146986",
       "defaultMessage": "HSN/SAC Code 23146986"
     },
     "barcodeBri3282": {
       "id": "app.newKeys.settings.barcodeBri3282",
       "defaultMessage": "Barcode: BRI3282"
     },
     "₹11999": {
       "id": "app.newKeys.settings.₹11999",
       "defaultMessage": "₹ 11999"
     },
     "miXEarphones": {
       "id": "app.newKeys.settings.miXEarphones",
       "defaultMessage": "MI X Earphones       1"
     },
     "size36": {
       "id": "app.newKeys.settings.size36",
       "defaultMessage": "Size 36"
     },
     "hsnSacCode23146986": {
       "id": "app.newKeys.settings.hsnSacCode23146986",
       "defaultMessage": "HSN/SAC Code 23146986"
     },
     "barcodeBri3282": {
       "id": "app.newKeys.settings.barcodeBri3282",
       "defaultMessage": "Barcode: BRI3282"
     },
     "₹399.0": {
       "id": "app.newKeys.settings.₹399.0",
       "defaultMessage": "₹ 399.0"
     },
     "2Items2Qty": {
       "id": "app.newKeys.settings.2Items2Qty",
       "defaultMessage": "2 Items(2 Qty)"
     },
     "2398": {
       "id": "app.newKeys.settings.2398",
       "defaultMessage": ": 2398"
     },
     "changeAmount": {
       "id": "app.newKeys.settings.changeAmount",
       "defaultMessage": "Change Amount : 50.00"
     },
     "loyaltyDiscount": {
       "id": "app.newKeys.settings.loyaltyDiscount",
       "defaultMessage": "Loyalty Discount : (-)50.00"
     },
     "taxableAmount": {
       "id": "app.newKeys.settings.taxableAmount",
       "defaultMessage": "Taxable Amount : 10777.95"
     },
     "gst12": {
       "id": "app.newKeys.settings.gst12",
       "defaultMessage": "GST 12% : 1293.35"
     },
     "cgst25": {
       "id": "app.newKeys.settings.cgst25",
       "defaultMessage": "CGST 2.5% : 269.45"
     },
     "deliveryCharge": {
       "id": "app.newKeys.settings.deliveryCharge",
       "defaultMessage": "Delivery Charge : 200.00"
     },
     "rounding": {
       "id": "app.newKeys.settings.rounding",
       "defaultMessage": "Rounding : 0.35"
     },
     "gs": {
       "id": "app.newKeys.settings.gs",
       "defaultMessage": "GS : 1293.35"
      },
      sampleFooterMessage:{
         id: "app.newKeys.settings.sampleFooterMessage",
          defaultMessage:"Sample Footer Message"
      },
      loyaltyPointsUsed:{
         id: "app.newKeys.settings.loyaltyPointsUsed",
         defaultMessage:"Loyalty Points Used : 10"
      },
      youSaved:{
         id: "app.newKeys.settings.youSaved",
         defaultMessage:"You Saved : 57.0"
      },
      totalMRP:{
         id: "app.newKeys.settings.totalMRP",
         defaultMessage:"Total MRP : 12398.00"
      },
      companyAddressLine1:{
         id: "app.newKeys.settings.companyAddressLine1",
         defaultMessage:"Company Address line 1"
      },
      companyAddressLine2:{
         id: "app.newKeys.settings.companyAddressLine2",
         defaultMessage:"Company Address line 2"
      },
      companyCity:{
         id: "app.newKeys.settings.companyCity",
         defaultMessage:"Company City"
      },
      companyState:{
         id: "app.newKeys.settings.companyState",
         defaultMessage:"Company State"
      },
      tin34:{
         id: "app.newKeys.settings.tin34",
         defaultMessage:"TIN : 34567890"
      },
      cin90:{
         id: "app.newKeys.settings.cin90",
         defaultMessage:"CIN : 90765467"
      },
      orderTypeAudit:{
         id: "app.newKeys.settings.orderTypeAudit",
         defaultMessage:"Order Type : AUDIT_BILL"
      },
      phone99:{
         id: "app.newKeys.settings.phone99",
         defaultMessage:"Phone : 9987675645"
      },
      addGur:{
         id: "app.newKeys.settings.addGur",
         defaultMessage:"Address : 109/c, Sector-22, Gurgoan"
      },
      dis25:{
         id: "app.newKeys.settings.dis25",
         defaultMessage:"Discount              (-)25.0"
      },
      cgst125:{
         id: "app.newKeys.settings.cgst125",
         defaultMessage:"CGST                   125.0"
      },
      mrp699:{
         id: "app.newKeys.settings.mrp699",
         defaultMessage:"MRP ₹ 699.0"
      },
      taxTaxable:{
         id: "app.newKeys.settings.taxTaxable",
         defaultMessage:"Tax          Taxable Amt        Tax Value"
      },
      cgst2398:{
         id: "app.newKeys.settings.cgst2398",
         defaultMessage:"CGST         2398.25            00115.00"
      },
      tenderAmount:{
         id: "app.newKeys.settings.tenderAmount",
         defaultMessage:"Tender Amount : 5000.00"
      },
      apkVersion:{
         id: "app.newKeys.settings.apkVersion",
         defaultMessage:"Apk Version"
      },
      cashRecievedValue:{
         id: "app.newKeys.settings.cashRecievedValue",
         defaultMessage:"12546.0"
      },
      threeNineNine:{
         id: "app.newKeys.settings.threeNineNine",
         defaultMessage:"399.0"
      },
      elevenNine:{
         id: "app.newKeys.settings.elevenNine",
         defaultMessage:"11999"
      },
      showMRP:{
         id:"app.newKeys.settings.showMRP",
         defaultMessage:"699"
      }
   },
   appDeviceSetting:{
      eventType:{
         id:"app.newKeys.appDeviceSetting.eventType",
         defaultMessage:"Event Type"
      },
      logRequestTimeLocal:{
         id:"app.newKeys.appDeviceSetting.logRequestTimeLocal",
         defaultMessage:"Log Request Time Local"
      },
      S3_URL:{
         id:"app.newKeys.appDeviceSetting.S3_URL",
         defaultMessage:"S3 URL"
      },
      viewLogs:{
         id:"app.newKeys.appDeviceSetting.viewLogs",
         defaultMessage:"View Logs"
      },
      deviceLogs:{
         id:"app.newKeys.appDeviceSetting.deviceLogs",
         defaultMessage:"Device Log"
      },
      deviceId:{
         id:"app.newKeys.appDeviceSetting.deviceId",
         defaultMessage:"Device Id"
      },
      deviceTotalKeyword:{
         id:"app.newKeys.appDeviceSetting.deviceTotalKeyword",
         defaultMessage:"device(s)"
      }
   },
   estore:{
      catalog:{
         editStoreProduct:{
            id:"app.newKeys.estore.catalog.editStoreProduct",
            defaultMessage:"QB eStore product configuration"
         }
      }
   }
},
   integraionModule:{
      integrationDashboard:{
         title:{
            id:"app.integraionModule.integrationDashboard.title",
            defaultMessage:"Integration Dashboard"
         },
         description:{
            id:"app.integraionModule.integrationDashboard.description",
            defaultMessage:"Here's an overview of your brand catalog."
         },
         availableIntegrations:{
            id:"app.integraionModule.integrationDashboard.availableIntegrations",
            defaultMessage:"Available Integrations"
         },
         enabledIntegrations:{
            id:"app.integraionModule.integrationDashboard.enabledIntegrations",
            defaultMessage:"Enabled Integrations"
         },
         Unicommerce:{
            id:"app.integraionModule.integrationDashboard.Unicommerce",
            defaultMessage:"Unicommerce"
         },
         Urbanpiper:{
            id:"app.integraionModule.integrationDashboard.Urbanpiper",
            defaultMessage:"Urbanpiper"
         },
         easyEcom:{
            id:"app.integraionModule.integrationDashboard.easyEcom",
            defaultMessage:"Easy Ecom"
         },
         easyRewards:{
            id:"app.integraionModule.integrationDashboard.easyRewards",
            defaultMessage:"Easy Rewards"
         },
         Mloyal:{
            id:"app.integraionModule.integrationDashboard.Mloyal",
            defaultMessage:"Mloyal"
         },
         UnicommerceDesc: {
            id:"app.integraionModule.integrationDashboard.UnicommerceDesc",
            defaultMessage:"Streamline operations, optimize efficiency - Unicommerce revolutionizes e-commerce management.",
         },
         UrbanpiperDesc:{
            id:"app.integraionModule.integrationDashboard.UrbanpiperDesc",
            defaultMessage:"Empower your restaurant's digital journey with UrbanPiper's cutting-edge technology stack."
         },
         easyEcomDesc:{
            id:"app.integraionModule.integrationDashboard.easyEcomDesc",
            defaultMessage:"Empower your e-commerce dreams with easyEcom's all-in-one platform for seamless growth."
         },
         easyRewardsDesc:{
            id:"app.integraionModule.integrationDashboard.easyRewardsDesc",
            defaultMessage:"Reward loyalty, foster connections - easyRewards enhances customer engagement effortlessly."
         },
         MloyalDesc:{
            id:"app.integraionModule.integrationDashboard.MloyalDesc",
            defaultMessage:"From personalized offers to insightful analytics, Mloyal transforms loyalty into a strategic advantage."
         },
      },
   },
   unicommerce:{
      catalog:{
         title:{
            id:"app.unicommerce.catalog.title",
            defaultMessage:"Store Catalog"
         },
         description:{
            id:"app.unicommerce.catalog.description",
            defaultMessage:"Fetch and update catalog from Unicommerce"
         },
         sync:{
            id:"app.unicommerce.catalog.sync",
            defaultMessage:"Sync"
         },
         editProductDetails:{
            id:"app.unicommerce.catalog.editProductDetails",
            defaultMessage:"Edit product details"
         },
         productName:{
            id:"app.unicommerce.catalog.productName",
            defaultMessage:"Product Name"
         },
         price:{
            id:"app.unicommerce.catalog.price",
            defaultMessage:"Price"
         },
         lowStockValue:{
            id:"app.unicommerce.catalog.lowStockValue",
            defaultMessage:"Low Stock Value"
         },
         safeStockLevel:{
            id:"app.unicommerce.catalog.safeStockLevel",
            defaultMessage:"Safe Stock Level"
         },
         mrp:{
            id:"app.unicommerce.catalog.mrp",
            defaultMessage:"M.R.P"
         },
         unableToGetStoreProductsList:{
            id:"app.unicommerce.catalog.unableToGetStoreProductsList",
            defaultMessage:"Unable to get Store Products List!"
         },
         sellingPrice:{
            id:"app.unicommerce.catalog.sellingPrice",
            defaultMessage:"Selling Price"
         },
         
      },
      settings:{
         title:{
            id:"app.unicommerce.settings.title",
            defaultMessage:"Settings"
         },
         description:{
            id:"app.unicommerce.settings.description",
            defaultMessage:"Update Unicommerce settings."
         },
         cardTitle1:{
            id:"app.unicommerce.settings.cardTitle1",
            defaultMessage:"Chain Settings"
         },
         enableUnicommerceIntergration:{
            id:"app.unicommerce.settings.enableUnicommerceIntergration",
            defaultMessage:"Enable Unicommerce Intergration"
         },
         isShippingManagedByUnicommerce:{
            id:"app.unicommerce.settings.isShippingManagedByUnicommerce",
            defaultMessage:"Is Shipping Managed By Unicommerce"
         },
         clientID:{
            id:"app.unicommerce.settings.clientID",
            defaultMessage:"ClientID"
         },
         grantType:{
            id:"app.unicommerce.settings.grantType",
            defaultMessage:"GrantType"
         },
         unicommerceIntegrationURL:{
            id:"app.unicommerce.settings.unicommerceIntegrationURL",
            defaultMessage:"Integration URL"
         },
         password:{
            id:"app.unicommerce.settings.password",
            defaultMessage:"Password"
         },
         userName:{
            id:"app.unicommerce.settings.userName",
            defaultMessage:"Username"
         },
         userName:{
            id:"app.unicommerce.settings.userName",
            defaultMessage:"Username"
         },
         cardTitle2:{
            id:"app.unicommerce.settings.cardTitle2",
            defaultMessage:"Store Settings"
         },
         selectStore:{
            id:"app.unicommerce.settings.selectStore",
            defaultMessage:"Select Store"
         },
         enableStoreUnicommerceIntegration:{
            id:"app.unicommerce.settings.enableStoreUnicommerceIntegration",
            defaultMessage:"Enable Store Unicommerce Intergration"
         },
         channel:{
            id:"app.unicommerce.settings.channel",
            defaultMessage:"Channel"
         },
         facilityCode:{
            id:"app.unicommerce.settings.facilityCode",
            defaultMessage:"Facility Code"
         },
         facilityName:{
            id:"app.unicommerce.settings.facilityName",
            defaultMessage:"Facility Name"
         },
      },
      apiLogs:{
         title:{
            id:"app.unicommerce.apiLogs.title",
            defaultMessage:"Unicommerce API Logs"
         },
         description:{
            id:"app.unicommerce.apiLogs.description",
            defaultMessage:"Detail report of Unicommerce API Logs. "
         },
         copy:{
            id:"app.unicommerce.apiLogs.copy",
            defaultMessage:"Copy"
         },
         apiName:{
            id:"app.unicommerce.apiLogs.apiName",
            defaultMessage:"API Name"
         },
         apiMethod:{
            id:"app.unicommerce.apiLogs.apiMethod",
            defaultMessage:"API Method"
         },
         serverRequestTimeLocal:{
            id:"app.unicommerce.apiLogs.serverRequestTimeLocal",
            defaultMessage:"Server Request Time"
         },
         serverRequestTimeUTC:{
            id:"app.unicommerce.apiLogs.serverRequestTimeUTC",
            defaultMessage:"Server Request Time UTC"
         },
         serverRequest:{
            id:"app.unicommerce.apiLogs.serverRequest",
            defaultMessage:"Server Request"
         },
         viewRequest:{
            id:"app.unicommerce.apiLogs.viewRequest",
            defaultMessage:"View Request"
         },
         serverResponse:{
            id:"app.unicommerce.apiLogs.serverResponse",
            defaultMessage:"Server Response"
         },
         viewResponse:{
            id:"app.unicommerce.apiLogs.viewResponse",
            defaultMessage:"View Response"
         },
      },
      salesOrder:{
         title:{
            id:"app.unicommerce.salesOrder.title",
            defaultMessage:"Unicommerce Sales Order List"
         },
         description:{
            id:"app.unicommerce.salesOrder.description",
            defaultMessage:"Detail report of unicommerce Sales Order List."
         },
         PINumber:{
            id:"app.unicommerce.salesOrder.PINumber",
            defaultMessage:"PI Number"
         },
         createOrderAtLocal:{
            id:"app.unicommerce.salesOrder.createOrderAtLocal",
            defaultMessage:"Created  At"
         },
         shippingPackageCode:{
            id:"app.unicommerce.salesOrder.shippingPackageCode",
            defaultMessage:"Shipping  Code"
         },
         channel:{
            id:"app.unicommerce.salesOrder.channel",
            defaultMessage:"Channel"
         },
         orderStatus:{
            id:"app.unicommerce.salesOrder.orderStatus",
            defaultMessage:"Order Status"
         },
         marketplaceOrderID:{
            id:"app.unicommerce.salesOrder.marketplaceOrderID",
            defaultMessage:"Marketplace ID"
         },
         
      }
   },
   easyRewards:{
      apiLogs:{
         title:{
            id:"app.easyRewards.apiLogs.title",
            defaultMessage:"Easy Rewards Api Logs"
         },
         description:{
            id:"app.easyRewards.apiLogs.description",
            defaultMessage:"Detail report of Easy Rewards Api Logs. "
         },
         serverRequestTimeLocal:{
            id:"app.easyRewards.apiLogs.serverRequestTimeLocal",
            defaultMessage:"Server Request Time"
         }
      },
      customerLedger:{
         title:{
            id:"app.easyRewards.customerLedger.title",
            defaultMessage:"Easy Rewards Customer Ledger"
         },
         description:{
            id:"app.easyRewards.customerLedger.description",
            defaultMessage:"Detail report of Customer Ledger."
         },
         id:{
            id:"app.easyRewards.customerLedger.id",
            defaultMessage:"ID"
         },
         phone:{
            id:"app.easyRewards.customerLedger.phone",
            defaultMessage:"Phone"
         },
         creationTime:{
            id:"app.easyRewards.customerLedger.creationTime",
            defaultMessage:"Creation Time"
         },
      },
      workFlowWizard:{
         title:{
            id:"app.easyRewards.workFlowWizard.title",
            defaultMessage:"Easy Rewards Work Flow Wizard"
         },
      }
   },
   mLoyal:{
      mLoyalLedger:{
         title:{
            id:"app.mLoyal.mLoyalLedger.title",
            defaultMessage:"Mloyal Ledger"
         },
         description:{
            id:"app.mLoyal.mLoyalLedger.description",
            defaultMessage:"Detail report of Mloyal Ledger."
         },
         id:{
            id:"app.mLoyal.mLoyalLedger.id",
            defaultMessage:"ID"
         },
         phone:{
            id:"app.mLoyal.mLoyalLedger.phone",
            defaultMessage:"Phone"
         },
         customerId:{
            id:"app.mLoyal.mLoyalLedger.customerId",
            defaultMessage:"Customer ID"
         },
         customerBalance:{
            id:"app.mLoyal.mLoyalLedger.customerBalance",
            defaultMessage:"Customer Balance"
         },
         reqType:{
            id:"app.mLoyal.mLoyalLedger.reqType",
            defaultMessage:"Req Type"
         },
         creationTime:{
            id:"app.mLoyal.mLoyalLedger.creationTime",
            defaultMessage:"Creation Time"
         }
      },
      mLoyalApiLogs:{
         title:{
            id:"app.mLoyal.mLoyalApiLogs.title",
            defaultMessage:"Mloyal Api Logs"
         },
         description:{
            id:"app.mLoyal.mLoyalApiLogs.description",
            defaultMessage:"Detail report of MLoyal Api Logs."
         },
         apiTrackerId:{
            id:"app.mLoyal.mLoyalApiLogs.apiTrackerId",
            defaultMessage:"Api Tracker ID"
         },
         thirdPartyChainId:{
            id:"app.mLoyal.mLoyalApiLogs.thirdPartyChainId",
            defaultMessage:"Third Party Chain ID"
         },
         clientIp:{
            id:"app.mLoyal.mLoyalApiLogs.clientIp",
            defaultMessage:"Client IP"
         },
         apiName:{
            id:"app.mLoyal.mLoyalApiLogs.apiName",
            defaultMessage:"Api Name"
         },
         apiMethod:{
            id:"app.mLoyal.mLoyalApiLogs.apiMethod",
            defaultMessage:"Api Method"
         },
         apiPath:{
            id:"app.mLoyal.mLoyalApiLogs.apiPath",
            defaultMessage:"Api Path"
         },
         token:{
            id:"app.mLoyal.mLoyalApiLogs.token",
            defaultMessage:"Token"
         },
         serverRequestTime:{
            id:"app.mLoyal.mLoyalApiLogs.serverRequestTime",
            defaultMessage:"Server Request Time"
         },
         responseTime:{
            id:"app.mLoyal.mLoyalApiLogs.responseTime",
            defaultMessage:"Response Time"
         },
         memoryUsage:{
            id:"app.mLoyal.mLoyalApiLogs.memoryUsage",
            defaultMessage:"Memory Usage"
         },
         statusCode:{
            id:"app.mLoyal.mLoyalApiLogs.statusCode",
            defaultMessage:"Status Code"
         },
         viewRequest:{
            id:"app.mLoyal.mLoyalApiLogs.viewRequest",
            defaultMessage:"View Request"
         },
         viewResponse:{
            id:"app.mLoyal.mLoyalApiLogs.viewResponse",
            defaultMessage:"View Response"
         }
      }

   },
   loginScreens: {
      loginPage: {
         poweredBy: {
         id: "app.loginScreens.loginPage.poweredBy",
         defaultMessage: "Powered by"
         },
         createAccountText: {
            id: "app.loginScreens.loginPage.createAccountText",
            defaultMessage: "Create an account"
         }
      }
    },
    navision: {
      navisionLog: {
         title: {
            id: "app.navision.navisionLog.title",
            defualtMessage: "Navision Logs"
         },
         desc: {
            id: "app.navision.navisionLog.desc",
            defualtMessage: "Detail report of Navision Logs"
         }
      }
    }
   }

   

const definedMessages = defineMessages(messages);

export default definedMessages;
