import {
  GET_INVENTORY_REQUEST,
  GET_INVENTORY_FAILURE,
  GET_INVENTORY_SUCCESS,
  STOCK_FORM_DATA,
  SET_CART_DATA,
  GET_UNITS_LIST,
  GET_PURCHASE_ORDERS,
  GET_PURCHASEORDER_DETAILS,
  GET_REQUISITION_ORDERS,
  GET_VENDORS_LISTING,
  CART_TRANSACTION,
  GET_STOCKTRANSACTION_HISTORY,
  GET_REMARKS_LIST,
  SET_VALIDPRODS,
  VALIDATION_REMARKS,
  ADD_VENDOR,
  GET_VENDOR_INVOICES,
  GET_INVOICE_PAYINFO,
  ADD_VENDOR_INVOICE,
  ADD_VENDOR_INVOICE_PAYMENT,
  EDIT_VENDOR_INVOICE_PAYMENT,
  EDIT_VENDOR_INVOICE,
  GET_PRODUCTS_BYCHAIN,
  GET_PRODUCTS_TRIMMED_BYCHAIN,
  GET_PRODUCTS_TRIMMED_WITH_COMBOS_BYCHAIN,
  GET_PRODUCTS_BYCHAIN_EXCEL,
  GET_REQUISITION_HISTORY,
  MAKE_STOCK_REQUISITION,
  GET_REQUISITION_IDS,
  GET_BATCHES_FAILURE,
  GET_BATCHES_REQUEST,
  GET_BATCHES_SUCCESS,
  GET_BATCH_PRODUCTS,
  GET_BATCH_PRODUCTS_LIST,
  EDIT_BATCH_TRANSACTION,
  CREATE_BATCH_TRANSACTION,
  INIT_LOADER,
  HALT_LOADER,
  FETCH_LOCALITY_ORDERS_REQUEST,
  FETCH_LOCALITY_ORDERS_SUCCESS,
  FETCH_LOCALITY_ORDERS_FAILURE,
  GET_CHAIN_ECOM_STORES_REQUEST,
  GET_CHAIN_ECOM_STORES_SUCCESS,
  GET_CHAIN_ECOM_STORES_FAILURE,
  STORE_LOCALITY_MAPPING_REQUEST,
  STORE_LOCALITY_MAPPING_SUCCESS,
  STORE_LOCALITY_MAPPING_FAILURE,
  DISPATCH_ORDER_REQUEST,
  DISPATCH_ORDER_SUCCESS,
  DISPATCH_ORDER_FAILURE,
  CLEAR_DISPATCH_ORDER_STATUS,
  FETCH_DO_SUMMARY_REQUEST,
  FETCH_DO_SUMMARY_SUCCESS,
  FETCH_DO_SUMMARY_FAILURE,
  FETCH_DO_DETAIL_REQUEST,
  FETCH_DO_DETAIL_SUCCESS,
  FETCH_DO_DETAIL_FAILURE,
  GET_STOCKCHAINPRODS_FAILURE,
  GET_STOCKCHAINPRODS_REQUEST,
  GET_STOCKCHAINPRODS_SUCCESS,
  GET_VENDORS_SOURCE_MAPPING,
  SUCCESS_NOTIFIER,
  CHECK_VENDORLIST_REQUEST,
  CHECK_VENDORLIST_SUCCESS,
  CHECK_VENDORLIST_FAILURE,
  CHECK_VENDOR_MAPPING_REQUEST,
  CHECK_VENDOR_MAPPING_SUCCESS,
  CHECK_VENDOR_MAPPING_FAILURE,
  GET_STORE_CATALOGUE_LIST_REQUEST,
  GET_STORE_CATALOGUE_LIST_SUCCESS,
  GET_STORE_CATALOGUE_LIST_FAILURE,
  GET_BATCHES_WEB_REQUEST,
  GET_BATCHES_WEB_SUCCESS,
  GET_BATCHES_WEB_FAILURE,
  GET_TRANSACTION_DETAILS_REQUEST,
  GET_TRANSACTION_DETAILS_SUCCESS,
  GET_TRANSACTION_DETAILS_FAILURE,
  ERROR_NOTIFIER,
  CHAIN_LEVEL_STORES,
  GET_PRODUCTS_PROMOTION,
  GET_PAYMENT_LIST
} from "./types";
import axiosClient from "../utils/axiosClient";
import React from "react";
import { FormattedMessage } from "react-intl";
import app from "../Internationalisation/MessageDescriptor";

export const getInventory = (req) => (dispatch) => {
  if (req.storeID <= 0) {
    dispatch({
      type: GET_INVENTORY_SUCCESS,
      payload: apiData
    });
    return [];
  }
  dispatch({
    type: GET_INVENTORY_REQUEST,
  });
  dispatch({
    type: INIT_LOADER,
  });
  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/getInventory", req)
    .then((data) => {
      apiData = data.data.data;
      dispatch({
        type: GET_INVENTORY_SUCCESS,
        payload: apiData,
      });
      dispatch({
        type: HALT_LOADER,
      });
      return apiData;
    })
    .catch((data) => {
      dispatch({
        type: GET_INVENTORY_FAILURE,
        payload: data,
      });
      dispatch({
        type: HALT_LOADER,
      });
    });
  return apiResponse;
};

export const stockProductsListChain = (req) => (dispatch) => {
  dispatch({
    type: GET_STOCKCHAINPRODS_REQUEST,
  });
  
  dispatch({
    type: INIT_LOADER,
  });
  

  var apiData = "";
  const apiResponse = axiosClient
    .get("/items/stockProductsListChain", req)
    .then((data) => {
      
      apiData = data.data.data;

      dispatch({
        type: HALT_LOADER,
      });
      dispatch({
        type: GET_STOCKCHAINPRODS_SUCCESS,
        payload: apiData,
      });

      return apiData;
    })
    .catch((data) => {
      dispatch({
        type: GET_STOCKCHAINPRODS_FAILURE,
        payload: data,
      });
      dispatch({
        type: HALT_LOADER,
      });
    });
  return apiResponse;
};

export const batches = (req) => (dispatch) => {
  dispatch({
    type: GET_BATCHES_REQUEST,
  });
  var apiData = "";
  const apiResponse = axiosClient
    .get("/items/batches", req)
    .then((data) => {
      apiData = data.data;
      dispatch({
        type: GET_BATCHES_SUCCESS,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      dispatch({
        type: GET_BATCHES_FAILURE,
        payload: data,
      });
    });
  return apiResponse;
};

export const batchProds = (req) => (dispatch) => {
  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/batchProds", req)
    .then((data) => {
      apiData = data.data.data;
      dispatch({
        type: GET_BATCH_PRODUCTS,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const getBatchProductsList = (req) => (dispatch) => {
  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/getBatchProductsList", req)
    .then((data) => {
      apiData = data.data.data;
      dispatch({
        type: GET_BATCH_PRODUCTS_LIST,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const stockFormData = (data) => (dispatch) => {
  

  dispatch({
    type: STOCK_FORM_DATA,
    payload: data,
  });
};

export const createBatchTransaction = (req) => (dispatch) => {
  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/createBatchTransaction", req)
    .then((data) => {
      if(!data.data.status) throw data;
      apiData = data.data.data;
      dispatch({
        type: CREATE_BATCH_TRANSACTION,
        payload: apiData,
      });
      dispatch({
        type: SUCCESS_NOTIFIER,
        payload: apiData?.message || "Batch created successfully",
      });
      return apiData;
    })
    .catch((data) => {
      dispatch({
        type: ERROR_NOTIFIER,
        payload: "Error while creating batch.",
      });
      
    });
  return apiResponse;
};

export const editBatchTransaction = (req) => (dispatch) => {
  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/editBatchTransaction", req)
    .then((data) => {
      apiData = data.data;
      dispatch({
        type: EDIT_BATCH_TRANSACTION,
        payload: apiData,
      });
      if(data.data.status){
        dispatch({
          type: SUCCESS_NOTIFIER,
          payload: apiData?.message || "Batch edited successfully",
        });
      }
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const setCartData = (data) => (dispatch) => {
  
  dispatch({
    type: SET_CART_DATA,
    payload: data,
  });
};

export const getUnitsList = (req) => (dispatch) => {
  dispatch({
    type: INIT_LOADER,
  });
  

  var apiData = "";
  const apiResponse = axiosClient
    .get("/items/getUnitsList", req)
    .then((data) => {
      apiData = data.data.data;
      dispatch({
        type: HALT_LOADER,
      });
      dispatch({
        type: GET_UNITS_LIST,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
      dispatch({
        type: HALT_LOADER,
      });
    });
  return apiResponse;
};

export const getPurchaseOrders = (req) => (dispatch) => {
  

  var apiData = "";
  dispatch({
    type: INIT_LOADER,
  });
  const apiResponse = axiosClient
    .post("/items/getPurchaseOrders", req)
    .then((data) => {
      apiData = data.data.data;
      

      dispatch({
        type: GET_PURCHASE_ORDERS,
        payload: apiData,
      });
      dispatch({
        type: HALT_LOADER,
      });
      return apiData;
    })
    .catch((data) => {
      dispatch({
        type: HALT_LOADER,
      });
      console.log("Error getpurchaseorders", data);
      
    });
  return apiResponse;
};

export const getPurchaseOrdersDetail = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/getPurchaseOrdersDetail", req)
    .then((data) => {
      let api = data.data;
      apiData = api.purchaseOrderDetail;
      

      dispatch({
        type: GET_PURCHASEORDER_DETAILS,
        payload: apiData,
      });
      return api;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const requestDetail = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/requestDetail", req)
    .then((data) => {
      apiData = data.data;
      

      dispatch({
        type: GET_REQUISITION_ORDERS,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const getVendorsListing = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .get("/items/getVendorsListing", req)
    .then((data) => {
      apiData = data.data.data;
      

      dispatch({
        type: GET_VENDORS_LISTING,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const getVendorSourceMapping = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/vendors/getVendorSourceMapping", req)
    .then((data) => {
      apiData = data.data.data;
      

      // dispatch({
      //   type:GET_VENDORS_SOURCE_MAPPING,
      //   payload: apiData
      // })
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const postVendorSourceMapping = (req) => (dispatch) => {
  dispatch({
    type: INIT_LOADER,
  });

  const apiResponse = axiosClient
    .post("/vendors/postVendorSourceMapping", req)
    .then((res) => {
      dispatch({
        type: SUCCESS_NOTIFIER,
        payload:  res && res.data && res.data.status ? res.data.message : "Vendor source mapping updated.",
      });

      dispatch({
        type: HALT_LOADER,
      });

      return res;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const postVendorProductMapping = (req) => (dispatch) => {
  dispatch({
    type: INIT_LOADER,
  });

  const apiResponse = axiosClient
    .post("/vendors/postVendorProductMapping", req)
    .then((res) => {
      dispatch({
        type: SUCCESS_NOTIFIER,
        payload: res && res.data && res.data.status ? res.data.message : "Vendor product mapping updated.",
      });

      dispatch({
        type: HALT_LOADER,
      });

      return res;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const createVendor = (req) => (dispatch) => {
  dispatch({
    type: INIT_LOADER,
  });

  const apiResponse = axiosClient
    .post("/vendors/createvendor", req)
    .then((res) => {
      dispatch({
        type: SUCCESS_NOTIFIER,
        payload: <FormattedMessage children={el=>el} {...app.common.defaultSuccessToast}/>,
      });

      dispatch({
        type: HALT_LOADER,
      });

      return res;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const deleteVendor = (req) => (dispatch) => {
  dispatch({
    type: INIT_LOADER,
  });

  const apiResponse = axiosClient
    .post("/vendors/deletevendor", req)
    .then((res) => {
      // dispatch({
      //   type: SUCCESS_NOTIFIER,
      //   payload: "Vendor deleted successfully.",
      // });

      dispatch({
        type: HALT_LOADER,
      });
      
      return res;
    })
    .catch((data) => {
      
    });

  return apiResponse;
};

export const editVendor = (req) => (dispatch) => {
  dispatch({
    type: INIT_LOADER,
  });

  const apiResponse = axiosClient
    .post("/vendors/editvendor", req)
    .then((res) => {
      dispatch({
        type: SUCCESS_NOTIFIER,
        payload: Boolean(res && res.data && res.data.message) ? res.data.message : <FormattedMessage children={el=>el} {...app.common.defaultSuccessToast}/>,
      });

      dispatch({
        type: HALT_LOADER,
      });
      
      return res;
    })
    .catch((data) => {
      
    });

  return apiResponse;
};

export const getVendorProductMapping = (req) => (dispatch) => {
  
  dispatch({
    type: CHECK_VENDOR_MAPPING_REQUEST,
  });
  dispatch({
    type: INIT_LOADER,
  });
  var apiData = "";
  const apiResponse = axiosClient
    .post("/vendors/getVendorProductMapping", req)
    .then((data) => {
      apiData = data.data.data;
      
      dispatch({
        type: CHECK_VENDOR_MAPPING_SUCCESS,
        payload: apiData,
      });
      dispatch({
        type: HALT_LOADER,
      });
      return apiData;
    })
    .catch((data) => {
      dispatch({
        type: CHECK_VENDOR_MAPPING_FAILURE,
        payload: data,
      });
      dispatch({
        type: HALT_LOADER,
      });
    });
  return apiResponse;
};

export const getSourceVendorsList = (req) => (dispatch) => {
  
  dispatch({
    type: CHECK_VENDORLIST_REQUEST,
  });
  dispatch({
    type: INIT_LOADER,
  });
  var apiData = "";
  const apiResponse = axiosClient
    .post("/vendors/getSourceVendorsList", req)
    .then((data) => {
      
      apiData = data.data;
      dispatch({
        type: CHECK_VENDORLIST_SUCCESS,
        payload: apiData,
      });
      dispatch({
        type: HALT_LOADER,
      });
      return apiData;
    })
    .catch((data) => {
      dispatch({
        type: CHECK_VENDORLIST_FAILURE,
        payload: data,
      });
      dispatch({
        type: HALT_LOADER,
      });
    });
  return apiResponse;
};

export const stockTransaction = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/stockTransaction", req)
    .then((data) => {
      apiData = data.data;
      

      dispatch({
        type: CART_TRANSACTION,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};


export const BulkStockTransactionWeb = (req) => dispatch =>{
  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/bulkStockTransactionWeb", req)
    .then((data) => {
      apiData = data.data;
      return apiData;
    })
    .catch((data) => {
      return data
    });
  return apiResponse;
}

export const stockRequisition = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/stockRequisition", req)
    .then((data) => {
      apiData = data.data;
      

      // dispatch({
      //   type: CART_TRANSACTION,
      //   payload: apiData,
      // });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const makeStockRequisition = (req) => (dispatch) => {
  
  dispatch({
    type: INIT_LOADER,
  });
  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/makeStockRequisition", req)
    .then((data) => {
      apiData = data.data;
      

      dispatch({
        type: MAKE_STOCK_REQUISITION,
        payload: apiData,
      });
      dispatch({
        type: HALT_LOADER,
      });
      return apiData;
    })
    .catch((err) => {
      dispatch({
        type: HALT_LOADER,
      });
      return err.response.data 
    });
  return apiResponse;
};

export const getStockRequisitionsWeb = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .get("/items/getStockRequisitionsWeb", req)
    .then((data) => {
      apiData = data.data;
      

      dispatch({
        type: GET_REQUISITION_IDS,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

// export const stockTransaction= req => dispatch => {  //old//
//   

// var apiData = "";
// const apiResponse = axiosClient
//   .post("/items/stockTransaction",req)
//   .then(data => {
//     apiData = data.data.data;
//     

//     dispatch({
//       type:CART_TRANSACTION,
//       payload: apiData
//   })
//     return apiData;
//   })
//   .catch(data => {
//   });
// return apiResponse;
// };

export const stockTransactionHistory = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/stockTransactionHistory", req)
    .then((data) => {
      apiData = data.data.data;
      

      dispatch({
        type: GET_STOCKTRANSACTION_HISTORY,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const stockRequestHistory = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/stockRequestHistory", req)
    .then((data) => {
      apiData = data.data.data;
      

      dispatch({
        type: GET_REQUISITION_HISTORY,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const remarks = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .get("/items/remarks", req)
    .then((data) => {
      apiData = data.data;
      

      dispatch({
        type: GET_REMARKS_LIST,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const setValidProds = (data) => (dispatch) => {
  dispatch({
    type: SET_VALIDPRODS,
    payload: data,
  });
};

export const validationRemarks = (req) => (dispatch) => {
  dispatch({
    type: INIT_LOADER,
  });
  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/validationRemarks", req)
    .then((data) => {
      apiData = data.data.data;
      dispatch({
        type: HALT_LOADER,
      });    
      dispatch({
        type: VALIDATION_REMARKS,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      dispatch({
        type: HALT_LOADER,
      });  
    });
  return apiResponse;
};

export const addVendor = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/addVendor", req)
    .then((data) => {
      apiData = data.data.data;
      

      dispatch({
        type: ADD_VENDOR,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const vendorInvoices = (req) => (dispatch) => {
  
  dispatch({
    type: INIT_LOADER,
  });

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/vendorInvoices", req)
    .then((data) => {
      apiData = data.data.data;
      

      dispatch({
        type: GET_VENDOR_INVOICES,
        payload: apiData,
      });
      dispatch({
        type: HALT_LOADER,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const getInvoicePaymentInfo = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/getInvoicePaymentInfo", req)
    .then((data) => {
      apiData = data.data;
      

      dispatch({
        type: GET_INVOICE_PAYINFO,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const addVendorInvoice = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/addVendorInvoice", req)
    .then((data) => {
      apiData = data.data;

      dispatch({
        type: ADD_VENDOR_INVOICE,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const editVendorInvoice = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/editVendorInvoice", req)
    .then((data) => {
      apiData = data.data;

      dispatch({
        type: EDIT_VENDOR_INVOICE,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const addVendorInvoicePayment = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/addVendorInvoicePayment", req)
    .then((data) => {
      apiData = data.data;

      dispatch({
        type: ADD_VENDOR_INVOICE_PAYMENT,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const editVendorInvoicePayment = (req) => (dispatch) => {
  

  var apiData = "";
  const apiResponse = axiosClient
    .post("/items/editVendorInvoicePayment", req)
    .then((data) => {
      apiData = data.data;

      dispatch({
        type: EDIT_VENDOR_INVOICE_PAYMENT,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};

export const productsChain = (req) => (dispatch) => {
  

  var apiData = "";
  dispatch({
    type: INIT_LOADER,
  });
  const apiResponse = axiosClient
    .get("/items/productsChain", req)
    //'/masterData/productsChain'
    .then((data) => {
      apiData = data.data.data;

      dispatch({
        type: GET_PRODUCTS_BYCHAIN,
        payload: apiData,
      });
      dispatch({
        type: HALT_LOADER,
      });
      return apiData;
    })
    .catch((data) => {
      
      dispatch({
        type: HALT_LOADER,
      });
    });
  return apiResponse;
};

export const productsListTrimmedWeb = (req) => (dispatch) => {
  

  var apiData = "";
  dispatch({
    type: INIT_LOADER,
  });
  const apiResponse = axiosClient
    .get("/items/productsListTrimmedWeb", req)
    //'/masterData/productsListTrimmedWeb'
    .then((data) => {
      
      apiData = data.data.data;

      dispatch({
        type: GET_PRODUCTS_TRIMMED_BYCHAIN,
        payload: apiData,
      });
      dispatch({
        type: HALT_LOADER,
      });
      return apiData;
    })
    .catch((data) => {
      
      dispatch({
        type: HALT_LOADER,
      });
    });
  return apiResponse;
};

export const productsListTrimmedWebWithCombos = (req) => (dispatch) => {
  

  var apiData = "";
  dispatch({
    type: INIT_LOADER,
  });
  const apiResponse = axiosClient
    .get("/items/productsListTrimmedWebWithCombos", req)
    //'/masterData/productsListTrimmedWeb'
    .then((data) => {
      
      apiData = data.data.data.productsListWithCombos.data.data;
      // return apiData;

      dispatch({
        type: GET_PRODUCTS_TRIMMED_WITH_COMBOS_BYCHAIN,
        payload: apiData
      })
      dispatch({
        type: HALT_LOADER,
      });
      return apiData;
    })
    .catch((data) => {
      
      dispatch({
        type: HALT_LOADER,
      });
    });
  return apiResponse;
};

export const exportProductsListChainWeb = (req) => (dispatch) => {
  

  var apiData = "";
  dispatch({
    type: INIT_LOADER,
  });
  const apiResponse = axiosClient
    .get("/items/exportProductsListChainWeb", req)
    //'/masterData/productsChain'
    .then((data) => {
      apiData = data.data.data;
      
      dispatch({
        type: GET_PRODUCTS_BYCHAIN_EXCEL,
        payload: apiData,
      });
      dispatch({
        type: HALT_LOADER,
      });
      return apiData;
    })
    .catch((data) => {
      
      dispatch({
        type: HALT_LOADER,
      });
    });
  return apiResponse;
};

export const productsChainPromotion = (req) => (dispatch) => {

	var apiData = "";
	dispatch({
		type: INIT_LOADER,
	});
	const apiResponse = axiosClient
		.get("/items/productsChainPromotion", req)
		.then((data) => {
			apiData = data.data.data;

			dispatch({
				type: GET_PRODUCTS_PROMOTION,
				payload: apiData,
			});
			dispatch({
				type: HALT_LOADER,
			});
			return apiData;
		})
		.catch((data) => {

			dispatch({
				type: HALT_LOADER,
			});
		});
	return apiResponse;
};

export const fetchPaymentValues = (req) => (dispatch) => {
	var apiData = "";
	dispatch({
		type: INIT_LOADER,
	});
	const apiResponse = axiosClient
		.get("/items/fetchPaymentValues", req)
		.then((data) => {
			apiData = data.data.data;
			dispatch({
				type: GET_PAYMENT_LIST,
				payload: apiData,
			});
			dispatch({
				type: HALT_LOADER,
			});
			return apiData;
		})
		.catch((data) => {
			dispatch({
				type: HALT_LOADER,
			});
      return { status: false, message: data.response.data.message || "Something went wrong" }
		});
	return apiResponse;
};

export const clearDispatchOrderStatus = () => (dispatch) => {
  dispatch({
    type: CLEAR_DISPATCH_ORDER_STATUS,
  });
};

let actions = [
  {
    dispatch: [
      FETCH_LOCALITY_ORDERS_REQUEST,
      FETCH_LOCALITY_ORDERS_SUCCESS,
      FETCH_LOCALITY_ORDERS_FAILURE,
    ],
    url: "/items/fetchLocalityOrders",
    type: "post",
  },
  {
    dispatch: [
      GET_CHAIN_ECOM_STORES_REQUEST,
      GET_CHAIN_ECOM_STORES_SUCCESS,
      GET_CHAIN_ECOM_STORES_FAILURE,
    ],
    url: "/items/getChainEcomStores",
    type: "get",
  },
  {
    dispatch: [
      STORE_LOCALITY_MAPPING_REQUEST,
      STORE_LOCALITY_MAPPING_SUCCESS,
      STORE_LOCALITY_MAPPING_FAILURE,
    ],
    url: "/items/fetchStoreLocalityMapping",
    type: "post",
  },
  {
    dispatch: [
      DISPATCH_ORDER_REQUEST,
      DISPATCH_ORDER_SUCCESS,
      DISPATCH_ORDER_FAILURE,
    ],
    url: "/items/dispatchOrder",
    type: "post",
  },
  {
    dispatch: [
      FETCH_DO_SUMMARY_REQUEST,
      FETCH_DO_SUMMARY_SUCCESS,
      FETCH_DO_SUMMARY_FAILURE,
    ],
    url: "/items/fetchDOSummary",
    type: "post",
  },
  {
    dispatch: [
      FETCH_DO_DETAIL_REQUEST,
      FETCH_DO_DETAIL_SUCCESS,
      FETCH_DO_DETAIL_FAILURE,
    ],
    url: "/items/fetchDODetail",
    type: "post",
  },
];

const genericAction = (i) => (data) => (dispatch) => {
  dispatch({
    type: actions[i].dispatch[0],
  });
  dispatch({
    type: INIT_LOADER,
  });
  axiosClient[actions[i].type](actions[i].url, data)
    .then(function (res) {
      dispatch({
        type: actions[i].dispatch[1],
        payload: res.data,
      });
      dispatch({
        type: HALT_LOADER,
      });
    })
    .catch(function (err) {
      dispatch({
        type: actions[i].dispatch[2],
        payload: err,
      });
      dispatch({
        type: HALT_LOADER,
      });
    });
};

export const fetchLocalityOrders = genericAction(0);
export const getChainEcomStores = genericAction(1);
export const fetchStoreLocalityMapping = genericAction(2);
export const dispatchOrder = genericAction(3);
export const fetchDOSummary = genericAction(4);
export const fetchDODetail = genericAction(5);

export const getStoreCatalogueListWEB = (req) => (dispatch) => {
  var apiData = "";
  dispatch({
    type: GET_STORE_CATALOGUE_LIST_REQUEST,
  });
  dispatch({
    type: INIT_LOADER,
  });

  const apiResponse = axiosClient
    .post(`/items/getStoreCatalogueListWEB`, req)
    .then((data) => {
      apiData = data.data.data;
      

      dispatch({
        type: HALT_LOADER,
      });
      dispatch({
        type: GET_STORE_CATALOGUE_LIST_SUCCESS,
        payload: apiData,
      });
      return apiData;
    })
    .catch((err) => {
      
      dispatch({
        type: HALT_LOADER,
      });
    });

  return apiResponse;
};

export const getBatchesWeb = (req) => (dispatch) => {
  dispatch({
    type: GET_BATCHES_WEB_REQUEST,
  });
  var apiData = "";
  

  const apiResponse = axiosClient
    .get(`/items/getBatchesWeb`, req)
    .then((data) => {
      apiData = data.data.batchList;
      
      dispatch({
        type: GET_BATCHES_WEB_SUCCESS,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      dispatch({
        type: GET_BATCHES_WEB_FAILURE,
        payload: data,
      });
    });
  return apiResponse;
};

export const getTransactionDetailsWeb = (req) => (dispatch) => {
  dispatch({
     type: GET_TRANSACTION_DETAILS_REQUEST,
  });
  var apiData = '';
  dispatch({
     type: INIT_LOADER,
  });

  const apiResponse = axiosClient
     .post('/items/getTransactionDetailsWeb', req)
     .then((data) => {
        apiData = data.data;
        dispatch({
           type: GET_TRANSACTION_DETAILS_SUCCESS,
           payload: apiData,
        });
        dispatch({
           type: HALT_LOADER,
        });
        return apiData;
     })
     .catch((data) => {
        dispatch({
           type: GET_TRANSACTION_DETAILS_FAILURE,
           payload: data,
        });
        dispatch({
           type: HALT_LOADER,
        });
     });
  return apiResponse;
};

export const fetchChainStoresAndWarehouses = (req) => (dispatch) => {
  var apiData = "";
  const apiResponse = axiosClient
    .get("/items/fetchChainStoresAndWarehouses", req)
    .then((data) => {
      apiData = data.data;
      dispatch({
        type: CHAIN_LEVEL_STORES,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};



export const fetchStockTransactionTypes = payload => dispatch =>{
  dispatch({
      type:INIT_LOADER,
  })
return axiosClient.get('/items/fetchStockTransactionTypes', payload)
      .then( function(res){
          if(!res?.data?.status) throw res;
          dispatch({
              type:HALT_LOADER,
          });
          
          return res.data;
      })
      .catch(function(err){
          dispatch({
              type:HALT_LOADER,
          });
          dispatch({
              type:ERROR_NOTIFIER,
              payload: err.message || "Faild To fetch StockTransactionTypes!"
          });
      })

}


export const getStockTransctionForApproval = payload => dispatch =>{
  dispatch({
      type:INIT_LOADER,
  })
return axiosClient.post('/items/getStockTransctionForApproval',payload)
      .then( function(res){
          if(!res?.data?.status) throw res;
          dispatch({
              type:HALT_LOADER,
          });
          
          return res.data;
      })
      .catch(function(err){
          dispatch({
              type:HALT_LOADER,
          });
          dispatch({
              type:ERROR_NOTIFIER,
              payload: err.message || "Failed To fetch Stock Approval Data!"
          });
      })

}
export const getStockTransctionForApprovalDetails = payload => dispatch =>{
  dispatch({
      type:INIT_LOADER,
  })
return axiosClient.post('/items/getStockTransctionForApprovalDetails',payload)
      .then( function(res){
          if(!res?.data?.status) throw res;
          dispatch({
              type:HALT_LOADER,
          });
          
          return res.data;
      })
      .catch(function(err){
          dispatch({
              type:HALT_LOADER,
          });
          dispatch({
              type:ERROR_NOTIFIER,
              payload: err.message || "Failed To fetch Stock Approval Data!"
          });
      })

}



export const updateStockTransctionApprovalDetails = payload => dispatch =>{
  dispatch({
      type:INIT_LOADER,
  })
return axiosClient.post('/items/updateStockTransctionApprovalDetails',payload)
      .then( function(res){
          if(!res?.data?.status) throw res;
          dispatch({
              type:HALT_LOADER,
          });
          // dispatch({
          //   type: SUCCESS_NOTIFIER,
          //   payload: res.data.message || "Stock Updated successfully",
          // });
          
          return res.data;
      })
      .catch(function(err){
          dispatch({
              type:HALT_LOADER,
          });
          dispatch({
              type:ERROR_NOTIFIER,
              payload: err.message || "Failed To save Stock Approval Data!"
          });
      })

}

export const getStockTransferListWeb = (req) => (dispatch) => {
  var apiData = "";
  const apiResponse = axiosClient
    .get("/items/getStockTransferListWeb", req)
    .then((data) => {
      apiData = data.data;
      return apiData;
    })
    .catch((data) => {
      
    });
  return apiResponse;
};
export const fetchGSTInvoiceOptions = payload => dispatch =>{
  dispatch({
    type:INIT_LOADER,
  })
return axiosClient.post('/items/fetchGSTInvoiceOptions',payload)
  .then( function(res){
    console.log(res,'kjhvdbashdas');
      if(!res?.data?.status) throw res;
      dispatch({
          type:HALT_LOADER,
      });
      return res.data;
  })
  .catch(function(err){
      dispatch({
          type:HALT_LOADER,
      });
      dispatch({
          type:ERROR_NOTIFIER,
          payload: err.message 
      });
  })
}

export const getEWayBills = payload => dispatch =>{
  dispatch({
    type:INIT_LOADER,
  })
return axiosClient.post('/items/fetchEwayBillReport',payload)
  .then( function(res){
      if(!res?.data?.status) throw res;
      dispatch({
          type:HALT_LOADER,
      });
      return res.data;
  })
  .catch(function(err){
      dispatch({
          type:HALT_LOADER,
      });
      dispatch({
          type:ERROR_NOTIFIER,
          payload: err.message || err.data.message
      });
  })
}

export const checkUnAssignedProducts = (req) => (dispatch) => {
  var apiData = "";
  dispatch({
    type:INIT_LOADER,
  })
  const apiResponse = axiosClient
    .post("/items/checkTransactionProductMapping", req)
    .then((data) => {
      dispatch({
        type:HALT_LOADER,
      })
      apiData = data.data;
      if(!apiData.status){
        dispatch({
          type: ERROR_NOTIFIER,
          payload: apiData.message,
        });
      }
      dispatch({
        type: CART_TRANSACTION,
        payload: apiData,
      });
      return apiData;
    })
    .catch((data) => {
      dispatch({
        type:HALT_LOADER,
      })
    });
  return apiResponse;
};

export const cancelIrn = (req) => (dispatch) => {
  var apiData = '';
  dispatch({
    type: INIT_LOADER,
  });

  const apiResponse = axiosClient
    .post('/items/cancelIrn', req)
    .then((data) => {
      apiData = data.data;
      dispatch({
        type: HALT_LOADER,
      });
      if(data.data.status){
        dispatch({
          type: SUCCESS_NOTIFIER,
          payload: data.data.message ||  <FormattedMessage children={el=>el} {...app.inventory.common.cancleIrnSuccess}/>,
        });
      }else if(!data.data.status){
        dispatch({
          type: ERROR_NOTIFIER,
          payload: data.data.message,
        });
      }
      return apiData;
    })
    .catch((data) => {
       dispatch({
         type: HALT_LOADER,
       });
    });
  return apiResponse;
};

export const cancelStockTransfer = (req) => (dispatch) => {
  var apiData = '';
  dispatch({
    type: INIT_LOADER,
  });

  const apiResponse = axiosClient
    .post('/items/cancelStockTransfer', req)
    .then((data) => {
      apiData = data.data;
      dispatch({
        type: HALT_LOADER,
      });
      if(data.data.status){
        dispatch({
          type: SUCCESS_NOTIFIER,
          payload: data.data.message ||  <FormattedMessage children={el=>el} {...app.inventory.common.cancleStockTransferSuccess}/>,
        });
      }else if(!data.data.status){
        dispatch({
          type: ERROR_NOTIFIER,
          payload: data.data.message,
        });
      }
      return apiData;
    })
    .catch((data) => {
       dispatch({
         type: HALT_LOADER,
       });
    });
  return apiResponse;
};


export const fetchStockTransactionPayload = request => async dispatch => {
  try{
    dispatch({type: INIT_LOADER});
    const response = await axiosClient.post('/inventory/fetchStockTransactionPayload', request);
    if(response.data.status){
      dispatch({type: HALT_LOADER});
      return response.data;
    }
    else throw response;
  }
  catch(err){
    dispatch({type: HALT_LOADER});
    return Boolean(err && err.response && err.response.data) ? err.response.data : err;
  }
}
export const getVendorMappedProductsList = payload => dispatch =>{
  dispatch({
      type:INIT_LOADER,
  })
return axiosClient.post('/items/getVendorMappedProductsList', payload)
      .then( function(res){
          if(!res?.data?.status) throw res;
          dispatch({
              type:HALT_LOADER,
          });
          
          return res.data;
      })
      .catch(function(err){
          dispatch({
              type:HALT_LOADER,
          });
          dispatch({
              type:ERROR_NOTIFIER,
              payload: err.message || "Faild To Get Products List!"
          });
      })

}

export const getVendorBill = payload => dispatch =>{
  dispatch({
      type:INIT_LOADER,
  })
return axiosClient.post('/items/getVendorBill', payload)
      .then( function(res){
          if(!res?.data?.status) throw res;
          dispatch({
              type:HALT_LOADER,
          });
          
          return res.data;
      })
      .catch(function(err){
          dispatch({
              type:HALT_LOADER,
          });
          dispatch({
              type:ERROR_NOTIFIER,
              payload: err.message || "Faild To Get Vendor Bill List!"
          });
      })

}

export const getVendorBillDetail = payload => dispatch =>{
  dispatch({
      type:INIT_LOADER,
  })
return axiosClient.post('/items/getVendorBillDetail', payload)
      .then( function(res){
          if(!res?.data?.status) throw res;
          dispatch({
              type:HALT_LOADER,
          });
          
          return res.data;
      })
      .catch(function(err){
          dispatch({
              type:HALT_LOADER,
          });
          dispatch({
              type:ERROR_NOTIFIER,
              payload: err.message || "Faild To Get Vendor Bill Detail!"
          });
      })

}

export const generateEwayBillByStockTransactionID = payload => dispatch =>{
  dispatch({
      type:INIT_LOADER,
  })
return axiosClient.post('/items/generateEwayBillByStockTransactionID', payload)
      .then( function(res){
          if(!res?.data?.status) throw res;
          dispatch({
              type:HALT_LOADER,
          });
          
          return res.data;
      })
      .catch(function(err){
          dispatch({
              type:HALT_LOADER,
          });
          dispatch({
              type:ERROR_NOTIFIER,
              payload: err.message || "Faild To Generated E-WAY Bill!"
          });
      })

}



